import { FC, HTMLAttributes } from 'react';
import cn from 'classnames';
import { BsCheck2, BsX } from 'react-icons/bs';

import styles from './styles.module.scss';

interface FuncGroup extends HTMLAttributes<HTMLDivElement> {
  status: boolean;
}

const TextWithTick: FC<FuncGroup> = ({
  className = '',
  children,
  status,
  ...props
}) => {
  return (
    <div
      className={cn([
        className,
        styles['kut-component-main'],
        status ? styles['active'] : styles['inactive'],
      ])}
      {...props}
    >
      <BsCheck2 className={cn([styles['kut-component-mark']])} />
      <BsX className={cn([styles['kut-component-mark']])} />
      {children}
    </div>
  );
};

export default TextWithTick;
