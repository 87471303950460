import { FC, HTMLAttributes } from 'react';
import { useLocation } from 'react-router-dom';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import cn from 'classnames';

import { useUrlTrack } from '../../services/hooks';
import { getServerImageUrl } from '../../utils/utils';

import styles from './styles.module.scss';

export interface MenuItemProps {
  icon: JSX.Element;
  title?: string;
  path?: string;
  unReadCount?: number;
  action?: () => Promise<void>;
}

interface ComProps extends HTMLAttributes<HTMLDivElement> {
  topMenuList: MenuItemProps[];
  bottomMenuList: MenuItemProps[];
  panelStatus: boolean;
  handleSidebarFold: () => void;
  handlePageTitle: (menuItem: MenuItemProps) => void;
}

const Sidebar: FC<ComProps> = ({
  className,
  topMenuList,
  bottomMenuList,
  panelStatus,
  handleSidebarFold,
  handlePageTitle,
}) => {
  const params = useLocation();
  const urlInfo = useUrlTrack();

  return (
    <div className={cn([styles[`sidebar-container`], className])}>
      <div className={styles[`top-sidebar-section`]}>
        <div className={styles[`logo-img`]}>
          <img
            alt={`logo-max`}
            src={getServerImageUrl(
              !panelStatus ? urlInfo.logo_min : urlInfo.logo,
            )}
            width={!panelStatus ? '47px' : '145px'}
            height={26}
          />
        </div>
        <div className={styles[`menu-list-wrapper`]}>
          {topMenuList.map((item, i) => {
            return (
              <div
                className={cn([
                  styles[`menu-list-item`],
                  params.pathname.indexOf(item.path || '') === 0
                    ? styles['active']
                    : '',
                ])}
                onClick={() => handlePageTitle(item)}
                key={i}
              >
                <>{item.icon}</>
                {panelStatus && (
                  <span className={styles['menu-list-item-text']}>
                    {item.title}
                  </span>
                )}
                {item.unReadCount && item.unReadCount > 0 ? (
                  <span className={styles[`unread-count-wrapper`]}>
                    {panelStatus && item.unReadCount}
                  </span>
                ) : (
                  <></>
                )}
              </div>
            );
          })}
        </div>
      </div>
      <div className={styles[`bottom-sidebar-section`]}>
        <div className={styles[`menu-list-wrapper`]}>
          {bottomMenuList.map((item, i) => {
            return (
              <div
                className={cn([
                  styles[`menu-list-item`],
                  params.pathname.indexOf(item.path || '') === 0
                    ? 'active'
                    : '',
                ])}
                onClick={() => handlePageTitle(item)}
                key={i}
              >
                <>{item.icon}</>
                {panelStatus && <>{item.title}</>}
                {item.unReadCount && item.unReadCount > 0 ? (
                  <span className={styles[`unread-count-wrapper`]}>
                    {panelStatus && item.unReadCount}
                  </span>
                ) : (
                  <></>
                )}
              </div>
            );
          })}
        </div>
      </div>
      <span
        className={styles[`sidebar-fold-button`]}
        onClick={() => handleSidebarFold()}
      >
        {!panelStatus ? <FaChevronRight /> : <FaChevronLeft />}
      </span>
    </div>
  );
};

export default Sidebar;
