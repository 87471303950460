import { FC } from 'react';
import { BsQuestionCircle, BsSearch } from 'react-icons/bs';
import { Link } from 'react-router-dom';

import { getStringByLanguage } from '../../utils/utils';

import imgGettingStarted from '../../assets/images/getting_started.png';
import imgDiscoverNews from '../../assets/images/discover_news.png';
import imgQNASection from '../../assets/images/qna_section.png';

import styles from './styles.module.scss';

const Help: FC = () => {
  return (
    <div className={`${styles['kut-component-body']}`}>
      <div className={`${styles['kut-component-header']}`}>
        <div>
          <BsQuestionCircle />
          <p className={`${styles['kut-component-header-title']}`}>
            {getStringByLanguage('WELCOME_HOW_CAN_WE_HELP_YOU')}
          </p>
          <div>
            <BsSearch />
            <input
              placeholder={getStringByLanguage('SEARCH_HERE_FOR_ANYTHING')}
            />
          </div>
          <p className={`${styles['kut-component-header-description']}`}>
            {getStringByLanguage('POPULAR_SEARCHES')}{' '}
            <Link to="#">{getStringByLanguage('COMMUNITY_TIPS')}</Link>,{' '}
            <Link to="#">{getStringByLanguage('ENERGY_CALCULATOR')}</Link>
          </p>
        </div>
      </div>
      <div className={`${styles['kut-component-content']}`}>
        <div>
          <div>
            <img
              src={imgGettingStarted}
              alt={getStringByLanguage('A_GUIDE_TO_GETTING_STARTED')}
            />
            <p className={`${styles['kut-content-item-title']}`}>
              {getStringByLanguage('A_GUIDE_TO_GETTING_STARTED')}
            </p>
            <p className={`${styles['kut-content-item-description']}`}>
              {getStringByLanguage(
                'DIVE_IN_THE_WORLD_OF_ENERGY_COMMUNITIES_AND_DISCOVER_NEW_WAYS_TO_SAVE_ON_YOUR_BILLS',
              )}
            </p>
            <a
              href="https://wesmart.notion.site/c2c29816241f4e8eb5aa4358973f5d69?v=e79299be8d5e498493f59e4682a32bd0"
              target="_blank"
            >
              {getStringByLanguage('READ_MORE')}
            </a>
          </div>
        </div>
        <div>
          <div>
            <img
              src={imgQNASection}
              alt={getStringByLanguage('VISIT_OUR_QNA_SECTION')}
            />
            <p className={`${styles['kut-content-item-title']}`}>
              {getStringByLanguage('VISIT_OUR_QNA_SECTION')}
            </p>
            <p className={`${styles['kut-content-item-description']}`}>
              {getStringByLanguage(
                'DIVE_IN_THE_WORLD_OF_ENERGY_COMMUNITIES_AND_DISCOVER_NEW_WAYS_TO_SAVE_ON_YOUR_BILLS',
              )}
            </p>
            <a
              href="https://wesmart.notion.site/c2c29816241f4e8eb5aa4358973f5d69?v=e79299be8d5e498493f59e4682a32bd0"
              target="_blank"
            >
              {getStringByLanguage('READ_MORE')}
            </a>
          </div>
        </div>
        <div>
          <div>
            <img
              src={imgDiscoverNews}
              alt={getStringByLanguage('DISCOVER_NEWS')}
            />
            <p className={`${styles['kut-content-item-title']}`}>
              {getStringByLanguage('DISCOVER_NEWS')}
            </p>
            <p className={`${styles['kut-content-item-description']}`}>
              {getStringByLanguage(
                'DIVE_IN_THE_WORLD_OF_ENERGY_COMMUNITIES_AND_DISCOVER_NEW_WAYS_TO_SAVE_ON_YOUR_BILLS',
              )}
            </p>
            <a href="https://hub.wesmart.com" target="_blank">
              {getStringByLanguage('DISCOVER_NOW')}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Help;
