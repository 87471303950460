import { FC, useEffect, useState } from 'react';
import { TableColumn } from 'react-data-table-component';
import * as DateFNS from 'date-fns';
import { useParams } from 'react-router-dom';
import cn from 'classnames';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { BsPlus, BsTrash2 } from 'react-icons/bs';
import Select from 'react-select';

import Table from '../../../components/Table/Table';
import SwitchView from '../../../components/SwitchView/SwitchView';
import Button, { BUTTON_TYPE } from '../../../components/Button/Button';
import SearchInput from '../../../components/SearchInput/SearchInput';
import { useLoadingBars } from '../../../components/LoadingBar/LoadingBar';
import ListView, { SortOrder } from '../../../components/ListView/ListView';
import Modal, { useModal } from '../../../components/Modal/Modal';
import InputForm from '../../../components/InputForm/InputForm';
import IOSSwitch from '../../../components/IOSSwitch/IOSSwitch';
import {
  streamTypes,
  fieldDict,
  filterOption,
  getAmount,
  sortFieldList,
  sortStringList,
  switchItems,
  streamTags,
} from './Streams.helper';

import { useDeviceType } from '../../../services/hooks';
import { colorDict, getStringByLanguage } from '../../../utils/utils';
import {
  StreamInfo,
  StreamTag,
  StreamType,
  addStream,
  deleteStreams,
  getStreamHits,
  updateStream,
} from '../../../apis/streams';

import styles from './styles.module.scss';

const Streams: FC = () => {
  const [showLoadingBar, hideLoadingBar] = useLoadingBars();
  const { isMobile } = useDeviceType();
  const { community_id, participant_id, device_id } = useParams();

  const [activeView, setActiveView] = useState(1);
  const [tableUrlParam, setTableUrlParam] = useState<{
    search: string;
  }>({
    search: '',
  });

  const [listUrlParam, setListUrlParam] = useState<{
    search: string;
    sort: SortOrder;
    sortField: keyof StreamInfo;
  }>({
    search: '',
    sort: SortOrder.ASC,
    sortField: '@timestamp',
  });

  const [selectedItems, setSelectedItems] = useState<{
    allSelected: boolean;
    selectedCount: number;
    selectedRows: StreamInfo[];
  }>({ allSelected: false, selectedCount: 0, selectedRows: [] });

  const [modalItem, setModalItem] = useState<StreamInfo>();
  const [modalDeleteItems, setModalDeleteItems] = useState<StreamInfo[]>([]);
  const [modalTitle, setModalTitle] = useState('');
  const [selectAll, setSelectAll] = useState(false);
  const [isEventFromAll, setIsEventFromAll] = useState(false);

  const init = async () => {};

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (isMobile) {
      setActiveView(0);
    }
  }, [isMobile, activeView]);

  const onViewChange = (index: number) => {
    setActiveView(index);
    setSelectedItems({
      allSelected: false,
      selectedCount: 0,
      selectedRows: [],
    });
    setSelectAll(false);
  };

  const updateRow = (stream: StreamInfo) => {
    setModalItem(stream);
    updateModal.show();
  };

  const deleteRow = (stream: StreamInfo) => {
    setModalTitle(
      `${getStringByLanguage('ARE_YOU_SURE_WANT_TO_DELETE')} <b>${
        stream._id
      }</b> ${getStringByLanguage('DOCUMENT').toLowerCase()}?`,
    );
    setModalDeleteItems([stream]);
    deleteModal.show();
  };

  const onDeleteSelected = () => {
    if (!selectedItems.selectedCount) {
      return;
    }
    setModalTitle(
      `${getStringByLanguage(
        'ARE_YOU_SURE_WANT_TO_DELETE',
      )} <b>${`${getStringByLanguage('SELECTED').toLowerCase()} ${
        selectedItems.selectedCount
      } ${getStringByLanguage('DEVICES').toLowerCase()}`}</b>?`,
    );
    setModalDeleteItems(selectedItems.selectedRows);
    deleteModal.show();
  };

  const onCreateNew = () => {
    createModal.show();
  };

  const onCreate = async (
    stream: Partial<Pick<StreamInfo, 'unit' | 'tag' | 'type'>> & {
      value?: number;
    },
  ) => {
    showLoadingBar();
    try {
      await addStream(community_id, participant_id, device_id, stream);
      toast.success(getStringByLanguage('SUCCESSFULLY_CREATED'));
      createModal.hide();
      setTableUrlParam({ ...tableUrlParam });
      setListUrlParam({ ...listUrlParam });
    } catch (error) {
      console.log(error);
    }
    hideLoadingBar();
  };

  const onUpdate = async ({ tag, type, value, unit }: Partial<StreamInfo>) => {
    showLoadingBar();
    try {
      const { _index, _id } = modalItem || {};
      if (!_index || !_id) {
        throw new Error(getStringByLanguage('REQUEST_ERROR'));
      }
      await updateStream(community_id, participant_id, device_id, {
        _index,
        _id,
        tag,
        type,
        value,
        unit,
      });
      toast.success(getStringByLanguage('SUCCESSFULLY_UPDATED'));
      updateModal.hide();
      setTableUrlParam({ ...tableUrlParam });
      setListUrlParam({ ...listUrlParam });
    } catch (error) {
      console.log(error);
    }
    hideLoadingBar();
  };

  const onDelete = async () => {
    showLoadingBar();

    try {
      await deleteStreams(
        community_id,
        participant_id,
        device_id,
        modalDeleteItems.map(({ _index, _id }) => ({ _index, _id })),
      );
      toast.success(getStringByLanguage('SUCCESSFULLY_DELETED'));
      setSelectedItems({
        allSelected: false,
        selectedCount: 0,
        selectedRows: [],
      });
      deleteModal.hide();
    } catch (error) {
      console.log(error);
    }
    setTableUrlParam({ ...tableUrlParam });
    setListUrlParam({ ...listUrlParam });
    hideLoadingBar();
  };

  const onItemSelected = (selected: {
    allSelected: boolean;
    selectedCount: number;
    selectedRows: StreamInfo[];
  }) => {
    setSelectedItems(selected);
  };

  const onSortChange = (sortField: keyof StreamInfo) => {
    const sort: SortOrder = listUrlParam.sort;
    if (listUrlParam.sortField === sortField) {
      setListUrlParam({
        ...listUrlParam,
        sort: sort === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC,
      });
    } else {
      setListUrlParam({
        ...listUrlParam,
        sortField,
        sort: SortOrder.ASC,
      });
    }
  };

  const columns: TableColumn<StreamInfo>[] = [
    {
      name: getStringByLanguage('TIME'),
      sortable: true,
      minWidth: '140px',
      selector: (row) =>
        DateFNS.format(new Date(row['@timestamp']), 'dd/MM/yyyy HH:mm:ss'),
    },
    {
      name: getStringByLanguage('TAG'),
      sortable: true,
      minWidth: '100px',
      selector: (row) => row.tag,
    },
    {
      name: getStringByLanguage('TYPE'),
      sortable: true,
      minWidth: '60px',
      selector: (row) => row.type,
    },
    {
      name: getStringByLanguage('AMOUNT'),
      sortable: true,
      minWidth: '70px',
      selector: (row) => getAmount(row.value),
    },
    {
      name: getStringByLanguage('UNIT'),
      sortable: true,
      minWidth: '50px',
      selector: (row) => row.unit,
    },
    {
      name: '',
      minWidth: '55px',
      maxWidth: '55px',
      cell: (data) => {
        return (
          <span
            className={styles['table-item-button-update']}
            onClick={() => data && updateRow(data)}
          >
            {getStringByLanguage('UPDATE')}
          </span>
        );
      },
    },
    {
      name: '',
      sortable: true,
      minWidth: '55px',
      maxWidth: '55px',
      cell: (data) => {
        return (
          <span
            className={styles['table-item-button-delete']}
            onClick={() => deleteRow(data)}
          >
            {getStringByLanguage('DELETE')}
          </span>
        );
      },
    },
  ];

  const createModal = useModal<
    Partial<Pick<StreamInfo, 'unit' | 'tag' | 'type'>> & {
      value?: number;
    }
  >({
    onSubmit: onCreate,
    initialValues: {
      type: StreamType.Total,
      tag: StreamTag.Consumption,
      value: 0,
      unit: 'kWh',
    },
    validationSchema: Yup.object().shape({
      type: Yup.string().required(
        getStringByLanguage('THIS_FIELD_IS_REQUIRED'),
      ),
      tag: Yup.string().required(getStringByLanguage('THIS_FIELD_IS_REQUIRED')),
      value: Yup.number().required('THIS_FIELD_IS_REQUIRED'),
      unit: Yup.string()
        .oneOf([getStringByLanguage('KWH')])
        .required(getStringByLanguage('THIS_FIELD_IS_REQUIRED')),
    }),
  });
  const updateModal = useModal<Partial<StreamInfo>>({
    onSubmit: onUpdate,
    initialValues: modalItem || {},
    validationSchema: Yup.object().shape({
      type: Yup.string().required(
        getStringByLanguage('THIS_FIELD_IS_REQUIRED'),
      ),
      tag: Yup.string().required(getStringByLanguage('THIS_FIELD_IS_REQUIRED')),
      value: Yup.lazy((v) => {
        if (typeof v === 'object') {
          return Yup.object({
            sum: Yup.number().required(),
          }).required();
        }
        return Yup.number().required();
      }),
      unit: Yup.string()
        .oneOf([getStringByLanguage('KWH')])
        .required(getStringByLanguage('THIS_FIELD_IS_REQUIRED')),
    }),
  });
  const deleteModal = useModal<{ forever?: boolean }>({
    onSubmit: onDelete,
    initialValues: {
      forever: false,
    },
    validationSchema: Yup.object().shape({
      forever: Yup.boolean().required(),
    }),
  });

  return (
    <div className={styles['kut-component-body']}>
      <div className={styles['kut-component-content']}>
        <div className={styles['kut-component-header']}>
          <div
            className={cn([
              styles['kut-component-sort'],
              activeView === 1 ? styles['hide'] : '',
            ])}
          >
            <span>{getStringByLanguage('SORT_BY')}</span>
            {sortFieldList.map((field, i) => (
              <span
                key={i}
                className={cn([
                  styles['kut-component-sort-option'],
                  listUrlParam.sortField === field ? styles['active'] : '',
                  styles[listUrlParam.sort],
                ])}
                onClick={() => onSortChange(field)}
              >
                {sortStringList[i]}
              </span>
            ))}
          </div>
          <div
            className={cn([
              styles['kut-component-select-all'],
              activeView === 1 ? styles['hide'] : '',
            ])}
          >
            <input
              type="checkbox"
              checked={selectAll}
              onChange={(event) => {
                const status = event.target.checked;
                setSelectAll(status);
                setIsEventFromAll(true);
              }}
              ref={(input) => {
                if (input) {
                  const status = selectAll;
                  if (!status && selectedItems.selectedCount) {
                    input.indeterminate = true;
                    return;
                  }
                  input.indeterminate = false;
                  status ? setSelectAll(true) : setSelectAll(false);
                }
              }}
            />
            <span>{getStringByLanguage('SELECT_ALL')}</span>
          </div>
          {!isMobile && (
            <SwitchView
              className={styles['kut-component-switch-view']}
              items={switchItems}
              selected={activeView}
              onViewChange={onViewChange}
            />
          )}
        </div>
        {activeView === 1 && (
          <div
            className={cn([
              styles['kut-component-table'],
              styles['kut-component-active'],
            ])}
          >
            <div className={styles['kut-component-actions']}>
              <Button
                className={cn([
                  styles['kut-component-buttons'],
                  styles['kut-buttons-large'],
                ])}
                outline={true}
                onClick={onCreateNew}
              >
                + {getStringByLanguage('CREATE_NEW')}
              </Button>
              <SearchInput
                className={styles['kut-component-search']}
                value={tableUrlParam.search}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setTableUrlParam({
                    search: event.target.value,
                  })
                }
              />
              <Button
                className={cn([
                  styles['kut-component-buttons'],
                  styles['kut-buttons-large'],
                  styles['kut-component-button-delete'],
                  selectedItems.selectedCount ? styles['kut-active'] : '',
                ])}
                type={BUTTON_TYPE.DANGER}
                onClick={onDeleteSelected}
              >
                {getStringByLanguage('DELETE_SELECTED')}
              </Button>
              <Button
                className={cn([
                  styles['kut-component-buttons'],
                  styles['kut-buttons-small'],
                ])}
                onClick={onCreateNew}
              >
                <BsPlus />
              </Button>
              <Button
                className={cn([
                  styles['kut-component-buttons'],
                  styles['kut-buttons-small'],
                  styles['kut-component-button-delete'],
                  selectedItems.selectedCount ? styles['kut-active'] : '',
                ])}
                type={BUTTON_TYPE.DANGER}
                onClick={onDeleteSelected}
              >
                <BsTrash2 />
              </Button>
            </div>
            <Table
              pagination={true}
              selectableRows
              onSelectedRowsChange={onItemSelected}
              urlParam={tableUrlParam}
              requestFunc={(options) =>
                getStreamHits({
                  ...options,
                  community_id,
                  participant_id,
                  device_id,
                })
              }
              fieldDict={fieldDict}
              columns={columns}
              paginationPerPage={10}
              paginationRowsPerPageOptions={[10, 20, 50]}
            />
          </div>
        )}
        {activeView === 0 && (
          <div
            className={cn([
              styles['kut-component-list-view'],
              styles['kut-component-active'],
            ])}
          >
            <div className={styles['kut-component-actions']}>
              <Button
                className={cn([
                  styles['kut-component-buttons'],
                  styles['kut-buttons-large'],
                ])}
                outline={true}
                onClick={onCreateNew}
              >
                + {getStringByLanguage('CREATE_NEW')}
              </Button>
              <SearchInput
                className={styles['kut-component-search']}
                value={listUrlParam.search}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setListUrlParam({
                    ...listUrlParam,
                    search: event.target.value,
                  })
                }
              />
              <Button
                className={cn([
                  styles['kut-component-buttons'],
                  styles['kut-buttons-large'],
                  styles['kut-component-button-delete'],
                  selectedItems.selectedCount ? styles['kut-active'] : '',
                ])}
                type={BUTTON_TYPE.DANGER}
                onClick={onDeleteSelected}
              >
                {getStringByLanguage('DELETE_SELECTED')}
              </Button>
              <Button
                className={cn([
                  styles['kut-component-buttons'],
                  styles['kut-buttons-small'],
                ])}
                onClick={onCreateNew}
              >
                <BsPlus />
              </Button>
              <Button
                className={cn([
                  styles['kut-component-buttons'],
                  styles['kut-buttons-small'],
                  styles['kut-component-button-delete'],
                  selectedItems.selectedCount ? styles['kut-active'] : '',
                ])}
                type={BUTTON_TYPE.DANGER}
                onClick={onDeleteSelected}
              >
                <BsTrash2 />
              </Button>
            </div>
            <ListView
              urlParam={listUrlParam}
              selectAll={selectAll}
              setSelectAll={setSelectAll}
              isEventFromAll={isEventFromAll}
              setIsEventFromAll={setIsEventFromAll}
              onItemSelected={onItemSelected}
              requestFunc={(options) =>
                getStreamHits({
                  ...options,
                  community_id,
                  participant_id,
                  device_id,
                })
              }
              ChildComponent={({
                className,
                onChange,
                checked,
                data,
              }: {
                className: string;
                onChange: (status: boolean) => void;
                checked: boolean;
                data: StreamInfo;
              }) => {
                return (
                  <div
                    className={cn([
                      className,
                      styles['kut-component-list-item'],
                    ])}
                  >
                    <div className={cn([styles['list-item-heading']])}>
                      <div className={cn([styles['list-item-name']])}>
                        <div>
                          <p>
                            <b>
                              {getAmount(data.value)}{' '}
                              {getStringByLanguage('KWH')}
                            </b>
                          </p>
                          <p className={cn([styles['list-item-created']])}>
                            {getStringByLanguage('CREATED_ON')}{' '}
                            <span>
                              {DateFNS.format(
                                new Date(data['@timestamp']),
                                'dd/MM/yyyy HH:mm:ss',
                              )}
                            </span>
                          </p>
                        </div>
                      </div>
                      <input
                        type="checkbox"
                        checked={checked}
                        onChange={(event) => onChange(event.target.checked)}
                      />
                    </div>
                    <div className={cn([styles['list-item-stream-content']])}>
                      <div className={cn([styles['list-item-stream-type']])}>
                        {data.type}
                      </div>
                      <div className={cn([styles['list-item-stream-tag']])}>
                        {data.tag}
                      </div>
                    </div>
                    <div className={cn([styles['list-item-actions']])}>
                      <div></div>
                      <div>
                        <span
                          className={cn([styles['list-item-button-update']])}
                          onClick={() => updateRow(data)}
                        >
                          {getStringByLanguage('UPDATE')}
                        </span>
                        <span
                          className={cn([styles['list-item-button-delete']])}
                          onClick={() => deleteRow(data)}
                        >
                          {getStringByLanguage('DELETE')}
                        </span>
                      </div>
                    </div>
                  </div>
                );
              }}
            />
          </div>
        )}
        <Modal {...createModal}>
          {(formik) => {
            return (
              <form
                className={cn([
                  styles['kut-modal-form'],
                  styles['kut-create-form'],
                ])}
              >
                <p className={cn([styles['kut-title']])}>
                  {getStringByLanguage(['CREATE', 'STREAM'])}
                </p>
                <hr />
                <InputForm
                  className={cn([
                    styles['kut-input-form'],
                    styles['kut-form-stream-value'],
                  ])}
                  heading={getStringByLanguage('VALUE')}
                  name={'value'}
                  onChange={formik.handleChange}
                  required
                  value={formik.values.value}
                  error={formik.touched.value && formik.errors.value}
                />
                <InputForm
                  className={cn([
                    styles['kut-form-stream-name'],
                    styles['kut-input-form'],
                  ])}
                  heading={getStringByLanguage('UNIT')}
                  name={'unit'}
                  onChange={formik.handleChange}
                  required
                  value={formik.values.unit}
                  error={formik.touched.unit && formik.errors.unit}
                />
                <div
                  className={cn([
                    styles['kut-input-form'],
                    styles['kut-form-stream-type'],
                  ])}
                >
                  <div>
                    <span className={cn([styles['input-form-title']])}>
                      {getStringByLanguage('TYPE')}
                    </span>
                    <Select
                      className={`${styles['input-form-content']}`}
                      options={streamTypes}
                      filterOption={filterOption}
                      name={`type`}
                      isSearchable
                      onChange={(v) => {
                        formik.setFieldValue('type', v?.value);
                      }}
                      value={streamTypes.find(
                        (value) => value.value === formik.values.type,
                      )}
                      maxMenuHeight={200}
                      styles={{
                        control: (baseStyle) => ({
                          ...baseStyle,
                          boxShadow: 'none',
                          border: 'none',
                          color: `rgb(var(--text-color))`,
                          padding: '0.5em 0.75em',
                          backgroundColor: 'rgb(var(--background-color))',
                          borderRadius: '10px',
                        }),
                        input: (baseStyle) => ({
                          ...baseStyle,
                          color: `rgb(var(--text-color))`,
                        }),
                        placeholder: (baseStyle) => ({
                          ...baseStyle,
                          color: `rgb(var(--text-color))`,
                        }),
                        singleValue: (baseStyle) => ({
                          ...baseStyle,
                          color: `rgb(var(--text-color))`,
                        }),
                        indicatorSeparator: (baseStyle) => ({
                          ...baseStyle,
                          width: 0,
                        }),
                        dropdownIndicator: (baseStyle) => ({
                          ...baseStyle,
                          padding: '0px',
                          marginRight: '0.5em',
                          color: colorDict['color-black-strong-light'],
                        }),
                        menu: (baseStyle) => ({
                          ...baseStyle,
                          backgroundColor: `rgb(var(--background-color))`,
                        }),
                        option: (baseStyle, state) => {
                          return {
                            ...baseStyle,
                            backgroundColor: state.isSelected
                              ? `rgba(var(--text-color), 1)`
                              : state.isFocused
                              ? `rgba(var(--text-color), .3)`
                              : 'inherit',
                            color: state.isSelected
                              ? `rgb(var(--content-background-color))`
                              : 'inherit',
                            cursor: 'pointer',
                            transition: 'all .3s',
                          };
                        },
                      }}
                    />
                  </div>
                </div>
                <div
                  className={cn([
                    styles['kut-input-form'],
                    styles['kut-form-stream-tag'],
                  ])}
                >
                  <div>
                    <span className={cn([styles['input-form-title']])}>
                      {getStringByLanguage('TAG')}
                    </span>
                    <Select
                      className={`${styles['input-form-content']}`}
                      options={streamTags}
                      filterOption={filterOption}
                      name={`tag`}
                      isSearchable
                      onChange={(v) => {
                        formik.setFieldValue('tag', v?.value);
                      }}
                      value={streamTags.find(
                        (value) => value.value === formik.values.tag,
                      )}
                      maxMenuHeight={200}
                      styles={{
                        control: (baseStyle) => ({
                          ...baseStyle,
                          boxShadow: 'none',
                          border: 'none',
                          color: `rgb(var(--text-color))`,
                          padding: '0.5em 0.75em',
                          backgroundColor: 'rgb(var(--background-color))',
                          borderRadius: '10px',
                        }),
                        input: (baseStyle) => ({
                          ...baseStyle,
                          color: `rgb(var(--text-color))`,
                        }),
                        placeholder: (baseStyle) => ({
                          ...baseStyle,
                          color: `rgb(var(--text-color))`,
                        }),
                        singleValue: (baseStyle) => ({
                          ...baseStyle,
                          color: `rgb(var(--text-color))`,
                        }),
                        indicatorSeparator: (baseStyle) => ({
                          ...baseStyle,
                          width: 0,
                        }),
                        dropdownIndicator: (baseStyle) => ({
                          ...baseStyle,
                          padding: '0px',
                          marginRight: '0.5em',
                          color: colorDict['color-black-strong-light'],
                        }),
                        menu: (baseStyle) => ({
                          ...baseStyle,
                          backgroundColor: `rgb(var(--background-color))`,
                        }),
                        option: (baseStyle, state) => {
                          return {
                            ...baseStyle,
                            backgroundColor: state.isSelected
                              ? `rgba(var(--text-color), 1)`
                              : state.isFocused
                              ? `rgba(var(--text-color), .3)`
                              : 'inherit',
                            color: state.isSelected
                              ? `rgb(var(--content-background-color))`
                              : 'inherit',
                            cursor: 'pointer',
                            transition: 'all .3s',
                          };
                        },
                      }}
                    />
                  </div>
                </div>
                <hr />
                <div className={cn([styles['kut-form-actions']])}>
                  <Button onClick={() => formik.handleSubmit()}>
                    {getStringByLanguage('CREATE')}
                  </Button>
                  <Button
                    type={BUTTON_TYPE.DANGER}
                    onClick={() => createModal.hide()}
                  >
                    {getStringByLanguage('CANCEL')}
                  </Button>
                </div>
              </form>
            );
          }}
        </Modal>
        <Modal {...updateModal}>
          {(formik) => {
            return (
              <form
                className={cn([
                  styles['kut-modal-form'],
                  styles['kut-update-form'],
                ])}
              >
                <p className={cn([styles['kut-title']])}>
                  {getStringByLanguage(['UPDATE', 'DOCUMENT'])}
                </p>
                <hr />
                <InputForm
                  className={cn([
                    styles['kut-input-form'],
                    styles['kut-form-stream-value'],
                  ])}
                  heading={getStringByLanguage('VALUE')}
                  name={'value'}
                  onChange={formik.handleChange}
                  required
                  value={formik.values.value}
                  error={formik.errors.value}
                />
                <InputForm
                  className={cn([
                    styles['kut-form-stream-name'],
                    styles['kut-input-form'],
                  ])}
                  heading={getStringByLanguage('UNIT')}
                  name={'unit'}
                  onChange={formik.handleChange}
                  required
                  value={formik.values.unit}
                  error={formik.errors.unit}
                />
                <div
                  className={cn([
                    styles['kut-input-form'],
                    styles['kut-form-stream-type'],
                  ])}
                >
                  <div
                    className={cn(['kut-tooltip-item'])}
                    data-tooltip-content={getStringByLanguage(
                      'THIS_FIELD_CANNOT_BE_UPDATED_TO_UPDATE_REMOVE_AND_RECREATE',
                    )}
                  >
                    <span className={cn([styles['input-form-title']])}>
                      {getStringByLanguage('TYPE')}
                    </span>
                    <Select
                      className={cn([styles['input-form-content']])}
                      options={streamTypes}
                      filterOption={filterOption}
                      name={`type`}
                      isDisabled
                      onChange={(v) => {
                        formik.setFieldValue('type', v?.value);
                      }}
                      value={streamTypes.find(
                        (value) => value.value === formik.values.type,
                      )}
                      maxMenuHeight={200}
                      styles={{
                        control: (baseStyle) => ({
                          ...baseStyle,
                          boxShadow: 'none',
                          border: 'none',
                          color: `rgb(var(--text-color))`,
                          padding: '0.5em 0.75em',
                          backgroundColor: 'rgb(var(--background-color))',
                          borderRadius: '10px',
                        }),
                        input: (baseStyle) => ({
                          ...baseStyle,
                          color: `rgb(var(--text-color))`,
                        }),
                        placeholder: (baseStyle) => ({
                          ...baseStyle,
                          color: `rgb(var(--text-color))`,
                        }),
                        singleValue: (baseStyle) => ({
                          ...baseStyle,
                          color: `rgb(var(--text-color))`,
                        }),
                        indicatorSeparator: (baseStyle) => ({
                          ...baseStyle,
                          width: 0,
                        }),
                        dropdownIndicator: (baseStyle) => ({
                          ...baseStyle,
                          padding: '0px',
                          marginRight: '0.5em',
                          color: colorDict['color-black-strong-light'],
                        }),
                        menu: (baseStyle) => ({
                          ...baseStyle,
                          backgroundColor: `rgb(var(--background-color))`,
                        }),
                        option: (baseStyle, state) => {
                          return {
                            ...baseStyle,
                            backgroundColor: state.isSelected
                              ? `rgba(var(--text-color), 1)`
                              : state.isFocused
                              ? `rgba(var(--text-color), .3)`
                              : 'inherit',
                            color: state.isSelected
                              ? `rgb(var(--content-background-color))`
                              : 'inherit',
                            cursor: 'pointer',
                            transition: 'all .3s',
                          };
                        },
                      }}
                    />
                  </div>
                </div>
                <div
                  className={cn([
                    styles['kut-input-form'],
                    styles['kut-form-stream-tag'],
                  ])}
                >
                  <div
                    className={cn(['kut-tooltip-item'])}
                    data-tooltip-content={getStringByLanguage(
                      'THIS_FIELD_CANNOT_BE_UPDATED_TO_UPDATE_REMOVE_AND_RECREATE',
                    )}
                  >
                    <span className={cn([styles['input-form-title']])}>
                      {getStringByLanguage('TAG')}
                    </span>
                    <Select
                      className={cn([styles['input-form-content']])}
                      options={streamTags}
                      filterOption={filterOption}
                      name={`tag`}
                      isDisabled
                      onChange={(v) => {
                        formik.setFieldValue('tag', v?.value);
                      }}
                      value={streamTags.find(
                        (value) => value.value === formik.values.tag,
                      )}
                      maxMenuHeight={200}
                      styles={{
                        control: (baseStyle) => ({
                          ...baseStyle,
                          boxShadow: 'none',
                          border: 'none',
                          color: `rgb(var(--text-color))`,
                          padding: '0.5em 0.75em',
                          backgroundColor: 'rgb(var(--background-color))',
                          borderRadius: '10px',
                        }),
                        input: (baseStyle) => ({
                          ...baseStyle,
                          color: `rgb(var(--text-color))`,
                        }),
                        placeholder: (baseStyle) => ({
                          ...baseStyle,
                          color: `rgb(var(--text-color))`,
                        }),
                        singleValue: (baseStyle) => ({
                          ...baseStyle,
                          color: `rgb(var(--text-color))`,
                        }),
                        indicatorSeparator: (baseStyle) => ({
                          ...baseStyle,
                          width: 0,
                        }),
                        dropdownIndicator: (baseStyle) => ({
                          ...baseStyle,
                          padding: '0px',
                          marginRight: '0.5em',
                          color: colorDict['color-black-strong-light'],
                        }),
                        menu: (baseStyle) => ({
                          ...baseStyle,
                          backgroundColor: `rgb(var(--background-color))`,
                        }),
                        option: (baseStyle, state) => {
                          return {
                            ...baseStyle,
                            backgroundColor: state.isSelected
                              ? `rgba(var(--text-color), 1)`
                              : state.isFocused
                              ? `rgba(var(--text-color), .3)`
                              : 'inherit',
                            color: state.isSelected
                              ? `rgb(var(--content-background-color))`
                              : 'inherit',
                            cursor: 'pointer',
                            transition: 'all .3s',
                          };
                        },
                      }}
                    />
                  </div>
                </div>
                <hr />
                <div className={cn([styles['kut-form-actions']])}>
                  <Button onClick={() => formik.handleSubmit()}>
                    {getStringByLanguage('SAVE_UPDATES')}
                  </Button>
                  <Button
                    type={BUTTON_TYPE.DANGER}
                    onClick={() => updateModal.hide()}
                  >
                    {getStringByLanguage('CANCEL')}
                  </Button>
                </div>
              </form>
            );
          }}
        </Modal>
        <Modal {...deleteModal}>
          {(formik) => (
            <form
              className={cn([
                styles['kut-modal-form'],
                styles['kut-delete-form'],
              ])}
            >
              <p dangerouslySetInnerHTML={{ __html: modalTitle }} />
              <hr />
              <div className={cn([styles['kut-form-delete-forever']])}>
                <span
                  style={{
                    color: `rgb(var(--text-color))`,
                  }}
                >
                  {getStringByLanguage('DELETE_FOREVER')}?
                </span>
                <IOSSwitch
                  name={'forever'}
                  checked={formik.values.forever}
                  onChange={formik.handleChange}
                />
              </div>
              <p
                className={cn([styles['kut-form-delete-forever-description']])}
              >
                {getStringByLanguage('DELETE_FOREVER_DESCRIPTION')}
              </p>
              <hr />
              <div className={cn([styles['kut-form-actions']])}>
                <Button onClick={() => formik.handleSubmit()}>
                  {getStringByLanguage('DELETE')}
                </Button>
                <Button
                  type={BUTTON_TYPE.DANGER}
                  onClick={() => deleteModal.hide()}
                >
                  {getStringByLanguage('CANCEL')}
                </Button>
              </div>
            </form>
          )}
        </Modal>
      </div>
    </div>
  );
};

export default Streams;
