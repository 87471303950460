import { TableColumn } from 'react-data-table-component';
import { BsArrowDownCircle } from 'react-icons/bs';
import { TbFileInvoice } from 'react-icons/tb';
import * as DateFNS from 'date-fns';
import cn from 'classnames';

import StatusSpan, { STATUS } from '../../components/StatusSpan/StatusSpan';

import { DocumentInfo, DOCUMENT_TYPE } from '../../apis/documents';
import {
  getDocumentUrl,
  getStringByLanguage,
  isNewDocument,
} from '../../utils/utils';

import styles from './styles.module.scss';
import ReactDOMServer from 'react-dom/server';

export enum DocumentType {
  Contract,
  Invoice,
}

export const defaultColumns: TableColumn<DocumentInfo>[] = [
  {
    name: getStringByLanguage('DOCUMENT_NAME'),
    sortable: true,
    minWidth: '100px',
    cell: (data: DocumentInfo) => {
      return (
        <div
          className={cn([
            styles['kut-list-item-heading'],
            isNewDocument(data.document_create_on)
              ? styles['kut-new-data']
              : '',
          ])}
        >
          <div
            className={cn(['kut-tooltip-item', styles['kut-list-item-name']])}
            data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
              <div className={styles['table-tooltip-item']}>
                {data.document_name}
              </div>,
            )}
          >
            <TbFileInvoice /> <span>{data.document_name}</span>
          </div>
        </div>
      );
    },
  },
  {
    name: getStringByLanguage('DOCUMENT_ID'),
    sortable: true,
    minWidth: '70px',
    center: true,
    cell: (data) => {
      return (
        <span
          className={
            (styles['kut-list-item-document-id'], cn(['kut-tooltip-item']))
          }
          data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
            <div className={styles['table-tooltip-item']}>
              {data.document_id}
            </div>,
          )}
        >
          {data.document_id}
        </span>
      );
    },
  },
  {
    name: getStringByLanguage('CREATED_ON'),
    sortable: true,
    minWidth: '70px',
    center: true,
    selector: (row) =>
      DateFNS.format(new Date(row.document_create_on), 'dd/MM/yyyy'),
  },
];

export interface SelectOptionsProps<T> {
  value: T;
  label: string | number | React.ReactElement;
}

export const documentTypes: SelectOptionsProps<DOCUMENT_TYPE>[] = [
  {
    value: DOCUMENT_TYPE.Contract,
    label: DOCUMENT_TYPE.Contract,
  },
  {
    value: DOCUMENT_TYPE.Invoice,
    label: DOCUMENT_TYPE.Invoice,
  },
];

export const contractColumns: TableColumn<DocumentInfo>[] =
  defaultColumns.concat([
    {
      name: getStringByLanguage('CONTRACT_STATUS'),
      sortable: true,
      minWidth: '70px',
      center: true,
      cell: (data) => {
        const status = data.document_status ? STATUS.PRIMARY : STATUS.DANGER;
        const text = data.document_status
          ? getStringByLanguage('SIGNED')
          : getStringByLanguage('NOT_SIGNED_YET');
        return <StatusSpan {...{ status, text }} />;
      },
    },
    {
      name: getStringByLanguage('ACTIONS'),
      sortable: true,
      minWidth: '70px',
      center: true,
      cell: (data) => {
        return (
          <a
            target={`_blank`}
            href={getDocumentUrl(data)}
            className={cn([styles['kut-list-item-actions']])}
          >
            <BsArrowDownCircle /> {getStringByLanguage('SAVE_TO_MY_COMPUTER')}
          </a>
        );
      },
    },
  ]);

export const invoiceColumns: TableColumn<DocumentInfo>[] =
  defaultColumns.concat([
    {
      name: getStringByLanguage('CONTRACT_STATUS'),
      sortable: true,
      minWidth: '70px',
      center: true,
      cell: (data) => {
        const status = data.document_status ? STATUS.SUCCESS : STATUS.DANGER;
        const text = data.document_status
          ? getStringByLanguage('PAID')
          : getStringByLanguage('NOT_PAID_YET');
        return <StatusSpan {...{ status, text }} />;
      },
    },
    {
      name: getStringByLanguage('ACTIONS'),
      sortable: true,
      minWidth: '70px',
      center: true,
      cell: (data) => {
        return (
          <a
            target={`_blank`}
            href={getDocumentUrl(data)}
            className={cn([styles['kut-list-item-actions']])}
          >
            <BsArrowDownCircle /> {getStringByLanguage('DOWNLOAD_INVOICE')}
          </a>
        );
      },
    },
  ]);

export const filterOption = (
  candidate: {
    value: string;
    label: string;
  },
  input: string,
) => {
  if (input) {
    return candidate.label.toLowerCase().indexOf(input.toLowerCase()) !== -1;
  }
  return true;
};
