import { Chart as ChartJS, BarController } from 'chart.js';
import { FC } from 'react';
import { Chart } from 'react-chartjs-2';
import { ChartProps } from 'react-chartjs-2/dist/types';
import * as DateFNS from 'date-fns';

import { ChartDataObject } from '../../pages/Community/Community.helper';

import { colorDict } from '../../utils/utils';

class BarWithTimeline extends BarController {
  draw() {
    // Call bubble controller method to draw all the points
    super.draw();

    // const canvas = this.chart.canvas;
    // const styles = window.getComputedStyle(canvas);
    // const paddingTop = parseFloat(styles.paddingTop);
    // const paddingBottom = parseFloat(styles.paddingBottom);
    // const borderTop = parseFloat(styles.borderTopWidth);
    // const borderBottom = parseFloat(styles.borderBottomWidth);
    // const drawableHeight =
    //   canvas.clientHeight -
    //   paddingTop -
    //   paddingBottom -
    //   borderTop -
    //   borderBottom;
    // console.log('Drawable Region Height:', drawableHeight);
    // Now we can do some custom drawing for this dataset. Here we'll draw a red box around the first point in each dataset
    const meta = this.getMeta();

    if (!meta.data.length) {
      return;
    }
    const pt0 = meta.data[0];
    const { y: y0 } = pt0;

    const { height } = meta.data[0].getProps(['base']);
    const chartInfo = this.chart;
    const labelInfo = chartInfo.scales.x.getLabelItems();
    const startPoint = labelInfo[0].options.translation?.[0] || 0;
    const endPoint = labelInfo[24].options.translation?.[0] || 0;

    if (height) {
      return;
    }

    const dis = (endPoint - startPoint) / (60 * 60 * 24);

    const date = new Date();
    const hour = date.getHours();
    const min = date.getMinutes();
    const sec = date.getSeconds();
    const now = hour * 3600 + min * 60 + sec;

    const x = startPoint + dis * now;
    const y = y0;

    const tHeight = 30;
    const tTop = 70;
    const top = tTop + tHeight;

    const radius = 2.2;

    const ctx = this.chart.ctx;
    ctx.save();
    ctx.fillStyle = colorDict['color-danger'];
    ctx.textAlign = 'center';
    ctx.fillRect(x, top, 1, y);
    ctx.beginPath();
    ctx.arc(x, top, radius, 0, 2 * Math.PI, false);
    // ctx.arc(x, y - radius, radius, 0, 2 * Math.PI, false);
    ctx.fill();
    ctx.closePath();
    ctx.fillText(DateFNS.format(date, 'HH:mm'), x, top - 10);
    ctx.restore();
  }
}

BarWithTimeline.id = 'BarWithTimeline';
BarWithTimeline.defaults = BarController.defaults;

ChartJS.register(BarWithTimeline);

export const BarTimeline: FC<
  Omit<ChartProps<'bar', ChartDataObject[]>, 'type'>
> = ({ ...props }) => {
  return <Chart type={'BarWithTimeline' as 'bar'} {...props} />;
};
