import { _global } from '../config/config';
import requestAPI from '../utils/requestAPI';
import { getStringByLanguage, isParticipantView } from '../utils/utils';
import { UserRole } from './users';
import { PaginationProps, prepareRequestParams, ResponseProps } from './utils';

export enum DOCUMENT_TYPE {
  Contract = 'Contract',
  Invoice = 'Invoice',
}

export interface DocumentInfo {
  id?: number;
  document_url: string;
  document_name: string;
  document_id: string;
  document_create_on: string;
  document_status: boolean;
  document_type: DOCUMENT_TYPE;
  participant_id: number;
}

export interface DocumentSubmit extends DocumentInfo {
  documents: File[];
}

export const getNewDocumentsCount = async (data?: {
  community_id?: number | null;
  participant_id?: number;
}) => {
  let { community_id, participant_id } = data || {};
  let url = '';

  switch (_global.user?.role) {
    case UserRole.Community_Manager:
      community_id = community_id || _global.user?.participant?.community_id;
      if (!participant_id && !isParticipantView()) {
        url += `community/${community_id}/document/count`;
        break;
      }
      participant_id = participant_id || _global.user?.participant?.id;
      url += `community/${community_id}/participant/${participant_id}/document/count`;
      break;
    case UserRole.Participant:
      community_id = _global.user?.participant?.community_id;
      participant_id = participant_id || _global.user?.participant?.id;
      url += `community/${community_id}/participant/${participant_id}/document/count`;
      break;
    default:
      throw new Error(getStringByLanguage('REQUEST_ERROR'));
  }

  return await requestAPI.get<ResponseProps<number>>(url);
};

export const getDocuments = async (
  data?: {
    community_id?: number | string | null;
    participant_id?: number;
  } & PaginationProps<DocumentInfo>,
) => {
  let { community_id, participant_id } = data || {};
  let url = '';

  switch (_global.user?.role) {
    case UserRole.Super_Admin:
      url += `community/${community_id}/participant/${participant_id}/document`;
      break;
    case UserRole.Sub_Admin:
      url += `community/${community_id}/participant/${participant_id}/document`;
      break;
    case UserRole.Community_Manager:
      community_id = community_id || _global.user?.participant?.community_id;
      if (!participant_id && !isParticipantView()) {
        url += `community/${community_id}/document`;
        break;
      }
      participant_id = participant_id || _global.user?.participant?.id;
      url += `community/${community_id}/participant/${participant_id}/document`;
      break;
    case UserRole.Participant:
      community_id = community_id || _global.user?.participant?.community_id;
      participant_id = participant_id || _global.user?.participant?.id;
      url += `community/${community_id}/participant/${participant_id}/document`;
      break;
  }

  const { search, page, perPage, sort, sortField } = data || {};

  url += prepareRequestParams({
    search,
    page,
    perPage,
    sort,
    sortField,
  });

  return await requestAPI.get<ResponseProps<[DocumentInfo[], number]>>(url);
};

export const addDocument = async ({
  community_id,
  participant_id,
  data,
}: {
  community_id: string | number | null | undefined;
  participant_id: string | number | null | undefined;
  data: Partial<DocumentSubmit>;
}) => {
  const formData = new FormData();
  const keys = Object.keys(data);
  const values = Object.values(data);
  keys.forEach((key, index) => {
    const value = values[index];
    if (value === null) {
      return;
    }
    if (typeof value === 'string' && value.length === 0) {
      return;
    }
    if (Array.isArray(value)) {
      value.forEach((val) => {
        formData.append(key, val);
      });
      return;
    }
    formData.append(key, `${value}`);
  });
  return (await requestAPI.post)<ResponseProps<DocumentInfo>>(
    `/community/${community_id}/participant/${participant_id}/document`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );
};

export const updateDocument = async ({
  community_id,
  participant_id,
  data,
}: {
  community_id: string | number | null | undefined;
  participant_id: string | number | null | undefined;
  data: Partial<DocumentSubmit>;
}) => {
  const formData = new FormData();
  const keys = Object.keys(data);
  const values = Object.values(data);
  keys.forEach((key, index) => {
    const value = values[index];
    if (value === null) {
      return;
    }
    if (typeof value === 'string' && value.length === 0) {
      return;
    }
    if (Array.isArray(value)) {
      value.forEach((val) => {
        formData.append(key, val);
      });
      return;
    }
    formData.append(key, `${value}`);
  });

  return (await requestAPI.patch)<ResponseProps<DocumentInfo>>(
    `/community/${community_id}/participant/${participant_id}/document/${data.id}`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );
};

export const deleteDocument = async (
  community_id: string | number | null | undefined,
  participant_id: string | number | null | undefined,
  id: number,
  forever = false,
) => {
  return (await requestAPI.delete)<ResponseProps<DocumentInfo>>(
    `/community/${community_id}/participant/${participant_id}/document/${id}?safeMode=${!forever}`,
  );
};
