import { FC, HTMLAttributes, useEffect, useRef, useState } from 'react';
import { Chart, TooltipLabelStyle, TooltipModel } from 'chart.js';
import cn from 'classnames';

import { ParticipantDictionaryById } from '../../apis/participants';

import styles from './styles.module.scss';
import { getStringByLanguage } from '../../utils/utils';

export interface ChartTooltipMemberProps {
  id: string;
  value: number;
}

const TitleElement = ({ content }: { content: string }) => (
  <div className={cn([styles['kut-chart-tooltip-title']])}>{content}</div>
);

const ContentElement = ({
  dict,
  members,
  content,
  color: { backgroundColor, borderColor },
  onlyPositive,
}: {
  dict: ParticipantDictionaryById;
  members: ChartTooltipMemberProps[];
  content: string;
  color: TooltipLabelStyle;
  onlyPositive: boolean;
}) => {
  if (onlyPositive) {
    const contents = content.split(':');
    if (contents[1][1] === '-') {
      content = contents[0] + ': ' + contents[1].slice(2);
    }
  }
  return (
    <div className={cn([styles['kut-chart-tooltip-content']])}>
      <div>
        <div
          className={cn([styles['kut-chart-tooltip-icon']])}
          style={{
            backgroundColor: `${backgroundColor}`,
            borderColor: `${borderColor}`,
          }}
        />
        <div className={cn([styles['kut-chart-tooltip-texts']])}>
          <span>{content}</span>
        </div>
      </div>
      {Object.keys(dict).length > 0 &&
        members.map(
          ({ id, value }, i) =>
            value > 0 && (
              <div key={i}>
                <div
                  className={cn([styles['kut-chart-tooltip-icon']])}
                  style={{ opacity: 0 }}
                />
                <div className={cn([styles['kut-chart-tooltip-texts']])}>
                  <span key={i}>
                    {(dict[id]?.participant_name ||
                      getStringByLanguage('UNKNOWN')) +
                      (dict[id]?.deleted_at
                        ? ` (${getStringByLanguage('REMOVED')})`
                        : '') +
                      ': ' +
                      value}
                  </span>
                </div>
              </div>
            ),
        )}
    </div>
  );
};

interface FuncGroup extends HTMLAttributes<HTMLDivElement> {
  dict: ParticipantDictionaryById;
  context: {
    chart: Chart;
    tooltip: TooltipModel<'bar'>;
  };
  onlyPositive?: boolean;
}

const ChartTooltip: FC<FuncGroup> = ({
  className = '',
  dict,
  context,
  onlyPositive = false,
}) => {
  const {
    chart,
    tooltip: {
      dataPoints,
      title,
      body,
      opacity,
      labelColors,
      caretX,
      caretY,
      options,
    },
  } = context;
  const containerRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(0);
  const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;
  const left = positionX + caretX;
  const top = positionY + caretY;
  const padding = options.padding + 'px';

  useEffect(() => {
    if (!containerRef || !containerRef.current) {
      return;
    }
    setWidth(containerRef.current.clientWidth);
  }, [containerRef]);

  return (
    <div
      className={cn([
        className,
        styles['kut-chart-tooltip-component'],
        left < width ? styles['arrow-right'] : styles['arrow-left'],
      ])}
      ref={containerRef}
      style={{ opacity, left, top, padding }}
    >
      <div className={cn([styles['kut-chart-tooltip-arrow']])} />
      <div className={cn([styles['kut-chart-tooltip-inner']])}>
        {title.map((content, i) => (
          <TitleElement key={i} content={content} />
        ))}
        {body
          .map(({ lines }) => lines.join(''))
          .map((content, i) => (
            <ContentElement
              key={i}
              members={
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (dataPoints[i].dataset.data[dataPoints[i].dataIndex] as any)
                  .members
              }
              dict={dict}
              content={content}
              color={labelColors[i]}
              onlyPositive={onlyPositive}
            />
          ))}
      </div>
    </div>
  );
};

export default ChartTooltip;
