import { FC } from 'react';
import { Link } from 'react-router-dom';

import { getServerImageUrl, getStringByLanguage } from '../../../utils/utils';
import { useUrlTrack } from '../../../services/hooks';

import worldImage from '../../../assets/images/world.svg';

import './styles.scss';

const IntroRegister: FC = () => {
  const urlInfo = useUrlTrack();

  const onLogin = () => {};

  return (
    <>
      <div className="kut-intro-register-body">
        <div className="kut-intro-register kut-device-desktop">
          <div className="kut-intro-register-form">
            <div className="kut-intro-register-logo">
              <img alt="WeSmart logo" src={getServerImageUrl(urlInfo.logo)} />
            </div>
            <div className="kut-intro-register-vector">
              <img alt="vector" src={worldImage} />
            </div>
            <p className="kut-intro-register-title">
              {getStringByLanguage(
                'MANAGE_AND_MONITOR_YOUR_ENERGY_COMMUNITY_IN_REAL_TIME',
              )}
            </p>
            <p className="kut-intro-register-description">
              {getStringByLanguage(
                'BECOME_PART_OF_AN_ENERGY_COMMUNITY_AND_START_SHARING_ENERGY_TODAY',
              )}
            </p>
            <span className="kut-intro-register-button" onClick={onLogin}>
              {getStringByLanguage('REGISTER_TODAY')}
            </span>
            <p className="kut-intro-register-text">
              {getStringByLanguage('OR')}
              <span>
                <Link to="/signin">{getStringByLanguage('LOGIN')}</Link>
              </span>{' '}
              {getStringByLanguage('IF_YOU_ARE_ALREADY_REGISTERED')}
            </p>
          </div>
          <div className="kut-intro-register-footer">
            {getStringByLanguage(
              'DEVELOPED_BY_WESMART_ALL_RIGHTS_RESERVED_2022',
            )}
          </div>
        </div>
        <div className="kut-intro-register kut-device-mobile">
          <div className="kut-intro-register-logo">
            <img alt="WeSmart logo" src={getServerImageUrl(urlInfo.logo)} />
          </div>
          <div className="kut-intro-register-form">
            <div className="kut-intro-register-vector">
              <img alt="vector" src={worldImage} />
            </div>
            <p className="kut-intro-register-title">
              {getStringByLanguage(
                'MANAGE_AND_MONITOR_YOUR_ENERGY_COMMUNITY_IN_REAL_TIME',
              )}
            </p>
            <p className="kut-intro-register-description">
              {getStringByLanguage(
                'BECOME_PART_OF_AN_ENERGY_COMMUNITY_AND_START_SHARING_ENERGY_TODAY',
              )}
            </p>
          </div>
          <span className="kut-intro-register-button" onClick={onLogin}>
            {getStringByLanguage('REGISTER_TODAY')}
          </span>
          <p className="kut-intro-register-text">
            {getStringByLanguage('OR')}
            <span>
              <Link to="/signin">{getStringByLanguage('LOGIN')}</Link>
            </span>{' '}
            {getStringByLanguage('IF_YOU_ARE_ALREADY_REGISTERED')}
          </p>
          <div className="kut-intro-register-footer">
            {getStringByLanguage(
              'DEVELOPED_BY_WESMART_ALL_RIGHTS_RESERVED_2022',
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default IntroRegister;
