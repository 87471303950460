import { FC } from 'react';
import cn from 'classnames';

import { colorDict } from '../../utils/utils';

import styles from './styles.module.scss';

export enum ImageColors {
  DEFAULT = 'color-default',
  DISABLED = 'color-default-little-light',
  PRIMARY = 'color-primary',
  PRIMARY_LIGHT = 'color-primary-little-light',
  SECONDARY = 'color-secondary',
  SECONDARY_LIGHT = 'color-secondary-light',
  SUCCESS = 'color-success',
  SUCCESS_LIGHT = 'color-success-light',
  WARNING = 'color-warning',
  WARNING_LIGHT = 'color-warning-light',
  DANGER = 'color-danger',
  DANGER_LIGHT = 'color-danger-light',
  INFO = 'color-info',
  INFO_LIGHT = 'color-info-light',
  TRANSPARENT = 'color-transparent',
  WHITE = 'color-white',
}

interface FuncGroup {
  className?: string;
  width?: number | string;
  height?: number | string;
  backWidth?: number | string;
  backHeight?: number | string;
  uri: string;
  color?: ImageColors;
  backColor?: ImageColors;
  status: boolean;
}
const SVGFromServer: FC<FuncGroup> = ({
  className = '',
  width = 45,
  height = 45,
  backWidth,
  backHeight,
  uri,
  color = ImageColors.PRIMARY,
  backColor = ImageColors.TRANSPARENT,
  status,
  ...props
}) => {
  const mask = `url('${uri}')`;

  return (
    <div
      className={cn([className, styles['kut-svg-from-server']])}
      style={{
        width: backWidth || width,
        height: backHeight || height,
        backgroundColor: colorDict[backColor],
      }}
      {...props}
    >
      <div
        className={cn([styles['kut-svg-image']])}
        style={{
          width: width,
          height: height,
          backgroundColor: colorDict[color],
          opacity: status ? 1 : 0.3,
          maskImage: mask,
          maskPosition: 'center',
          maskRepeat: 'no-repeat',
          maskSize: 'contain',
          WebkitMaskImage: mask,
          WebkitMaskPosition: 'center',
          WebkitMaskRepeat: 'no-repeat',
          WebkitMaskSize: 'contain',
        }}
      />
    </div>
  );
};

export default SVGFromServer;
