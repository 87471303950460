import { FC, useRef } from 'react';
import cn from 'classnames';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import ReCAPTCHA from 'react-google-recaptcha';

import { useLoadingBars } from '../../components/LoadingBar/LoadingBar';

import { API_RECAPTCHA_KEY } from '../../config/config';
import { getStringByLanguage } from '../../utils/utils';
import { FeedbackTypes, sendFeedback } from '../../apis/users';
import { useAppSelector } from '../../services/hooks';
import { getViewMode } from '../../services/reducers/sharedReducer';

import styles from './styles.module.scss';

const Feedback: FC = () => {
  const [showLoadingBar, hideLoadingBar] = useLoadingBars();
  const maxContentLength = 1000;
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const mode = useAppSelector(getViewMode);

  const formik = useFormik({
    initialValues: {
      type: FeedbackTypes.Bug,
      description: '',
      recaptcha: '',
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      type: Yup.string()
        .oneOf([FeedbackTypes.Bug, FeedbackTypes.Comment, FeedbackTypes.Other])
        .required(getStringByLanguage('THIS_FIELD_IS_REQUIRED')),
      description: Yup.string()
        .max(maxContentLength)
        .required(getStringByLanguage('THIS_FIELD_IS_REQUIRED')),
      recaptcha: Yup.string().required('reCAPTCHA is required'),
    }),
    onSubmit: async (values) => {
      showLoadingBar();
      try {
        await sendFeedback(values);
        formik.setFieldTouched('description', false);
        formik.setFieldTouched('recaptcha', false);
        formik.setFieldValue('description', '');
        formik.setFieldValue('recaptcha', '');
        recaptchaRef.current?.reset();
        toast.success(getStringByLanguage('EMAIL_SENT'));
      } catch (error) {
        recaptchaRef.current?.reset();
        console.log(error);
      }
      hideLoadingBar();
    },
  });

  const optionList = [
    {
      value: FeedbackTypes.Bug,
      label: getStringByLanguage('REPORT_A_BUG'),
    },
    {
      value: FeedbackTypes.Comment,
      label: getStringByLanguage('COMMENT'),
    },
    {
      value: FeedbackTypes.Other,
      label: getStringByLanguage('OTHER'),
    },
  ];

  return (
    <div className={styles['kut-component-body']}>
      <div className={styles['kut-component-content']}>
        <p className={styles['kut-content-title']}>
          {getStringByLanguage('HELP_US_IMPROVE_YOUR_EXPERIENCE')}
        </p>
        <p className={styles['kut-content-description']}>
          {getStringByLanguage(
            'DO_YOU_HAVE_A_SUGGESTION_COMMENT_OR_FOUND_SOME_BUG_GIVE_US_SOME_MORE_DETAILS_IN_THE_FIELD_BELOW',
          )}
        </p>
        <p
          className={cn([
            styles['kut-content-header'],
            (formik.touched.description && formik.errors.description) ||
            (formik.touched.recaptcha && formik.errors.recaptcha)
              ? styles['kut-content-error']
              : styles['kut-content-length'],
          ])}
        >
          {formik.touched.description && formik.errors.description
            ? formik.errors.description
            : formik.touched.recaptcha && formik.errors.recaptcha
            ? formik.errors.recaptcha
            : `${formik.values.description.length} / ${maxContentLength}`}
        </p>
        <textarea
          className={styles['kut-content-details']}
          name="description"
          value={formik.values.description}
          onChange={formik.handleChange}
          placeholder={getStringByLanguage('DESCRIBE_YOUR_ISSUE_OR_IDEA_HERE')}
        />
        <div className={styles['kut-content-type']}>
          {optionList.map(({ value, label }, i) => (
            <div key={i}>
              <input
                name="type"
                type="radio"
                value={value}
                onChange={formik.handleChange}
                checked={formik.values.type === value}
              />
              {label}
            </div>
          ))}
        </div>
        <div className={styles['kut-content-recaptcha']}>
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={API_RECAPTCHA_KEY}
            theme={mode}
            onChange={(value) => {
              formik.setFieldValue('recaptcha', value);
              formik.validateField('recaptcha');
            }}
          />
        </div>
        <span
          className={styles['kut-content-submit']}
          onClick={() => formik.handleSubmit()}
        >
          {getStringByLanguage('SEND_FEEDBACK')}
        </span>
      </div>
    </div>
  );
};

export default Feedback;
