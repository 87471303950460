import { FC, useEffect, useState } from 'react';
import { TableColumn } from 'react-data-table-component';
import * as DateFNS from 'date-fns';
import { Link, useParams } from 'react-router-dom';
import cn from 'classnames';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { BsPlus, BsTrash2 } from 'react-icons/bs';
import Select from 'react-select';

import Table from '../../../components/Table/Table';
import SwitchView from '../../../components/SwitchView/SwitchView';
import Button, { BUTTON_TYPE } from '../../../components/Button/Button';
import SearchInput from '../../../components/SearchInput/SearchInput';
import { useLoadingBars } from '../../../components/LoadingBar/LoadingBar';
import ListView, { SortOrder } from '../../../components/ListView/ListView';
import StatusSpan, { STATUS } from '../../../components/StatusSpan/StatusSpan';
import SVGFromServer, {
  ImageColors,
} from '../../../components/SVGFromServer/SVGFromServer';
import Modal, { useModal } from '../../../components/Modal/Modal';
import InputForm from '../../../components/InputForm/InputForm';
import IOSSwitch from '../../../components/IOSSwitch/IOSSwitch';
import {
  DeviceTypeProps,
  fieldDict,
  filterOption,
  sortFieldList,
  sortStringList,
  switchItems,
} from './Devices.helper';

import { useDeviceType } from '../../../services/hooks';
import { PersonType, getParticipantInfo } from '../../../apis/participants';
import {
  DeviceInfo,
  addDevice,
  deleteDevice,
  getDevices,
  updateDevice,
} from '../../../apis/devices';
import {
  DeviceMode,
  getDeviceTypes,
  printDeviceMode,
} from '../../../apis/deviceTypes';
import {
  colorDict,
  getServerImageUrl,
  getStringByLanguage,
  getTagNames,
} from '../../../utils/utils';

import styles from './styles.module.scss';
import ReactDOMServer from 'react-dom/server';

const Devices: FC = () => {
  const [showLoadingBar, hideLoadingBar] = useLoadingBars();
  const { isMobile } = useDeviceType();
  const { community_id, participant_id } = useParams();

  const [activeView, setActiveView] = useState(1);
  const [tableUrlParam, setTableUrlParam] = useState<{
    search: string;
  }>({
    search: '',
  });

  const [listUrlParam, setListUrlParam] = useState<{
    search: string;
    sort: SortOrder;
    sortField: keyof DeviceInfo;
  }>({
    search: '',
    sort: SortOrder.ASC,
    sortField: 'device_name',
  });

  const [deviceTypes, setDeviceTypes] = useState<DeviceTypeProps[]>([]);
  const [selectedItems, setSelectedItems] = useState<{
    allSelected: boolean;
    selectedCount: number;
    selectedRows: Partial<DeviceInfo>[];
  }>({ allSelected: false, selectedCount: 0, selectedRows: [] });

  const [modalItem, setModalItem] = useState<DeviceInfo>();
  const [modalDeleteItems, setModalDeleteItems] = useState<
    Partial<DeviceInfo>[]
  >([]);
  const [modalTitle, setModalTitle] = useState('');
  const [selectAll, setSelectAll] = useState(false);
  const [isEventFromAll, setIsEventFromAll] = useState(false);

  const init = async () => {
    const {
      data: { data: participant },
    } = await getParticipantInfo({
      community_id: community_id,
      id: participant_id,
    });

    if (!participant) {
      throw new Error('No participant');
    }

    const { data } = await getDeviceTypes();
    const personType = participant.type;

    if (data.data) {
      setDeviceTypes(
        data.data[0]
          .filter((v) => {
            const { device_type_mode: mode } = v;
            if (
              mode === DeviceMode.Producer &&
              personType === PersonType.Consumer
            ) {
              return false;
            }

            if (
              mode === DeviceMode.Consumer &&
              personType === PersonType.Producer
            ) {
              return false;
            }

            return true;
          })
          .map<DeviceTypeProps>((v) => {
            const {
              device_type_name: name,
              device_type_image: image,
              device_type_mode: mode,
            } = v;

            const defualtImage = 'uploads/Grid.svg';

            const color =
              mode === DeviceMode.Prosumer
                ? ImageColors.PRIMARY
                : mode === DeviceMode.Producer
                ? ImageColors.WARNING
                : ImageColors.SUCCESS;
            return {
              value: v.id,
              label: (
                <div
                  className={cn([
                    'kut-tooltip-item',
                    styles['kut-form-device-type-item'],
                  ])}
                  data-tooltip-content={printDeviceMode(mode) || ''}
                >
                  <SVGFromServer
                    className={cn([styles['item-image']])}
                    uri={getServerImageUrl(image || defualtImage)}
                    status={true}
                    color={ImageColors.WHITE}
                    backColor={color}
                    width={16}
                    height={16}
                    backWidth={28}
                    backHeight={28}
                  />
                  <span>{name}</span>
                </div>
              ),
              search: v.device_type_name,
            };
          }),
      );
    }
  };

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (isMobile) {
      setActiveView(0);
    }
  }, [isMobile, activeView]);

  const onViewChange = (index: number) => {
    setActiveView(index);
    setSelectedItems({
      allSelected: false,
      selectedCount: 0,
      selectedRows: [],
    });
    setSelectAll(false);
  };

  const updateRow = (device: DeviceInfo) => {
    setModalItem(device);
    updateModal.show();
  };

  const deleteRow = (device: DeviceInfo) => {
    setModalTitle(
      `${getStringByLanguage('ARE_YOU_SURE_WANT_TO_DELETE')} <b>${
        device.device_name
      }</b> ${getStringByLanguage('DEVICE').toLowerCase()}?`,
    );
    setModalDeleteItems([device]);
    deleteModal.show();
  };

  const onDeleteSelected = () => {
    if (!selectedItems.selectedCount) {
      return;
    }
    setModalTitle(
      `${getStringByLanguage(
        'ARE_YOU_SURE_WANT_TO_DELETE',
      )} <b>${`${getStringByLanguage('SELECTED').toLowerCase()} ${
        selectedItems.selectedCount
      } ${getStringByLanguage('DEVICES').toLowerCase()}`}</b>?`,
    );
    setModalDeleteItems(selectedItems.selectedRows);
    deleteModal.show();
  };

  const onCreateNew = () => {
    createModal.show();
  };

  const onCreate = async (device: Partial<DeviceInfo>) => {
    showLoadingBar();
    try {
      await addDevice(community_id, participant_id, device);
      toast.success(getStringByLanguage('SUCCESSFULLY_CREATED'));
      setTableUrlParam({ ...tableUrlParam });
      setListUrlParam({ ...listUrlParam });
      createModal.hide();
    } catch (error) {
      console.log(error);
    }
    hideLoadingBar();
  };

  const onUpdate = async (device: Partial<DeviceInfo>) => {
    showLoadingBar();
    try {
      await updateDevice(community_id, participant_id, device);
      toast.success(getStringByLanguage('SUCCESSFULLY_UPDATED'));
      setTableUrlParam({ ...tableUrlParam });
      setListUrlParam({ ...listUrlParam });
      updateModal.hide();
    } catch (error) {
      console.log(error);
    }
    hideLoadingBar();
  };

  const onDelete = async (params: { forever?: boolean }) => {
    showLoadingBar();

    const promises = modalDeleteItems.map(async (device) => {
      if (!device.id) {
        throw getStringByLanguage('REQUEST_ERROR');
      }
      return await deleteDevice(
        community_id,
        participant_id,
        device.id,
        params.forever,
      );
    });
    try {
      await promises;
      toast.success(getStringByLanguage('SUCCESSFULLY_DELETED'));
      setSelectedItems({
        allSelected: false,
        selectedCount: 0,
        selectedRows: [],
      });
      setTableUrlParam({ ...tableUrlParam });
      setListUrlParam({ ...listUrlParam });
      deleteModal.hide();
    } catch (error) {
      console.log(error);
    }
    hideLoadingBar();
  };

  const onItemSelected = (selected: {
    allSelected: boolean;
    selectedCount: number;
    selectedRows: Partial<DeviceInfo>[];
  }) => {
    setSelectedItems(selected);
  };

  const onSortChange = (sortField: keyof DeviceInfo) => {
    const sort: SortOrder = listUrlParam.sort;
    if (listUrlParam.sortField === sortField) {
      setListUrlParam({
        ...listUrlParam,
        sort: sort === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC,
      });
    } else {
      setListUrlParam({
        ...listUrlParam,
        sortField,
        sort: SortOrder.ASC,
      });
    }
  };

  const columns: TableColumn<DeviceInfo>[] = [
    {
      name: getStringByLanguage('NAME'),
      sortable: true,
      minWidth: '100px',
      cell: (data) => {
        const device_type_image =
          data.deviceType?.device_type_image || 'uploads/Grid.svg';
        const device_type = data.deviceType?.device_type_mode;
        const color =
          device_type === DeviceMode.Prosumer
            ? ImageColors.PRIMARY
            : device_type === DeviceMode.Producer
            ? ImageColors.WARNING
            : ImageColors.SUCCESS;
        return (
          <div className={styles['table-item-device-name']}>
            <SVGFromServer
              className={cn([
                'kut-tooltip-item',
                styles['table-item-device-image'],
              ])}
              data-tooltip-content={printDeviceMode(device_type) || ''}
              uri={getServerImageUrl(device_type_image)}
              status={true}
              color={ImageColors.WHITE}
              backColor={color}
              width={16}
              height={16}
              backWidth={28}
              backHeight={28}
            />
            <span
              className={cn(['kut-tooltip-item'])}
              data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                <div className={styles['table-tooltip-item']}>
                  {data.device_name || data.deviceType?.device_type_name}
                </div>,
              )}
            >
              {data.device_name || data.deviceType?.device_type_name}
            </span>
          </div>
        );
      },
    },
    {
      name: getStringByLanguage('TAGS'),
      sortable: true,
      minWidth: '100px',
      style: {
        cursor: 'pointer',
      },
      cell: (data) => {
        const { device_tag } = data;
        if (!device_tag) {
          return <></>;
        }
        try {
          const tags: string[] = getTagNames(device_tag);
          const vId = tags.findIndex((tag) => tag.toLowerCase() === 'main');
          const activeId = vId < 0 ? 0 : vId;
          const rest = tags.length - 1;

          return (
            <div className={styles['table-item-device-tags']}>
              {tags.map((tag, i) => (
                <span
                  className={cn([
                    vId !== -1 ? styles['kut-device-main'] : '',
                    activeId === i ? styles['kut-visible'] : '',
                  ])}
                  key={i}
                >
                  {tag}
                </span>
              ))}
              {rest > 0 && ` +${rest}`}
            </div>
          );
        } catch (error) {
          console.log(error);
        }
        return <></>;
      },
    },
    {
      name: getStringByLanguage('EAN'),
      sortable: true,
      minWidth: '100px',
      cell: (data) => {
        return (
          <div
            className={cn(['kut-tooltip-item'])}
            data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
              <div className={styles['table-tooltip-item']}>
                {data.ean_code}
              </div>,
            )}
          >
            {data.ean_code}
          </div>
        );
      },
    },
    {
      name: getStringByLanguage('STATUS'),
      sortable: true,
      minWidth: '100px',
      cell: (data) => {
        return (
          <StatusSpan
            text={
              data.ean_status
                ? getStringByLanguage('ACCEPTED')
                : getStringByLanguage('NOT_ACCEPTED')
            }
            status={data.ean_status ? STATUS.PRIMARY : STATUS.DANGER}
          />
        );
      },
    },
    {
      name: getStringByLanguage('POWER'),
      sortable: true,
      minWidth: '10px',
      selector: (data) => data.power,
    },
    {
      name: getStringByLanguage('DECLINATION'),
      sortable: true,
      minWidth: '10px',
      compact: true,
      selector: (data) => data.declination,
    },
    {
      name: getStringByLanguage('AZIMUTH'),
      sortable: true,
      minWidth: '10px',
      selector: (data) => data.azimuth,
    },
    {
      name: '',
      minWidth: '70px',
      cell: (data) => {
        return (
          <Link
            className={styles['table-item-view-streams']}
            to={`/manage/communities/${community_id}/participants/${participant_id}/devices/${data.id}/streams`}
          >
            {getStringByLanguage('VIEW_STREAMS')} {'>'}
          </Link>
        );
      },
    },
    {
      name: '',
      width: '70px',
      cell: (data) => {
        return (
          <span
            className={styles['table-item-button-update']}
            onClick={() => data && updateRow(data)}
          >
            {getStringByLanguage('UPDATE')}
          </span>
        );
      },
    },
    {
      name: '',
      sortable: true,
      width: '70px',
      compact: true,
      style: {
        justifyContent: 'center',
      },
      cell: (data) => {
        return (
          <span
            className={styles['table-item-button-delete']}
            onClick={() => deleteRow(data)}
          >
            {getStringByLanguage('DELETE')}
          </span>
        );
      },
    },
  ];

  const createModal = useModal<Partial<DeviceInfo>>({
    onSubmit: onCreate,
    initialValues: {
      device_name: '',
      device_type_id: 1,
      ean_code: '',
      ean_status: false,
      device_tag: '',
      power: 0,
      declination: 0,
      azimuth: 0,
    },
    validationSchema: Yup.object().shape({
      device_name: Yup.string().required(
        getStringByLanguage('THIS_FIELD_IS_REQUIRED'),
      ),
      device_type_id: Yup.number().required(
        getStringByLanguage('THIS_FIELD_IS_REQUIRED'),
      ),
      ean_code: Yup.string().required(
        getStringByLanguage('THIS_FIELD_IS_REQUIRED'),
      ),
      ean_status: Yup.boolean().required(
        getStringByLanguage('THIS_FIELD_IS_REQUIRED'),
      ),
      power: Yup.number().required(
        getStringByLanguage('THIS_FIELD_IS_REQUIRED'),
      ),
      declination: Yup.number().required(
        getStringByLanguage('THIS_FIELD_IS_REQUIRED'),
      ),
      azimuth: Yup.number().required(
        getStringByLanguage('THIS_FIELD_IS_REQUIRED'),
      ),
    }),
  });
  const updateModal = useModal<Partial<DeviceInfo>>({
    onSubmit: onUpdate,
    initialValues: modalItem || {},
    validationSchema: Yup.object().shape({
      device_name: Yup.string().required(
        getStringByLanguage('THIS_FIELD_IS_REQUIRED'),
      ),
      device_type_id: Yup.number().required(
        getStringByLanguage('THIS_FIELD_IS_REQUIRED'),
      ),
      ean_code: Yup.string().required(
        getStringByLanguage('THIS_FIELD_IS_REQUIRED'),
      ),
      ean_status: Yup.boolean().required(
        getStringByLanguage('THIS_FIELD_IS_REQUIRED'),
      ),
      power: Yup.number().required(
        getStringByLanguage('THIS_FIELD_IS_REQUIRED'),
      ),
      declination: Yup.number().required(
        getStringByLanguage('THIS_FIELD_IS_REQUIRED'),
      ),
      azimuth: Yup.number().required(
        getStringByLanguage('THIS_FIELD_IS_REQUIRED'),
      ),
    }),
  });
  const deleteModal = useModal<{ forever?: boolean }>({
    onSubmit: onDelete,
    initialValues: {
      forever: false,
    },
    validationSchema: Yup.object().shape({
      forever: Yup.boolean().required(),
    }),
  });

  return (
    <div className={styles['kut-component-body']}>
      <div className={styles['kut-component-content']}>
        <div className={styles['kut-component-header']}>
          <div
            className={cn([
              styles['kut-component-sort'],
              activeView === 1 ? styles['hide'] : '',
            ])}
          >
            <span>{getStringByLanguage('SORT_BY')}</span>
            {sortFieldList.map((field, i) => (
              <span
                key={i}
                className={cn([
                  styles['kut-component-sort-option'],
                  listUrlParam.sortField === field ? styles['active'] : '',
                  styles[listUrlParam.sort],
                ])}
                onClick={() => onSortChange(field)}
              >
                {sortStringList[i]}
              </span>
            ))}
          </div>
          <div
            className={cn([
              styles['kut-component-select-all'],
              activeView === 1 ? styles['hide'] : '',
            ])}
          >
            <input
              type="checkbox"
              checked={selectAll}
              onChange={(event) => {
                const status = event.target.checked;
                setSelectAll(status);
                setIsEventFromAll(true);
              }}
              ref={(input) => {
                if (input) {
                  const status = selectAll;
                  if (!status && selectedItems.selectedCount) {
                    input.indeterminate = true;
                    return;
                  }
                  input.indeterminate = false;
                  status ? setSelectAll(true) : setSelectAll(false);
                }
              }}
            />
            <span>{getStringByLanguage('SELECT_ALL')}</span>
          </div>
          {!isMobile && (
            <SwitchView
              className={styles['kut-component-switch-view']}
              items={switchItems}
              selected={activeView}
              onViewChange={onViewChange}
            />
          )}
        </div>
        {activeView === 1 && (
          <div
            className={cn([
              styles['kut-component-table'],
              styles['kut-component-active'],
            ])}
          >
            <div className={styles['kut-component-actions']}>
              <Button
                className={cn([
                  styles['kut-component-buttons'],
                  styles['kut-buttons-large'],
                ])}
                outline={true}
                onClick={onCreateNew}
              >
                + {getStringByLanguage('CREATE_NEW')}
              </Button>
              <SearchInput
                className={styles['kut-component-search']}
                value={tableUrlParam.search}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setTableUrlParam({
                    search: event.target.value,
                  })
                }
              />
              <Button
                className={cn([
                  styles['kut-component-buttons'],
                  styles['kut-buttons-large'],
                  styles['kut-component-button-delete'],
                  selectedItems.selectedCount ? styles['kut-active'] : '',
                ])}
                type={BUTTON_TYPE.DANGER}
                onClick={onDeleteSelected}
              >
                {getStringByLanguage('DELETE_SELECTED')}
              </Button>
              <Button
                className={cn([
                  styles['kut-component-buttons'],
                  styles['kut-buttons-small'],
                ])}
                onClick={onCreateNew}
              >
                <BsPlus />
              </Button>
              <Button
                className={cn([
                  styles['kut-component-buttons'],
                  styles['kut-buttons-small'],
                  styles['kut-component-button-delete'],
                  selectedItems.selectedCount ? styles['kut-active'] : '',
                ])}
                type={BUTTON_TYPE.DANGER}
                onClick={onDeleteSelected}
              >
                <BsTrash2 />
              </Button>
            </div>
            <Table
              pagination={true}
              selectableRows
              onSelectedRowsChange={onItemSelected}
              urlParam={tableUrlParam}
              requestFunc={(options) =>
                getDevices({ ...options, community_id, participant_id })
              }
              fieldDict={fieldDict}
              columns={columns}
              paginationPerPage={10}
              paginationRowsPerPageOptions={[10, 20, 50]}
            />
          </div>
        )}
        {activeView === 0 && (
          <div
            className={cn([
              styles['kut-component-list-view'],
              styles['kut-component-active'],
            ])}
          >
            <div className={styles['kut-component-actions']}>
              <Button
                className={cn([
                  styles['kut-component-buttons'],
                  styles['kut-buttons-large'],
                ])}
                outline={true}
                onClick={onCreateNew}
              >
                + {getStringByLanguage('CREATE_NEW')}
              </Button>
              <SearchInput
                className={styles['kut-component-search']}
                value={listUrlParam.search}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setListUrlParam({
                    ...listUrlParam,
                    search: event.target.value,
                  })
                }
              />
              <Button
                className={cn([
                  styles['kut-component-buttons'],
                  styles['kut-buttons-large'],
                  styles['kut-component-button-delete'],
                  selectedItems.selectedCount ? styles['kut-active'] : '',
                ])}
                type={BUTTON_TYPE.DANGER}
                onClick={onDeleteSelected}
              >
                {getStringByLanguage('DELETE_SELECTED')}
              </Button>
              <Button
                className={cn([
                  styles['kut-component-buttons'],
                  styles['kut-buttons-small'],
                ])}
                onClick={onCreateNew}
              >
                <BsPlus />
              </Button>
              <Button
                className={cn([
                  styles['kut-component-buttons'],
                  styles['kut-buttons-small'],
                  styles['kut-component-button-delete'],
                  selectedItems.selectedCount ? styles['kut-active'] : '',
                ])}
                type={BUTTON_TYPE.DANGER}
                onClick={onDeleteSelected}
              >
                <BsTrash2 />
              </Button>
            </div>
            <ListView
              className={styles['kut-component-list-view-main']}
              urlParam={listUrlParam}
              selectAll={selectAll}
              setSelectAll={setSelectAll}
              isEventFromAll={isEventFromAll}
              setIsEventFromAll={setIsEventFromAll}
              onItemSelected={onItemSelected}
              requestFunc={(options) =>
                getDevices({ ...options, community_id, participant_id })
              }
              ChildComponent={({
                className,
                onChange,
                checked,
                data,
              }: {
                className: string;
                onChange: (status: boolean) => void;
                checked: boolean;
                data: DeviceInfo;
              }) => {
                const device_type_image =
                  data.deviceType.device_type_image || 'uploads/Grid.svg';
                const device_type = data.deviceType.device_type_mode;
                const color =
                  device_type === DeviceMode.Prosumer
                    ? ImageColors.PRIMARY
                    : device_type === DeviceMode.Producer
                    ? ImageColors.WARNING
                    : ImageColors.SUCCESS;

                const tags: string[] = getTagNames(data.device_tag);
                const vId = tags.findIndex(
                  (tag) => tag.toLowerCase() === 'main',
                );
                const activeId = vId < 0 ? 0 : vId;
                const rest = tags.length - 1;

                return (
                  <div
                    className={cn([
                      className,
                      styles['kut-component-list-item'],
                    ])}
                  >
                    <div className={cn([styles['list-item-heading']])}>
                      <div className={cn([styles['list-item-name']])}>
                        <SVGFromServer
                          className={cn([
                            'kut-tooltip-item',
                            styles['list-item-device-image'],
                          ])}
                          data-tooltip-content={
                            printDeviceMode(device_type) || ''
                          }
                          uri={getServerImageUrl(device_type_image)}
                          status={true}
                          color={ImageColors.WHITE}
                          backColor={color}
                          width={16}
                          height={16}
                          backWidth={28}
                          backHeight={28}
                        />
                        <div>
                          <div className={styles['list-item-device-tags']}>
                            <b>
                              {data.device_name ||
                                data.deviceType?.device_type_name}
                            </b>
                            <div>
                              {tags.map((tag, i) => (
                                <span
                                  className={cn([
                                    vId !== -1 ? styles['kut-device-main'] : '',
                                    activeId === i ? styles['kut-visible'] : '',
                                  ])}
                                  key={i}
                                >
                                  {tag}
                                </span>
                              ))}
                              {rest > 0 && `+${rest}`}
                            </div>
                          </div>
                          <div className={cn([styles['list-item-created']])}>
                            {getStringByLanguage('CREATED_ON')}{' '}
                            <span>
                              {data.created_at
                                ? DateFNS.format(
                                    new Date(data.created_at),
                                    'dd/MM/yyyy',
                                  )
                                : ''}
                            </span>
                          </div>
                        </div>
                      </div>
                      <input
                        type="checkbox"
                        checked={checked}
                        onChange={(event) => onChange(event.target.checked)}
                      />
                    </div>
                    <div className={cn([styles['list-item-views']])}>
                      <div
                        className={cn([
                          'kut-tooltip-item',
                          styles['list-item-type'],
                          styles[data.deviceType.device_type_mode],
                        ])}
                        data-tooltip-content={printDeviceMode(
                          data.deviceType.device_type_mode,
                        )}
                      >
                        {data.deviceType.device_type_mode[0]}
                      </div>
                      <div className={cn([styles['list-item-views-link']])}>
                        <Link
                          className={cn([styles['list-item-view-streams']])}
                          to={`/manage/communities/${community_id}/participants/${participant_id}/devices/${data.id}/streams`}
                        >
                          {getStringByLanguage('VIEW_STREAMS')} {'>'}
                        </Link>
                      </div>
                    </div>
                    <div className={cn([styles['list-item-actions']])}>
                      <div className={cn([styles['list-item-action-items']])}>
                        <div className={cn([styles['list-item-power']])}>
                          <span>{getStringByLanguage('POWER')}:</span>
                          <span>{data.power}</span>
                        </div>
                        <div className={cn([styles['list-item-declination']])}>
                          <span>{getStringByLanguage('DECLINATION')}:</span>
                          <span>{data.declination}</span>
                        </div>
                        <div className={cn([styles['list-item-azimuth']])}>
                          <span>{getStringByLanguage('AZIMUTH')}:</span>
                          <span>{data.azimuth}</span>
                        </div>
                        <div className={cn([styles['list-item-ean']])}>
                          <span>{getStringByLanguage('EAN')}:</span>
                          <span>{data.ean_code}</span>
                        </div>
                      </div>
                      <div className={cn([styles['list-item-action-buttons']])}>
                        <span
                          className={cn([styles['list-item-button-update']])}
                          onClick={() => updateRow(data)}
                        >
                          {getStringByLanguage('UPDATE')}
                        </span>
                        <span
                          className={cn([styles['list-item-button-delete']])}
                          onClick={() => deleteRow(data)}
                        >
                          {getStringByLanguage('DELETE')}
                        </span>
                      </div>
                    </div>
                  </div>
                );
              }}
            />
          </div>
        )}
        <Modal {...createModal}>
          {(formik) => {
            return (
              <form
                className={cn([
                  styles['kut-modal-form'],
                  styles['kut-create-form'],
                ])}
              >
                <p className={cn([styles['kut-title']])}>
                  {getStringByLanguage(['CREATE', 'DEVICE'])}
                </p>
                <hr />
                <InputForm
                  className={cn([
                    styles['kut-form-device-name'],
                    styles['kut-input-form'],
                  ])}
                  heading={getStringByLanguage('DEVICE_NAME')}
                  name={'device_name'}
                  onChange={formik.handleChange}
                  required
                  value={formik.values.device_name}
                  error={
                    formik.touched.device_name && formik.errors.device_name
                  }
                />
                <div
                  className={cn([
                    styles['kut-input-form'],
                    styles['kut-form-device-type'],
                  ])}
                >
                  <div>
                    <span
                      className={cn([styles['kut-form-device-type-label']])}
                    >
                      {getStringByLanguage('DEVICE_TYPE')}
                    </span>
                    <div
                      className={cn([styles['kut-form-device-type-select']])}
                      style={{
                        color: `rgb(var(--text-color))`,
                        backgroundColor: `rgb(var(--background-color))`,
                      }}
                    >
                      <Select
                        className={`${styles['kut-form-device-type-select']}`}
                        options={deviceTypes}
                        filterOption={filterOption}
                        name={`device_type_id`}
                        isSearchable
                        onChange={(v) => {
                          formik.setFieldValue('device_type_id', v?.value);
                        }}
                        value={deviceTypes.find(
                          (value) =>
                            value.value === formik.values.device_type_id,
                        )}
                        maxMenuHeight={200}
                        styles={{
                          control: (baseStyle) => ({
                            ...baseStyle,
                            boxShadow: 'none',
                            border: 'none',
                            color: `rgb(var(--text-color))`,
                            padding: '0.5em 0.75em',
                            backgroundColor: 'transparent',
                            borderRadius: '10px',
                          }),
                          input: (baseStyle) => ({
                            ...baseStyle,
                            color: `rgb(var(--text-color))`,
                            paddingLeft: 38,
                          }),
                          placeholder: (baseStyle) => ({
                            ...baseStyle,
                            color: `rgb(var(--text-color))`,
                          }),
                          singleValue: (baseStyle) => ({
                            ...baseStyle,
                            color: `rgb(var(--text-color))`,
                          }),
                          indicatorSeparator: (baseStyle) => ({
                            ...baseStyle,
                            width: 0,
                          }),
                          dropdownIndicator: (baseStyle) => ({
                            ...baseStyle,
                            padding: '0px',
                            marginRight: '0.5em',
                            color: colorDict['color-black-strong-light'],
                          }),
                          menu: (baseStyle) => ({
                            ...baseStyle,
                            backgroundColor: `rgb(var(--background-color))`,
                          }),
                          option: (baseStyle, state) => {
                            return {
                              ...baseStyle,
                              backgroundColor: state.isSelected
                                ? `rgba(var(--text-color), 1)`
                                : state.isFocused
                                ? `rgba(var(--text-color), .3)`
                                : 'inherit',
                              color: state.isSelected
                                ? `rgb(var(--content-background-color))`
                                : 'inherit',
                              cursor: 'pointer',
                              transition: 'all .3s',
                            };
                          },
                        }}
                      />
                    </div>
                  </div>
                </div>
                <InputForm
                  className={cn([
                    styles['kut-form-device-ean'],
                    styles['kut-input-form'],
                  ])}
                  heading={getStringByLanguage('EAN')}
                  name={'ean_code'}
                  onChange={formik.handleChange}
                  required
                  value={formik.values.ean_code}
                  error={formik.touched.ean_code && formik.errors.ean_code}
                />
                <InputForm
                  className={cn([
                    styles['kut-form-device-power'],
                    styles['kut-input-form'],
                  ])}
                  heading={getStringByLanguage('POWER')}
                  name={'power'}
                  onChange={formik.handleChange}
                  required
                  value={formik.values.power}
                  error={formik.touched.power && formik.errors.power}
                />
                <InputForm
                  className={cn([
                    styles['kut-form-device-declination'],
                    styles['kut-input-form'],
                  ])}
                  heading={getStringByLanguage('DECLINATION')}
                  name={'declination'}
                  onChange={formik.handleChange}
                  required
                  value={formik.values.declination}
                  error={
                    formik.touched.declination && formik.errors.declination
                  }
                />
                <InputForm
                  className={cn([
                    styles['kut-form-device-azimuth'],
                    styles['kut-input-form'],
                  ])}
                  heading={getStringByLanguage('AZIMUTH')}
                  name={'azimuth'}
                  onChange={formik.handleChange}
                  required
                  value={formik.values.azimuth}
                  error={formik.touched.azimuth && formik.errors.azimuth}
                />
                <div
                  className={cn([
                    styles['kut-input-form'],
                    styles['kut-form-device-ean-status'],
                  ])}
                >
                  <div>
                    <span>{getStringByLanguage('EAN_STATUS')}</span>
                    <IOSSwitch
                      name={'ean_status'}
                      checked={formik.values.ean_status}
                      onChange={formik.handleChange}
                    />
                  </div>
                </div>
                <hr />
                <div className={cn([styles['kut-form-actions']])}>
                  <Button onClick={() => formik.handleSubmit()}>
                    {getStringByLanguage('CREATE')}
                  </Button>
                  <Button
                    type={BUTTON_TYPE.DANGER}
                    onClick={() => createModal.hide()}
                  >
                    {getStringByLanguage('CANCEL')}
                  </Button>
                </div>
              </form>
            );
          }}
        </Modal>
        <Modal {...updateModal}>
          {(formik) => {
            return (
              <form
                className={cn([
                  styles['kut-modal-form'],
                  styles['kut-update-form'],
                ])}
              >
                <p className={cn([styles['kut-title']])}>
                  {getStringByLanguage(['UPDATE', 'DEVICE'])}
                </p>
                <hr />
                <InputForm
                  className={cn([
                    styles['kut-form-device-name'],
                    styles['kut-input-form'],
                  ])}
                  heading={getStringByLanguage('DEVICE_NAME')}
                  name={'device_name'}
                  onChange={formik.handleChange}
                  required
                  value={formik.values.device_name}
                  error={formik.errors.device_name}
                />
                <div
                  className={cn([
                    styles['kut-input-form'],
                    styles['kut-form-device-type'],
                  ])}
                >
                  <div>
                    <span
                      className={cn([styles['kut-form-device-type-label']])}
                    >
                      {getStringByLanguage('DEVICE_TYPE')}
                    </span>
                    <div
                      className={cn([styles['kut-form-device-type-select']])}
                      style={{
                        color: `rgb(var(--text-color))`,
                        backgroundColor: `rgb(var(--background-color))`,
                      }}
                    >
                      <Select
                        className={`${styles['kut-form-device-type-select']}`}
                        options={deviceTypes}
                        filterOption={filterOption}
                        name={`device_type_id`}
                        isSearchable
                        onChange={(v) => {
                          formik.setFieldValue('device_type_id', v?.value);
                        }}
                        value={deviceTypes.find(
                          (value) =>
                            value.value === formik.values.device_type_id,
                        )}
                        maxMenuHeight={200}
                        styles={{
                          control: (baseStyle) => ({
                            ...baseStyle,
                            boxShadow: 'none',
                            border: 'none',
                            color: `rgb(var(--text-color))`,
                            padding: '0.5em 0.75em',
                            backgroundColor: 'transparent',
                            borderRadius: '10px',
                          }),
                          input: (baseStyle) => ({
                            ...baseStyle,
                            color: `rgb(var(--text-color))`,
                            paddingLeft: 38,
                          }),
                          placeholder: (baseStyle) => ({
                            ...baseStyle,
                            color: `rgb(var(--text-color))`,
                          }),
                          singleValue: (baseStyle) => ({
                            ...baseStyle,
                            color: `rgb(var(--text-color))`,
                          }),
                          indicatorSeparator: (baseStyle) => ({
                            ...baseStyle,
                            width: 0,
                          }),
                          dropdownIndicator: (baseStyle) => ({
                            ...baseStyle,
                            padding: '0px',
                            marginRight: '0.5em',
                            color: colorDict['color-black-strong-light'],
                          }),
                          menu: (baseStyle) => ({
                            ...baseStyle,
                            backgroundColor: `rgb(var(--background-color))`,
                          }),
                          option: (baseStyle, state) => {
                            return {
                              ...baseStyle,
                              backgroundColor: state.isSelected
                                ? `rgba(var(--text-color), 1)`
                                : state.isFocused
                                ? `rgba(var(--text-color), .3)`
                                : 'inherit',
                              color: state.isSelected
                                ? `rgb(var(--content-background-color))`
                                : 'inherit',
                              cursor: 'pointer',
                              transition: 'all .3s',
                            };
                          },
                        }}
                      />
                    </div>
                  </div>
                </div>
                <InputForm
                  className={cn([
                    styles['kut-form-device-ean'],
                    styles['kut-input-form'],
                  ])}
                  heading={getStringByLanguage('EAN')}
                  name={'ean_code'}
                  onChange={formik.handleChange}
                  required
                  value={formik.values.ean_code}
                  error={formik.errors.ean_code}
                />
                <InputForm
                  className={cn([
                    styles['kut-form-device-power'],
                    styles['kut-input-form'],
                  ])}
                  heading={getStringByLanguage('POWER')}
                  name={'power'}
                  onChange={formik.handleChange}
                  required
                  value={formik.values.power}
                  error={formik.errors.power}
                />
                <InputForm
                  className={cn([
                    styles['kut-form-device-declination'],
                    styles['kut-input-form'],
                  ])}
                  heading={getStringByLanguage('DECLINATION')}
                  name={'declination'}
                  onChange={formik.handleChange}
                  required
                  value={formik.values.declination}
                  error={formik.errors.declination}
                />
                <InputForm
                  className={cn([
                    styles['kut-form-device-azimuth'],
                    styles['kut-input-form'],
                  ])}
                  heading={getStringByLanguage('AZIMUTH')}
                  name={'azimuth'}
                  onChange={formik.handleChange}
                  required
                  value={formik.values.azimuth}
                  error={formik.errors.azimuth}
                />
                <div
                  className={cn([
                    styles['kut-input-form'],
                    styles['kut-form-device-ean-status'],
                  ])}
                >
                  <div>
                    <span>{getStringByLanguage('EAN_STATUS')}</span>
                    <IOSSwitch
                      name={'ean_status'}
                      checked={formik.values.ean_status}
                      onChange={formik.handleChange}
                    />
                  </div>
                </div>
                <hr />
                <div className={cn([styles['kut-form-actions']])}>
                  <Button onClick={() => formik.handleSubmit()}>
                    {getStringByLanguage('SAVE_UPDATES')}
                  </Button>
                  <Button
                    type={BUTTON_TYPE.DANGER}
                    onClick={() => updateModal.hide()}
                  >
                    {getStringByLanguage('CANCEL')}
                  </Button>
                </div>
              </form>
            );
          }}
        </Modal>
        <Modal {...deleteModal}>
          {(formik) => (
            <form
              className={cn([
                styles['kut-modal-form'],
                styles['kut-delete-form'],
              ])}
            >
              <p dangerouslySetInnerHTML={{ __html: modalTitle }} />
              <hr />
              <div className={cn([styles['kut-form-delete-forever']])}>
                <span
                  style={{
                    color: `rgb(var(--text-color))`,
                  }}
                >
                  {getStringByLanguage('DELETE_FOREVER')}?
                </span>
                <IOSSwitch
                  name={'forever'}
                  checked={formik.values.forever}
                  onChange={formik.handleChange}
                />
              </div>
              <p
                className={cn([styles['kut-form-delete-forever-description']])}
              >
                {getStringByLanguage('DELETE_FOREVER_DESCRIPTION')}
              </p>
              <hr />
              <div className={cn([styles['kut-form-actions']])}>
                <Button onClick={() => formik.handleSubmit()}>
                  {getStringByLanguage('DELETE')}
                </Button>
                <Button
                  type={BUTTON_TYPE.DANGER}
                  onClick={() => deleteModal.hide()}
                >
                  {getStringByLanguage('CANCEL')}
                </Button>
              </div>
            </form>
          )}
        </Modal>
      </div>
    </div>
  );
};

export default Devices;
