import { BsGrid } from 'react-icons/bs';
import { AiOutlineUnorderedList } from 'react-icons/ai';

import { SwitchItem } from '../../../components/SwitchView/SwitchView';

import { getStringByLanguage } from '../../../utils/utils';
import { Gender, UserInfo, UserRole } from '../../../apis/users';

export const fieldDict: {
  [key: string]: keyof UserInfo;
} = {
  [getStringByLanguage('EMAIL')]: 'email',
  [getStringByLanguage('FIRST_NAME')]: 'first_name',
  [getStringByLanguage('LAST_NAME')]: 'last_name',
  [getStringByLanguage('ROLE')]: 'role',
  [getStringByLanguage('PRONOUNS')]: 'gender',
  [getStringByLanguage('PHONE')]: 'phone',
  [getStringByLanguage('COMPANY_NAME')]: 'company_name',
};

export const sortFieldList: (keyof UserInfo)[] = Object.values(fieldDict);
export const sortStringList: string[] = Object.keys(fieldDict);

export interface UserRoleProps {
  value: UserRole;
  label: string | number | React.ReactElement;
}

export const userRoleTypes: UserRoleProps[] = [
  {
    value: UserRole.Participant,
    label: UserRole.Participant,
  },
  {
    value: UserRole.Community_Manager,
    label: UserRole.Community_Manager,
  },
  {
    value: UserRole.Super_Admin,
    label: UserRole.Super_Admin,
  },
  {
    value: UserRole.Sub_Admin,
    label: UserRole.Sub_Admin,
  },
];

export interface GenderProps {
  value: Gender;
  label: string | number | React.ReactElement;
}

export const genderTypes: GenderProps[] = [
  {
    value: Gender.Male,
    label: Gender.Male,
  },
  {
    value: Gender.Female,
    label: Gender.Female,
  },
];

export const filterOption = (
  candidate: {
    value: string;
    label: string;
  },
  input: string,
) => {
  if (input) {
    return candidate.label.toLowerCase().indexOf(input.toLowerCase()) !== -1;
  }
  return true;
};

export const switchItems: SwitchItem[] = [
  {
    IconComponent: BsGrid,
  },
  {
    IconComponent: AiOutlineUnorderedList,
  },
];
