import { FunctionComponent } from 'react';

import ResetPassword from '../pages/Auth/ResetPassword/ResetPassword';
import Signin from '../pages/Auth/Signin/Signin';
import Intro from '../pages/Auth/Intro/Intro';
import Signup from '../pages/Auth/Signup/Signup';
import IntroRegister from '../pages/Auth/IntroRegister/IntroRegister';
import Community from '../pages/Community/Community';
import Devices from '../pages/Devices/Devices';
import Documents from '../pages/Documents/Documents';
import Participants from '../pages/Participants/Participants';
import Energy from '../pages/Energy/Energy';
import Feedback from '../pages/Feedback/Feedback';
import Help from '../pages/Help/Help';
import Settings from '../pages/Settings/Settings';

import Users from '../pages/Admin/Users/Users';
import Dashboard from '../pages/Admin/Dashboard/Dashboard';
import Communities from '../pages/Admin/Communities/Communities';
import CommunityParticipants from '../pages/Admin/Participants/Participants';
import CommunityDocuments from '../pages/Admin/Documents/Documents';
import CommunityDevices from '../pages/Admin/Devices/Devices';
import CommunityStreams from '../pages/Admin/Streams/Streams';

export interface URL_PROPS {
  path: string;
  component: FunctionComponent;
  session: boolean;
  layout: boolean;
}

export const URL_LIST: URL_PROPS[] = [
  // User
  {
    path: '/',
    component: Intro,
    session: false,
    layout: false,
  },
  {
    path: '/signin',
    component: Signin,
    session: false,
    layout: false,
  },
  {
    path: '/reset-password/:token',
    component: ResetPassword,
    session: false,
    layout: false,
  },
  {
    path: '/reset-password',
    component: ResetPassword,
    session: false,
    layout: false,
  },
  {
    path: '/signup',
    component: Signup,
    session: false,
    layout: false,
  },
  {
    path: '/intro-register',
    component: IntroRegister,
    session: false,
    layout: false,
  },
  {
    path: '/community',
    component: Community,
    session: true,
    layout: true,
  },
  {
    path: '/devices',
    component: Devices,
    session: true,
    layout: true,
  },
  {
    path: '/documents',
    component: Documents,
    session: true,
    layout: true,
  },
  {
    path: '/participants',
    component: Participants,
    session: true,
    layout: true,
  },
  {
    path: '/energy',
    component: Energy,
    session: true,
    layout: true,
  },
  {
    path: '/feedback',
    component: Feedback,
    session: true,
    layout: true,
  },
  {
    path: '/help',
    component: Help,
    session: true,
    layout: true,
  },
  {
    path: '/settings',
    component: Settings,
    session: true,
    layout: true,
  },
  {
    path: '/manage/users',
    component: Users,
    session: true,
    layout: true,
  },
  {
    path: '/community/:community_id',
    component: Dashboard,
    session: true,
    layout: true,
  },
  {
    path: '/manage/communities',
    component: Communities,
    session: true,
    layout: true,
  },
  {
    path: '/manage/communities/:community_id/participants',
    component: CommunityParticipants,
    session: true,
    layout: true,
  },
  {
    path: '/manage/communities/:community_id/participants/:participant_id/documents',
    component: CommunityDocuments,
    session: true,
    layout: true,
  },
  {
    path: '/manage/communities/:community_id/participants/:participant_id/devices',
    component: CommunityDevices,
    session: true,
    layout: true,
  },
  {
    path: '/manage/communities/:community_id/participants/:participant_id/devices/:device_id/streams',
    component: CommunityStreams,
    session: true,
    layout: true,
  },
];
