import {
  Dispatch,
  FC,
  MouseEventHandler,
  RefObject,
  SetStateAction,
  useRef,
} from 'react';
import { Link, useLocation } from 'react-router-dom';
import cn from 'classnames';

import { MenuItemProps } from '../Sidebar/Sidebar';

import styles from './styles.module.scss';

interface FuncGroup {
  itemList: MenuItemProps[];
  modalStatus: boolean;
  setModalStatus: Dispatch<SetStateAction<boolean>>;
  listeners?: RefObject<HTMLElement>[];
  handlePageTitle: (menuItem: MenuItemProps) => void;
}

const MenuModal: FC<FuncGroup> = ({
  itemList,
  modalStatus,
  setModalStatus,
  listeners = [],
  handlePageTitle,
}) => {
  const params = useLocation();
  const containerRef = useRef<HTMLDivElement>(null);
  const itemContainerRef = useRef<HTMLDivElement>(null);

  const containerClickEvent: MouseEventHandler<HTMLDivElement> = (ev) => {
    if (!itemContainerRef.current?.contains(ev.target as Node)) {
      !listeners.filter((listener) => {
        return listener.current?.contains(ev.target as Node);
      }).length && setModalStatus(false);
    }
  };

  return (
    <div
      ref={containerRef}
      onClick={containerClickEvent}
      className={cn([
        styles['main-body-modal-container'],
        modalStatus ? styles['active'] : styles['inactive'],
      ])}
    >
      <div className={cn([styles['modal-item-container']])}>
        <div ref={itemContainerRef} className={cn([styles['modal-item-list']])}>
          {itemList.map((item, index) => (
            <div key={index}>
              <Link
                className={cn([
                  styles['kut-modal-item'],
                  item.path === params.pathname ? styles['active'] : '',
                  item.unReadCount ? styles['new-item'] : '',
                ])}
                onClick={() => {
                  handlePageTitle(item), setModalStatus(false);
                }}
                to={item.path || '#'}
              >
                {item.icon}
                <span>{item.title}</span>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MenuModal;
