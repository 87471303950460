import { FC } from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';

import { SubComponentsProps } from './Settings';
import Button, { BUTTON_TYPE } from '../../components/Button/Button';
import StatusSpan, { STATUS } from '../../components/StatusSpan/StatusSpan';
import Table from '../../components/Table/Table';

import { PRICES, getStringByLanguage, numUtils } from '../../utils/utils';
import { BillingHistory } from '../../apis/users';
import { useUrlTrack } from '../../services/hooks';
import stripeImg from '../../assets/images/stripe.png';

import styles from './styles.module.scss';

const statusDict: {
  [key: string]: STATUS;
} = {
  signed: STATUS.PRIMARY,
  'not paid yet': STATUS.DANGER,
  paid: STATUS.SUCCESS,
};

const statusText: {
  [key: string]: string;
} = {
  paid: getStringByLanguage('PAID'),
  canceled: getStringByLanguage('CANCELED'),
};

const customStyles = {
  tableWrapper: {
    style: {
      display: 'block',
    },
  },
  headRow: {
    style: {
      color: 'rgba(var(--text-color), .5)',
      backgroundColor: 'rgb(var(--content-background-color))',
      fontSize: '0.917em',
      borderBottomWidth: '0px',
      minHeight: '30px',
    },
  },
  rows: {
    style: {
      fontSize: '0.625em',
      color: 'rgb(var(--text-color))',
      backgroundColor: 'rgb(var(--content-background-color))',
      minHeight: '3em',
      '&:not(:last-of-type)': {
        borderBottomWidth: '0px',
      },
    },
    highlightOnHoverStyle: {
      color: 'rgba(var(--text-color), .7)',
      backgroundColor: 'rgb(var(--background-color))',
      outlineColor: 'transparent',
      transitionProperty: 'background-color, color',
      transitionDuration: '0.3s',
    },
  },
  noData: {
    style: {
      color: 'rgb(var(--text-color))',
    },
  },
};

const UserBillingPlanComponent: FC<
  Omit<SubComponentsProps, 'userInfo'> &
    Required<Pick<SubComponentsProps, 'userInfo'>>
> = ({ userInfo, navToSelectPlan }) => {
  const isDefaultUrl = useUrlTrack();

  const columns = [
    {
      name: getStringByLanguage('NAME'),
      sortable: true,
      minWidth: '100px',
      compact: true,
      selector: (data: BillingHistory) => data.name,
    },
    {
      name: getStringByLanguage('EMAIL'),
      sortable: true,
      minWidth: '70px',
      compact: true,
      selector: (data: BillingHistory) => data.email,
    },
    {
      name: isDefaultUrl
        ? getStringByLanguage('EAN')
        : getStringByLanguage('PDL'),
      sortable: true,
      minWidth: '70px',
      compact: true,
      selector: (data: BillingHistory) => data.ean,
    },
    {
      name: getStringByLanguage('ACTIVE_PLAN'),
      sortable: true,
      minWidth: '70px',
      compact: true,
      selector: (data: BillingHistory) => data.active_plan,
    },
    {
      name: getStringByLanguage('PLAN_COST'),
      sortable: true,
      minWidth: '50px',
      compact: true,
      selector: (data: BillingHistory) =>
        `${data.unit_currency}${numUtils.decimal(
          data.unit_amount / data.unit_amount_decimal,
          2,
        )}`,
    },
    {
      name: getStringByLanguage('STATUS'),
      sortable: true,
      minWidth: '100px',
      compact: true,
      cell: (data: BillingHistory) => (
        <StatusSpan
          status={statusDict[data.status]}
          text={statusText[data.status]}
        />
      ),
    },
    {
      name: getStringByLanguage('PAYMENT_DATE'),
      sortable: true,
      minWidth: '70px',
      compact: true,
      selector: (data: BillingHistory) => data.date,
    },
    {
      name: getStringByLanguage('ACTIONS'),
      minWidth: '70px',
      compact: true,
      cell: (data: BillingHistory) =>
        data.status.length && (
          <Link to="#">{getStringByLanguage('DOWNLOAD_INVOICE')}</Link>
        ),
    },
    {
      name: '',
      minWidth: '70px',
      compact: true,
      cell: (data: BillingHistory) =>
        data.status.length && (
          <Link to="#">{getStringByLanguage('VIEW_INVOICE')}</Link>
        ),
    },
  ];

  return (
    <div className={styles['kut-user-billing-plan']}>
      <div className={styles['kut-billing-current-plan']}>
        <p className={styles['kut-billing-title']}>
          {getStringByLanguage('YOUR_CURRENT_PLAN')}
        </p>
        <div className={styles['kut-billing-plan-content']}>
          <span className={styles['kut-billing-current']}>
            {PRICES[userInfo.plan].label}
          </span>
          <div className={styles['kut-billing-actions']}>
            <div className={styles['kut-billing-action-buttons']}>
              <span
                onClick={() => {
                  navToSelectPlan?.();
                }}
              >
                {getStringByLanguage('UPGRADE_PLAN')}
              </span>
              <span>{getStringByLanguage('CANCEL_PLAN')}</span>
            </div>
            <p className={styles['kut-billing-action-text']}>
              {getStringByLanguage('CHOOSE_THE_PLAN_THAT_WORKS_BEST_FOR_YOU')}
            </p>
          </div>
        </div>
      </div>
      <div className={styles['kut-billing-person']}>
        <p className={styles['kut-billing-title']}>
          {getStringByLanguage('BILLING_PERSON')}
        </p>
        <p className={styles['kut-billing-person-content']}>
          {userInfo.customer_name ||
            `${userInfo.first_name} ${userInfo.last_name}`}
        </p>
      </div>
      <div className={styles['kut-billing-update-information']}>
        <Link className={styles['kut-billing-update-link']} to="#">
          {getStringByLanguage('UPDATE_YOUR_BILLING_INFORMATION')}
        </Link>
        <span className={styles['kut-billing-update-text']}>
          {getStringByLanguage('POWERED_BY')}
        </span>
        <img
          className={styles['kut-billing-update-company']}
          src={stripeImg}
          alt={getStringByLanguage('STRIPE')}
        />
      </div>
      <hr />
      <div className={styles['kut-billing-history']}>
        <p
          className={cn([
            styles['kut-billing-title'],
            'kut-flex',
            'kut-justify-between',
          ])}
        >
          <span>{getStringByLanguage('BILLING_HISTORY')}</span>
          <span className={`kut-cursor-pointer kut-hover-opacity`}>
            {getStringByLanguage('SEE_ALL_INVOICES')} {'>'}
          </span>
        </p>
        <Table
          pagination={false}
          columns={columns}
          data={userInfo.billing_history}
          customStyles={customStyles}
        />
      </div>
      <hr />
      <div className={styles['kut-bottom-form']}>
        <p>
          {getStringByLanguage('YOUR_PLAN_RENEWS_ON')} {userInfo.plan_renews_on}
        </p>
        <div className={styles['kut-bottom-actions']}>
          <Button type={BUTTON_TYPE.DEFAULT} outline={true}>
            {getStringByLanguage('CANCEL')}
          </Button>
          <Button type={BUTTON_TYPE.PRIMARY}>
            {getStringByLanguage('SAVE_UPDATES')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default UserBillingPlanComponent;
