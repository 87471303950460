import { FC, useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import cn from 'classnames';
import { useCookies } from 'react-cookie';
import { Tooltip } from 'react-tooltip';
import { Helmet } from 'react-helmet';

import LoadingBar from './components/LoadingBar/LoadingBar';

import { useAppDispatch, useAppSelector, useUrlTrack } from './services/hooks';
import {
  getScrollStatus,
  getViewMode,
  setColors,
} from './services/reducers/sharedReducer';
import AppRouter from './routes';
import { getUserInfo } from './apis/users';
import { userSignin } from './services/reducers/userReducer';
import { ACCESS_TOKEN } from './config/config';
import { getServerImageUrl } from './utils/utils';

import './assets/sass/app.scss';
import './App.scss';
import 'react-toastify/dist/ReactToastify.css';
import 'react-tooltip/dist/react-tooltip.css';

const App: FC = () => {
  const mode = useAppSelector(getViewMode);
  const dispatch = useAppDispatch();
  const urlInfo = useUrlTrack();
  const scroll = useAppSelector(getScrollStatus);
  const [sessionLoading, setSessionLoading] = useState(true);

  const [cookies] = useCookies();

  useEffect(() => {
    if (!cookies[ACCESS_TOKEN]) {
      setSessionLoading(false);
      return;
    }

    getUserInfo()
      .then(({ data }) => {
        dispatch(userSignin(data.data));
        setSessionLoading(false);
      })
      .catch(() => {
        setSessionLoading(false);
      });
  }, []);

  useEffect(() => {
    document.body.className = cn([
      'kut-app',
      mode === 'dark' ? 'kut-mode-dark' : '',
    ]);

    dispatch(
      setColors({
        textColor: getComputedStyle(document.body).getPropertyValue(
          '--text-color',
        ),
        backgroundColor: getComputedStyle(document.body).getPropertyValue(
          '--background-color',
        ),
        contentBackgroundColor: getComputedStyle(
          document.body,
        ).getPropertyValue('--content-background-color'),
      }),
    );
  }, [mode]);

  useEffect(() => {
    const loadingClassName = 'kut-app-is-loading';
    const htmlTags = document.getElementsByTagName('html');
    const _scroll = !scroll;
    if (!htmlTags) {
      return;
    }

    const classPos = htmlTags[0].className.indexOf(loadingClassName);

    if ((_scroll && classPos !== -1) || (!_scroll && classPos === -1)) {
      return;
    }

    if (_scroll && classPos === -1) {
      htmlTags[0].className = htmlTags[0].className + ' ' + loadingClassName;
      return;
    }

    htmlTags[0].className = `${htmlTags[0].className.substring(
      0,
      classPos - 1,
    )}${htmlTags[0].className.slice(classPos + loadingClassName.length, -1)}`;
  }, [scroll]);

  return (
    <>
      <Helmet>
        <title>{urlInfo.title}</title>
        <link rel="icon" href={getServerImageUrl(urlInfo.favicon)}></link>
        <meta name="description" content={urlInfo.title} />
      </Helmet>
      <ToastContainer autoClose={8000} />
      <LoadingBar />
      {!sessionLoading && <AppRouter />}
      <Tooltip
        className={cn(['kut-tooltip-container'])}
        anchorSelect=".kut-tooltip-item"
      />
    </>
  );
};

export default App;
