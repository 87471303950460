import { FC } from 'react';
import cn from 'classnames';
import { IoNotificationsOffOutline } from 'react-icons/io5';
import { FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';

import { SubComponentsProps } from './Settings';
import Button, { BUTTON_TYPE } from '../../components/Button/Button';
import IOSSwitch from '../../components/IOSSwitch/IOSSwitch';
import { useLoadingBars } from '../../components/LoadingBar/LoadingBar';

import { updateUserInfo } from '../../apis/users';
import { getStringByLanguage } from '../../utils/utils';

import styles from './styles.module.scss';

interface Notifications {
  daily: boolean;
  weekly: boolean;
  monthly: boolean;
}

const UserNotificationComponent: FC<
  Omit<SubComponentsProps, 'userInfo'> &
    Required<Pick<SubComponentsProps, 'userInfo' | 'updateUser2NewInfo'>>
> = ({ userInfo, updateUser2NewInfo }) => {
  const [showLoadingBar, hideLoadingBar] = useLoadingBars();
  let notifications: Notifications;

  try {
    notifications = JSON.parse(userInfo.notifications);
  } catch (error) {
    notifications = {
      daily: false,
      weekly: false,
      monthly: false,
    };
  }

  const inputFormSchema = Yup.object().shape({
    daily: Yup.boolean().required(
      getStringByLanguage('THIS_FIELD_IS_REQUIRED'),
    ),
    weekly: Yup.boolean().required(
      getStringByLanguage('THIS_FIELD_IS_REQUIRED'),
    ),
    monthly: Yup.boolean().required(
      getStringByLanguage('THIS_FIELD_IS_REQUIRED'),
    ),
  });

  const formik = useFormik({
    initialValues: {
      daily: notifications.daily,
      weekly: notifications.weekly,
      monthly: notifications.monthly,
    },
    validationSchema: inputFormSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      showLoadingBar();
      try {
        const { data } = await updateUserInfo({
          notifications: JSON.stringify(values),
        });
        if (!data.data) {
          return;
        }
        updateUser2NewInfo(data.data);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        console.log(error);
      }
      hideLoadingBar();
    },
  });

  const handleSubmit = () => {
    formik.handleSubmit();
  };

  return (
    <FormikProvider value={formik}>
      <form className={styles['kut-user-notification']}>
        <p
          className={cn([
            styles['kut-notification-title'],
            styles['kut-notification-disable-all-title'],
          ])}
        >
          {getStringByLanguage('DO_NOT_DISTURB')}
        </p>
        <div className={styles['kut-notification-disable-all']}>
          <IoNotificationsOffOutline />
          <span>{getStringByLanguage('DISABLE_ALL_NOTIFICATIONS')}</span>
        </div>
        <p
          className={cn([
            styles['kut-notification-title'],
            styles['kut-notification-configuration'],
          ])}
        >
          {getStringByLanguage('CONFIGURE_EMAIL_NOTIFICATIONS_FREQUENCY')}
        </p>
        <div
          className={cn([
            styles['kut-notification-daily'],
            styles['kut-notification-events'],
          ])}
        >
          <IOSSwitch
            type="primary"
            sx={{ m: 1, width: 28, height: 17, margin: 0 }}
            name={`daily`}
            onChange={formik.handleChange}
            checked={formik.values.daily}
          />
          <p className={styles['kut-notification-events-title']}>
            {getStringByLanguage('DAILY_SUMMARIES')}
          </p>
          <p className={styles['kut-notification-events-details']}>
            {getStringByLanguage(
              'GET_NOTIFIED_DAILY_ABOUT_WHAT_HAPPENS_IN_YOUR_COMMUNITY',
            )}
          </p>
        </div>
        <div
          className={cn([
            styles['kut-notification-weekly'],
            styles['kut-notification-events'],
          ])}
        >
          <IOSSwitch
            type="primary"
            sx={{ m: 1, width: 28, height: 17, margin: 0 }}
            name={`weekly`}
            onChange={formik.handleChange}
            checked={formik.values.weekly}
          />
          <p className={styles['kut-notification-events-title']}>
            {getStringByLanguage('WEEKLY_REPORT')}
          </p>
          <p className={styles['kut-notification-events-details']}>
            {getStringByLanguage(
              'GET_NOTIFIED_WEEKLY_ABOUT_WHAT_HAPPENS_IN_YOUR_COMMUNITY',
            )}
          </p>
        </div>
        <div
          className={cn([
            styles['kut-notification-monthly'],
            styles['kut-notification-events'],
          ])}
        >
          <IOSSwitch
            type="primary"
            sx={{ m: 1, width: 28, height: 17, margin: 0 }}
            name={`monthly`}
            onChange={formik.handleChange}
            checked={formik.values.monthly}
          />
          <p className={styles['kut-notification-events-title']}>
            {getStringByLanguage('MONTHLY_REPORT')}
          </p>
          <p className={styles['kut-notification-events-details']}>
            {getStringByLanguage(
              'GET_NOTIFIED_MONTHLY_ABOUT_WHAT_HAPPENS_IN_YOUR_COMMUNITY',
            )}
          </p>
        </div>
        <hr />
        <div className={styles['kut-bottom-form']}>
          <p>
            {getStringByLanguage(
              'NOTIFICATIONS_ARE_SENT_DIRECTLY_TO_YOUR_CURRENT_EMAIL',
            )}
          </p>
          <div className={styles['kut-bottom-actions']}>
            <Button type={BUTTON_TYPE.DEFAULT} outline={true}>
              {getStringByLanguage('CANCEL')}
            </Button>
            <Button type={BUTTON_TYPE.PRIMARY} onClick={handleSubmit}>
              {getStringByLanguage('SAVE_UPDATES')}
            </Button>
          </div>
        </div>
      </form>
    </FormikProvider>
  );
};

export default UserNotificationComponent;
