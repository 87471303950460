import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { MODE_DARK, MODE_LIGHT } from '../../utils/constants';
import { RootState } from '../store';
import { TimeRange } from '../../apis/streams';

export interface ColorInfo {
  textColor: string;
  backgroundColor: string;
  contentBackgroundColor: string;
}

export interface GPSLocation {
  latitude: number;
  longitude: number;
}

export interface SharedState {
  selectedRange: TimeRange;
  selectedDate: number;
  loading: number;
  scroll: boolean;
  mode: Mode;
  asyncMode: boolean;
  colors: ColorInfo;
  panelStatus: boolean;
  location: GPSLocation;
}

export type Mode = typeof MODE_LIGHT | typeof MODE_DARK;

/**
 * loading: number, 0 is loaded status, others are loading status. Can not be less than 0.
 * mode: 'light', 'dark'. Default is 'light'.
 */

const initialState: SharedState = {
  selectedRange: TimeRange.Today,
  selectedDate: new Date().getTime(),
  loading: 0,
  scroll: true,
  mode:
    localStorage.getItem('color-mode') === MODE_DARK ? MODE_DARK : MODE_LIGHT,
  asyncMode: localStorage.getItem('color-mode-async') === 'true',
  colors: {
    textColor: '0,0,0',
    backgroundColor: '0,0,0',
    contentBackgroundColor: '0,0,0',
  },
  panelStatus: true,
  location: { latitude: 0, longitude: 0 },
};

export const sharedSlice = createSlice({
  name: 'shared',
  initialState,
  reducers: {
    setSelectedRange: (state, action: PayloadAction<TimeRange>) => {
      state.selectedRange = action.payload;
    },
    setSelectedDate: (state, action: PayloadAction<number>) => {
      state.selectedDate = action.payload;
    },
    showLoading: (state) => {
      state.loading++;
      state.scroll = false;
    },
    hideLoading: (state) => {
      if (state.loading === 0) {
        return;
      }
      state.loading--;
      if (!state.loading) {
        state.scroll = true;
      }
    },
    setScrollStatus: (state, action: PayloadAction<boolean>) => {
      state.scroll = action.payload;
    },
    setViewMode: (state, action: PayloadAction<Mode>) => {
      state.mode = action.payload;
      localStorage.setItem('color-mode', action.payload);
    },
    setViewAsyncMode: (state, action: PayloadAction<boolean>) => {
      state.asyncMode = action.payload;
      localStorage.setItem('color-mode-async', `${action.payload}`);
    },
    setColors: (state, action: PayloadAction<ColorInfo>) => {
      state.colors = action.payload;
    },
    setPanelStatus: (state) => {
      state.panelStatus = !state.panelStatus;
    },
    setLocationGPS: (state, action: PayloadAction<GPSLocation>) => {
      state.location = action.payload;
    },
  },
});

export const {
  setSelectedRange,
  setSelectedDate,
  showLoading,
  hideLoading,
  setScrollStatus,
  setViewMode,
  setViewAsyncMode,
  setColors,
  setPanelStatus,
  setLocationGPS,
} = sharedSlice.actions;

export const getSelectedRange = (state: RootState) =>
  state.shared.selectedRange;
export const getSelectedDate = (state: RootState) => state.shared.selectedDate;

export const getLoadingStatus = (state: RootState) => state.shared.loading;
export const getScrollStatus = (state: RootState) => state.shared.scroll;
export const getPanelStatus = (state: RootState) => state.shared.panelStatus;
export const getViewMode = (state: RootState) => state.shared.mode;
export const getViewAsyncMode = (state: RootState) => state.shared.asyncMode;
export const getColors = (state: RootState) => state.shared.colors;
export const getLocationGPS = (state: RootState) => state.shared.location;

export default sharedSlice.reducer;
