import { BsGrid } from 'react-icons/bs';
import { AiOutlineUnorderedList } from 'react-icons/ai';

import { SwitchItem } from '../../../components/SwitchView/SwitchView';

import { CommunityInfo } from '../../../apis/communities';
import { getStringByLanguage } from '../../../utils/utils';

export const fieldDict: {
  [key: string]: keyof CommunityInfo;
} = {
  [getStringByLanguage('NAME')]: 'community_name',
  [getStringByLanguage('CREATED_ON')]: 'created_at',
};

export const sortFieldList: (keyof CommunityInfo)[] = Object.values(fieldDict);
export const sortStringList: string[] = Object.keys(fieldDict);

export const switchItems: SwitchItem[] = [
  {
    IconComponent: BsGrid,
  },
  {
    IconComponent: AiOutlineUnorderedList,
  },
];
