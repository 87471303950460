import { ChangeEventHandler, FC, MouseEventHandler, useState } from 'react';
import Select from 'react-select';
import { CiWarning } from 'react-icons/ci';
import * as Yup from 'yup';
import { FormikProvider, useFormik } from 'formik';
import cn from 'classnames';
import { toast } from 'react-toastify';

import { SubComponentsProps } from './Settings';
import Button, { BUTTON_TYPE } from '../../components/Button/Button';
import { useLoadingBars } from '../../components/LoadingBar/LoadingBar';
import InputForm from '../../components/InputForm/InputForm';

import {
  colorDict,
  getServerImageUrl,
  getStringByLanguage,
} from '../../utils/utils';
import { defaultAvatars } from '../../utils/constants';
import { useAppDispatch, useUrlTrack } from '../../services/hooks';
import { updateUserInfo } from '../../apis/users';
import { userSignin } from '../../services/reducers/userReducer';

import styles from './styles.module.scss';

const genderOptions = [
  { value: 'Male', label: 'He/Him' },
  { value: 'Female', label: 'She/Her' },
];

const UserProfileComponent: FC<
  Omit<SubComponentsProps, 'userInfo'> &
    Required<Pick<SubComponentsProps, 'userInfo'>>
> = ({ userInfo, updateUser2NewInfo }) => {
  const [showLoadingBar, hideLoadingBar] = useLoadingBars();
  const dispatch = useAppDispatch();
  const isDefaultUrl = useUrlTrack();
  const [newAvatarPreview, setNewAvatarPreview] = useState<string | null>(null);
  const [newAvatar, setNewAvatar] = useState<File | null>(null);

  const inputFormSchema = Yup.object().shape({
    gender: Yup.string()
      .matches(
        /(^|\s)(Male|Female)($|\s)/,
        getStringByLanguage('INVALID_EMAIL_ADDRESS'),
      )
      .required(getStringByLanguage('THIS_FIELD_IS_REQUIRED')),
    first_name: Yup.string().required(
      getStringByLanguage('THIS_FIELD_IS_REQUIRED'),
    ),
    last_name: Yup.string().required(
      getStringByLanguage('THIS_FIELD_IS_REQUIRED'),
    ),
    email: Yup.string()
      .matches(
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
        getStringByLanguage('INVALID_EMAIL_ADDRESS'),
      )
      .required(getStringByLanguage('THIS_FIELD_IS_REQUIRED')),
    ean: Yup.string().nullable().notRequired(),
    job_title: Yup.string().nullable().notRequired(),
    company_name: Yup.string().nullable().notRequired(),
    participant_address: Yup.string().nullable().notRequired(),
    participant_city: Yup.string().nullable().notRequired(),
    participant_country: Yup.string().nullable().notRequired(),
    participant_postalcode: Yup.string().nullable().notRequired(),
    participant_vat: Yup.number().nullable().notRequired(),
  });

  const formik = useFormik({
    initialValues: {
      gender: userInfo.gender,
      first_name: userInfo.first_name || '',
      last_name: userInfo.last_name || '',
      email: userInfo.email || '',
      ean: userInfo.ean || '',
      job_title: userInfo.job_title || '',
      company_name: userInfo.company_name || '',
      participant_address: userInfo.participant?.participant_address || null,
      participant_city: userInfo.participant?.participant_city || null,
      participant_country: userInfo.participant?.participant_country || null,
      participant_postalcode:
        userInfo.participant?.participant_postalcode || null,
      participant_vat: userInfo.participant?.participant_vat || null,
    },
    enableReinitialize: true,
    validationSchema: inputFormSchema,
    onSubmit: async (values) => {
      showLoadingBar();
      try {
        const { data } = await updateUserInfo({ ...values, newAvatar });
        if (data.data) {
          updateUser2NewInfo(data.data);
          dispatch(userSignin(data.data));
        }
      } catch (error) {
        console.log(error);
      }
      hideLoadingBar();
    },
  });

  const handleSubmit = () => {
    formik.handleSubmit();
  };

  const handleAvatarChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (!e.target.files || !e.target.files.length) return;
    const file = e.target.files[0];
    if (!file.type.startsWith('image/')) {
      return;
    }
    showLoadingBar();
    setNewAvatar(file);
    const fr = new FileReader();
    fr.onload = (ev) => {
      if (ev.target?.result && typeof ev.target?.result === 'string') {
        setNewAvatarPreview(ev.target?.result);
      }
      hideLoadingBar();
    };
    fr.onerror = (ev) => {
      toast.error(ev.target?.error?.message);
      hideLoadingBar();
    };
    fr.readAsDataURL(file);
  };

  const handleAvatarRemove: MouseEventHandler<HTMLSpanElement> = () => {
    setNewAvatarPreview(null);
    setNewAvatar(null);
  };

  return (
    <FormikProvider value={formik}>
      <form
        className={styles['kut-user-profile']}
        onSubmit={formik.handleSubmit}
      >
        <div className={styles['kut-user-avatar']}>
          <p className={styles['kut-heading']}>
            {getStringByLanguage('YOUR_PROFILE_PHOTO')}
          </p>
          <div className={styles['kut-content']}>
            <img
              className={styles['kut-avatar-image']}
              alt="Avatar"
              src={
                newAvatarPreview ||
                (userInfo.avatar && getServerImageUrl(userInfo.avatar)) ||
                defaultAvatars[userInfo.gender] ||
                defaultAvatars['Male']
              }
            />
            <div className={styles['kut-avatar-actions']}>
              <div className={styles['kut-avatar-actions-buttons']}>
                <label className={styles['kut-link']}>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleAvatarChange}
                    hidden
                  />
                  {getStringByLanguage('UPLOAD_NEW_PHOTO')}
                </label>
                <span
                  className={styles['kut-link']}
                  onClick={handleAvatarRemove}
                >
                  {getStringByLanguage('REMOVE_PHOTO')}
                </span>
              </div>
              <p className={`kut-opacity-50`}>
                {getStringByLanguage(
                  'UPLOAD_A_PHOTO_SO_OTHER_MEMBERS_CAN_RECOGNIZE_YOU',
                )}
              </p>
            </div>
          </div>
        </div>
        <div className={styles['kut-user-info']}>
          <div
            className={cn([
              styles['kut-user-info-pronouns'],
              styles['kut-input-form'],
              styles['required'],
            ])}
          >
            <p className={styles['kut-heading']}>
              {getStringByLanguage('PRONOUNS')}
            </p>
            <Select
              className={styles['kut-content']}
              name={`gender`}
              onChange={formik.handleChange}
              options={genderOptions}
              value={genderOptions.find(
                (value) => value.value === formik.values.gender,
              )}
              styles={{
                control: (baseStyle) => ({
                  ...baseStyle,
                  boxShadow: 'none',
                  border: 'none',
                  backgroundColor: 'transparent',
                  minHeight: '2.375em',
                }),
                singleValue: (baseStyle) => ({
                  ...baseStyle,
                  color: 'inherit',
                }),
                indicatorSeparator: (baseStyle) => ({
                  ...baseStyle,
                  width: 0,
                }),
                dropdownIndicator: (baseStyle) => ({
                  ...baseStyle,
                  padding: '0px',
                  marginRight: '0.5em',
                  color: colorDict['color-black-strong-light'],
                }),
                menu: (baseStyle) => ({
                  ...baseStyle,
                  backgroundColor: 'inherit',
                }),
                option: (baseStyle, state) => {
                  return {
                    ...baseStyle,
                    backgroundColor: state.isSelected
                      ? 'rgb(var(--text-color), .7)'
                      : state.isFocused
                      ? 'rgba(0, 0, 0, .15)'
                      : 'inherit',
                    color: state.isSelected
                      ? 'rgb(var(--content-background-color))'
                      : 'inherit',
                    transition: 'all .3s',
                  };
                },
              }}
            />
          </div>
          <InputForm
            className={cn([
              styles['kut-user-info-name'],
              styles['kut-input-form'],
            ])}
            heading={getStringByLanguage('YOUR_NAME')}
            name={`first_name`}
            onChange={formik.handleChange}
            value={formik.values.first_name}
            error={formik.errors.first_name}
            required
          />
          <InputForm
            className={cn([
              styles['kut-user-info-surname'],
              styles['kut-input-form'],
            ])}
            heading={getStringByLanguage('YOUR_SURNAME')}
            name={`last_name`}
            onChange={formik.handleChange}
            value={formik.values.last_name}
            error={formik.errors.last_name}
            required
          />
          <InputForm
            className={cn([
              styles['kut-user-info-email'],
              styles['kut-input-form'],
            ])}
            heading={getStringByLanguage('EMAIL')}
            name={`email`}
            onChange={formik.handleChange}
            value={formik.values.email}
            error={formik.errors.email}
            required
            readOnly
          />
          <InputForm
            className={cn([
              styles['kut-user-info-ean'],
              styles['kut-input-form'],
            ])}
            heading={
              isDefaultUrl
                ? getStringByLanguage('EAN')
                : getStringByLanguage('PDL')
            }
            name={`ean`}
            onChange={formik.handleChange}
            value={formik.values.ean}
            error={formik.errors.ean}
            readOnly
          />
          <InputForm
            className={cn([
              styles['kut-user-info-job-title'],
              styles['kut-input-form'],
            ])}
            heading={getStringByLanguage('JOB_TITLE')}
            name={`job_title`}
            onChange={formik.handleChange}
            value={formik.values.job_title}
            error={formik.errors.job_title}
          />
        </div>
        <hr className={styles['kut-hr-bottom-none']} />
        <div className={styles['kut-user-company']}>
          <InputForm
            className={cn([
              styles['kut-user-company-name'],
              styles['kut-input-form'],
            ])}
            heading={getStringByLanguage('COMPANY_NAME')}
            name={`company_name`}
            onChange={formik.handleChange}
            value={formik.values.company_name}
            error={formik.errors.company_name}
          />
          <InputForm
            className={cn([
              styles['kut-user-company-vat'],
              styles['kut-input-form'],
            ])}
            heading={getStringByLanguage('VAT')}
            name={`participant_vat`}
            onChange={formik.handleChange}
            value={formik.values.participant_vat}
            error={formik.errors.participant_vat}
          />
          <InputForm
            className={cn([
              styles['kut-user-company-address'],
              styles['kut-input-form'],
            ])}
            heading={getStringByLanguage('ADDRESS')}
            name={`participant_address`}
            onChange={formik.handleChange}
            value={formik.values.participant_address}
            error={formik.errors.participant_address}
          />
          <InputForm
            className={cn([
              styles['kut-user-company-city'],
              styles['kut-input-form'],
            ])}
            heading={getStringByLanguage('CITY')}
            name={`participant_city`}
            onChange={formik.handleChange}
            value={formik.values.participant_city}
            error={formik.errors.participant_city}
          />
          <InputForm
            className={cn([
              styles['kut-user-company-country'],
              styles['kut-input-form'],
            ])}
            heading={getStringByLanguage('COUNTRY')}
            name={`participant_country`}
            onChange={formik.handleChange}
            value={formik.values.participant_country}
            error={formik.errors.participant_country}
          />
          <InputForm
            className={cn([
              styles['kut-user-company-postcode'],
              styles['kut-input-form'],
            ])}
            heading={getStringByLanguage('POSTCODE')}
            name={`participant_postalcode`}
            onChange={formik.handleChange}
            value={formik.values.participant_postalcode}
            error={formik.errors.participant_postalcode}
          />
        </div>
        <hr />
        <div className={styles['kut-bottom-form']}>
          <div className={styles['kut-profile-bottom-status']}>
            {userInfo.refer && (
              <>
                <p>
                  {getStringByLanguage('INVITED_BY')}{' '}
                  {userInfo.refer.first_name} {userInfo.refer.last_name}{' '}
                  {getStringByLanguage('ON')} {userInfo.refer.date}
                </p>
                <p>
                  <CiWarning />{' '}
                  {getStringByLanguage('PERMANENTLY_DEACTIVATE_ACCOUNT')}
                </p>
              </>
            )}
          </div>
          <div className={styles['kut-bottom-actions']}>
            <Button type={BUTTON_TYPE.DEFAULT} outline={true}>
              {getStringByLanguage('CANCEL')}
            </Button>
            <Button type={BUTTON_TYPE.PRIMARY} onClick={handleSubmit}>
              {getStringByLanguage('SAVE_UPDATES')}
            </Button>
          </div>
        </div>
      </form>
    </FormikProvider>
  );
};

export default UserProfileComponent;
