import { FC } from 'react';
import { IconType } from 'react-icons';
import { BsCheckCircle, BsInfoCircle, BsXCircle } from 'react-icons/bs';
import { ImBlocked, ImWarning } from 'react-icons/im';

import styles from './styles.module.scss';

export enum STATUS {
  PRIMARY = 'Primary',
  SECONDARY = 'Secondary',
  SUCCESS = 'Success',
  WARNING = 'Warning',
  DANGER = 'Danger',
  INFO = 'Info',
}

const _default = {
  themes: {
    [STATUS.PRIMARY]: styles['kut-status-primary'],
    [STATUS.SECONDARY]: styles['kut-status-secondary'],
    [STATUS.SUCCESS]: styles['kut-status-success'],
    [STATUS.WARNING]: styles['kut-status-warning'],
    [STATUS.DANGER]: styles['kut-status-danger'],
    [STATUS.INFO]: styles['kut-status-info'],
  },
  texts: {
    [STATUS.PRIMARY]: 'Signed',
    [STATUS.SECONDARY]: 'Blocked',
    [STATUS.SUCCESS]: 'Paid',
    [STATUS.WARNING]: 'Warning',
    [STATUS.DANGER]: 'Not paid yet',
    [STATUS.INFO]: 'Pending',
  },
  icons: {
    [STATUS.PRIMARY]: BsCheckCircle,
    [STATUS.SECONDARY]: ImBlocked,
    [STATUS.SUCCESS]: BsCheckCircle,
    [STATUS.WARNING]: ImWarning,
    [STATUS.DANGER]: BsXCircle,
    [STATUS.INFO]: BsInfoCircle,
  },
};

interface FuncGroup {
  className?: string;
  IconComponent?: IconType;
  text?: string;
  status?: STATUS;
}

const StatusSpan: FC<FuncGroup> = ({
  className = '',
  text,
  IconComponent,
  status = STATUS.DANGER,
  ...props
}) => {
  IconComponent = IconComponent || _default.icons[status];
  text = text || _default.texts[status];

  return (
    <div
      className={`${styles['kut-status-span']} ${_default.themes[status]} ${className}`}
      {...props}
    >
      <IconComponent className={`${styles['kut-status-icon']}`} />
      <span>{text}</span>
    </div>
  );
};

export default StatusSpan;
