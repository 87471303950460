export interface PaginationProps<T> {
  search?: string;
  page?: number;
  perPage?: number;
  sort?: 'ASC' | 'DESC';
  sortField?: keyof T;
}

export interface RequestProps {
  community_id?: number;
  participant_id?: number;
}

export interface RequestWithPaginationProps<T>
  extends RequestProps,
    PaginationProps<T> {}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface ResponseProps<T = any> {
  status: number;
  message?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: T;
}

export interface ParamsObject {
  [key: string]: string | Date | boolean | object | number | undefined;
}
export interface AnyObject {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

export const params2String = (
  params: AnyObject | AnyObject[],
  prefix?: string,
) => {
  const keys = Object.keys(params);
  const values = Object.values(params);

  const url: string[] = [];
  keys.forEach((key, index) => {
    const pre = prefix ? prefix + '[' + key + ']' : key,
      value = values[index];
    if (value !== undefined) {
      url.push(
        value instanceof Date || typeof value !== 'object'
          ? encodeURIComponent(pre) + '=' + encodeURIComponent(value.toString())
          : params2String(value, pre),
      );
    }
  });

  return url.filter((s) => s.length).join('&');
};

export const prepareRequestParams = (params: AnyObject) => {
  const url = params2String(params);
  if (!url.length) {
    return url;
  }

  return `?${url}`;
};
