import requestAPI from '../utils/requestAPI';
import { getStringByLanguage } from '../utils/utils';
import { ResponseProps } from './utils';

export enum DeviceMode {
  Producer = 'Producer',
  Consumer = 'Consumer',
  Prosumer = 'Prosumer',
}

export const printDeviceMode = (type: DeviceMode) => {
  return type === DeviceMode.Producer
    ? getStringByLanguage('PRODUCER')
    : type === DeviceMode.Consumer
    ? getStringByLanguage('CONSUMER')
    : type === DeviceMode.Prosumer
    ? getStringByLanguage('PROSUMER')
    : '';
};

export interface DeviceTypeInfo {
  id: number;
  device_type_name: string;
  device_type_mode: DeviceMode;
  device_type_image: string | null;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
}

export const getDeviceTypes = async () => {
  return await requestAPI.get<ResponseProps<[DeviceTypeInfo[], number]>>(
    '/deviceType',
  );
};
