import { FC, HTMLAttributes } from 'react';
import cn from 'classnames';

import styles from './styles.module.scss';

interface SubComponentProps {
  text?: string;
  onClick: () => void;
}

export interface RadioItemProps {
  value: number | string;
  text?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props?: { [key: string]: any };
  component?: FC<SubComponentProps>;
}

interface FuncGroup extends Omit<HTMLAttributes<HTMLDivElement>, 'onChange'> {
  itemList: RadioItemProps[];
  groupId: string;
  value: number | string;
  component?: FC<SubComponentProps>;
  readOnly?: boolean;
  onChange: (value: number | string) => void;
}

const RadioGroup: FC<FuncGroup> = ({
  className = '',
  itemList,
  groupId,
  value: groupValue,
  component,
  readOnly,
  onChange,
  ...props
}) => {
  return (
    <div
      className={cn([className, styles['kut-radio-group-component']])}
      {...props}
    >
      {itemList.map((item, i) => {
        const { value, text, component: child, props: childProps = {} } = item;
        const RadioItem = component ? component : child ? child : null;
        return (
          <div className={cn([styles['kut-radio-item']])} key={i}>
            <input
              name={`kut-radio-buttons-${groupId}`}
              type="radio"
              value={value}
              onChange={() => !readOnly && onChange(value)}
              checked={groupValue === value}
            />
            {RadioItem ? (
              <RadioItem
                onClick={() => onChange(value)}
                text={text}
                {...childProps}
              />
            ) : (
              <span onClick={() => onChange(value)}>{text}</span>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default RadioGroup;
