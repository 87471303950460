import { _global } from '../config/config';
import requestAPI from '../utils/requestAPI';
import { isParticipantView } from '../utils/utils';
import { DeviceTypeInfo } from './deviceTypes';
import { ParticipantInfo } from './participants';
import { StreamInfo } from './streams';
import { UserRole } from './users';
import { PaginationProps, prepareRequestParams, ResponseProps } from './utils';

export interface DeviceInfo {
  id: number;
  participant_id: number;
  device_type_id?: number;
  device_name: string;
  device_tag: string | null;
  ean_code: string;
  ean_status: boolean;
  power: number;
  declination: number;
  azimuth: number;
  created_at: string;
  updated_at?: string;
  deleted_at?: string | null;
  participant: ParticipantInfo;
  deviceType: DeviceTypeInfo;
}

interface DeviceReqProps {
  community_id?: number | null;
  participant_id?: number | null;
  withDeleted?: boolean;
}

export interface DeviceResponse {
  devices: DeviceInfo[];
  streams?: (StreamInfo | undefined)[];
}

export const getDeviceInfo = async (
  id?:
    | number
    | string
    | {
        id?: number | string;
        participant_id: number | string;
        community_id?: number | string;
      },
) => {
  if (typeof id !== 'object') {
    id = {
      id: id,
      participant_id: _global.user?.participant?.id || 0,
      community_id: _global.user?.participant?.community_id || 0,
    };
  }

  const { community_id = 0, participant_id = 0, id: device_id = 0 } = id;

  const url = `community/${community_id}/participant/${participant_id}/device/${device_id}`;
  return await requestAPI.get<ResponseProps<DeviceInfo>>(url);
};

export const getCommunityDeviceCount = async ({
  community_id,
  withDeleted,
}: {
  community_id?: number | null;
  withDeleted?: boolean;
} = {}) => {
  let url = '';

  switch (_global.user?.role) {
    case UserRole.Community_Manager:
    case UserRole.Participant:
      community_id = community_id || _global.user?.participant?.community_id;
      url += `community/${community_id}/device/count`;
      break;
    default:
      url += `community/${community_id}/device/count`;
      break;
  }

  url += prepareRequestParams({
    withDeleted,
  });

  return await requestAPI.get<
    ResponseProps<{
      producers: [Pick<DeviceInfo, 'id' | 'deleted_at'>[], number];
      consumers: [Pick<DeviceInfo, 'id' | 'deleted_at'>[], number];
      prosumers: [Pick<DeviceInfo, 'id' | 'deleted_at'>[], number];
    }>
  >(url);
};

export const getDevices = async ({
  community_id,
  participant_id,
  ...data
}: DeviceReqProps & PaginationProps<DeviceInfo> = {}) => {
  let url = '';

  switch (_global.user?.role) {
    case UserRole.Community_Manager:
      community_id = community_id || _global.user?.participant?.community_id;
      if (!participant_id && !isParticipantView()) {
        url += `community/${community_id}/device`;
        break;
      }
      participant_id = participant_id || _global.user?.participant?.id;
      url += `community/${community_id}/participant/${participant_id}/device`;
      break;
    case UserRole.Participant:
      community_id = community_id || _global.user?.participant?.community_id;
      participant_id = participant_id || _global.user?.participant?.id;
      url += `community/${community_id}/participant/${participant_id}/device`;
      break;
    default:
      if (!participant_id) {
        url += `community/${community_id}/device`;
        break;
      }
      url += `community/${community_id}/participant/${participant_id}/device`;
      break;
  }

  const { search, page, perPage, sort, withDeleted, sortField } = data;

  url += prepareRequestParams({
    search,
    page,
    perPage,
    sort,
    sortField,
    withDeleted,
  });

  return await requestAPI.get<ResponseProps<[DeviceInfo[], number]>>(url);
};

export const addDevice = async (
  community_id: string | number | null | undefined,
  participant_id: string | number | null | undefined,
  data: Partial<DeviceInfo>,
) => {
  return (await requestAPI.post)<ResponseProps<DeviceInfo>>(
    `/community/${community_id}/participant/${participant_id}/device`,
    data,
  );
};

export const updateDevice = async (
  community_id: string | number | null | undefined,
  participant_id: string | number | null | undefined,
  data: Partial<DeviceInfo>,
) => {
  delete data.deviceType;
  return (await requestAPI.patch)<ResponseProps<DeviceInfo>>(
    `/community/${community_id}/participant/${participant_id}/device/${data.id}`,
    data,
  );
};

export const deleteDevice = async (
  community_id: string | number | null | undefined,
  participant_id: string | number | null | undefined,
  id: number,
  forever = false,
) => {
  return (await requestAPI.delete)<ResponseProps<DeviceInfo>>(
    `/community/${community_id}/participant/${participant_id}/device/${id}?safeMode=${!forever}`,
  );
};
