import React, { useEffect, useState } from 'react';
import { FC } from 'react';
import cn from 'classnames';
import { Oval } from 'react-loader-spinner';

import { colorDict } from '../../utils/utils';
import { useHTMLScroll } from '../../services/hooks';

import styles from './styles.module.scss';

export enum SortOrder {
  ASC = 'asc',
  DESC = 'desc',
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AnyData = any;

interface FuncGroup {
  className?: string;
  data?: AnyData[];
  selectAll: boolean;
  setSelectAll: (value: React.SetStateAction<boolean>) => void;
  isEventFromAll: boolean;
  setIsEventFromAll: (value: React.SetStateAction<boolean>) => void;
  ChildComponent: (props: {
    className: string;
    onChange: (status: boolean) => void;
    checked: boolean;
    data: AnyData;
  }) => JSX.Element;
  onItemSelected: (selected: {
    allSelected: boolean;
    selectedCount: number;
    selectedRows: AnyData[];
  }) => void;
  requestFunc?: (options?: AnyData) => Promise<AnyData>;
  urlParam?: { [key: string]: AnyData };
}

const ListView: FC<FuncGroup> = ({
  className = '',
  data = [],
  selectAll,
  setSelectAll,
  isEventFromAll,
  setIsEventFromAll,
  ChildComponent,
  onItemSelected,
  requestFunc,
  urlParam,
}) => {
  const { isEndOfScroll } = useHTMLScroll();
  const [listData, setListData] = useState<AnyData[]>(data);
  const [totalRows, setTotalRows] = useState(data.length);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [forceReload, setForceReload] = useState(false);
  const [checkStatus, setCheckStatus] = useState(data.map(() => false));

  const perPage = 20;

  useEffect(() => {
    let dt: AnyData[];
    if (data.length) dt = data;
    else dt = listData;
    setListData(dt);
  }, []);

  useEffect(() => {
    if (data.length) {
      setListData(data);
    }
  }, [data]);

  useEffect(() => {
    if (isEventFromAll) {
      setCheckStatus(listData.map(() => selectAll));
      setIsEventFromAll(false);
    }
  }, [isEventFromAll]);

  useEffect(() => {
    const selectedRows = listData.filter((data, i) => checkStatus[i]);
    const selectedCount = selectedRows.length;
    setSelectAll(selectedCount === listData.length);
    onItemSelected({
      allSelected: selectedCount === listData.length,
      selectedCount: selectedCount,
      selectedRows: selectedRows,
    });
  }, [checkStatus]);

  useEffect(() => {
    const fetchedRows = page * perPage;
    if (fetchedRows < totalRows) {
      setPage(page + 1);
    }
  }, [isEndOfScroll]);

  useEffect(() => {
    if (urlParam) {
      setPage(1);
      setForceReload(true);
      setListData([]);
    }
  }, [urlParam]);

  useEffect(() => {
    setForceReload(true);
  }, [page]);

  useEffect(() => {
    if (forceReload) {
      fetchData();
    }
  }, [forceReload]);

  const fetchData = async () => {
    setLoading(true);

    if (requestFunc) {
      try {
        const response = await requestFunc({
          page,
          perPage,
          ...urlParam,
        });
        if (response.data.data) {
          setListData(listData.concat(response.data.data[0]));
          setCheckStatus(
            checkStatus.concat(response.data.data[0].map(() => false)),
          );
          setTotalRows(response.data.data[1]);
        }
      } catch (error) {
        console.log(error);
      }
    }

    setLoading(false);
    setForceReload(false);
  };

  const onHandleCheck = (idx: number, status: boolean) => {
    const newStatus: boolean[] = checkStatus.map((v) => v);
    newStatus[idx] = status;
    setCheckStatus(newStatus);
  };

  return (
    <div className={cn([className, styles['kut-list-view']])}>
      {listData.map((data, i) => (
        <ChildComponent
          className={cn([styles['kut-list-item']])}
          checked={checkStatus[i]}
          onChange={(status) => onHandleCheck(i, status)}
          key={i}
          data={data}
        />
      ))}

      <Oval
        visible={!!loading}
        wrapperClass={styles['kut-list-loading']}
        color={colorDict['color-primary']}
        secondaryColor={colorDict['color-primary-little-light']}
        width={80}
        height={150}
        strokeWidth={2}
        strokeWidthSecondary={2}
      />
    </div>
  );
};

export default ListView;
