import { FC } from 'react';
import {
  Routes,
  Route,
  Navigate,
  BrowserRouter as Router,
} from 'react-router-dom';

import MainLayout from '../layouts/MainLayout';

import { URL_LIST } from './urls';
import { getLoginedUserInfo } from '../services/reducers/userReducer';
import { useAppSelector } from '../services/hooks';
import { UserRole } from '../apis/users';

const AppRouter: FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const userInfo = useAppSelector(getLoginedUserInfo);

  return (
    <Router>
      <Routes>
        {URL_LIST.map((urlItem, index) => (
          <Route
            key={index}
            path={urlItem.path}
            element={
              urlItem.session ? (
                userInfo ? (
                  urlItem.layout ? (
                    <MainLayout>
                      <urlItem.component />
                    </MainLayout>
                  ) : (
                    <urlItem.component />
                  )
                ) : (
                  <Navigate to="/signin" />
                )
              ) : userInfo ? (
                userInfo.role === UserRole.Super_Admin ||
                userInfo.role === UserRole.Sub_Admin ? (
                  <Navigate to="/manage/communities" />
                ) : (
                  <Navigate to="/community" />
                )
              ) : urlItem.layout ? (
                <MainLayout>
                  <urlItem.component />
                </MainLayout>
              ) : (
                <urlItem.component />
              )
            }
          />
        ))}
        <Route path="*" element={<p>Page not found. (404)</p>} />
      </Routes>
    </Router>
  );
};

export default AppRouter;
