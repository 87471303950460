import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { languageOptions, UserInfo } from '../../apis/users';
import { RootState } from '../store';

export interface UserState {
  userInfo?: UserInfo;
}

const initialState: UserState = {
  userInfo: undefined,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    userSignin: (state, action: PayloadAction<UserState['userInfo']>) => {
      if (!action.payload) {
        return;
      }
      state.userInfo = { ...state.userInfo, ...action.payload };
      const language = state.userInfo?.language;
      localStorage.setItem(
        'lang',
        (
          languageOptions.find((value) => value.value === language) || {
            src: 'en',
          }
        ).src,
      );
    },
    userLogout: (state) => {
      state.userInfo = undefined;
    },
  },
});

export const { userSignin, userLogout } = userSlice.actions;

export const getLoginedUserInfo = (state: RootState) => state.user.userInfo;
export const getUserLanguage = (state: RootState) =>
  (
    languageOptions.find(
      (value) => value.value === state.user.userInfo?.language,
    ) || { src: 'en' }
  ).src;

export default userSlice.reducer;
