import { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';
import cn from 'classnames';

import { MenuItemProps } from '../Sidebar/Sidebar';

import styles from './styles.module.scss';

interface FuncGroup {
  itemList: MenuItemProps[];
  handlePageTitle: (menuItem: MenuItemProps) => void;
}

const Footer: FC<FuncGroup> = ({ itemList, handlePageTitle }) => {
  const params = useLocation();

  return (
    <>
      <div
        className={cn([
          styles['main-body-footer-container'],
          'kut-device-mobile',
        ])}
      >
        {itemList.map((item, index) => (
          <div
            className={cn([
              styles['kut-footer-item'],
              item.path === params.pathname ? styles['active'] : '',
              item.unReadCount ? styles['new-item'] : '',
            ])}
            key={index}
            onClick={() => handlePageTitle(item)}
          >
            {item.icon}
          </div>
        ))}
      </div>
      <div
        className={cn([
          styles['main-body-footer-container'],
          'kut-device-mobile',
          styles['fake'],
        ])}
      >
        {itemList.map((item, index) => (
          <Link
            className={cn([
              styles['kut-footer-item'],
              item.path === params.pathname ? styles['active'] : '',
              item.unReadCount ? styles['new-item'] : '',
            ])}
            key={index}
            to={item.path || '#'}
          >
            {item.icon}
          </Link>
        ))}
      </div>
    </>
  );
};

export default Footer;
