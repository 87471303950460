import { FC } from 'react';
// import { FC, useEffect, useState } from "react";
import { Oval } from 'react-loader-spinner';
import { useAppDispatch, useAppSelector } from '../../services/hooks';
import {
  hideLoading,
  getLoadingStatus,
  showLoading,
} from '../../services/reducers/sharedReducer';
import { colorDict } from '../../utils/utils';

import styles from './styles.module.scss';

interface FuncGroup {
  className?: string;
}
const LoadingBar: FC<FuncGroup> = ({ className = '', ...props }) => {
  const loading = useAppSelector(getLoadingStatus);

  return (
    <Oval
      visible={!!loading}
      wrapperClass={`${styles.loadingComponent} ${className} ${
        loading ? styles.active : styles.inactive
      }`}
      color={colorDict['color-primary']}
      secondaryColor={colorDict['color-primary-little-light']}
      width={80}
      height={800}
      strokeWidth={2}
      strokeWidthSecondary={2}
      {...props}
    />
  );
};

export const useShowLoadingBar = () => {
  const dispatch = useAppDispatch();
  const showLoadingBar = () => dispatch(showLoading());

  return showLoadingBar;
};

export const useHideLoadingBar = () => {
  const dispatch = useAppDispatch();
  const hideLoadingBar = () => dispatch(hideLoading());

  return hideLoadingBar;
};

export const useLoadingBars = () => {
  const dispatch = useAppDispatch();
  const showLoadingBar = () => dispatch(showLoading());
  const hideLoadingBar = () => dispatch(hideLoading());

  return [showLoadingBar, hideLoadingBar];
};

// export interface LoadingBarControllers {
// 	showLoadingBar: () => void,
// 	hideLoadingBar: () => void,
// }

// export const useLoadingBars = () => {
// 	const dispatch = useAppDispatch();
// 	const [loadingBarControllers, setLoadingBarControllers] = useState<LoadingBarControllers>({
// 		showLoadingBar: () => {},
// 		hideLoadingBar: () => {},
// 	});

// 	useEffect(() => {
// 		setLoadingBarControllers({
// 			showLoadingBar: () => dispatch(showLoading()),
// 			hideLoadingBar: () => dispatch(hideLoading()),
// 		});
// 	}, [dispatch]);

// 	return loadingBarControllers;
// }

export default LoadingBar;
