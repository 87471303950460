import { _global } from '../config/config';
import requestAPI from '../utils/requestAPI';
import { UserRole } from './users';
import { PaginationProps, prepareRequestParams, ResponseProps } from './utils';

export enum NOTIFICATION_TYPE {
  Weather = 'Weather',
  Invoice = 'Invoice',
  Other = 'Other',
}

export const NOTIFICATION_STATUS = {
  NONE: 0,
  CHECKED: 0x1,
  FOLLOWED: 0x2,
  NOT_AGAIN: 0x4,
};

export interface NotificationFields {
  [key: string]: string | number | Date;
}

export interface NotificationInfo {
  id: number;
  participant_id: number;
  notification_type: NOTIFICATION_TYPE;
  notification_title: string | null;
  notification_text: string | null;
  notification_subtext: string | null;
  notification_start_at: Date;
  notification_end_at: Date;
  notification_fields: NotificationFields | null;
  notification_status: number;
}

export const getNotifications = async (
  data?: {
    community_id?: number | string | null;
    participant_id?: number;
  } & PaginationProps<NotificationInfo>,
) => {
  let { community_id, participant_id } = data || {};
  let url = '';

  switch (_global.user?.role) {
    case UserRole.Super_Admin:
      url += `community/${community_id}/participant/${participant_id}/notification`;
      break;
    case UserRole.Sub_Admin:
      url += `community/${community_id}/participant/${participant_id}/notification`;
      break;
    case UserRole.Community_Manager:
      community_id = community_id || _global.user?.participant?.community_id;
      if (!participant_id) {
        url += `community/${community_id}/notification`;
        break;
      }
      url += `community/${community_id}/participant/${participant_id}/notification`;
      break;
    case UserRole.Participant:
      community_id = community_id || _global.user?.participant?.community_id;
      participant_id = participant_id || _global.user?.participant?.id;
      url += `community/${community_id}/participant/${participant_id}/notification`;
      break;
  }

  const { search, page, perPage, sort, sortField } = data || {};

  url += prepareRequestParams({
    search,
    page,
    perPage,
    sort,
    sortField,
  });

  return await requestAPI.get<ResponseProps<[NotificationInfo[], number]>>(url);
};

export const addNotification = async (
  community_id: string | number | null | undefined,
  participant_id: string | number | null | undefined,
  data: Partial<NotificationInfo>,
) => {
  return (await requestAPI.post)<ResponseProps<NotificationInfo>>(
    `/community/${community_id}/participant/${participant_id}/notification`,
    data,
  );
};

export const updateNotification = async (
  community_id: string | number | null | undefined,
  participant_id: string | number | null | undefined,
  data: Partial<NotificationInfo>,
) => {
  return (await requestAPI.patch)<ResponseProps<NotificationInfo>>(
    `/community/${community_id}/participant/${participant_id}/notification/${data.id}`,
    data,
  );
};

export const updateNotificationStatus = async ({
  community_id = _global.user?.participant?.community_id,
  participant_id = _global.user?.participant?.id,
  status,
  value,
  data,
}: {
  community_id?: string | number | null;
  participant_id?: string | number | null;
  status: keyof typeof NOTIFICATION_STATUS;
  value: boolean;
  data: Partial<NotificationInfo>;
}) => {
  let url = `/community/${community_id}/participant/${participant_id}/notification/${data.id}/checked`;
  url += prepareRequestParams({
    status,
    value,
  });

  return (await requestAPI.post)<ResponseProps<NotificationInfo>>(url);
};

export const deleteNotification = async (
  {
    community_id = _global.user?.participant?.community_id,
    participant_id = _global.user?.participant?.id,
    id,
  }: {
    community_id?: string | number | null;
    participant_id?: string | number | null;
    id: number;
  },
  forever = false,
) => {
  if (!community_id || !participant_id) {
    return;
  }
  return (await requestAPI.delete)<ResponseProps<NotificationInfo>>(
    `/community/${community_id}/participant/${participant_id}/notification/${id}?safeMode=${!forever}`,
  );
};
