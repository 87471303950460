import { FC, HTMLAttributes } from 'react';
import cn from 'classnames';

import styles from './styles.module.scss';

interface FuncGroup extends HTMLAttributes<HTMLDivElement> {
  text: string;
}

const ToastTemplate: FC<FuncGroup> = ({ className = '', text }) => {
  return (
    <div className={cn([className, styles['toast-template-container']])}>
      <div dangerouslySetInnerHTML={{ __html: text }}></div>
    </div>
  );
};

export const buildToastHTML = (text: string) => {
  return <ToastTemplate text={text} />;
};

export default ToastTemplate;
