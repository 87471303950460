import { BsGrid } from 'react-icons/bs';
import { AiOutlineUnorderedList } from 'react-icons/ai';

import { SwitchItem } from '../../../components/SwitchView/SwitchView';

import { getStringByLanguage } from '../../../utils/utils';
import {
  StreamBasicInfo,
  StreamInfo,
  StreamTag,
  StreamType,
} from '../../../apis/streams';

export const fieldDict: {
  [key: string]: keyof StreamInfo;
} = {
  [getStringByLanguage('TIME')]: '@timestamp',
  [getStringByLanguage('TYPE')]: 'type',
  [getStringByLanguage('TAG')]: 'tag',
  [getStringByLanguage('AMOUNT')]: 'value',
  [getStringByLanguage('UNIT')]: 'unit',
};

export const sortFieldList: (keyof StreamInfo)[] = Object.values(fieldDict);
export const sortStringList: string[] = Object.keys(fieldDict);

export const streamTypes: {
  value: StreamType;
  label: string | number | React.ReactElement;
}[] = [
  {
    value: StreamType.Total,
    label: StreamType.Total,
  },
  {
    value: StreamType.Shared,
    label: StreamType.Shared,
  },
];

export const streamTags: {
  value: StreamTag;
  label: string | number | React.ReactElement;
}[] = [
  {
    value: StreamTag.Consumption,
    label: StreamTag.Consumption,
  },
  {
    value: StreamTag.Production,
    label: StreamTag.Production,
  },
];

export const filterOption = (
  candidate: {
    value: string;
    label: string;
  },
  input: string,
) => {
  if (input) {
    return candidate.label.toLowerCase().indexOf(input.toLowerCase()) !== -1;
  }
  return true;
};

export const switchItems: SwitchItem[] = [
  {
    IconComponent: BsGrid,
  },
  {
    IconComponent: AiOutlineUnorderedList,
  },
];

export const getAmount = (value: StreamBasicInfo['value']): number =>
  typeof value === 'number' ? value : value.sum;
