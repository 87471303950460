import { FC, useEffect, useState } from 'react';
import { TableColumn } from 'react-data-table-component';
import * as DateFNS from 'date-fns';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { BsPlus, BsTrash2 } from 'react-icons/bs';

import Table from '../../../components/Table/Table';
import SwitchView from '../../../components/SwitchView/SwitchView';
import Button, { BUTTON_TYPE } from '../../../components/Button/Button';
import SearchInput from '../../../components/SearchInput/SearchInput';
import { useLoadingBars } from '../../../components/LoadingBar/LoadingBar';
import ListView, { SortOrder } from '../../../components/ListView/ListView';
import Modal, { useModal } from '../../../components/Modal/Modal';
import IOSSwitch from '../../../components/IOSSwitch/IOSSwitch';
import InputForm from '../../../components/InputForm/InputForm';
import LegalStructureCode from '../../../components/LegalStructureCode/LegalStructureCode';
import {
  fieldDict,
  sortFieldList,
  sortStringList,
  switchItems,
} from './Communities.helper';

import {
  CommunityInfo,
  addCommunity,
  deleteCommunity,
  getCommunities,
  updateCommunity,
} from '../../../apis/communities';
import { useDeviceType } from '../../../services/hooks';
import { getStringByLanguage } from '../../../utils/utils';

import styles from './styles.module.scss';

const Communities: FC = () => {
  const [showLoadingBar, hideLoadingBar] = useLoadingBars();
  const { isMobile } = useDeviceType();

  const [activeView, setActiveView] = useState(1);
  const [tableUrlParam, setTableUrlParam] = useState<{
    search: string;
  }>({
    search: '',
  });

  const [listUrlParam, setListUrlParam] = useState<{
    search: string;
    sort: SortOrder;
    sortField: keyof CommunityInfo;
  }>({
    search: '',
    sort: SortOrder.ASC,
    sortField: 'community_name',
  });

  const [selectedItems, setSelectedItems] = useState<{
    allSelected: boolean;
    selectedCount: number;
    selectedRows: CommunityInfo[];
  }>({ allSelected: false, selectedCount: 0, selectedRows: [] });

  const [modalItem, setModalItem] = useState<CommunityInfo | null>(null);
  const [modalDeleteItems, setModalDeleteItems] = useState<CommunityInfo[]>([]);
  const [modalTitle, setModalTitle] = useState('');
  const [selectAll, setSelectAll] = useState(false);
  const [isEventFromAll, setIsEventFromAll] = useState(false);
  const [onlyView, setOnlyView] = useState(false);

  useEffect(() => {
    if (isMobile) {
      setActiveView(0);
    }
  }, [isMobile, activeView]);

  const onViewChange = (index: number) => {
    setActiveView(index);
    setSelectedItems({
      allSelected: false,
      selectedCount: 0,
      selectedRows: [],
    });
    setSelectAll(false);
  };
  const onRowClicked = (community: CommunityInfo) => {
    setModalItem(community);
    setOnlyView(true);
    cuvModal.show();
  };
  const updateRow = (community: CommunityInfo) => {
    setModalItem(community);
    setOnlyView(false);
    cuvModal.show();
  };
  const deleteRow = (community: CommunityInfo) => {
    setModalTitle(
      `${getStringByLanguage('ARE_YOU_SURE_WANT_TO_DELETE')} <b>${
        community.community_name
      }</b> ${getStringByLanguage('COMMUNITY').toLowerCase()}?`,
    );
    setModalDeleteItems([community]);
    deleteModal.show();
  };

  const onDeleteSelected = () => {
    if (!selectedItems.selectedCount) {
      return;
    }
    setModalTitle(
      `${getStringByLanguage(
        'ARE_YOU_SURE_WANT_TO_DELETE',
      )} <b>${`${getStringByLanguage('SELECTED').toLowerCase()} ${
        selectedItems.selectedCount
      } ${getStringByLanguage('COMMUNITIES').toLowerCase()}`}</b>?`,
    );
    setModalDeleteItems(selectedItems.selectedRows);
    deleteModal.show();
  };

  const onCreateNew = () => {
    setModalItem(null);
    setOnlyView(false);
    cuvModal.show();
  };

  const onCreate = async (community: Partial<CommunityInfo>) => {
    showLoadingBar();
    try {
      await addCommunity(community);
      toast.success(getStringByLanguage('SUCCESSFULLY_CREATED'));
      setTableUrlParam({ ...tableUrlParam });
      setListUrlParam({ ...listUrlParam });
      cuvModal.hide();
    } catch (error) {
      console.log(error);
    }
    hideLoadingBar();
  };

  const onUpdate = async (community: Partial<CommunityInfo>) => {
    showLoadingBar();
    try {
      await updateCommunity(community);
      toast.success(getStringByLanguage('SUCCESSFULLY_UPDATED'));
      setTableUrlParam({ ...tableUrlParam });
      setListUrlParam({ ...listUrlParam });
      cuvModal.hide();
    } catch (error) {
      console.log(error);
    }
    hideLoadingBar();
  };

  const onDelete = async (params: { forever?: boolean }) => {
    showLoadingBar();

    const promises = modalDeleteItems.map(async (community) => {
      if (!community.id) {
        throw getStringByLanguage('REQUEST_ERROR');
      }
      return await deleteCommunity(community.id, params.forever);
    });
    try {
      await promises;
      toast.success(getStringByLanguage('SUCCESSFULLY_DELETED'));
      setTableUrlParam({ ...tableUrlParam });
      setListUrlParam({ ...listUrlParam });
      setSelectedItems({
        allSelected: false,
        selectedCount: 0,
        selectedRows: [],
      });
      deleteModal.hide();
    } catch (error) {
      console.log(error);
    }
    hideLoadingBar();
  };

  const onItemSelected = (selected: {
    allSelected: boolean;
    selectedCount: number;
    selectedRows: CommunityInfo[];
  }) => {
    setSelectedItems(selected);
  };

  const onSortChange = (sortField: keyof CommunityInfo) => {
    const sort: SortOrder = listUrlParam.sort;
    if (listUrlParam.sortField === sortField) {
      setListUrlParam({
        ...listUrlParam,
        sort: sort === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC,
      });
    } else {
      setListUrlParam({
        ...listUrlParam,
        sortField,
        sort: SortOrder.ASC,
      });
    }
  };

  const columns: TableColumn<CommunityInfo>[] = [
    {
      name: getStringByLanguage('NAME'),
      sortable: true,
      minWidth: '100px',
      compact: true,
      style: {
        cursor: 'pointer',
      },
      selector: (data) => data.community_name,
    },
    {
      name: getStringByLanguage('CREATED_ON'),
      sortable: true,
      minWidth: '100px',
      compact: true,
      style: {
        cursor: 'pointer',
      },
      selector: (data) =>
        data.created_at
          ? DateFNS.format(new Date(data.created_at), 'dd/MM/yyyy')
          : '',
    },
    {
      name: getStringByLanguage('ADDRESS'),
      sortable: true,
      minWidth: '100px',
      compact: true,
      style: {
        cursor: 'pointer',
      },
      selector: (data) => data.community_address || '',
    },
    {
      name: getStringByLanguage('COUNTRY'),
      sortable: true,
      minWidth: '100px',
      compact: true,
      style: {
        cursor: 'pointer',
      },
      selector: (data) => data.community_country || '',
    },
    {
      name: '',
      minWidth: '70px',
      compact: true,
      cell: (data) => {
        return (
          <Link
            className={styles['table-item-community-dashboard']}
            to={`/community/${data.id}`}
          >
            {getStringByLanguage('COMMUNITY_DASHBOARD')} {'>'}
          </Link>
        );
      },
    },
    {
      name: '',
      minWidth: '70px',
      compact: true,
      cell: (data) => {
        return (
          <Link
            className={styles['table-item-view-participants']}
            to={`/manage/communities/${data.id}/participants`}
          >
            {getStringByLanguage('VIEW_PARTICIPANTS')} {'>'}
          </Link>
        );
      },
    },
    {
      name: '',
      width: '70px',
      compact: true,
      cell: (data) => {
        return (
          <span
            className={styles['table-item-button-update']}
            onClick={() => updateRow(data)}
          >
            {getStringByLanguage('UPDATE')}
          </span>
        );
      },
    },
    {
      name: '',
      sortable: true,
      width: '70px',
      compact: true,
      style: {
        justifyContent: 'center',
      },
      cell: (data) => {
        return (
          <span
            className={styles['table-item-button-delete']}
            onClick={() => deleteRow(data)}
          >
            {getStringByLanguage('DELETE')}
          </span>
        );
      },
    },
  ];

  const cuvModal = useModal<Partial<CommunityInfo>>({
    onSubmit: modalItem ? onUpdate : onCreate,
    initialValues: modalItem || {
      community_name: '',
      community_latitude: 0,
      community_longitude: 0,
      community_price: 0.2,
      supplier_price: 0.4,
      community_address: null,
      community_city: null,
      community_country: null,
      community_postalcode: null,
      community_dso: null,
      community_idn: null,
      community_office_idn: null,
      community_vat: null,
      community_nace: null,
      community_lscn: null,
      community_lscs: null,
    },
    validationSchema: Yup.object().shape({
      community_name: Yup.string().required(
        getStringByLanguage('THIS_FIELD_IS_REQUIRED'),
      ),
      community_latitude: Yup.number().required(
        getStringByLanguage('THIS_FIELD_IS_REQUIRED'),
      ),
      community_longitude: Yup.number().required(
        getStringByLanguage('THIS_FIELD_IS_REQUIRED'),
      ),
      community_price: Yup.number().required(
        getStringByLanguage('THIS_FIELD_IS_REQUIRED'),
      ),
      supplier_price: Yup.number().required(
        getStringByLanguage('THIS_FIELD_IS_REQUIRED'),
      ),
      community_address: Yup.string().nullable().notRequired(),
      community_city: Yup.string().nullable().notRequired(),
      community_country: Yup.string().nullable().notRequired(),
      community_postalcode: Yup.string().nullable().notRequired(),
      community_dso: Yup.string().nullable().notRequired(),
      community_idn: Yup.number().nullable().notRequired(),
      community_office_idn: Yup.number().nullable().notRequired(),
      community_vat: Yup.number().nullable().notRequired(),
      community_nace: Yup.number().nullable().notRequired(),
      community_lscn: Yup.number().nullable().notRequired(),
      community_lscs: Yup.string().when(
        ['community_lscn'],
        (community_lscn, schema) => {
          if (community_lscn) {
            return schema.required();
          }
          return schema.nullable().notRequired();
        },
      ),
    }),
    customStyle: {
      content: {
        maxWidth: '800px',
      },
    },
  });
  const deleteModal = useModal<{ forever?: boolean }>({
    onSubmit: onDelete,
    initialValues: {
      forever: false,
    },
    validationSchema: Yup.object().shape({
      forever: Yup.boolean().required(),
    }),
  });

  return (
    <div className={styles['kut-component-body']}>
      <div className={styles['kut-component-content']}>
        <div className={styles['kut-component-header']}>
          <div
            className={cn([
              styles['kut-component-sort'],
              activeView === 1 ? styles['hide'] : '',
            ])}
          >
            <span>{getStringByLanguage('SORT_BY')}</span>
            {sortFieldList.map((field, i) => (
              <span
                key={i}
                className={cn([
                  styles['kut-component-sort-option'],
                  listUrlParam.sortField === field ? styles['active'] : '',
                  styles[listUrlParam.sort],
                ])}
                onClick={() => onSortChange(field)}
              >
                {sortStringList[i]}
              </span>
            ))}
          </div>
          <div
            className={cn([
              styles['kut-component-select-all'],
              activeView === 1 ? styles['hide'] : '',
            ])}
          >
            <input
              type="checkbox"
              checked={selectAll}
              onChange={(event) => {
                const status = event.target.checked;
                setSelectAll(status);
                setIsEventFromAll(true);
              }}
              ref={(input) => {
                if (input) {
                  const status = selectAll;
                  if (!status && selectedItems.selectedCount) {
                    input.indeterminate = true;
                    return;
                  }
                  input.indeterminate = false;
                  status ? setSelectAll(true) : setSelectAll(false);
                }
              }}
            />
            <span>{getStringByLanguage('SELECT_ALL')}</span>
          </div>
          {!isMobile && (
            <SwitchView
              className={styles['kut-component-switch-view']}
              items={switchItems}
              selected={activeView}
              onViewChange={onViewChange}
            />
          )}
        </div>
        {activeView === 1 && (
          <div
            className={cn([
              styles['kut-component-table'],
              styles['kut-component-active'],
            ])}
          >
            <div className={styles['kut-component-actions']}>
              <Button
                className={cn([
                  styles['kut-component-buttons'],
                  styles['kut-buttons-large'],
                ])}
                outline={true}
                onClick={onCreateNew}
              >
                + {getStringByLanguage('CREATE_NEW')}
              </Button>
              <SearchInput
                className={styles['kut-component-search']}
                value={tableUrlParam.search}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setTableUrlParam({
                    search: event.target.value,
                  })
                }
              />
              <Button
                className={cn([
                  styles['kut-component-buttons'],
                  styles['kut-buttons-large'],
                  styles['kut-component-button-delete'],
                  selectedItems.selectedCount ? styles['kut-active'] : '',
                ])}
                type={BUTTON_TYPE.DANGER}
                onClick={onDeleteSelected}
              >
                {getStringByLanguage('DELETE_SELECTED')}
              </Button>
              <Button
                className={cn([
                  styles['kut-component-buttons'],
                  styles['kut-buttons-small'],
                ])}
                onClick={onCreateNew}
              >
                <BsPlus />
              </Button>
              <Button
                className={cn([
                  styles['kut-component-buttons'],
                  styles['kut-buttons-small'],
                  styles['kut-component-button-delete'],
                  selectedItems.selectedCount ? styles['kut-active'] : '',
                ])}
                type={BUTTON_TYPE.DANGER}
                onClick={onDeleteSelected}
              >
                <BsTrash2 />
              </Button>
            </div>
            <Table
              pagination={true}
              selectableRows
              onSelectedRowsChange={onItemSelected}
              onRowClicked={onRowClicked}
              urlParam={tableUrlParam}
              requestFunc={getCommunities}
              fieldDict={fieldDict}
              columns={columns}
              paginationPerPage={10}
              paginationRowsPerPageOptions={[10, 20, 50]}
            />
          </div>
        )}
        {activeView === 0 && (
          <div
            className={cn([
              styles['kut-component-list-view'],
              styles['kut-component-active'],
            ])}
          >
            <div className={styles['kut-component-actions']}>
              <Button
                className={cn([
                  styles['kut-component-buttons'],
                  styles['kut-buttons-large'],
                ])}
                outline={true}
                onClick={onCreateNew}
              >
                + {getStringByLanguage('CREATE_NEW')}
              </Button>
              <SearchInput
                className={styles['kut-component-search']}
                value={listUrlParam.search}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setListUrlParam({
                    ...listUrlParam,
                    search: event.target.value,
                  })
                }
              />
              <Button
                className={cn([
                  styles['kut-component-buttons'],
                  styles['kut-buttons-large'],
                  styles['kut-component-button-delete'],
                  selectedItems.selectedCount ? styles['kut-active'] : '',
                ])}
                type={BUTTON_TYPE.DANGER}
                onClick={onDeleteSelected}
              >
                {getStringByLanguage('DELETE_SELECTED')}
              </Button>
              <Button
                className={cn([
                  styles['kut-component-buttons'],
                  styles['kut-buttons-small'],
                ])}
                onClick={onCreateNew}
              >
                <BsPlus />
              </Button>
              <Button
                className={cn([
                  styles['kut-component-buttons'],
                  styles['kut-buttons-small'],
                  styles['kut-component-button-delete'],
                  selectedItems.selectedCount ? styles['kut-active'] : '',
                ])}
                type={BUTTON_TYPE.DANGER}
                onClick={onDeleteSelected}
              >
                <BsTrash2 />
              </Button>
            </div>
            <ListView
              urlParam={listUrlParam}
              selectAll={selectAll}
              setSelectAll={setSelectAll}
              isEventFromAll={isEventFromAll}
              setIsEventFromAll={setIsEventFromAll}
              onItemSelected={onItemSelected}
              requestFunc={getCommunities}
              ChildComponent={({
                className,
                onChange,
                checked,
                data,
              }: {
                className: string;
                onChange: (status: boolean) => void;
                checked: boolean;
                data: CommunityInfo;
              }) => (
                <div
                  className={cn([className, styles['kut-component-list-item']])}
                >
                  <div className={cn([styles['list-item-heading']])}>
                    <p
                      className={cn([
                        styles['list-item-name'],
                        'kut-tooltip-item',
                      ])}
                      data-tooltip-content={getStringByLanguage(
                        'CLICK_TO_SEE_DETAILS',
                      )}
                      onClick={() => onRowClicked(data)}
                    >
                      <b>{data.community_name}</b>&nbsp;
                      {getStringByLanguage('COMMUNITY')} {' >'}
                    </p>
                    <input
                      type="checkbox"
                      checked={checked}
                      onChange={(event) => onChange(event.target.checked)}
                    />
                  </div>
                  <p className={cn([styles['list-item-created']])}>
                    {getStringByLanguage('CREATED_ON')}{' '}
                    {data.created_at
                      ? DateFNS.format(new Date(data.created_at), 'dd/MM/yyyy')
                      : ''}
                  </p>
                  <Link
                    className={cn([styles['list-item-view-participants']])}
                    to={`/manage/communities/${data.id}/participants`}
                  >
                    {getStringByLanguage('VIEW_PARTICIPANTS')} {'>'}
                  </Link>
                  <div className={cn([styles['list-item-content']])}>
                    <div>
                      <span>{getStringByLanguage('LATITUDE')}</span>
                      <span>{data.community_latitude}</span>
                    </div>
                    <div>
                      <span>{getStringByLanguage('LONGITUDE')}</span>
                      <span>{data.community_longitude}</span>
                    </div>
                    <div>
                      <span>{getStringByLanguage('COMMUNITY_PRICE')}</span>
                      <span>{data.community_price}</span>
                    </div>
                    <div>
                      <span>
                        {getStringByLanguage('AVERAGE_SUPPLIER_PRICE')}
                      </span>
                      <span>{data.supplier_price}</span>
                    </div>
                  </div>
                  <div className={cn([styles['list-item-actions']])}>
                    <div>
                      <Link
                        className={cn([
                          styles['list-item-community-dashboard'],
                        ])}
                        to={`/community/${data.id}`}
                      >
                        {getStringByLanguage('COMMUNITY_DASHBOARD')} {'>'}
                      </Link>
                    </div>
                    <div>
                      <span
                        className={cn([styles['list-item-button-update']])}
                        onClick={() => updateRow(data)}
                      >
                        {getStringByLanguage('UPDATE')}
                      </span>
                      <span
                        className={cn([styles['list-item-button-delete']])}
                        onClick={() => deleteRow(data)}
                      >
                        {getStringByLanguage('DELETE')}
                      </span>
                    </div>
                  </div>
                </div>
              )}
            />
          </div>
        )}
        <Modal {...cuvModal}>
          {(formik) => {
            return (
              <form className={cn([styles['kut-modal-form']])}>
                <p className={cn([styles['kut-title']])}>
                  {onlyView
                    ? formik.values.community_name +
                      ' ' +
                      getStringByLanguage('COMMUNITY')
                    : modalItem
                    ? getStringByLanguage(['UPDATE', 'COMMUNITY'])
                    : getStringByLanguage(['CREATE', 'COMMUNITY'])}
                </p>
                <hr />
                <div className={cn([styles['kut-modal-viewport']])}>
                  <div className={cn([styles['kut-modal-column']])}>
                    {!onlyView && (
                      <InputForm
                        className={cn([
                          styles['kut-form-community-name'],
                          styles['kut-input-form'],
                        ])}
                        heading={getStringByLanguage('COMMUNITY_NAME')}
                        name={'community_name'}
                        onChange={formik.handleChange}
                        required
                        value={formik.values.community_name}
                        error={
                          formik.touched.community_name &&
                          formik.errors.community_name
                        }
                      />
                    )}
                    <InputForm
                      className={cn([
                        styles['kut-form-latitude'],
                        styles['kut-input-form'],
                      ])}
                      heading={getStringByLanguage('LATITUDE')}
                      name={'community_latitude'}
                      onChange={formik.handleChange}
                      required
                      readOnly={onlyView}
                      value={formik.values.community_latitude}
                      error={
                        formik.touched.community_latitude &&
                        formik.errors.community_latitude
                      }
                    />
                    <InputForm
                      className={cn([
                        styles['kut-form-longitude'],
                        styles['kut-input-form'],
                      ])}
                      heading={getStringByLanguage('LONGITUDE')}
                      name={'community_longitude'}
                      onChange={formik.handleChange}
                      required
                      readOnly={onlyView}
                      value={formik.values.community_longitude}
                      error={
                        formik.touched.community_longitude &&
                        formik.errors.community_longitude
                      }
                    />
                    <InputForm
                      className={cn([
                        styles['kut-form-community-price'],
                        styles['kut-input-form'],
                      ])}
                      heading={getStringByLanguage('COMMUNITY_PRICE')}
                      name={'community_price'}
                      onChange={formik.handleChange}
                      required
                      readOnly={onlyView}
                      value={formik.values.community_price}
                      error={formik.errors.community_price}
                    />
                    <InputForm
                      className={cn([
                        styles['kut-form-average-supplier-price'],
                        styles['kut-input-form'],
                      ])}
                      heading={getStringByLanguage('AVERAGE_SUPPLIER_PRICE')}
                      name={'supplier_price'}
                      onChange={formik.handleChange}
                      required
                      readOnly={onlyView}
                      value={formik.values.supplier_price}
                      error={formik.errors.supplier_price}
                    />
                  </div>
                  <div className={cn([styles['kut-modal-column']])}>
                    <InputForm
                      className={cn([
                        styles['kut-form-address'],
                        styles['kut-input-form'],
                      ])}
                      heading={getStringByLanguage(['COMMUNITY', 'ADDRESS'])}
                      name={'community_address'}
                      onChange={formik.handleChange}
                      value={formik.values.community_address}
                      error={
                        formik.touched.community_address &&
                        formik.errors.community_address
                      }
                    />
                    <InputForm
                      className={cn([
                        styles['kut-form-city'],
                        styles['kut-input-form'],
                      ])}
                      heading={getStringByLanguage(['COMMUNITY', 'CITY'])}
                      name={'community_city'}
                      onChange={formik.handleChange}
                      readOnly={onlyView}
                      value={formik.values.community_city}
                      error={
                        formik.touched.community_city &&
                        formik.errors.community_city
                      }
                    />
                    <InputForm
                      className={cn([
                        styles['kut-form-country'],
                        styles['kut-input-form'],
                      ])}
                      heading={getStringByLanguage(['COMMUNITY', 'COUNTRY'])}
                      name={'community_country'}
                      onChange={formik.handleChange}
                      readOnly={onlyView}
                      value={formik.values.community_country}
                      error={
                        formik.touched.community_country &&
                        formik.errors.community_country
                      }
                    />
                    <InputForm
                      className={cn([
                        styles['kut-form-postcode'],
                        styles['kut-input-form'],
                      ])}
                      heading={getStringByLanguage(['COMMUNITY', 'POSTCODE'])}
                      name={'community_postalcode'}
                      onChange={formik.handleChange}
                      readOnly={onlyView}
                      value={formik.values.community_postalcode}
                      error={
                        formik.touched.community_postalcode &&
                        formik.errors.community_postalcode
                      }
                    />
                  </div>
                  <div className={cn([styles['kut-modal-column']])}>
                    <InputForm
                      className={cn([
                        styles['kut-form-dso'],
                        styles['kut-input-form'],
                      ])}
                      heading={getStringByLanguage(['DSO'])}
                      name={'community_dso'}
                      onChange={formik.handleChange}
                      value={formik.values.community_dso}
                      error={
                        formik.touched.community_dso &&
                        formik.errors.community_dso
                      }
                    />
                    <InputForm
                      className={cn([
                        styles['kut-form-idn'],
                        styles['kut-input-form'],
                      ])}
                      heading={getStringByLanguage(['ID_NUMBER'])}
                      name={'community_idn'}
                      onChange={formik.handleChange}
                      readOnly={onlyView}
                      value={formik.values.community_idn}
                      error={
                        formik.touched.community_idn &&
                        formik.errors.community_idn
                      }
                    />
                    <InputForm
                      className={cn([
                        styles['kut-form-office-idn'],
                        styles['kut-input-form'],
                      ])}
                      heading={getStringByLanguage(['OFFICE_ID_NUMBER'])}
                      name={'community_office_idn'}
                      onChange={formik.handleChange}
                      readOnly={onlyView}
                      value={formik.values.community_office_idn}
                      error={
                        formik.touched.community_office_idn &&
                        formik.errors.community_office_idn
                      }
                    />
                  </div>
                  <div className={cn([styles['kut-modal-column']])}>
                    <InputForm
                      className={cn([
                        styles['kut-form-dso'],
                        styles['kut-input-form'],
                      ])}
                      heading={getStringByLanguage(['VAT'])}
                      name={'community_vat'}
                      onChange={formik.handleChange}
                      value={formik.values.community_vat}
                      error={
                        formik.touched.community_vat &&
                        formik.errors.community_vat
                      }
                    />
                    <InputForm
                      className={cn([
                        styles['kut-form-idn'],
                        styles['kut-input-form'],
                      ])}
                      heading={getStringByLanguage(['NACE'])}
                      name={'community_nace'}
                      onChange={formik.handleChange}
                      readOnly={onlyView}
                      value={formik.values.community_nace}
                      error={
                        formik.touched.community_nace &&
                        formik.errors.community_nace
                      }
                    />
                    <LegalStructureCode
                      className={cn([
                        styles['kut-form-legal-structure-code'],
                        styles['kut-input-form'],
                      ])}
                      name="community_lscn"
                      heading={getStringByLanguage(['LEGAL_STRUCTURE_CODE'])}
                      onChange={({ lscn, lscs }) => {
                        formik.setFieldValue('community_lscn', lscn);
                        formik.setFieldValue('community_lscs', lscs);
                      }}
                      readOnly={onlyView}
                      value={{
                        lscn: formik.values.community_lscn,
                        lscs: formik.values.community_lscs,
                      }}
                      error={
                        (formik.touched.community_lscn &&
                          formik.errors.community_lscn) ||
                        (formik.touched.community_lscn &&
                          formik.errors.community_lscs)
                      }
                    />
                  </div>
                </div>
                <div className={cn([styles['kut-form-actions']])}>
                  {!onlyView && (
                    <Button onClick={() => formik.handleSubmit()}>
                      {modalItem
                        ? getStringByLanguage(['SAVE_UPDATES'])
                        : getStringByLanguage(['CREATE'])}
                    </Button>
                  )}
                  <Button
                    type={BUTTON_TYPE.DANGER}
                    onClick={() => cuvModal.hide()}
                  >
                    {getStringByLanguage('CANCEL')}
                  </Button>
                </div>
              </form>
            );
          }}
        </Modal>
        <Modal {...deleteModal}>
          {(formik) => (
            <form
              className={cn([
                styles['kut-modal-form'],
                styles['kut-delete-form'],
              ])}
            >
              <p dangerouslySetInnerHTML={{ __html: modalTitle }} />
              <hr />
              <div className={cn([styles['kut-form-delete-forever']])}>
                <span
                  style={{
                    color: `rgb(var(--text-color))`,
                  }}
                >
                  {getStringByLanguage('DELETE_FOREVER')}?
                </span>
                <IOSSwitch
                  name={'forever'}
                  checked={formik.values.forever}
                  onChange={formik.handleChange}
                />
              </div>
              <p
                className={cn([styles['kut-form-delete-forever-description']])}
              >
                {getStringByLanguage('DELETE_FOREVER_DESCRIPTION')}
              </p>
              <hr />
              <div className={cn([styles['kut-form-actions']])}>
                <Button onClick={() => formik.handleSubmit()}>
                  {getStringByLanguage('DELETE')}
                </Button>
                <Button
                  type={BUTTON_TYPE.DANGER}
                  onClick={() => deleteModal.hide()}
                >
                  {getStringByLanguage('CANCEL')}
                </Button>
              </div>
            </form>
          )}
        </Modal>
      </div>
    </div>
  );
};

export default Communities;
