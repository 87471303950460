import { FC, InputHTMLAttributes } from 'react';
import cn from 'classnames';

import styles from './styles.module.scss';

interface FuncGroup
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'value'> {
  heading: string;
  required?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error?: any;
  value: string | number | readonly string[] | undefined | null;
}

const InputForm: FC<FuncGroup> = ({
  heading,
  required,
  error,
  className = '',
  value,
  ...props
}) => {
  if (value === null) {
    value = '';
  }

  return (
    <div
      className={cn([
        className,
        styles['kut-input-form'],
        required && styles['required'],
      ])}
    >
      <div>
        <p
          className={`${styles['kut-heading']}`}
          style={{ color: props.style?.color }}
        >
          {heading}
        </p>
        <input
          className={`${styles['kut-content']}`}
          value={value}
          {...props}
        />
      </div>
      {error && <p className={`${styles['kut-error']}`}>{error}</p>}
    </div>
  );
};

export default InputForm;
