import { FC } from 'react';
import cn from 'classnames';

import { SubComponentsProps } from './Settings';
import Button, { BUTTON_TYPE } from '../../components/Button/Button';

import { getStringByLanguage } from '../../utils/utils';

import styles from './styles.module.scss';

interface PlanItem {
  id: string;
  classname: string;
  name: string;
  type: string;
  price: string;
  items: { title: string; needsPro: boolean; active: boolean }[];
}

const planList: PlanItem[] = [
  {
    id: 'PRICE_PARTICIPANT',
    classname: styles['kut-select-plan-participant'],
    name: getStringByLanguage('PARTICIPANT'),
    type: getStringByLanguage('MONTHLY_CHARGE'),
    price: getStringByLanguage('FREE'),
    items: [
      {
        title: getStringByLanguage('COMMUNITY_DASHBOARD_SELF_CONSUMPTION_KPIS'),
        needsPro: false,
        active: true,
      },
      {
        title: getStringByLanguage(
          'ENERGY_DATA_DASHBOARD_IF_DSO_DATA_IS_AVAILABLE',
        ),
        needsPro: false,
        active: true,
      },
      {
        title: getStringByLanguage('SETTINGS_AND_NOTIFICATIONS'),
        needsPro: false,
        active: true,
      },
      {
        title: getStringByLanguage('PARTICIPANT_MANAGEMENT'),
        needsPro: false,
        active: false,
      },
      {
        title: getStringByLanguage('DEVICE_MANAGEMENT'),
        needsPro: false,
        active: false,
      },
      {
        title: getStringByLanguage('DISTRIBUTION_OF_SELF_CONSUMED_ENERGY'),
        needsPro: false,
        active: false,
      },
      {
        title: getStringByLanguage('PRICE_MANAGEMENT'),
        needsPro: false,
        active: false,
      },
      {
        title: getStringByLanguage('INVOICING_TO_PARTICIPANTS'),
        needsPro: false,
        active: false,
      },
      {
        title: getStringByLanguage('OPTIMISATION_AND_RECOMMENDATIONS'),
        needsPro: true,
        active: false,
      },
      {
        title: getStringByLanguage('PERSONALISED_DASHBOARDS_AND_BRANDING'),
        needsPro: true,
        active: false,
      },
      {
        title: getStringByLanguage('PREMIUM_HELPDESK'),
        needsPro: true,
        active: false,
      },
    ],
  },
  {
    id: 'PRICE_COMMUNITY_MANAGER',
    classname: styles['kut-select-plan-community-manager'],
    name: getStringByLanguage('COMMUNITY_MANAGER'),
    type: getStringByLanguage('MONTHLY_CHARGE'),
    price: '€49',
    items: [
      {
        title: getStringByLanguage('COMMUNITY_DASHBOARD_SELF_CONSUMPTION_KPIS'),
        needsPro: false,
        active: true,
      },
      {
        title: getStringByLanguage(
          'ENERGY_DATA_DASHBOARD_IF_DSO_DATA_IS_AVAILABLE',
        ),
        needsPro: false,
        active: true,
      },
      {
        title: getStringByLanguage('SETTINGS_AND_NOTIFICATIONS'),
        needsPro: false,
        active: true,
      },
      {
        title: getStringByLanguage('PARTICIPANT_MANAGEMENT'),
        needsPro: false,
        active: true,
      },
      {
        title: getStringByLanguage('DEVICE_MANAGEMENT'),
        needsPro: false,
        active: true,
      },
      {
        title: getStringByLanguage('DISTRIBUTION_OF_SELF_CONSUMED_ENERGY'),
        needsPro: false,
        active: true,
      },
      {
        title: getStringByLanguage('PRICE_MANAGEMENT'),
        needsPro: false,
        active: true,
      },
      {
        title: getStringByLanguage('INVOICING_TO_PARTICIPANTS'),
        needsPro: false,
        active: true,
      },
      {
        title: getStringByLanguage('OPTIMISATION_AND_RECOMMENDATIONS'),
        needsPro: true,
        active: false,
      },
      {
        title: getStringByLanguage('PERSONALISED_DASHBOARDS_AND_BRANDING'),
        needsPro: true,
        active: false,
      },
      {
        title: getStringByLanguage('PREMIUM_HELPDESK'),
        needsPro: true,
        active: false,
      },
    ],
  },
  {
    id: 'PRICE_COMMUNITY_MANAGER_PRO',
    classname: styles['kut-select-plan-community-manager-pro'],
    name: getStringByLanguage('COMMUNITY_MANAGER'),
    type: getStringByLanguage('MONTHLY_CHARGE'),
    price: '€99',
    items: [
      {
        title: getStringByLanguage('COMMUNITY_DASHBOARD_SELF_CONSUMPTION_KPIS'),
        needsPro: false,
        active: true,
      },
      {
        title: getStringByLanguage(
          'ENERGY_DATA_DASHBOARD_IF_DSO_DATA_IS_AVAILABLE',
        ),
        needsPro: false,
        active: true,
      },
      {
        title: getStringByLanguage('SETTINGS_AND_NOTIFICATIONS'),
        needsPro: false,
        active: true,
      },
      {
        title: getStringByLanguage('PARTICIPANT_MANAGEMENT'),
        needsPro: false,
        active: true,
      },
      {
        title: getStringByLanguage('DEVICE_MANAGEMENT'),
        needsPro: false,
        active: true,
      },
      {
        title: getStringByLanguage('DISTRIBUTION_OF_SELF_CONSUMED_ENERGY'),
        needsPro: false,
        active: true,
      },
      {
        title: getStringByLanguage('PRICE_MANAGEMENT'),
        needsPro: false,
        active: true,
      },
      {
        title: getStringByLanguage('INVOICING_TO_PARTICIPANTS'),
        needsPro: false,
        active: true,
      },
      {
        title: getStringByLanguage('OPTIMISATION_AND_RECOMMENDATIONS'),
        needsPro: true,
        active: true,
      },
      {
        title: getStringByLanguage('PERSONALISED_DASHBOARDS_AND_BRANDING'),
        needsPro: true,
        active: true,
      },
      {
        title: getStringByLanguage('PREMIUM_HELPDESK'),
        needsPro: true,
        active: true,
      },
    ],
  },
];

const UserSelectPlanComponent: FC<
  Omit<SubComponentsProps, 'userInfo'> &
    Required<Pick<SubComponentsProps, 'userInfo'>>
> = ({ userInfo, navToBillingPlan = () => {} }) => {
  return (
    <div className={styles['kut-user-select-plan']}>
      <span
        className={cn([
          styles['kut-select-plan-back-link'],
          styles['kut-link'],
        ])}
        onClick={navToBillingPlan}
      >
        ←{getStringByLanguage('GO_BACK_TO_MY_PROFILE')}
      </span>
      <span className={styles['kut-select-plan-title']}>
        {getStringByLanguage('CHOOSE_THE_PLAN_THAT_SUITS_YOUR_NEEDS')}
      </span>
      <div className={styles['kut-select-plan-content']}>
        {planList.map((v, i) => (
          <div
            key={i}
            className={cn([
              v.classname,
              userInfo.plan === v.id && styles['active'],
            ])}
          >
            <div className={styles['kut-select-plan-item-content']}>
              <p className={styles['kut-select-plan-item-name']}>
                <span>
                  {v.name}
                  {i + 1 === planList.length && (
                    <span className={styles['kut-pro-span']}>
                      {getStringByLanguage('PRO')}
                    </span>
                  )}
                </span>
              </p>
              <p className={styles['kut-select-plan-item-type']}>{v.type}</p>
              <p className={styles['kut-select-plan-item-price']}>{v.price}</p>
              {v.items.map((vv, ii) => (
                <p
                  key={ii}
                  className={cn([
                    styles['kut-select-plan-item-category'],
                    vv.needsPro && styles['kut-plan-pro'],
                    vv.active ? styles['active'] : styles['inactive'],
                  ])}
                >
                  <span
                    className={styles['kut-select-plan-item-category-title']}
                  >
                    {vv.title}
                  </span>
                  {vv.needsPro && (
                    <span className={styles['kut-pro-span']}>
                      {getStringByLanguage('PRO')}
                    </span>
                  )}
                </p>
              ))}
              <Button
                className={styles['kut-select-plan-item-button']}
                type={
                  i + 1 === planList.length
                    ? BUTTON_TYPE.SECONDARY
                    : BUTTON_TYPE.PRIMARY
                }
                outline={!i}
              >
                {userInfo.plan === v.id
                  ? getStringByLanguage('CURRENT_PLAN')
                  : getStringByLanguage('CHOOSE_THIS_PLAN')}
              </Button>
              <span
                className={cn([
                  styles['kut-select-plan-item-link'],
                  styles['kut-link'],
                ])}
              >
                {userInfo.plan === v.id
                  ? getStringByLanguage('CURRENT_PLAN')
                  : getStringByLanguage('GET_A_DEMO')}
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default UserSelectPlanComponent;
