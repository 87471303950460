import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';

import { ResponseProps } from '../apis/utils';
import {
  ACCESS_TOKEN,
  API_BASE_URL,
  COOKIE_OPTION_DEFAULT,
} from '../config/config';
import { getJSONOrString, getStringByLanguage } from './utils';

export type { AxiosResponse };

const requestAPI = axios.create({
  baseURL: `${API_BASE_URL}/`,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
  },
});

requestAPI.interceptors.request.use((request: AxiosRequestConfig) => {
  const data = request.data;
  if (!data || data instanceof FormData) {
    return request;
  }
  const keys = Object.keys(data);
  keys.forEach((key) => {
    if (data[key] === null) {
      delete data[key];
    }
    if (typeof data[key] === 'string' && data[key].length === 0) {
      delete data[key];
    }
  });
  return request;
});

requestAPI.interceptors.response.use(
  (response: AxiosResponse<ResponseProps>) => {
    // Do something with response data
    const { status, data } = response;
    if (status !== 200) {
      toast.error(data.message);
    }
    return response;
  },
  (error) => {
    const message =
      error?.response?.data?.message || error?.message || 'Request failed';
    toast.error(getStringByLanguage(getJSONOrString(message)));
    if (error?.response?.headers?.session === 'expired') {
      Cookies.remove(ACCESS_TOKEN, COOKIE_OPTION_DEFAULT);
      window.location.replace(
        `/signin?session=expired&redirect_url=${window.location.href}`,
      );
    }
    throw new Error(error);
  },
);

export default requestAPI;
