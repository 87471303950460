import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { connectRouter } from 'connected-react-router';

import userReducer from './reducers/userReducer';
import sharedReducer from './reducers/sharedReducer';
import communityReducer from './reducers/communityReducer';

import history from '../utils/history';

export const store = configureStore({
  reducer: {
    user: userReducer,
    shared: sharedReducer,
    community: communityReducer,
    router: connectRouter(history),
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
