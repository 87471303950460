import { FC, useEffect, useState } from 'react';
import DataTable, {
  createTheme,
  TableColumn,
  TableProps,
  TableStyles,
  PaginationOptions,
} from 'react-data-table-component';
import cn from 'classnames';

import { getStringByLanguage } from '../../utils/utils';
import styles from './styles.module.scss';

export enum SortOrder {
  ASC = 'asc',
  DESC = 'desc',
}

createTheme('elastic', {
  text: {
    primary: 'rgb(var(--text-color))',
    secondary: 'rgb(var(--text-color))',
  },
  background: {
    default: 'rgb(var(--content-background-color))',
  },
  context: {
    background: 'rgb(var(--content-background-color))',
    text: 'rgb(var(--text-color))',
  },
  divider: {
    default: 'rgba(var(--text-color), .3)',
  },
  highlightOnHover: {
    default: 'rgba(var(--background-color), .5)',
    text: 'rgba(var(--text-color), .7)',
  },
});

const defaultStyle: TableStyles = {
  header: {
    style: {
      minHeight: '76px',
      fontSize: '0.75em',
    },
  },
  rows: {
    style: {
      fontSize: '0.818em',
    },
  },
  headCells: {
    style: {
      paddingLeft: 3,
      paddingRight: 3,
    },
  },
  cells: {
    style: {
      paddingLeft: 3,
      paddingRight: 3,
    },
  },
  noData: {
    style: {
      padding: '24px',
    },
  },
};

const defaultPerPage = 5;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type TableDataProps = any;

interface FuncGroup extends Omit<TableProps<TableDataProps>, 'data'> {
  className?: string;
  columns: TableColumn<TableDataProps>[];
  data?: TableDataProps[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fieldDict?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  requestFunc?: (options?: any) => Promise<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  urlParam?: { [key: string]: any };
}

const Table: FC<FuncGroup> = ({
  className = '',
  columns,
  data,
  fieldDict = {},
  requestFunc,
  urlParam,
  pagination = true,
  customStyles = {},
  paginationPerPage,
  paginationRowsPerPageOptions,
  ...props
}) => {
  const [tableData, setTableData] = useState<TableDataProps[]>([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [perPageCount, setPerPageCount] = useState(
    paginationPerPage || defaultPerPage,
  );
  const [sortOrder, setSortOrder] = useState<SortOrder>(SortOrder.ASC);
  const [sortField, setSortField] = useState('id');
  const [noDataComponent, setNoDataComponent] = useState<React.ReactNode>();
  const [paginationOption, setPaginationOption] = useState<PaginationOptions>();

  useEffect(() => {
    fetchTableData();
  }, [urlParam, pageNo, sortOrder, perPageCount]);

  const fetchTableData = async (
    page: number = pageNo,
    perPage: number = perPageCount,
    sort: SortOrder = sortOrder,
    field = sortField,
  ) => {
    setLoading(true);
    setNoDataComponent(getStringByLanguage('THERE_ARE_NO_RECORDS_TO_DISPLAY'));
    setPaginationOption({
      rowsPerPageText: getStringByLanguage('ROWS_PER_PAGE'),
    });
    if (requestFunc) {
      const response = await requestFunc({
        page,
        perPage,
        sort,
        sortField: fieldDict[field],
        ...urlParam,
      });
      if (response.data.data) {
        setTableData(response.data.data[0]);
        setTotalRows(response.data.data[1]);
      }
    }
    setLoading(false);
  };

  const handlePageChange = (page: number) => {
    setPageNo(page);
  };

  const handlePerRowsChange = async (perPage: number, page: number) => {
    setPerPageCount(perPage);
    setPageNo(page);
  };

  const handleSort = (
    selectedColumn: TableColumn<TableDataProps>,
    sortDirection: SortOrder,
  ) => {
    setSortField(selectedColumn.name as string);
    setSortOrder(sortDirection);
  };

  return (
    <div className={`${cn([className, styles['kut-table']])}`}>
      <DataTable
        className={``}
        columns={columns}
        data={data || tableData}
        noDataComponent={noDataComponent}
        progressPending={loading}
        pagination={pagination}
        paginationComponentOptions={paginationOption}
        paginationServer
        highlightOnHover
        paginationTotalRows={data ? data.length : totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        onSort={handleSort}
        theme="elastic"
        paginationPerPage={paginationPerPage || defaultPerPage}
        paginationRowsPerPageOptions={
          paginationRowsPerPageOptions ||
          (paginationPerPage
            ? [paginationPerPage]
            : [defaultPerPage, 10, 15, 25, 50])
        }
        customStyles={{ ...defaultStyle, ...customStyles }}
        {...props}
      />
    </div>
  );
};

export default Table;
