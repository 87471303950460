import maleImg from '../assets/images/default/male.png';
import femaleImg from '../assets/images/default/female.png';

export const NAV_TODAY = 0;
export const NAV_WEEK = 1;
export const NAV_MONTH = 2;
export const NAV_YEAR = 3;

export const MODE_DARK = 'dark';
export const MODE_LIGHT = 'light';

export const defaultAvatars = {
  Male: maleImg,
  Female: femaleImg,
};

export const languageSrcProps = ['en', 'fr', 'es', 'nl'] as const;
export const languageKeyPropsFR = [
  'ACTIONS',
  'MY_COMMUNITY',
  'MY_PARTICIPANTS',
  'MY_DOCUMENTS',
  'MY_DEVICES',
  'MY_ENERGY',
  'MY_CONSUMPTION',
  'MY_PRODUCTION',
  'SETTINGS',
  'GET_HELP',
  'SEND_FEEDBACK',
  'LOGOUT',
  'COMMUNITIES',
  'USERS',
  'SORT_BY',
  'CREATE_NEW',
  'DELETE_SELECTED',
  'SEARCH_HERE',
  'COMMUNITY_DASHBOARD',
  'COMMUNITY_NAME',
  'VIEW_COMMUNITIES',
  'VIEW_PARTICIPANTS',
  'VIEW_DOCUMENTS',
  'DOCUMENTS',
  'VIEW_DEVICES',
  'VIEW_STREAMS',
  'STREAMS',
  'NOTION_LINK',
  'NOTION_ID',
  'CREATE',
  'INVITE',
  'UPDATE',
  'DELETE',
  'COMMUNITY',
  'ARE_YOU_SURE_WANT_TO_DELETE',
  'DELETE_FOREVER',
  'DELETE_FOREVER_DESCRIPTION',
  'SELECTED',
  'SELECT_ALL',
  'SELECT_CONTRACTS',
  'SELECT_INVOICES',
  'DEVELOPED_BY_WESMART_ALL_RIGHTS_RESERVED_2022',
  'DEVELOPED_BY_WESMART_ALL_RIGHTS_RESERVED_2023',
  'SUCCESSFULLY_REGISTERED',
  'SUCCESSFULLY_CREATED',
  'SUCCESSFULLY_UPDATED',
  'SUCCESSFULLY_DELETED',
  'YOU_CANNOT_REGISTER_YOURSELF_PLEASE_MAIL_TO_ADMIN_TO_SIGN_UP',
  'REQUEST_ERROR',
  'THIS_FIELD_IS_REQUIRED',
  'ENTER_EMAIL_ADDRESS',
  'INVALID_GENDER',
  'INVALID_EMAIL_ADDRESS',
  'INVALID_POSTCODE',
  'ENTER_PASSWORD',
  'TODAY',
  'THIS_WEEK',
  'THIS_MONTH',
  'THIS_YEAR',
  'CUSTOM',
  'DAY',
  'WEEK',
  'MONTH',
  'YEAR',
  'COMMUNITY_SELF_SUFFICIENCY',
  'CONSUMPTION',
  'COMMUNITY_PRICE',
  'AVERAGE_SUPPLIER_PRICE',
  'GAINS',
  'SAVINGS',
  'FROM_GRID',
  'FROM_COMMUNITY',
  'PRODUCERS',
  'CONSUMERS',
  'EDIT_COMMUNITY_INFO',
  'SEE_COMMUNITY_PAGE',
  'ADD_COMMUNITY_MANAGER',
  'COMMUNITY_MANAGERS',
  'REQUESTS_TO_JOIN',
  'COMMUNITY_MEMBERS',
  'CONNECTED',
  'DISCONNECTED',
  'NAME',
  'EMAIL',
  '_EMAIL',
  '_PASSWORD',
  'AT_LEAST',
  '_CHARACTERS',
  'A_MIXTURE_OF_BOTH_UPPERCASE_AND_LOWERCASE_LETTERS',
  'A_MIXTURE_OF_LETTERS_AND_NUMBERS',
  'INCLUSION_OF_AT_LEAST_ONE_SPECIAL_CHARACTER',
  'PASSWORD_MATCHED',
  'CREATE_A_PASSWORD_IN_ORDER_TO_PROTECT_YOUR_ACCOUNT_AND_BE_THE_ONLY_ONE_WHO_HAS_ACCESS_TO_IT',
  'CREATE_A_PASSWORD',
  'REPEAT_PASSWORD',
  'POST_CODE',
  'EAN',
  'PDL',
  'EAN_STATUS',
  'STATUS',
  'TYPE',
  'JOINED',
  'JOINED_ON',
  'REQUEST_STATUS',
  'RESPOND',
  'ALWAYS_ACCEPT_REQUESTS',
  'SWITCH_VIEW',
  'DOWNLOAD_ALL_INVOICES',
  'COMMUNITY_CONTRACTS',
  'ENERGY_BILLS',
  'DOCUMENT_NAME',
  'DOCUMENT_ID',
  'CREATED_ON',
  'CONTRACT_STATUS',
  'PRODUCTION',
  'SHAREABLE_PRODUCTION',
  'VIEW_MY_PUBLIC_PROFILE',
  'PROFILE',
  'BILLING_PLAN',
  'NOTIFICATION_SETTINGS',
  'NOTIFICATIONS',
  'SECURITY',
  'LANGUAGE_AND_APPEARENCE',
  'OTHER_SETTINGS',
  'WELCOME_HOW_CAN_WE_HELP_YOU',
  'SEARCH_HERE_FOR_ANYTHING',
  'POPULAR_SEARCHES',
  'COMMUNITY_TIPS',
  'ENERGY_CALCULATOR',
  'A_GUIDE_TO_GETTING_STARTED',
  'VISIT_OUR_QNA_SECTION',
  'CONTACT_US_FOR_MORE_QUESTIONS',
  'READ_MORE',
  'DIVE_IN_THE_WORLD_OF_ENERGY_COMMUNITIES_AND_DISCOVER_NEW_WAYS_TO_SAVE_ON_YOUR_BILLS',
  'CONTACT_US_NOW',
  'HELP_US_IMPROVE_YOUR_EXPERIENCE',
  'DO_YOU_HAVE_A_SUGGESTION_COMMENT_OR_FOUND_SOME_BUG_GIVE_US_SOME_MORE_DETAILS_IN_THE_FIELD_BELOW',
  'DESCRIBE_YOUR_ISSUE_OR_IDEA_HERE',
  'REPORT_A_BUG',
  'COMMENT',
  'OTHER',
  'MANAGE_AND_MONITOR_YOUR_ENERGY_COMMUNITY_IN_REAL_TIME',
  'BECOME_PART_OF_AN_ENERGY_COMMUNITY_AND_START_SHARING_ENERGY_TODAY',
  'OR',
  'IF_YOU_ARE_ALREADY_REGISTERED',
  'LOGIN',
  'SIGNIN',
  'REGISTER_TODAY',
  'TOTAL_PRODUCTION',
  'TOTAL_CONSUMPTION',
  'KWH',
  'MWH',
  'GWH',
  'YOUR_PROFILE_PHOTO',
  'UPLOAD_NEW_PHOTO',
  'REMOVE_PHOTO',
  'UPLOAD_A_PHOTO_SO_OTHER_MEMBERS_CAN_RECOGNIZE_YOU',
  'PRONOUNS',
  'HE',
  'HIM',
  'YOUR_EMAIL',
  'WESMART_SOLUTIONS',
  'FIRST_NAME',
  'LAST_NAME',
  'YOUR_NAME',
  'YOUR_SURNAME',
  'PHONE',
  'ROLE',
  'GENDER',
  'JOB_TITLE',
  'COMPANY_NAME',
  'COMPANY_VAT_NUMBER',
  'ADDRESS',
  'CITY',
  'COUNTRY',
  'POSTCODE',
  'DSO',
  'ID_NUMBER',
  'OFFICE_ID_NUMBER',
  'VAT',
  'NACE',
  'LEGAL_STRUCTURE_CODE',
  'COMPANY_ADDRESS',
  'COMPANY_CITY',
  'COMPANY_COUNTRY',
  'COMPANY_POSTCODE',
  'INVITED_BY',
  'ON',
  'PERMANENTLY_DEACTIVATE_ACCOUNT',
  'YOUR_CURRENT_PLAN',
  'UPGRADE_PLAN',
  'CANCEL_PLAN',
  'GO_BACK_TO_MY_PROFILE',
  'CHOOSE_THE_PLAN_THAT_WORKS_BEST_FOR_YOU',
  'BILLING_PERSON',
  'UPDATE_YOUR_BILLING_INFORMATION',
  'POWERED_BY',
  'STRIPE',
  'BILLING_HISTORY',
  'SEE_ALL_INVOICES',
  'ACTIVE_PLAN',
  'PLAN_COST',
  'PAYMENT_DATE',
  'SAVE_TO_MY_COMPUTER',
  'DOWNLOAD_INVOICE',
  'VIEW_INVOICE',
  'YOUR_PLAN_RENEWS_ON',
  'CANCEL',
  'SAVE_UPDATES',
  'CHOOSE_THE_PLAN_THAT_SUITS_YOUR_NEEDS',
  'USER',
  'PARTICIPANT',
  'PARTICIPANTS',
  'COMMUNITY_MANAGER',
  'ADMIN',
  'SUPER',
  'SUB',
  'PRO',
  'MONTHLY_CHARGE',
  'FREE',
  'COMMUNITY_DASHBOARD_SELF_CONSUMPTION_KPIS',
  'ENERGY_DATA_DASHBOARD_IF_DSO_DATA_IS_AVAILABLE',
  'SETTINGS_AND_NOTIFICATIONS',
  'THERE_ARE_NO_RECORDS_TO_DISPLAY',
  'ROWS_PER_PAGE',
  'PARTICIPANT_MANAGEMENT',
  'DEVICE_MANAGEMENT',
  'DISTRIBUTION_OF_SELF_CONSUMED_ENERGY',
  'PRICE_MANAGEMENT',
  'INVOICING_TO_PARTICIPANTS',
  'OPTIMISATION_AND_RECOMMENDATIONS',
  'PERSONALISED_DASHBOARDS_AND_BRANDING',
  'PREMIUM_HELPDESK',
  'CHOOSE_THIS_PLAN',
  'CURRENT_PLAN',
  'GET_A_DEMO',
  'DO_NOT_DISTURB',
  'DISABLE_ALL_NOTIFICATIONS',
  'CONFIGURE_EMAIL_NOTIFICATIONS_FREQUENCY',
  'DAILY_SUMMARIES',
  'GET_NOTIFIED_DAILY_ABOUT_WHAT_HAPPENS_IN_YOUR_COMMUNITY',
  'WEEKLY_REPORT',
  'GET_NOTIFIED_WEEKLY_ABOUT_WHAT_HAPPENS_IN_YOUR_COMMUNITY',
  'MONTHLY_REPORT',
  'GET_NOTIFIED_MONTHLY_ABOUT_WHAT_HAPPENS_IN_YOUR_COMMUNITY',
  'NOTIFICATIONS_ARE_SENT_DIRECTLY_TO_YOUR_CURRENT_EMAIL',
  'WESMART_ENERGY_COMMUNITY',
  'DOWNLOAD_YOUR_DATA',
  'DECIDE_HOW_THE_COMMUNITY_MANAGERS_YOUR_PERSONAL_INFO',
  'CURRENT_PASSWORD',
  'UPDATE_PASSWORD',
  'CONFIRM_NEW_PASSWORD',
  'SECURITY_LEVELS',
  'TWO_FACTOR_AUTHENTICATION',
  'REQUIRE_AN_AUTHENTICATION_CODE_WHEN_LOGGING_IN',
  'SECURITY_LOGOUT',
  'LOGOUT_OF_ALL_DEVICES_AND_BROWSERS',
  'IN_APP_LANGUAGE',
  'APP_BRANDING',
  'CHANGE_LOGO',
  'REMOVE_LOGO',
  'THEME',
  'LIGHT',
  'ACTIVE',
  'ACCEPTED',
  'NOT_ACCEPTED',
  'DARK',
  'SYNC_WITH_SYSTEM',
  'APP_APPEARENCE_CHANGES_WITH_THE_OPERATING_SYSTEM_BROWSER',
  'THE_APP_WITH_GO_TO_DARK_MODE_IN_ABOUT',
  'THE_APP_WITH_GO_TO_LIGHT_MODE_IN_ABOUT',
  'HOURS',
  'AND',
  'MINUTES',
  'CANCELED',
  'SIGNED',
  'NOT_SIGNED_YET',
  'PAID',
  'NOT_PAID_YET',
  'WESMART_BRINGS_A_DIGITAL_PLATFORM_AND_SERVICES_TO_OPERATE_RENEWABLE_ENERGY_COMMUNITIES',
  'SWEEN_BRINGS_A_DIGITAL_PLATFORM_AND_SERVICES_TO_OPERATE_RENEWABLE_ENERGY_COMMUNITIES',
  'PARTICIPANT_NAME',
  'DEVICE',
  'DEVICES',
  'TAGS',
  'DEVICE_NAME',
  'DEVICE_TYPE',
  'DOCUMENT',
  'DOWNLOAD',
  'DOCUMENT_TYPE',
  'DOCUMENT_STATUS',
  'DOCUMENT_URL',
  'PRODUCER',
  'CONSUMER',
  'PROSUMER',
  'PERSON_TYPE',
  'EXPORT_DATA',
  'INDIVIDUAL_SELF_CONSUMPTION',
  'COLLECTIVE_SELF_CONSUMPTION',
  'SHARED_PRODUCTION',
  'UNKNOWN',
  'POWER',
  'DECLINATION',
  'AZIMUTH',
  'LATITUDE',
  'LONGITUDE',
  'THIS_FIELD_MUST_BE_NUMBER',
  'STREAM',
  'VALUE',
  'UNIT',
  'TIME',
  'TAG',
  'AMOUNT',
  'THIS_FIELD_CANNOT_BE_UPDATED_TO_UPDATE_REMOVE_AND_RECREATE',
  'COMMUNITY_ENERGY_USAGE',
  'EMAIL_SENT',
  'REMOVED',
  'SESSION_HAS_EXPIRED',
  'THE__',
  'SELF_CONSUMPTION',
  'IS_THE_PART_OF_THE_CONSUMPTION_THAT_COMES_FROM_THE_RENEWABLE_ENERGY_PRODUCTION_OF_THE_COMMUNITY',
  'IN_THIS_CASE_IT_IS_EQUAL_TO',
  'SELF_SUFFICIENCY',
  'IS_THE_SELF_CONSUMPTION_DIVIDED_BY_THE_TOTAL_CONSUMPTION',
  'THIS_MEANS_THAT',
  'OF_THE_COMMUNITY_CONSUMPTION_COMES_FROM_THE_RENEWABLE_ENERGY_PRODUCTION_OF_THE_COMMUNITY',
  'THE_SAVINGS_ARE_THE_AGGREGATED',
  'BILL_SAVINGS',
  'OF_THE_COMMUNITY_MEMBERS_DUE_TO_THE_CONSUMPTION_OF_THE_RENEWABLE_ENERGY_OF_THE_COMMUNITY',
  'THESE_SAVINGS_ARE_HOWEVER_AN_APPROXIMATION_TAKEN_WITH_HYPOTHESES_AND_ARE_NOT_THE_REAL_ONE',
  'CODE_LABEL',
  'CLICK_TO_SEE_DETAILS',
  'WESMART',
  'SEND_PASSWORD_RESET_EMAIL',
  'ENTER_YOUR_USER_ACCOUNTS_VERIFIED_EMAIL_ADDRESS_AND_WE_WILL_SEND_YOU_A_PASSWORD_RESET_LINK',
  'PASSWORD_RESET_EMAIL_SENT',
  'CHECK_YOUR_EMAIL_FOR_A_LINK_TO_RESET_YOUR_PASSWORD',
  'IF_IT_DOES_NOT_APPEAR_WITHIN_A_FEW_MINUTES_CHECK_YOUR_SPAM_FOLDER',
  'CONTINUE',
  'IS_THE_PART_OF_THE_SHAREABLE_PRODUCTION_THAT_HAS_BEEN_SHARED_AMONG_THE_MEMBERS_AND_SELF_CONSUMED',
  'IS_THE_PART_OF_THE_ENERGY_PRODUCTION_OF_PRODUCERS_THAT_IS_AVAILABLE_FOR_THE_CUSTOMERS',
  'IT_IS_ALSO_CALLED_INJECTION',
  'IS_THE_AMOUNT_OF_ENERGY_THAT_MEMBERS_TAKE_FROM_THE_GRID',
  'IT_IS_THE_OFFTAKE_CONSUMPTION',
  'THE_AMOUNT_OF_ENERGY_THAT_YOU_TAKE_FROM_THE_GRID',
  'THE_PART_OF_YOUR_OWN_PRODUCTION_THAT_YOU_INJECT_INTO_THE_GRID_AND_MAKE_AVAILABLE_TO_THE_OTHER_MEMBERS',
  'THE_PART_OF_THE_CONSUMPTION_THAT_COMES_FROM_THE_ENERGY_SHARING',
  'THE_PART_OF_THE_SHAREABLE_PRODUCTION_THAT_HAS_BEEN_SHARED',
  'THE_PART_OF_YOUR_OWN_PRODUCTION_THAT_IS_SELF_CONSUMED_AND_NOT_INJECTED_INTO_THE_GRID',
  'IF_YOU_SUM_THE_CONSUMPTION_WITH_THE_INDIVIDUAL_SELF_CONSUMPTION_YOU_OBTAIN_YOUR_REAL_CONSUMPTION',
  'IF_YOU_SUM_THE_SHAREABLE_PRODUCTION_WITH_THE_INDIVIDUAL_SELF_CONSUMPTION_YOU_OBTAIN_YOUR_REAL_PRODUCTION',
  'DRAG_AND_DROP_A_FILE_HERE_OR_CLICK_TO_SELECT_FILE',
  'FILE_FORMAT_ERROR',
  'I_WILL_FOLLOW_THE_RECOMMENDATION',
  'I_WILL_NOT_FOLLOW_THE_RECOMMENDATION',
  'DISCOVER_NOW',
  'DISCOVER_NEWS',
  // New string
] as const;

export const languageKeyPropsBE = [
  'Session has expired',
  'You do not have permission',
  'Invalid request data',
  'Not enough request data',
  'Invalid sort field',
  'Invalid tag format',
  'Invalid document type',
  'Error catched',
  'Invalid notification type',
  'This device is producer.',
  'This device is consumer.',
  'Can not do this action.',
  'Request data is not correct',
  'Incorrect field type',
  'You have already signed up',
  'Please reset your password',
  'Invalid notification setting format',
  'Report a bug',
  'Comment',
  'Other',
  'Email already exists',
  'Sorry, there was an issue with your login.',
  'Please check your credentials and try again.',
  // New BE string
] as const;

export const languageKeyProps = [
  ...languageKeyPropsFR,
  ...languageKeyPropsBE,
] as const;

export type LanguageSrcProps = (typeof languageSrcProps)[number];
export type LanguageKeyPropsFR = (typeof languageKeyPropsFR)[number];
export type LanguageKeyPropsBE = (typeof languageKeyPropsBE)[number];
export type LanguageKeyProps = (typeof languageKeyProps)[number];

export const stringsFR: {
  [key in LanguageSrcProps]: { [key in LanguageKeyPropsFR]: string };
} = {
  en: {
    ACTIONS: 'Actions',
    MY_COMMUNITY: 'My Community',
    MY_PARTICIPANTS: 'My Participants',
    MY_DOCUMENTS: 'My Documents',
    MY_DEVICES: 'My Devices',
    MY_ENERGY: 'My Energy',
    MY_CONSUMPTION: 'My Consumption',
    MY_PRODUCTION: 'My Production',
    SETTINGS: 'Settings',
    GET_HELP: 'Get help',
    SEND_FEEDBACK: 'Send feedback',
    LOGOUT: 'Logout',
    COMMUNITIES: 'Communities',
    USERS: 'Users',
    SORT_BY: 'Sort by',
    CREATE_NEW: 'Create new',
    DELETE_SELECTED: 'Delete selected',
    SEARCH_HERE: 'Search here',
    COMMUNITY_DASHBOARD: 'Community dashboard',
    COMMUNITY_NAME: 'Community Name',
    VIEW_COMMUNITIES: 'View communities',
    VIEW_PARTICIPANTS: 'View participants',
    VIEW_DOCUMENTS: 'View documents',
    DOCUMENTS: 'Documents',
    VIEW_DEVICES: 'View devices',
    VIEW_STREAMS: 'View streams',
    STREAMS: 'Streams',
    NOTION_LINK: 'Notion link',
    NOTION_ID: 'Notion Id',
    CREATE: 'Create',
    INVITE: 'Invite',
    UPDATE: 'Update',
    DELETE: 'Delete',
    COMMUNITY: 'Community',
    ARE_YOU_SURE_WANT_TO_DELETE: 'Are you sure want to delete',
    DELETE_FOREVER: 'Delete forever',
    DELETE_FOREVER_DESCRIPTION:
      'If you check this box, the item will be deleted from our database and cannot be recovered.',
    SELECTED: 'Selected',
    SELECT_ALL: 'Select all',
    SELECT_CONTRACTS: 'Select Contracts',
    SELECT_INVOICES: 'Select Invoices',
    DEVELOPED_BY_WESMART_ALL_RIGHTS_RESERVED_2022:
      'Developed by Wesmart © All rights reserved 2022',
    DEVELOPED_BY_WESMART_ALL_RIGHTS_RESERVED_2023:
      'Developed by Wesmart © All rights reserved 2023',
    SUCCESSFULLY_REGISTERED: 'Successfully registered',
    SUCCESSFULLY_CREATED: 'Successfully created',
    SUCCESSFULLY_UPDATED: 'Successfully updated',
    SUCCESSFULLY_DELETED: 'Successfully deleted',
    YOU_CANNOT_REGISTER_YOURSELF_PLEASE_MAIL_TO_ADMIN_TO_SIGN_UP:
      'You cannot register yourself. <br />Please mail to francois@wesmart.com to sign up',
    REQUEST_ERROR: 'Request error',
    THIS_FIELD_IS_REQUIRED: 'This field is required',
    ENTER_EMAIL_ADDRESS: 'Enter email address',
    INVALID_GENDER: 'Invalid gender',
    INVALID_EMAIL_ADDRESS: 'Invalid email address',
    INVALID_POSTCODE: 'Invalid postcode',
    ENTER_PASSWORD: 'Enter password',
    TODAY: 'Today',
    THIS_WEEK: 'This Week',
    THIS_MONTH: 'This Month',
    THIS_YEAR: 'This Year',
    CUSTOM: 'Custom',
    DAY: 'Day',
    WEEK: 'Week',
    MONTH: 'Month',
    YEAR: 'Year',
    COMMUNITY_SELF_SUFFICIENCY: 'Community self-sufficiency',
    CONSUMPTION: 'Consumption',
    COMMUNITY_PRICE: 'Community Price',
    AVERAGE_SUPPLIER_PRICE: 'Average Supplier Price',
    GAINS: 'Gains',
    SAVINGS: 'Savings',
    FROM_GRID: 'From grid',
    FROM_COMMUNITY: 'From community',
    PRODUCERS: 'Producers',
    CONSUMERS: 'Consumers',
    EDIT_COMMUNITY_INFO: 'Edit community info',
    SEE_COMMUNITY_PAGE: 'See community page',
    ADD_COMMUNITY_MANAGER: 'Add community manager',
    COMMUNITY_MANAGERS: 'Community manager(s)',
    REQUESTS_TO_JOIN: 'Requests to join',
    COMMUNITY_MEMBERS: 'Community members',
    CONNECTED: 'Connected',
    DISCONNECTED: 'Disconnected',
    NAME: 'Name',
    EMAIL: 'Email',
    _EMAIL: 'email',
    _PASSWORD: 'password',
    AT_LEAST: 'At least',
    _CHARACTERS: 'characters',
    A_MIXTURE_OF_BOTH_UPPERCASE_AND_LOWERCASE_LETTERS:
      'A mixture of both uppercase and lowercase letters',
    A_MIXTURE_OF_LETTERS_AND_NUMBERS: 'A mixture of letters and numbers',
    INCLUSION_OF_AT_LEAST_ONE_SPECIAL_CHARACTER:
      'Inclusion of at least one special character, e.g., ! @ # ? ]',
    PASSWORD_MATCHED: 'Password matched',
    CREATE_A_PASSWORD_IN_ORDER_TO_PROTECT_YOUR_ACCOUNT_AND_BE_THE_ONLY_ONE_WHO_HAS_ACCESS_TO_IT:
      'Create a password in order to protect your account and be the only one who has access to it',
    CREATE_A_PASSWORD: 'Create a password',
    REPEAT_PASSWORD: 'Repeat password',
    POST_CODE: 'Post code',
    EAN: 'EAN',
    PDL: 'PDL',
    EAN_STATUS: 'EAN Status',
    STATUS: 'Status',
    TYPE: 'Type',
    JOINED: 'Joined',
    JOINED_ON: 'Joined on',
    REQUEST_STATUS: 'Request status',
    RESPOND: 'Respond',
    ALWAYS_ACCEPT_REQUESTS: 'Always accept requests',
    SWITCH_VIEW: 'Switch view',
    DOWNLOAD_ALL_INVOICES: 'Download all invoices',
    COMMUNITY_CONTRACTS: 'Community Contracts',
    ENERGY_BILLS: 'Energy bills',
    DOCUMENT_NAME: 'Document name',
    DOCUMENT_ID: 'Document ID',
    CREATED_ON: 'Created on',
    CONTRACT_STATUS: 'Contract Status',
    PRODUCTION: 'Production',
    SHAREABLE_PRODUCTION: 'Shareable Production',
    VIEW_MY_PUBLIC_PROFILE: 'View my public profile',
    PROFILE: 'Profile',
    BILLING_PLAN: 'Billing plan',
    NOTIFICATION_SETTINGS: 'Notification settings',
    NOTIFICATIONS: 'Notifications',
    SECURITY: 'Security',
    LANGUAGE_AND_APPEARENCE: 'Language & appearence',
    OTHER_SETTINGS: 'Other settings',
    WELCOME_HOW_CAN_WE_HELP_YOU: 'Welcome, how can we help you?',
    SEARCH_HERE_FOR_ANYTHING: 'Search here for anything',
    POPULAR_SEARCHES: 'Popular searches',
    COMMUNITY_TIPS: 'Community tips',
    ENERGY_CALCULATOR: 'Energy Caculator',
    A_GUIDE_TO_GETTING_STARTED: 'A guide to getting started',
    VISIT_OUR_QNA_SECTION: 'Visit our Q&A section',
    CONTACT_US_FOR_MORE_QUESTIONS: 'Contact us for more questions',
    READ_MORE: 'Read more',
    DIVE_IN_THE_WORLD_OF_ENERGY_COMMUNITIES_AND_DISCOVER_NEW_WAYS_TO_SAVE_ON_YOUR_BILLS:
      'Dive in the world of energy communities and discover new ways to save on your bills',
    CONTACT_US_NOW: 'Contact us now',
    HELP_US_IMPROVE_YOUR_EXPERIENCE: 'Help us improve your experience!',
    DO_YOU_HAVE_A_SUGGESTION_COMMENT_OR_FOUND_SOME_BUG_GIVE_US_SOME_MORE_DETAILS_IN_THE_FIELD_BELOW:
      'Do you have a suggestion, comment or found some bug?\nGive us some more details in the field below',
    DESCRIBE_YOUR_ISSUE_OR_IDEA_HERE: 'Describe your issue or idea here...',
    REPORT_A_BUG: 'Report a bug',
    COMMENT: 'Comment',
    OTHER: 'Other',
    MANAGE_AND_MONITOR_YOUR_ENERGY_COMMUNITY_IN_REAL_TIME:
      'Manage and monitor your energy consumption in real time.',
    BECOME_PART_OF_AN_ENERGY_COMMUNITY_AND_START_SHARING_ENERGY_TODAY:
      'Become part of an energy community and start sharing energy today',
    OR: 'or',
    IF_YOU_ARE_ALREADY_REGISTERED: 'if you are already registered',
    LOGIN: 'log in',
    SIGNIN: 'Sign in',
    REGISTER_TODAY: 'Register today',
    TOTAL_PRODUCTION: 'Total Production',
    TOTAL_CONSUMPTION: 'Total Consumption',
    KWH: 'kWh',
    MWH: 'MWh',
    GWH: 'GWh',
    YOUR_PROFILE_PHOTO: 'Your profile photo',
    UPLOAD_NEW_PHOTO: 'Upload new photo',
    REMOVE_PHOTO: 'Remove photo',
    UPLOAD_A_PHOTO_SO_OTHER_MEMBERS_CAN_RECOGNIZE_YOU:
      'Upload a photo so other members can recognize you',
    PRONOUNS: 'Pronouns',
    HE: 'He',
    HIM: 'Him',
    YOUR_EMAIL: 'Your email',
    WESMART_SOLUTIONS: 'WeSmart Solutions',
    FIRST_NAME: 'First name',
    LAST_NAME: 'Last name',
    YOUR_NAME: 'Your name',
    YOUR_SURNAME: 'Your surname',
    PHONE: 'Phone',
    ROLE: 'Role',
    GENDER: 'Gender',
    JOB_TITLE: 'Job title',
    COMPANY_NAME: 'Company name',
    COMPANY_VAT_NUMBER: 'Company VAT number',
    ADDRESS: 'Address',
    CITY: 'City',
    COUNTRY: 'Country',
    POSTCODE: 'Postcode',
    DSO: 'DSO',
    ID_NUMBER: 'ID Number',
    OFFICE_ID_NUMBER: 'Office ID Number',
    VAT: 'VAT',
    NACE: 'NACE',
    LEGAL_STRUCTURE_CODE: 'Legal Structure Code',
    COMPANY_ADDRESS: 'Company Address',
    COMPANY_CITY: 'Company City',
    COMPANY_COUNTRY: 'Company Country',
    COMPANY_POSTCODE: 'Company Postcode',
    INVITED_BY: 'Invited by',
    ON: 'on',
    PERMANENTLY_DEACTIVATE_ACCOUNT: 'Permanently deactivate account',
    YOUR_CURRENT_PLAN: 'Your current plan',
    UPGRADE_PLAN: 'Upgrade plan',
    CANCEL_PLAN: 'Cancel plan',
    GO_BACK_TO_MY_PROFILE: 'Go back to my profile',
    CHOOSE_THE_PLAN_THAT_WORKS_BEST_FOR_YOU:
      'Choose the plan that works best for you',
    BILLING_PERSON: 'Billing person',
    UPDATE_YOUR_BILLING_INFORMATION: 'Update your billing information',
    POWERED_BY: 'Powered by',
    STRIPE: 'stripe',
    BILLING_HISTORY: 'Billing history',
    SEE_ALL_INVOICES: 'See all invoices',
    ACTIVE_PLAN: 'Active plan',
    PLAN_COST: 'Plan cost',
    PAYMENT_DATE: 'Payment date',
    SAVE_TO_MY_COMPUTER: 'Save to my computer',
    DOWNLOAD_INVOICE: 'Download invoice',
    VIEW_INVOICE: 'View invoice',
    YOUR_PLAN_RENEWS_ON: 'Your plan renews on',
    CANCEL: 'Cancel',
    SAVE_UPDATES: 'Save updates',
    CHOOSE_THE_PLAN_THAT_SUITS_YOUR_NEEDS:
      'Choose the plan that suits your needs',
    USER: 'User',
    PARTICIPANT: 'Participant',
    PARTICIPANTS: 'Participants',
    COMMUNITY_MANAGER: 'Community Manager',
    ADMIN: 'Admin',
    SUPER: 'Super',
    SUB: 'Sub',
    PRO: 'Pro',
    MONTHLY_CHARGE: 'Monthly charge',
    FREE: 'Free',
    COMMUNITY_DASHBOARD_SELF_CONSUMPTION_KPIS:
      'Community Dashboard: self-consumption KPIs',
    ENERGY_DATA_DASHBOARD_IF_DSO_DATA_IS_AVAILABLE:
      'Energy data dashboard (if DSO data is available)',
    SETTINGS_AND_NOTIFICATIONS: 'Settings & Notifications',
    THERE_ARE_NO_RECORDS_TO_DISPLAY: 'There are no records to display',
    ROWS_PER_PAGE: 'Rows per page',
    PARTICIPANT_MANAGEMENT: 'Participant Management',
    DEVICE_MANAGEMENT: 'Device Management',
    DISTRIBUTION_OF_SELF_CONSUMED_ENERGY:
      'Distribution of self-consumed energy',
    PRICE_MANAGEMENT: 'Price Management',
    INVOICING_TO_PARTICIPANTS: 'Invoicing to participants',
    OPTIMISATION_AND_RECOMMENDATIONS: 'Optimisation & Recommendations',
    PERSONALISED_DASHBOARDS_AND_BRANDING: 'Personalised Dashboards & Branding',
    PREMIUM_HELPDESK: 'Premium helpdesk',
    CHOOSE_THIS_PLAN: 'Choose this plan',
    CURRENT_PLAN: 'Current plan',
    GET_A_DEMO: 'Get a demo',
    DO_NOT_DISTURB: 'Do not disturb',
    DISABLE_ALL_NOTIFICATIONS: 'Disable all notifications',
    CONFIGURE_EMAIL_NOTIFICATIONS_FREQUENCY:
      'Configure email notifications frequency',
    DAILY_SUMMARIES: 'Daily summaries',
    GET_NOTIFIED_DAILY_ABOUT_WHAT_HAPPENS_IN_YOUR_COMMUNITY:
      'Get notified daily about what happens in your community',
    WEEKLY_REPORT: 'Weekly report',
    GET_NOTIFIED_WEEKLY_ABOUT_WHAT_HAPPENS_IN_YOUR_COMMUNITY:
      'Get notified weekly about what happens in your community',
    MONTHLY_REPORT: 'Monthly report',
    GET_NOTIFIED_MONTHLY_ABOUT_WHAT_HAPPENS_IN_YOUR_COMMUNITY:
      'Get notified monthly about what happens in your community',
    NOTIFICATIONS_ARE_SENT_DIRECTLY_TO_YOUR_CURRENT_EMAIL:
      'Notifications are sent directly to your current email',
    WESMART_ENERGY_COMMUNITY: 'WeSmart Energy Community',
    DOWNLOAD_YOUR_DATA: 'Download your data',
    DECIDE_HOW_THE_COMMUNITY_MANAGERS_YOUR_PERSONAL_INFO:
      'Decide how the community managers your personal info',
    CURRENT_PASSWORD: 'Current password',
    UPDATE_PASSWORD: 'Update password',
    CONFIRM_NEW_PASSWORD: 'Confirm new password',
    SECURITY_LEVELS: 'Security levels',
    TWO_FACTOR_AUTHENTICATION: 'Two factor authentication',
    REQUIRE_AN_AUTHENTICATION_CODE_WHEN_LOGGING_IN:
      'Require an authentication code when logging in',
    SECURITY_LOGOUT: 'Security logout',
    LOGOUT_OF_ALL_DEVICES_AND_BROWSERS: 'Logout of all devices and browsers',
    IN_APP_LANGUAGE: 'In app language',
    APP_BRANDING: 'App branding',
    CHANGE_LOGO: 'Change logo',
    REMOVE_LOGO: 'Remove logo',
    THEME: 'Theme',
    LIGHT: 'Light',
    ACTIVE: 'active',
    ACCEPTED: 'Accepted',
    NOT_ACCEPTED: 'Not accepted',
    DARK: 'Dark',
    SYNC_WITH_SYSTEM: 'Sync with system',
    APP_APPEARENCE_CHANGES_WITH_THE_OPERATING_SYSTEM_BROWSER:
      'App appearence changes with the operating system/browser',
    THE_APP_WITH_GO_TO_DARK_MODE_IN_ABOUT:
      'The app with go to dark mode in about',
    THE_APP_WITH_GO_TO_LIGHT_MODE_IN_ABOUT:
      'The app with go to light mode in about',
    HOURS: 'hours',
    AND: 'and',
    MINUTES: 'minutes',
    CANCELED: 'Canceled',
    SIGNED: 'Signed',
    NOT_SIGNED_YET: 'Not signed yet',
    PAID: 'Paid',
    NOT_PAID_YET: 'Not paid yet',
    WESMART_BRINGS_A_DIGITAL_PLATFORM_AND_SERVICES_TO_OPERATE_RENEWABLE_ENERGY_COMMUNITIES:
      'Wesmart brings a digital platform and services to operate renewable energy communities',
    SWEEN_BRINGS_A_DIGITAL_PLATFORM_AND_SERVICES_TO_OPERATE_RENEWABLE_ENERGY_COMMUNITIES:
      'Sween brings a digital platform and services to operate renewable energy communities',
    PARTICIPANT_NAME: 'Participant Name',
    DEVICE: 'Device',
    DEVICES: 'Devices',
    TAGS: 'Tags',
    DEVICE_NAME: 'Device name',
    DEVICE_TYPE: 'Device type',
    DOCUMENT: 'Document',
    DOWNLOAD: 'Download',
    DOCUMENT_TYPE: 'Document type',
    DOCUMENT_STATUS: 'Document status',
    DOCUMENT_URL: 'Document URL',
    PRODUCER: 'Producer',
    CONSUMER: 'Consumer',
    PROSUMER: 'Prosumer',
    PERSON_TYPE: 'Person type',
    EXPORT_DATA: 'Export data',
    INDIVIDUAL_SELF_CONSUMPTION: 'Individual Self consumption',
    COLLECTIVE_SELF_CONSUMPTION: 'Collective Self consumption',
    SHARED_PRODUCTION: 'Shared production',
    UNKNOWN: 'Unknown',
    POWER: 'Power',
    DECLINATION: 'Declination',
    AZIMUTH: 'Azimuth',
    LATITUDE: 'Latitude',
    LONGITUDE: 'Longitude',
    THIS_FIELD_MUST_BE_NUMBER: 'This field must be number',
    STREAM: 'Stream',
    VALUE: 'Value',
    UNIT: 'Unit',
    TIME: 'Time',
    TAG: 'Tag',
    AMOUNT: 'Amount',
    THIS_FIELD_CANNOT_BE_UPDATED_TO_UPDATE_REMOVE_AND_RECREATE:
      'This field cannot be updated.\nTo update, remove and recreate.',
    COMMUNITY_ENERGY_USAGE: 'Community energy usage',
    EMAIL_SENT: 'Email sent',
    REMOVED: 'Removed',
    SESSION_HAS_EXPIRED: 'Session has expired',
    THE__: 'The ',
    SELF_CONSUMPTION: 'self-consumption',
    IS_THE_PART_OF_THE_CONSUMPTION_THAT_COMES_FROM_THE_RENEWABLE_ENERGY_PRODUCTION_OF_THE_COMMUNITY:
      'is the part of the consumption that comes from the renewable energy production of the community.',
    IN_THIS_CASE_IT_IS_EQUAL_TO: 'In this case it is equal to',
    SELF_SUFFICIENCY: 'self-sufficiency',
    IS_THE_SELF_CONSUMPTION_DIVIDED_BY_THE_TOTAL_CONSUMPTION:
      'is the self-consumption divided by the total consumption.',
    THIS_MEANS_THAT: 'This means that',
    OF_THE_COMMUNITY_CONSUMPTION_COMES_FROM_THE_RENEWABLE_ENERGY_PRODUCTION_OF_THE_COMMUNITY:
      'of the community consumption comes from the renewable energy production of the community.',
    THE_SAVINGS_ARE_THE_AGGREGATED: 'The savings are the aggregated',
    BILL_SAVINGS: 'bill savings',
    OF_THE_COMMUNITY_MEMBERS_DUE_TO_THE_CONSUMPTION_OF_THE_RENEWABLE_ENERGY_OF_THE_COMMUNITY:
      'of the community members due to the consumption of the renewable energy of the community.',
    THESE_SAVINGS_ARE_HOWEVER_AN_APPROXIMATION_TAKEN_WITH_HYPOTHESES_AND_ARE_NOT_THE_REAL_ONE:
      'These savings are however an approximation taken with hypotheses and are not the real ones.',
    CODE_LABEL: 'Code;Label',
    CLICK_TO_SEE_DETAILS: 'Click to see details',
    WESMART: 'WeSmart',
    SEND_PASSWORD_RESET_EMAIL: 'Send password reset email',
    ENTER_YOUR_USER_ACCOUNTS_VERIFIED_EMAIL_ADDRESS_AND_WE_WILL_SEND_YOU_A_PASSWORD_RESET_LINK:
      "Enter your user account's verified email address and we will send you a password reset link",
    PASSWORD_RESET_EMAIL_SENT: 'Password reset email sent.',
    CHECK_YOUR_EMAIL_FOR_A_LINK_TO_RESET_YOUR_PASSWORD:
      'Check your email for a link to reset your password.',
    IF_IT_DOES_NOT_APPEAR_WITHIN_A_FEW_MINUTES_CHECK_YOUR_SPAM_FOLDER:
      "If it doesn't appear within a few minutes, check your spam folder.",
    CONTINUE: 'Continue',
    IS_THE_PART_OF_THE_SHAREABLE_PRODUCTION_THAT_HAS_BEEN_SHARED_AMONG_THE_MEMBERS_AND_SELF_CONSUMED:
      'is the part of the shareable production that has been shared among the members and self-consumed.',
    IS_THE_PART_OF_THE_ENERGY_PRODUCTION_OF_PRODUCERS_THAT_IS_AVAILABLE_FOR_THE_CUSTOMERS:
      'is the part of the energy production of producers that is available for the consumers.',
    IT_IS_ALSO_CALLED_INJECTION: 'It is also called injection.',
    IS_THE_AMOUNT_OF_ENERGY_THAT_MEMBERS_TAKE_FROM_THE_GRID:
      'is the amount of energy that members take from the grid.',
    IT_IS_THE_OFFTAKE_CONSUMPTION: 'It is the offtake consumption.',
    THE_AMOUNT_OF_ENERGY_THAT_YOU_TAKE_FROM_THE_GRID:
      'The amount of energy that you take from the grid.',
    THE_PART_OF_YOUR_OWN_PRODUCTION_THAT_YOU_INJECT_INTO_THE_GRID_AND_MAKE_AVAILABLE_TO_THE_OTHER_MEMBERS:
      'The part of your own production that you inject into the grid and make available to the other members.',
    THE_PART_OF_THE_CONSUMPTION_THAT_COMES_FROM_THE_ENERGY_SHARING:
      'The part of the consumption that comes from the energy sharing.',
    THE_PART_OF_THE_SHAREABLE_PRODUCTION_THAT_HAS_BEEN_SHARED:
      'The part of the Shareable production that has been shared.',
    THE_PART_OF_YOUR_OWN_PRODUCTION_THAT_IS_SELF_CONSUMED_AND_NOT_INJECTED_INTO_THE_GRID:
      'The part of your own production that is self consumed and not injected into the grid.',
    IF_YOU_SUM_THE_CONSUMPTION_WITH_THE_INDIVIDUAL_SELF_CONSUMPTION_YOU_OBTAIN_YOUR_REAL_CONSUMPTION:
      'If you sum the Consumption with the Individual self consumption you obtain your real consumption.',
    IF_YOU_SUM_THE_SHAREABLE_PRODUCTION_WITH_THE_INDIVIDUAL_SELF_CONSUMPTION_YOU_OBTAIN_YOUR_REAL_PRODUCTION:
      'If you sum the Shareable production with the Individual self consumption you obtain your real production.',
    DRAG_AND_DROP_A_FILE_HERE_OR_CLICK_TO_SELECT_FILE:
      'Drag and drop a file here or click to select file',
    FILE_FORMAT_ERROR: 'File format error',
    I_WILL_FOLLOW_THE_RECOMMENDATION: 'I will follow the recommendation',
    I_WILL_NOT_FOLLOW_THE_RECOMMENDATION:
      'I will not follow the recommendation',
    DISCOVER_NOW: 'Discover now',
    DISCOVER_NEWS: 'Discover news',
    // New string
  },
  fr: {
    ACTIONS: 'Actions',
    MY_COMMUNITY: 'Ma Communauté',
    MY_PARTICIPANTS: 'Mes Participants',
    MY_DOCUMENTS: 'Mes Documents',
    MY_DEVICES: 'Mes Équipements',
    MY_ENERGY: 'Mon Énergie',
    MY_CONSUMPTION: 'Ma Consommation',
    MY_PRODUCTION: 'Ma Production',
    SETTINGS: 'Paramètres',
    GET_HELP: "Obtenir de l'aide",
    SEND_FEEDBACK: 'Envoyer des commentaires',
    LOGOUT: 'Se déconnecter',
    COMMUNITIES: 'Communautés',
    USERS: 'Utilisateurs',
    SORT_BY: 'Trier par',
    CREATE_NEW: 'Créer un nouveau',
    DELETE_SELECTED: 'Supprimer la sélection',
    SEARCH_HERE: 'Rechercher ici',
    COMMUNITY_DASHBOARD: 'Tableau de bord de la communauté',
    COMMUNITY_NAME: 'Nom de la communauté',
    VIEW_COMMUNITIES: 'Voir les communautés',
    VIEW_PARTICIPANTS: 'Voir les participants',
    VIEW_DOCUMENTS: 'Voir les documents',
    DOCUMENTS: 'Documents',
    VIEW_DEVICES: 'Voir les équipements',
    VIEW_STREAMS: 'Voir les flux',
    STREAMS: 'Flux',
    NOTION_LINK: 'Lien Notion',
    NOTION_ID: 'Identifiant Notion',
    CREATE: 'Créer',
    INVITE: 'Inviter',
    UPDATE: 'Mise à jour',
    DELETE: 'Supprimer',
    COMMUNITY: 'Communauté',
    ARE_YOU_SURE_WANT_TO_DELETE: 'Êtes-vous sûr de vouloir supprimer',
    DELETE_FOREVER: 'Supprimer définitivement',
    DELETE_FOREVER_DESCRIPTION:
      'Si vous cochez cette case, l’élément sera supprimé de notre base de données et ne pourra pas être récupéré.',
    SELECTED: 'Sélectionné',
    SELECT_ALL: 'Tout sélectionner',
    SELECT_CONTRACTS: 'Sélectionnez des contrats',
    SELECT_INVOICES: 'Sélectionnez les factures',
    DEVELOPED_BY_WESMART_ALL_RIGHTS_RESERVED_2022:
      'Développé par Wesmart © Tous droits réservés 2022',
    DEVELOPED_BY_WESMART_ALL_RIGHTS_RESERVED_2023:
      'Développé par Wesmart © Tous droits réservés 2023',
    SUCCESSFULLY_REGISTERED: 'Enregistré avec succès',
    SUCCESSFULLY_CREATED: 'Créé avec succès',
    SUCCESSFULLY_UPDATED: 'Mise à jour réussie',
    SUCCESSFULLY_DELETED: 'Supprimé avec succès',
    YOU_CANNOT_REGISTER_YOURSELF_PLEASE_MAIL_TO_ADMIN_TO_SIGN_UP:
      'Vous ne pouvez pas vous inscrire vous-même. <br />Veuillez envoyer un e-mail à francois@wesmart.com pour vous inscrire',
    REQUEST_ERROR: 'Erreur de requête',
    THIS_FIELD_IS_REQUIRED: 'Ce champ est obligatoire',
    ENTER_EMAIL_ADDRESS: "Entrez l'adresse e-mail",
    INVALID_GENDER: 'Genre invalide',
    INVALID_EMAIL_ADDRESS: 'Adresse e-mail invalide',
    INVALID_POSTCODE: 'Code postal invalide',
    ENTER_PASSWORD: 'Entrez le mot de passe',
    TODAY: "Aujourd'hui",
    THIS_WEEK: 'Cette semaine',
    THIS_MONTH: 'Ce mois-ci',
    THIS_YEAR: 'Cette année',
    CUSTOM: 'Coutume',
    DAY: 'Jour',
    WEEK: 'Semaine',
    MONTH: 'Mois',
    YEAR: 'Année',
    COMMUNITY_SELF_SUFFICIENCY: 'Autosuffisance communautaire',
    CONSUMPTION: 'Consommation',
    COMMUNITY_PRICE: 'Prix de la communauté',
    AVERAGE_SUPPLIER_PRICE: 'Prix moyen fournisseur',
    GAINS: 'Gains',
    SAVINGS: 'Économies',
    FROM_GRID: 'Du réseau',
    FROM_COMMUNITY: 'De la communauté',
    PRODUCERS: 'Producteurs',
    CONSUMERS: 'Consommateurs',
    EDIT_COMMUNITY_INFO: 'Modifier les informations de la communauté',
    SEE_COMMUNITY_PAGE: 'Voir la page de la communauté',
    ADD_COMMUNITY_MANAGER: 'Ajouter un gestionnaire de communauté',
    COMMUNITY_MANAGERS: 'Gestionnaire(s) de communauté',
    REQUESTS_TO_JOIN: "Demandes d'adhésion",
    COMMUNITY_MEMBERS: 'Membres de la communauté',
    CONNECTED: 'Connecté',
    DISCONNECTED: 'Déconnecté',
    NAME: 'Nom',
    EMAIL: 'E-mail',
    _EMAIL: 'e-mail',
    _PASSWORD: 'mot de passe',
    AT_LEAST: 'Au moins',
    _CHARACTERS: 'caractères',
    A_MIXTURE_OF_BOTH_UPPERCASE_AND_LOWERCASE_LETTERS:
      'Un mélange de lettres majuscules et minuscules',
    A_MIXTURE_OF_LETTERS_AND_NUMBERS: 'Un mélange de lettres et de chiffres',
    INCLUSION_OF_AT_LEAST_ONE_SPECIAL_CHARACTER:
      "Inclusion d'au moins un caractère spécial, par exemple, ! @ # ? ]",
    PASSWORD_MATCHED: 'Mot de passe correspondant',
    CREATE_A_PASSWORD_IN_ORDER_TO_PROTECT_YOUR_ACCOUNT_AND_BE_THE_ONLY_ONE_WHO_HAS_ACCESS_TO_IT:
      'Créez un mot de passe pour protéger votre compte et être le seul à y avoir accès',
    CREATE_A_PASSWORD: 'Créer un mot de passe',
    REPEAT_PASSWORD: 'Répéter le mot de passe',
    POST_CODE: 'Code postal',
    EAN: 'EAN',
    PDL: 'PDL',
    EAN_STATUS: 'Statut EAN',
    STATUS: 'Statut',
    TYPE: 'Type',
    JOINED: 'Rejoint',
    JOINED_ON: 'Rejoint le',
    REQUEST_STATUS: 'Statut de la demande',
    RESPOND: 'Répondre',
    ALWAYS_ACCEPT_REQUESTS: 'Toujours accepter les demandes',
    SWITCH_VIEW: 'Changer de vue',
    DOWNLOAD_ALL_INVOICES: 'Télécharger toutes les factures',
    COMMUNITY_CONTRACTS: 'Contrats communautaires',
    ENERGY_BILLS: "Factures d'énergie",
    DOCUMENT_NAME: 'Nom du document',
    DOCUMENT_ID: 'ID du document',
    CREATED_ON: 'Créé le',
    CONTRACT_STATUS: 'Statut du contrat',
    PRODUCTION: 'Production',
    SHAREABLE_PRODUCTION: 'Production partageable',
    VIEW_MY_PUBLIC_PROFILE: 'Voir mon profil public',
    PROFILE: 'Profil',
    BILLING_PLAN: 'Plan de facturation',
    NOTIFICATION_SETTINGS: 'Paramètres de notification',
    NOTIFICATIONS: 'Notifications',
    SECURITY: 'Sécurité',
    LANGUAGE_AND_APPEARENCE: 'Langue et apparence',
    OTHER_SETTINGS: 'Autres paramètres',
    WELCOME_HOW_CAN_WE_HELP_YOU: 'Bienvenue, comment pouvons-nous vous aider ?',
    SEARCH_HERE_FOR_ANYTHING: 'Recherchez ici tout ce que vous voulez',
    POPULAR_SEARCHES: 'Recherches populaires',
    COMMUNITY_TIPS: 'Conseils communautaires',
    ENERGY_CALCULATOR: "Calculateur d'énergie",
    A_GUIDE_TO_GETTING_STARTED: 'Un guide pour commencer',
    VISIT_OUR_QNA_SECTION: 'Visitez notre section Q&A',
    CONTACT_US_FOR_MORE_QUESTIONS: 'Contactez-nous pour plus de questions',
    READ_MORE: 'Lire la suite',
    DIVE_IN_THE_WORLD_OF_ENERGY_COMMUNITIES_AND_DISCOVER_NEW_WAYS_TO_SAVE_ON_YOUR_BILLS:
      "Plongez dans le monde des communautés énergétiques et découvrez de nouvelles façons d'économiser sur vos factures",
    CONTACT_US_NOW: 'Contactez-nous maintenant',
    HELP_US_IMPROVE_YOUR_EXPERIENCE: 'Aidez-nous à améliorer votre expérience!',
    DO_YOU_HAVE_A_SUGGESTION_COMMENT_OR_FOUND_SOME_BUG_GIVE_US_SOME_MORE_DETAILS_IN_THE_FIELD_BELOW:
      'Avez-vous une suggestion, un commentaire ou avez-vous trouvé un bug ?\nDonnez-nous plus de détails dans le champ ci-dessous',
    DESCRIBE_YOUR_ISSUE_OR_IDEA_HERE:
      'Décrivez votre problème ou votre idée ici...',
    REPORT_A_BUG: 'Signaler un bug',
    COMMENT: 'Commentaire',
    OTHER: 'Autre',
    MANAGE_AND_MONITOR_YOUR_ENERGY_COMMUNITY_IN_REAL_TIME:
      'Gérez et surveillez votre communauté énergétique en temps réel.',
    BECOME_PART_OF_AN_ENERGY_COMMUNITY_AND_START_SHARING_ENERGY_TODAY:
      "Devenez membre d'une communauté énergétique et commencez à partager de l'énergie dès aujourd'hui",
    OR: 'ou',
    IF_YOU_ARE_ALREADY_REGISTERED: 'si vous êtes déjà inscrit',
    LOGIN: 'se connecter',
    SIGNIN: 'se connecter',
    REGISTER_TODAY: "Inscrivez-vous aujourd'hui",
    TOTAL_PRODUCTION: 'Production totale',
    TOTAL_CONSUMPTION: 'Consommation totale',
    KWH: 'kWh',
    MWH: 'MWh',
    GWH: 'GWh',
    YOUR_PROFILE_PHOTO: 'Votre photo de profil',
    UPLOAD_NEW_PHOTO: 'Télécharger une nouvelle photo',
    REMOVE_PHOTO: 'Supprimer la photo',
    UPLOAD_A_PHOTO_SO_OTHER_MEMBERS_CAN_RECOGNIZE_YOU:
      'Téléchargez une photo pour que les autres membres puissent vous reconnaître',
    PRONOUNS: 'Pronoms',
    HE: 'Il',
    HIM: 'Lui',
    YOUR_EMAIL: 'Votre email',
    WESMART_SOLUTIONS: 'Solutions WeSmart',
    FIRST_NAME: 'Prénom',
    LAST_NAME: 'Nom de famille',
    YOUR_NAME: 'Votre nom',
    YOUR_SURNAME: 'Votre nom de famille',
    PHONE: 'Téléphone',
    ROLE: 'Rôle',
    GENDER: 'Genre',
    JOB_TITLE: 'Titre du poste',
    COMPANY_NAME: "Nom de l'entreprise",
    COMPANY_VAT_NUMBER: "Numéro de TVA de l'entreprise",
    ADDRESS: 'Adresse',
    CITY: 'Ville',
    COUNTRY: 'Pays',
    POSTCODE: 'Postcode',
    DSO: 'DSO',
    ID_NUMBER: "Numéro d'identification",
    OFFICE_ID_NUMBER: "Numéro d'identification du siège social",
    VAT: 'TVA',
    NACE: 'NACE',
    LEGAL_STRUCTURE_CODE: 'Code des structures juridiques',
    COMPANY_ADDRESS: "Adresse de l'entreprise",
    COMPANY_CITY: "Ville de l'entreprise",
    COMPANY_COUNTRY: "Pays de l'entreprise",
    COMPANY_POSTCODE: "Code postal de l'entreprise",
    INVITED_BY: 'Invité par',
    ON: 'le',
    PERMANENTLY_DEACTIVATE_ACCOUNT:
      'Désactiver le compte de manière permanente',
    YOUR_CURRENT_PLAN: 'Votre plan actuel',
    UPGRADE_PLAN: 'Mettre à niveau le plan',
    CANCEL_PLAN: 'Annuler le plan',
    GO_BACK_TO_MY_PROFILE: 'Retourner à mon profil',
    CHOOSE_THE_PLAN_THAT_WORKS_BEST_FOR_YOU:
      'Choisissez le plan qui convient le mieux à vos besoins',
    BILLING_PERSON: 'Personne de facturation',
    UPDATE_YOUR_BILLING_INFORMATION:
      'Mettre à jour vos informations de facturation',
    POWERED_BY: 'Propulsé par',
    STRIPE: 'Stripe',
    BILLING_HISTORY: 'Historique de facturation',
    SEE_ALL_INVOICES: 'Voir toutes les factures',
    ACTIVE_PLAN: 'Plan actif',
    PLAN_COST: 'Coût du plan',
    PAYMENT_DATE: 'Date de paiement',
    SAVE_TO_MY_COMPUTER: 'Enregistrer sur mon ordinateur',
    DOWNLOAD_INVOICE: 'Télécharger la facture',
    VIEW_INVOICE: 'Voir la facture',
    YOUR_PLAN_RENEWS_ON: 'Votre plan se renouvelle le',
    CANCEL: 'Annuler',
    SAVE_UPDATES: 'Enregistrer les mises à jour',
    CHOOSE_THE_PLAN_THAT_SUITS_YOUR_NEEDS:
      'Choisissez le plan qui convient le mieux à vos besoins',
    USER: 'Utilisateur',
    PARTICIPANT: 'Participant',
    PARTICIPANTS: 'Participants',
    COMMUNITY_MANAGER: 'Gestionnaire de communauté',
    ADMIN: 'Administrateur',
    SUPER: 'Super',
    SUB: 'Sous',
    PRO: 'Pro',
    MONTHLY_CHARGE: 'Frais mensuels',
    FREE: 'Gratuit',
    COMMUNITY_DASHBOARD_SELF_CONSUMPTION_KPIS:
      "Tableau de bord de la communauté: KPI d'autoconsommation",
    ENERGY_DATA_DASHBOARD_IF_DSO_DATA_IS_AVAILABLE:
      'Tableau de bord des données énergétiques (si les données DSO sont disponibles)',
    SETTINGS_AND_NOTIFICATIONS: 'Paramètres et notifications',
    THERE_ARE_NO_RECORDS_TO_DISPLAY: 'Il n’y a pas de données à afficher',
    ROWS_PER_PAGE: 'lignes par page',
    PARTICIPANT_MANAGEMENT: 'Gestion des participants',
    DEVICE_MANAGEMENT: 'Gestion des appareils',
    DISTRIBUTION_OF_SELF_CONSUMED_ENERGY:
      "Répartition de l'énergie autoconsommée",
    PRICE_MANAGEMENT: 'Gestion des prix',
    INVOICING_TO_PARTICIPANTS: 'Facturation aux participants',
    OPTIMISATION_AND_RECOMMENDATIONS: 'Optimisation et recommandations',
    PERSONALISED_DASHBOARDS_AND_BRANDING:
      'Tableaux de bord personnalisés et branding',
    PREMIUM_HELPDESK: "Service d'assistance premium",
    CHOOSE_THIS_PLAN: 'Choisir ce plan',
    CURRENT_PLAN: 'Plan actuel',
    GET_A_DEMO: 'Obtenir une démo',
    DO_NOT_DISTURB: 'Ne pas déranger',
    DISABLE_ALL_NOTIFICATIONS: 'Désactiver toutes les notifications',
    CONFIGURE_EMAIL_NOTIFICATIONS_FREQUENCY:
      'Configurer la fréquence des notifications par e-mail',
    DAILY_SUMMARIES: 'Résumés quotidiens',
    GET_NOTIFIED_DAILY_ABOUT_WHAT_HAPPENS_IN_YOUR_COMMUNITY:
      'Recevoir une notification quotidienne sur ce qui se passe dans votre communauté',
    WEEKLY_REPORT: 'Rapport hebdomadaire',
    GET_NOTIFIED_WEEKLY_ABOUT_WHAT_HAPPENS_IN_YOUR_COMMUNITY:
      'Recevoir une notification hebdomadaire sur ce qui se passe dans votre communauté',
    MONTHLY_REPORT: 'Rapport mensuel',
    GET_NOTIFIED_MONTHLY_ABOUT_WHAT_HAPPENS_IN_YOUR_COMMUNITY:
      'Recevoir une notification mensuelle sur ce qui se passe dans votre communauté',
    NOTIFICATIONS_ARE_SENT_DIRECTLY_TO_YOUR_CURRENT_EMAIL:
      'Les notifications sont envoyées directement à votre e-mail actuel',
    WESMART_ENERGY_COMMUNITY: 'Communauté énergétique WeSmart',
    DOWNLOAD_YOUR_DATA: 'Télécharger vos données',
    DECIDE_HOW_THE_COMMUNITY_MANAGERS_YOUR_PERSONAL_INFO:
      'Décidez comment les gestionnaires de la communauté gèrent vos informations personnelles',
    CURRENT_PASSWORD: 'Mot de passe actuel',
    UPDATE_PASSWORD: 'Mettre à jour le mot de passe',
    CONFIRM_NEW_PASSWORD: 'Confirmer le nouveau mot de passe',
    SECURITY_LEVELS: 'Niveaux de sécurité',
    TWO_FACTOR_AUTHENTICATION: 'Authentification à deux facteurs',
    REQUIRE_AN_AUTHENTICATION_CODE_WHEN_LOGGING_IN:
      "Exiger un code d'authentification lors de la connexion",
    SECURITY_LOGOUT: 'Déconnexion sécurisée',
    LOGOUT_OF_ALL_DEVICES_AND_BROWSERS:
      'Déconnexion de tous les équipements et navigateurs',
    IN_APP_LANGUAGE: "Langue de l'application",
    APP_BRANDING: "Branding de l'application",
    CHANGE_LOGO: 'Changer le logo',
    REMOVE_LOGO: 'Supprimer le logo',
    THEME: 'Thème',
    LIGHT: 'Clair',
    ACTIVE: 'actif',
    ACCEPTED: 'Accepté',
    NOT_ACCEPTED: 'Non accepté',
    DARK: 'Sombre',
    SYNC_WITH_SYSTEM: 'Synchroniser avec le système',
    APP_APPEARENCE_CHANGES_WITH_THE_OPERATING_SYSTEM_BROWSER:
      "L'apparence de l'application change avec le système d'exploitation/le navigateur",
    THE_APP_WITH_GO_TO_DARK_MODE_IN_ABOUT:
      "L'application passera en mode sombre dans À propos",
    THE_APP_WITH_GO_TO_LIGHT_MODE_IN_ABOUT:
      "L'application passera en mode clair dans À propos",
    HOURS: 'heures',
    AND: 'et',
    MINUTES: 'minutes',
    CANCELED: 'Annulé',
    SIGNED: 'Signé',
    NOT_SIGNED_YET: 'Pas encore signé',
    PAID: 'Payé',
    NOT_PAID_YET: 'Pas encore payé',
    WESMART_BRINGS_A_DIGITAL_PLATFORM_AND_SERVICES_TO_OPERATE_RENEWABLE_ENERGY_COMMUNITIES:
      "WeSmart apporte une plateforme numérique et des services pour exploiter des communautés d'énergie renouvelable",
    SWEEN_BRINGS_A_DIGITAL_PLATFORM_AND_SERVICES_TO_OPERATE_RENEWABLE_ENERGY_COMMUNITIES:
      "Sween apporte une plateforme numérique et des services pour exploiter des communautés d'énergie renouvelable",
    PARTICIPANT_NAME: 'Nom du participant',
    DEVICE: 'Équipement',
    DEVICES: 'Équipements',
    TAGS: 'Mots clés',
    DEVICE_NAME: "Nom de l'appareil",
    DEVICE_TYPE: "Type d'appareil",
    DOCUMENT: 'Document',
    DOWNLOAD: 'Télécharger',
    DOCUMENT_TYPE: 'Type de document',
    DOCUMENT_STATUS: 'Statut du document',
    DOCUMENT_URL: 'URL Du Document',
    PRODUCER: 'Producteur',
    CONSUMER: 'Consommateur',
    PROSUMER: 'Prosommateur',
    PERSON_TYPE: 'Type de personne',
    EXPORT_DATA: 'Exporter des données',
    INDIVIDUAL_SELF_CONSUMPTION: 'Autoconsommation individuelle',
    COLLECTIVE_SELF_CONSUMPTION: 'Autoconsommation collective',
    SHARED_PRODUCTION: 'Production partagée',
    UNKNOWN: 'Inconnue',
    POWER: 'Puissance',
    DECLINATION: 'Déclinaison',
    AZIMUTH: 'Azimut',
    LATITUDE: 'Latitude',
    LONGITUDE: 'Longitude',
    THIS_FIELD_MUST_BE_NUMBER: 'Ce champ doit être un numéro',
    STREAM: 'Flux',
    VALUE: 'Valeur',
    UNIT: 'Unité',
    TIME: 'Temps',
    TAG: 'Étiqueter',
    AMOUNT: 'Montant',
    THIS_FIELD_CANNOT_BE_UPDATED_TO_UPDATE_REMOVE_AND_RECREATE:
      'Ce champ ne peut pas être mis à jour.\nPour mettre à jour, supprimer et recréer.',
    COMMUNITY_ENERGY_USAGE: "Consommation d'énergie communautaire",
    EMAIL_SENT: 'Email envoyé',
    REMOVED: 'Supprimé',
    SESSION_HAS_EXPIRED: 'La session a expiré',
    THE__: "L'",
    SELF_CONSUMPTION: 'autoconsommation',
    IS_THE_PART_OF_THE_CONSUMPTION_THAT_COMES_FROM_THE_RENEWABLE_ENERGY_PRODUCTION_OF_THE_COMMUNITY:
      "est la partie de la consommation qui provient de la production d'énergie renouvelable de la communauté.",
    IN_THIS_CASE_IT_IS_EQUAL_TO: 'Dans ce cas, il est égal à',
    SELF_SUFFICIENCY: 'autosuffisance',
    IS_THE_SELF_CONSUMPTION_DIVIDED_BY_THE_TOTAL_CONSUMPTION:
      'est l’autoconsommation divisée par la consommation totale.',
    THIS_MEANS_THAT: 'Cela signifie que',
    OF_THE_COMMUNITY_CONSUMPTION_COMES_FROM_THE_RENEWABLE_ENERGY_PRODUCTION_OF_THE_COMMUNITY:
      'de la consommation communautaire provient de la production d’énergie renouvelable de la communauté.',
    THE_SAVINGS_ARE_THE_AGGREGATED: 'Les économies sont le total',
    BILL_SAVINGS: 'économies cumulées',
    OF_THE_COMMUNITY_MEMBERS_DUE_TO_THE_CONSUMPTION_OF_THE_RENEWABLE_ENERGY_OF_THE_COMMUNITY:
      "sur la facture des membres de la communauté grâce à la consommation de l'énergie renouvelable de la communauté.",
    THESE_SAVINGS_ARE_HOWEVER_AN_APPROXIMATION_TAKEN_WITH_HYPOTHESES_AND_ARE_NOT_THE_REAL_ONE:
      'Ces économies sont cependant une approximation prise avec des hypothèses et ne sont pas les réalités.',
    CODE_LABEL: 'Code;Libellé',
    CLICK_TO_SEE_DETAILS: 'Cliquez pour voir les détails',
    WESMART: 'WeSmart',
    SEND_PASSWORD_RESET_EMAIL:
      'Envoyer un e-mail de réinitialisation du mot de passe',
    ENTER_YOUR_USER_ACCOUNTS_VERIFIED_EMAIL_ADDRESS_AND_WE_WILL_SEND_YOU_A_PASSWORD_RESET_LINK:
      "Entrez l'adresse e-mail vérifiée de votre compte utilisateur et nous vous enverrons un lien de réinitialisation du mot de passe",
    PASSWORD_RESET_EMAIL_SENT:
      'E-mail de réinitialisation du mot de passe envoyé.',
    CHECK_YOUR_EMAIL_FOR_A_LINK_TO_RESET_YOUR_PASSWORD:
      'Vérifiez votre courrier électronique pour trouver un lien permettant de réinitialiser votre mot de passe.',
    IF_IT_DOES_NOT_APPEAR_WITHIN_A_FEW_MINUTES_CHECK_YOUR_SPAM_FOLDER:
      "S'il n'apparaît pas au bout de quelques minutes, vérifiez votre dossier spam.",
    CONTINUE: 'Continuer',
    IS_THE_PART_OF_THE_SHAREABLE_PRODUCTION_THAT_HAS_BEEN_SHARED_AMONG_THE_MEMBERS_AND_SELF_CONSUMED:
      'est la partie de la production partageable qui a été partagée entre les membres et autoconsommée.',
    IS_THE_PART_OF_THE_ENERGY_PRODUCTION_OF_PRODUCERS_THAT_IS_AVAILABLE_FOR_THE_CUSTOMERS:
      "est la partie de la production d'énergie des producteurs qui est disponible pour les consommateurs.",
    IT_IS_ALSO_CALLED_INJECTION: "On l'appelle aussi injection.",
    IS_THE_AMOUNT_OF_ENERGY_THAT_MEMBERS_TAKE_FROM_THE_GRID:
      "est la quantité d'énergie que les membres prélèvent sur le réseau.",
    IT_IS_THE_OFFTAKE_CONSUMPTION: "C'est la consommation de prélèvement.",
    THE_AMOUNT_OF_ENERGY_THAT_YOU_TAKE_FROM_THE_GRID:
      "La quantité d'énergie que vous prélevez sur le réseau.",
    THE_PART_OF_YOUR_OWN_PRODUCTION_THAT_YOU_INJECT_INTO_THE_GRID_AND_MAKE_AVAILABLE_TO_THE_OTHER_MEMBERS:
      'La partie de votre propre production que vous injectez dans le réseau et mettez à disposition des autres membres.',
    THE_PART_OF_THE_CONSUMPTION_THAT_COMES_FROM_THE_ENERGY_SHARING:
      "La part de la consommation qui provient du partage d'énergie.",
    THE_PART_OF_THE_SHAREABLE_PRODUCTION_THAT_HAS_BEEN_SHARED:
      'La partie de la production partageable qui a été partagée.',
    THE_PART_OF_YOUR_OWN_PRODUCTION_THAT_IS_SELF_CONSUMED_AND_NOT_INJECTED_INTO_THE_GRID:
      'La partie de votre propre production qui est autoconsommée et non injectée dans le réseau.',
    IF_YOU_SUM_THE_CONSUMPTION_WITH_THE_INDIVIDUAL_SELF_CONSUMPTION_YOU_OBTAIN_YOUR_REAL_CONSUMPTION:
      "Si vous additionnez la consommation avec l'autoconsommation individuelle, vous obtenez votre consommation réelle.",
    IF_YOU_SUM_THE_SHAREABLE_PRODUCTION_WITH_THE_INDIVIDUAL_SELF_CONSUMPTION_YOU_OBTAIN_YOUR_REAL_PRODUCTION:
      'Si vous additionnez la production partageable avec l’autoconsommation individuelle, vous obtenez votre production réelle.',
    DRAG_AND_DROP_A_FILE_HERE_OR_CLICK_TO_SELECT_FILE:
      'Faites glisser et déposez un fichier ici ou cliquez pour sélectionner un fichier',
    FILE_FORMAT_ERROR: 'Erreur de format de fichier',
    I_WILL_FOLLOW_THE_RECOMMENDATION: 'Je vais suivre la recommandation',
    I_WILL_NOT_FOLLOW_THE_RECOMMENDATION: 'Je ne suivrai pas la recommandation',
    DISCOVER_NOW: 'Découvrez maintenant',
    DISCOVER_NEWS: "Découvrez l'actualité",
    // New string
  },
  es: {
    ACTIONS: 'Acciones',
    MY_COMMUNITY: 'Mi Comunidad',
    MY_PARTICIPANTS: 'Mis Participantes',
    MY_DOCUMENTS: 'Mis Documentos',
    MY_DEVICES: 'Mis Dispositivos',
    MY_ENERGY: 'Mi Energía',
    MY_CONSUMPTION: 'Mi Consumo',
    MY_PRODUCTION: 'Mi Produccion',
    SETTINGS: 'Configuración',
    GET_HELP: 'Obtener ayuda',
    SEND_FEEDBACK: 'Enviar comentarios',
    LOGOUT: 'Cerrar sesión',
    COMMUNITIES: 'Comunidades',
    USERS: 'Usuarios',
    SORT_BY: 'Ordenar por',
    CREATE_NEW: 'Crear nuevo',
    DELETE_SELECTED: 'Eliminar seleccionado',
    SEARCH_HERE: 'Buscar aquí',
    COMMUNITY_DASHBOARD: 'Panel de comunidad',
    COMMUNITY_NAME: 'Nombre de la comunidad',
    VIEW_COMMUNITIES: 'Ver comunidades',
    VIEW_PARTICIPANTS: 'Ver participantes',
    VIEW_DOCUMENTS: 'Ver documentos',
    DOCUMENTS: 'Documentos',
    VIEW_DEVICES: 'Ver dispositivos',
    VIEW_STREAMS: 'Ver transmisiones',
    STREAMS: 'Transmisiones',
    NOTION_LINK: 'Enlace de Notion',
    NOTION_ID: 'ID de Notion',
    CREATE: 'Crear',
    INVITE: 'Invitar',
    UPDATE: 'Actualizar',
    DELETE: 'Eliminar',
    COMMUNITY: 'Comunidad',
    ARE_YOU_SURE_WANT_TO_DELETE: '¿Estás seguro de que quieres eliminar',
    DELETE_FOREVER: 'Eliminar para siempre',
    DELETE_FOREVER_DESCRIPTION:
      'Si marca esta casilla, el elemento se eliminará de nuestra base de datos y no se podrá recuperar.',
    SELECTED: 'Seleccionado',
    SELECT_ALL: 'Seleccionar todo',
    SELECT_CONTRACTS: 'Seleccionar contratos',
    SELECT_INVOICES: 'Seleccionar facturas',
    DEVELOPED_BY_WESMART_ALL_RIGHTS_RESERVED_2022:
      'Desarrollado por Wesmart © Todos los derechos reservados 2022',
    DEVELOPED_BY_WESMART_ALL_RIGHTS_RESERVED_2023:
      'Desarrollado por Wesmart © Todos los derechos reservados 2023',
    SUCCESSFULLY_REGISTERED: 'Registrado exitosamente',
    SUCCESSFULLY_CREATED: 'Creado con éxito',
    SUCCESSFULLY_UPDATED: 'Actualizado exitosamente',
    SUCCESSFULLY_DELETED: 'Eliminado con éxito',
    YOU_CANNOT_REGISTER_YOURSELF_PLEASE_MAIL_TO_ADMIN_TO_SIGN_UP:
      'No puedes registrarte tú mismo. <br />Por favor envía un correo electrónico a francois@wesmart.com para registrarte',
    REQUEST_ERROR: 'Error de solicitud',
    THIS_FIELD_IS_REQUIRED: 'Este campo es obligatorio',
    ENTER_EMAIL_ADDRESS: 'Ingrese la dirección de correo electrónico',
    INVALID_GENDER: 'Género inválido',
    INVALID_EMAIL_ADDRESS: 'Dirección de correo electrónico inválida',
    INVALID_POSTCODE: 'Código postal inválido',
    ENTER_PASSWORD: 'Ingrese la contraseña',
    TODAY: 'Hoy',
    THIS_WEEK: 'Esta semana',
    THIS_MONTH: 'Este mes',
    THIS_YEAR: 'Este año',
    CUSTOM: 'Costumbre',
    DAY: 'Día',
    WEEK: 'Semana',
    MONTH: 'Mes',
    YEAR: 'Año',
    COMMUNITY_SELF_SUFFICIENCY: 'Autosuficiencia comunitaria',
    CONSUMPTION: 'Consumo',
    COMMUNITY_PRICE: 'Precio de la comunidad',
    AVERAGE_SUPPLIER_PRICE: 'Precio promedio del proveedor',
    GAINS: 'Ganancias',
    SAVINGS: 'Ahorros',
    FROM_GRID: 'De la red',
    FROM_COMMUNITY: 'De la comunidad',
    PRODUCERS: 'Productores',
    CONSUMERS: 'Consumidores',
    EDIT_COMMUNITY_INFO: 'Editar información de la comunidad',
    SEE_COMMUNITY_PAGE: 'Ver página de la comunidad',
    ADD_COMMUNITY_MANAGER: 'Agregar administrador de la comunidad',
    COMMUNITY_MANAGERS: 'Administrador(es) de la comunidad',
    REQUESTS_TO_JOIN: 'Solicitudes para unirse',
    COMMUNITY_MEMBERS: 'Miembros de la comunidad',
    CONNECTED: 'Conectado',
    DISCONNECTED: 'Desconectado',
    NAME: 'Nombre',
    EMAIL: 'Correo electrónico',
    _EMAIL: 'correo electrónico',
    _PASSWORD: 'contraseña',
    AT_LEAST: 'Al menos',
    _CHARACTERS: 'caracteres',
    A_MIXTURE_OF_BOTH_UPPERCASE_AND_LOWERCASE_LETTERS:
      'Una mezcla de letras mayúsculas y minúsculas',
    A_MIXTURE_OF_LETTERS_AND_NUMBERS: 'Una mezcla de letras y números',
    INCLUSION_OF_AT_LEAST_ONE_SPECIAL_CHARACTER:
      'Inclusión de al menos un carácter especial, por ejemplo, ! @ # ? ]',
    PASSWORD_MATCHED: 'Contraseña coincidente',
    CREATE_A_PASSWORD_IN_ORDER_TO_PROTECT_YOUR_ACCOUNT_AND_BE_THE_ONLY_ONE_WHO_HAS_ACCESS_TO_IT:
      'Crea una contraseña para proteger tu cuenta y ser el único que tenga acceso a ella',
    CREATE_A_PASSWORD: 'Crear una contraseña',
    REPEAT_PASSWORD: 'Repetir contraseña',
    POST_CODE: 'Código postal',
    EAN: 'EAN',
    PDL: 'PDL',
    EAN_STATUS: 'Estado de EAN',
    STATUS: 'Estado',
    TYPE: 'Tipo',
    JOINED: 'Unido',
    JOINED_ON: 'Unido el',
    REQUEST_STATUS: 'Estado de solicitud',
    RESPOND: 'Responder',
    ALWAYS_ACCEPT_REQUESTS: 'Aceptar siempre solicitudes',
    SWITCH_VIEW: 'Cambiar vista',
    DOWNLOAD_ALL_INVOICES: 'Descargar todas las facturas',
    COMMUNITY_CONTRACTS: 'Contratos de la comunidad',
    ENERGY_BILLS: 'Facturas de energía',
    DOCUMENT_NAME: 'Nombre del documento',
    DOCUMENT_ID: 'ID del documento',
    CREATED_ON: 'Creado el',
    CONTRACT_STATUS: 'Estado del contrato',
    PRODUCTION: 'Producción',
    SHAREABLE_PRODUCTION: 'Producción compartible',
    VIEW_MY_PUBLIC_PROFILE: 'Ver mi perfil público',
    PROFILE: 'Perfil',
    BILLING_PLAN: 'Plan de facturación',
    NOTIFICATION_SETTINGS: 'Configuración de notificaciones',
    NOTIFICATIONS: 'Notificaciones',
    SECURITY: 'Seguridad',
    LANGUAGE_AND_APPEARENCE: 'Idioma y apariencia',
    OTHER_SETTINGS: 'Otras configuraciones',
    WELCOME_HOW_CAN_WE_HELP_YOU: 'Bienvenido, ¿cómo podemos ayudarte?',
    SEARCH_HERE_FOR_ANYTHING: 'Busca aquí cualquier cosa',
    POPULAR_SEARCHES: 'Búsquedas populares',
    COMMUNITY_TIPS: 'Consejos de la comunidad',
    ENERGY_CALCULATOR: 'Calculadora de energía',
    A_GUIDE_TO_GETTING_STARTED: 'Una guía para empezar',
    VISIT_OUR_QNA_SECTION: 'Visita nuestra sección de preguntas y respuestas',
    CONTACT_US_FOR_MORE_QUESTIONS: 'Contáctanos para más preguntas',
    READ_MORE: 'Leer más',
    DIVE_IN_THE_WORLD_OF_ENERGY_COMMUNITIES_AND_DISCOVER_NEW_WAYS_TO_SAVE_ON_YOUR_BILLS:
      'Sumérgete en el mundo de las comunidades de energía y descubre nuevas formas de ahorrar en tus facturas',
    CONTACT_US_NOW: 'Contáctanos ahora',
    HELP_US_IMPROVE_YOUR_EXPERIENCE: '¡Ayúdanos a mejorar tu experiencia!',
    DO_YOU_HAVE_A_SUGGESTION_COMMENT_OR_FOUND_SOME_BUG_GIVE_US_SOME_MORE_DETAILS_IN_THE_FIELD_BELOW:
      '¿Tienes alguna sugerencia, comentario o encontraste algún error?\nDanos más detalles en el campo de abajo',
    DESCRIBE_YOUR_ISSUE_OR_IDEA_HERE: 'Describe tu problema o idea aquí...',
    REPORT_A_BUG: 'Reportar un error',
    COMMENT: 'Comentario',
    OTHER: 'Otro',
    MANAGE_AND_MONITOR_YOUR_ENERGY_COMMUNITY_IN_REAL_TIME:
      'Administra y monitorea tu consumo de energía en tiempo real.',
    BECOME_PART_OF_AN_ENERGY_COMMUNITY_AND_START_SHARING_ENERGY_TODAY:
      'Únete a una comunidad de energía y comienza a compartir energía hoy',
    OR: 'o',
    IF_YOU_ARE_ALREADY_REGISTERED: 'si ya estás registrado',
    LOGIN: 'iniciar sesión',
    SIGNIN: 'Registrarse',
    REGISTER_TODAY: 'Regístrate hoy',
    TOTAL_PRODUCTION: 'Producción total',
    TOTAL_CONSUMPTION: 'Consumo total',
    KWH: 'kWh',
    MWH: 'MWh',
    GWH: 'GWh',
    YOUR_PROFILE_PHOTO: 'Tu foto de perfil',
    UPLOAD_NEW_PHOTO: 'Subir nueva foto',
    REMOVE_PHOTO: 'Eliminar foto',
    UPLOAD_A_PHOTO_SO_OTHER_MEMBERS_CAN_RECOGNIZE_YOU:
      'Sube una foto para que otros miembros puedan reconocerte',
    PRONOUNS: 'Pronombres',
    HE: 'Él',
    HIM: 'Lo',
    YOUR_EMAIL: 'Tu correo electrónico',
    WESMART_SOLUTIONS: 'WeSmart Solutions',
    FIRST_NAME: 'Nombre de pila',
    LAST_NAME: 'Apellido',
    YOUR_NAME: 'Tu nombre',
    YOUR_SURNAME: 'Tu apellido',
    PHONE: 'Teléfono',
    ROLE: 'Role',
    GENDER: 'Género',
    JOB_TITLE: 'Título del trabajo',
    COMPANY_NAME: 'Nombre de la empresa',
    COMPANY_VAT_NUMBER: 'Número de IVA de la empresa',
    ADDRESS: 'Dirección',
    CITY: 'Ciudad',
    COUNTRY: 'País',
    POSTCODE: 'Postcode',
    DSO: 'DSO',
    ID_NUMBER: 'Número de identificación',
    OFFICE_ID_NUMBER: 'Número de identificación de la oficina',
    VAT: 'IVA',
    NACE: 'NACE',
    LEGAL_STRUCTURE_CODE: 'Código de estructura jurídica',
    COMPANY_ADDRESS: 'Dirección de la empresa',
    COMPANY_CITY: 'Ciudad de la empresa',
    COMPANY_COUNTRY: 'País de la empresa',
    COMPANY_POSTCODE: 'Código postal de la empresa',
    INVITED_BY: 'Invitado por',
    ON: 'en',
    PERMANENTLY_DEACTIVATE_ACCOUNT: 'Desactivar cuenta permanentemente',
    YOUR_CURRENT_PLAN: 'Tu plan actual',
    UPGRADE_PLAN: 'Actualizar plan',
    CANCEL_PLAN: 'Cancelar plan',
    GO_BACK_TO_MY_PROFILE: 'Volver a mi perfil',
    CHOOSE_THE_PLAN_THAT_WORKS_BEST_FOR_YOU:
      'Elige el plan que mejor se adapte a ti',
    BILLING_PERSON: 'Persona de facturación',
    UPDATE_YOUR_BILLING_INFORMATION: 'Actualiza tu información de facturación',
    POWERED_BY: 'Desarrollado por',
    STRIPE: 'Stripe',
    BILLING_HISTORY: 'Historial de facturación',
    SEE_ALL_INVOICES: 'Ver todas las facturas',
    ACTIVE_PLAN: 'Plan activo',
    PLAN_COST: 'Costo del plan',
    PAYMENT_DATE: 'Fecha de pago',
    SAVE_TO_MY_COMPUTER: 'Guardar en mi computadora',
    DOWNLOAD_INVOICE: 'Descargar factura',
    VIEW_INVOICE: 'Ver factura',
    YOUR_PLAN_RENEWS_ON: 'Tu plan se renueva el',
    CANCEL: 'Cancelar',
    SAVE_UPDATES: 'Guardar actualizaciones',
    CHOOSE_THE_PLAN_THAT_SUITS_YOUR_NEEDS:
      'Elige el plan que se adapte a tus necesidades',
    USER: 'Usuario',
    PARTICIPANT: 'Participante',
    PARTICIPANTS: 'Participantes',
    COMMUNITY_MANAGER: 'Administrador de la comunidad',
    ADMIN: 'Administrador',
    SUPER: 'Super',
    SUB: 'Sub',
    PRO: 'Pro',
    MONTHLY_CHARGE: 'Cargo mensual',
    FREE: 'Gratis',
    COMMUNITY_DASHBOARD_SELF_CONSUMPTION_KPIS:
      'Panel de control de la comunidad: KPI de autoconsumo',
    ENERGY_DATA_DASHBOARD_IF_DSO_DATA_IS_AVAILABLE:
      'Panel de datos de energía (si los datos del DSO están disponibles)',
    SETTINGS_AND_NOTIFICATIONS: 'Configuración y notificaciones',
    THERE_ARE_NO_RECORDS_TO_DISPLAY: 'No hay registros para mostrar',
    ROWS_PER_PAGE: 'Filas por página',
    PARTICIPANT_MANAGEMENT: 'Gestión de participantes',
    DEVICE_MANAGEMENT: 'Gestión de dispositivos',
    DISTRIBUTION_OF_SELF_CONSUMED_ENERGY:
      'Distribución de energía autoconsumida',
    PRICE_MANAGEMENT: 'Gestión de precios',
    INVOICING_TO_PARTICIPANTS: 'Facturación a los participantes',
    OPTIMISATION_AND_RECOMMENDATIONS: 'Optimización y recomendaciones',
    PERSONALISED_DASHBOARDS_AND_BRANDING: 'Paneles personalizados y marca',
    PREMIUM_HELPDESK: 'Soporte premium',
    CHOOSE_THIS_PLAN: 'Elige este plan',
    CURRENT_PLAN: 'Plan actual',
    GET_A_DEMO: 'Obtener una demostración',
    DO_NOT_DISTURB: 'No molestar',
    DISABLE_ALL_NOTIFICATIONS: 'Desactivar todas las notificaciones',
    CONFIGURE_EMAIL_NOTIFICATIONS_FREQUENCY:
      'Configurar la frecuencia de las notificaciones por correo electrónico',
    DAILY_SUMMARIES: 'Resúmenes diarios',
    GET_NOTIFIED_DAILY_ABOUT_WHAT_HAPPENS_IN_YOUR_COMMUNITY:
      'Recibe notificaciones diarias sobre lo que sucede en tu comunidad',
    WEEKLY_REPORT: 'Informe semanal',
    GET_NOTIFIED_WEEKLY_ABOUT_WHAT_HAPPENS_IN_YOUR_COMMUNITY:
      'Recibe notificaciones semanales sobre lo que sucede en tu comunidad',
    MONTHLY_REPORT: 'Informe mensual',
    GET_NOTIFIED_MONTHLY_ABOUT_WHAT_HAPPENS_IN_YOUR_COMMUNITY:
      'Recibe notificaciones mensuales sobre lo que sucede en tu comunidad',
    NOTIFICATIONS_ARE_SENT_DIRECTLY_TO_YOUR_CURRENT_EMAIL:
      'Las notificaciones se envían directamente a tu correo electrónico actual',
    WESMART_ENERGY_COMMUNITY: 'Comunidad de energía WeSmart',
    DOWNLOAD_YOUR_DATA: 'Descarga tus datos',
    DECIDE_HOW_THE_COMMUNITY_MANAGERS_YOUR_PERSONAL_INFO:
      'Decide cómo los administradores de la comunidad manejan tu información personal',
    CURRENT_PASSWORD: 'Contraseña actual',
    UPDATE_PASSWORD: 'Actualizar contraseña',
    CONFIRM_NEW_PASSWORD: 'Confirmar nueva contraseña',
    SECURITY_LEVELS: 'Niveles de seguridad',
    TWO_FACTOR_AUTHENTICATION: 'Autenticación de dos factores',
    REQUIRE_AN_AUTHENTICATION_CODE_WHEN_LOGGING_IN:
      'Requiere un código de autenticación al iniciar sesión',
    SECURITY_LOGOUT: 'Cierre de sesión seguro',
    LOGOUT_OF_ALL_DEVICES_AND_BROWSERS:
      'Cerrar sesión en todos los dispositivos y navegadores',
    IN_APP_LANGUAGE: 'Idioma de la aplicación',
    APP_BRANDING: 'Marca de la aplicación',
    CHANGE_LOGO: 'Cambiar logo',
    REMOVE_LOGO: 'Eliminar logo',
    THEME: 'Tema',
    LIGHT: 'Claro',
    ACTIVE: 'activo',
    ACCEPTED: 'Aceptado',
    NOT_ACCEPTED: 'No aceptado',
    DARK: 'Oscuro',
    SYNC_WITH_SYSTEM: 'Sincronizar con el sistema',
    APP_APPEARENCE_CHANGES_WITH_THE_OPERATING_SYSTEM_BROWSER:
      'La apariencia de la aplicación cambia con el sistema operativo/navegador',
    THE_APP_WITH_GO_TO_DARK_MODE_IN_ABOUT:
      'La aplicación cambiará al modo oscuro en aproximadamente',
    THE_APP_WITH_GO_TO_LIGHT_MODE_IN_ABOUT:
      'La aplicación cambiará al modo claro en aproximadamente',
    HOURS: 'horas',
    AND: 'y',
    MINUTES: 'minutos',
    CANCELED: 'Cancelado',
    SIGNED: 'Firmado',
    NOT_SIGNED_YET: 'Aún no firmado',
    PAID: 'Pagado',
    NOT_PAID_YET: 'Aún no pagado',
    WESMART_BRINGS_A_DIGITAL_PLATFORM_AND_SERVICES_TO_OPERATE_RENEWABLE_ENERGY_COMMUNITIES:
      'WeSmart trae una plataforma digital y servicios para operar comunidades de energía renovable',
    SWEEN_BRINGS_A_DIGITAL_PLATFORM_AND_SERVICES_TO_OPERATE_RENEWABLE_ENERGY_COMMUNITIES:
      'Sween trae una plataforma digital y servicios para operar comunidades de energía renovable',
    PARTICIPANT_NAME: 'Nombre del participante',
    DEVICE: 'Dispositivo',
    DEVICES: 'Dispositivos',
    TAGS: 'Etiquetas',
    DEVICE_NAME: 'Nombre del dispositivo',
    DEVICE_TYPE: 'Tipo de dispositivo',
    DOCUMENT: 'Documento',
    DOWNLOAD: 'Descargar',
    DOCUMENT_TYPE: 'Tipo de documento',
    DOCUMENT_STATUS: 'Estado del documento',
    DOCUMENT_URL: 'URL del documento',
    PRODUCER: 'Productor',
    CONSUMER: 'Consumidor',
    PROSUMER: 'Prosumidor',
    PERSON_TYPE: 'Tipo de persona',
    EXPORT_DATA: 'Exportar datos',
    INDIVIDUAL_SELF_CONSUMPTION: 'Autoconsumo individual',
    COLLECTIVE_SELF_CONSUMPTION: 'Autoconsumo colectivo',
    SHARED_PRODUCTION: 'Producción compartida',
    UNKNOWN: 'Desconocida',
    POWER: 'Fuerza',
    DECLINATION: 'Declinación',
    AZIMUTH: 'Azimut',
    LATITUDE: 'Latitud',
    LONGITUDE: 'Longitud',
    THIS_FIELD_MUST_BE_NUMBER: 'Este campo debe ser número',
    STREAM: 'Arroyo',
    VALUE: 'Valor',
    UNIT: 'Unidad',
    TIME: 'Tiempo',
    TAG: 'Etiqueta',
    AMOUNT: 'Cantidad',
    THIS_FIELD_CANNOT_BE_UPDATED_TO_UPDATE_REMOVE_AND_RECREATE:
      'Este campo no se puede actualizar.\nPara actualizar, eliminar y volver a crear.',
    COMMUNITY_ENERGY_USAGE: 'Uso de energía de la comunidad',
    EMAIL_SENT: 'Email enviado',
    REMOVED: 'Remota',
    SESSION_HAS_EXPIRED: 'La sesión ha expirado',
    THE__: 'La ',
    SELF_CONSUMPTION: 'ser-consumo',
    IS_THE_PART_OF_THE_CONSUMPTION_THAT_COMES_FROM_THE_RENEWABLE_ENERGY_PRODUCTION_OF_THE_COMMUNITY:
      'es la parte del consumo que proviene de la producción de energía renovable de la comunidad.',
    IN_THIS_CASE_IT_IS_EQUAL_TO: 'En este caso es igual a',
    SELF_SUFFICIENCY: 'ser-suficiencia',
    IS_THE_SELF_CONSUMPTION_DIVIDED_BY_THE_TOTAL_CONSUMPTION:
      'es el autoconsumo dividido por el consumo total.',
    THIS_MEANS_THAT: 'Esto significa que',
    OF_THE_COMMUNITY_CONSUMPTION_COMES_FROM_THE_RENEWABLE_ENERGY_PRODUCTION_OF_THE_COMMUNITY:
      'del consumo comunitario proviene de la producción de energía renovable de la comunidad.',
    THE_SAVINGS_ARE_THE_AGGREGATED: 'Los ahorros son el agregado.',
    BILL_SAVINGS: 'ahorro agregado en las facturas',
    OF_THE_COMMUNITY_MEMBERS_DUE_TO_THE_CONSUMPTION_OF_THE_RENEWABLE_ENERGY_OF_THE_COMMUNITY:
      'de los miembros de la comunidad debido al consumo de energía renovable de la comunidad.',
    THESE_SAVINGS_ARE_HOWEVER_AN_APPROXIMATION_TAKEN_WITH_HYPOTHESES_AND_ARE_NOT_THE_REAL_ONE:
      'Sin embargo, estos ahorros son una aproximación tomada con hipótesis y no son reales.',
    CODE_LABEL: 'Código;Fraseología',
    CLICK_TO_SEE_DETAILS: 'Haga clic para ver detalles',
    WESMART: 'WeSmart',
    SEND_PASSWORD_RESET_EMAIL:
      'Enviar correo electrónico para restablecer contraseña',
    ENTER_YOUR_USER_ACCOUNTS_VERIFIED_EMAIL_ADDRESS_AND_WE_WILL_SEND_YOU_A_PASSWORD_RESET_LINK:
      'Ingrese la dirección de correo electrónico verificada de su cuenta de usuario y le enviaremos un enlace para restablecer la contraseña.',
    PASSWORD_RESET_EMAIL_SENT:
      'Correo electrónico de restablecimiento de contraseña enviado.',
    CHECK_YOUR_EMAIL_FOR_A_LINK_TO_RESET_YOUR_PASSWORD:
      'Revise su correo electrónico para obtener un enlace para restablecer su contraseña.',
    IF_IT_DOES_NOT_APPEAR_WITHIN_A_FEW_MINUTES_CHECK_YOUR_SPAM_FOLDER:
      'Si no aparece en unos minutos, revisa tu carpeta de spam.',
    CONTINUE: 'Continuar',
    IS_THE_PART_OF_THE_SHAREABLE_PRODUCTION_THAT_HAS_BEEN_SHARED_AMONG_THE_MEMBERS_AND_SELF_CONSUMED:
      'es la parte de la producción compartible que ha sido repartida entre los socios y autoconsumida.',
    IS_THE_PART_OF_THE_ENERGY_PRODUCTION_OF_PRODUCERS_THAT_IS_AVAILABLE_FOR_THE_CUSTOMERS:
      'es la parte de la producción de energía de los productores que está disponible para los consumidores.',
    IT_IS_ALSO_CALLED_INJECTION: 'También se le llama inyección.',
    IS_THE_AMOUNT_OF_ENERGY_THAT_MEMBERS_TAKE_FROM_THE_GRID:
      'es la cantidad de energía que los miembros toman de la red.',
    IT_IS_THE_OFFTAKE_CONSUMPTION: 'Es el consumo de salida.',
    THE_AMOUNT_OF_ENERGY_THAT_YOU_TAKE_FROM_THE_GRID:
      'La cantidad de energía que tomas de la red.',
    THE_PART_OF_YOUR_OWN_PRODUCTION_THAT_YOU_INJECT_INTO_THE_GRID_AND_MAKE_AVAILABLE_TO_THE_OTHER_MEMBERS:
      'La parte de tu propia producción que inyectas en la grilla y pones a disposición de los demás integrantes.',
    THE_PART_OF_THE_CONSUMPTION_THAT_COMES_FROM_THE_ENERGY_SHARING:
      'La parte del consumo que proviene del reparto de energía.',
    THE_PART_OF_THE_SHAREABLE_PRODUCTION_THAT_HAS_BEEN_SHARED:
      'La parte de la producción Shareable que se ha compartido.',
    THE_PART_OF_YOUR_OWN_PRODUCTION_THAT_IS_SELF_CONSUMED_AND_NOT_INJECTED_INTO_THE_GRID:
      'La parte de la producción propia que es autoconsumida y no inyectada a la red.',
    IF_YOU_SUM_THE_CONSUMPTION_WITH_THE_INDIVIDUAL_SELF_CONSUMPTION_YOU_OBTAIN_YOUR_REAL_CONSUMPTION:
      'Si sumas el Consumo con el Autoconsumo Individual obtienes tu consumo real.',
    IF_YOU_SUM_THE_SHAREABLE_PRODUCTION_WITH_THE_INDIVIDUAL_SELF_CONSUMPTION_YOU_OBTAIN_YOUR_REAL_PRODUCTION:
      'Si sumas la producción compartible con el autoconsumo individual obtienes tu producción real.',
    DRAG_AND_DROP_A_FILE_HERE_OR_CLICK_TO_SELECT_FILE:
      'Arrastre y suelte un archivo aquí o haga clic para seleccionar el archivo',
    FILE_FORMAT_ERROR: 'Error de formato de archivo',
    I_WILL_FOLLOW_THE_RECOMMENDATION: 'Seguiré la recomendación',
    I_WILL_NOT_FOLLOW_THE_RECOMMENDATION: 'No seguiré la recomendación',
    DISCOVER_NOW: 'Descubre ahora',
    DISCOVER_NEWS: 'Descubre noticias',
    // New string
  },
  nl: {
    ACTIONS: 'Acties',
    MY_COMMUNITY: 'Mijn Gemeenschap',
    MY_PARTICIPANTS: 'Mijn Deelnemers',
    MY_DOCUMENTS: 'Mijn Documenten',
    MY_DEVICES: 'Mijn Apparaten',
    MY_ENERGY: 'Mijn Energie',
    MY_CONSUMPTION: 'Mijn Verbruik',
    MY_PRODUCTION: 'Mijn Productie',
    SETTINGS: 'Instellingen',
    GET_HELP: 'Hulp krijgen',
    SEND_FEEDBACK: 'Feedback verzenden',
    LOGOUT: 'Uitloggen',
    COMMUNITIES: 'Gemeenschappen',
    USERS: 'Gebruikers',
    SORT_BY: 'Sorteren op',
    CREATE_NEW: 'Nieuwe aanmaken',
    DELETE_SELECTED: 'Geselecteerde verwijderen',
    SEARCH_HERE: 'Zoek hier',
    COMMUNITY_DASHBOARD: 'Gemeenschap dashboard',
    COMMUNITY_NAME: 'Gemeenschapsnaam',
    VIEW_COMMUNITIES: 'Gemeenschappen bekijken',
    VIEW_PARTICIPANTS: 'Deelnemers bekijken',
    VIEW_DOCUMENTS: 'Documenten bekijken',
    DOCUMENTS: 'Documenten',
    VIEW_DEVICES: 'Apparaten bekijken',
    VIEW_STREAMS: 'Streams bekijken',
    STREAMS: 'Streams',
    NOTION_LINK: 'Notion-link',
    NOTION_ID: 'Notion-ID',
    CREATE: 'Creëren',
    INVITE: 'Uitnodiging',
    UPDATE: 'Bijwerken',
    DELETE: 'Verwijderen',
    COMMUNITY: 'Gemeenschap',
    ARE_YOU_SURE_WANT_TO_DELETE: 'Weet u zeker dat u wilt verwijderen',
    DELETE_FOREVER: 'Definitief verwijderen',
    DELETE_FOREVER_DESCRIPTION:
      'Als u dit vakje aanvinkt, wordt het item uit onze database verwijderd en kan het niet worden hersteld.',
    SELECTED: 'Geselecteerd',
    SELECT_ALL: 'Alles selecteren',
    SELECT_CONTRACTS: 'Selecteer Contracten',
    SELECT_INVOICES: 'Selecteer Facturen',
    DEVELOPED_BY_WESMART_ALL_RIGHTS_RESERVED_2022:
      'Ontwikkeld door Wesmart © Alle rechten voorbehouden 2022',
    DEVELOPED_BY_WESMART_ALL_RIGHTS_RESERVED_2023:
      'Ontwikkeld door Wesmart © Alle rechten voorbehouden 2023',
    SUCCESSFULLY_REGISTERED: 'Succesvol geregistreerd',
    SUCCESSFULLY_CREATED: 'Succesvol aangemaakt',
    SUCCESSFULLY_UPDATED: 'Succesvol geüpdatet',
    SUCCESSFULLY_DELETED: 'Succesvol verwijderd',
    YOU_CANNOT_REGISTER_YOURSELF_PLEASE_MAIL_TO_ADMIN_TO_SIGN_UP:
      'U kunt uzelf niet registreren. <br />Stuur alstublieft een e-mail naar francois@wesmart.com om u aan te melden',
    REQUEST_ERROR: 'Fout bij aanvraag',
    THIS_FIELD_IS_REQUIRED: 'Dit veld is verplicht',
    ENTER_EMAIL_ADDRESS: 'Voer e-mailadres in',
    INVALID_GENDER: 'Ongeldig geslacht',
    INVALID_EMAIL_ADDRESS: 'Ongeldig e-mailadres',
    INVALID_POSTCODE: 'Ongeldige postcode',
    ENTER_PASSWORD: 'Voer wachtwoord in',
    TODAY: 'Vandaag',
    THIS_WEEK: 'Deze week',
    THIS_MONTH: 'Deze maand',
    THIS_YEAR: 'Dit jaar',
    CUSTOM: 'Aangepast',
    DAY: 'Dag',
    WEEK: 'Week',
    MONTH: 'Maand',
    YEAR: 'Jaar',
    COMMUNITY_SELF_SUFFICIENCY: 'Gemeenschappelijke zelfvoorziening',
    CONSUMPTION: 'Verbruik',
    COMMUNITY_PRICE: 'Gemeenschapsprijs',
    AVERAGE_SUPPLIER_PRICE: 'Gemiddelde leveranciersprijs',
    GAINS: 'Winsten',
    SAVINGS: 'Besparingen',
    FROM_GRID: 'Van het net',
    FROM_COMMUNITY: 'Van de gemeenschap',
    PRODUCERS: 'Producenten',
    CONSUMERS: 'Consumenten',
    EDIT_COMMUNITY_INFO: 'Gemeenschapsinformatie bewerken',
    SEE_COMMUNITY_PAGE: 'Bekijk gemeenschapspagina',
    ADD_COMMUNITY_MANAGER: 'Voeg gemeenschapsbeheerder toe',
    COMMUNITY_MANAGERS: 'Gemeenschapsbeheerder(s)',
    REQUESTS_TO_JOIN: 'Verzoeken om lid te worden',
    COMMUNITY_MEMBERS: 'Gemeenschapsleden',
    CONNECTED: 'Verbonden',
    DISCONNECTED: 'Verbroken',
    NAME: 'Naam',
    EMAIL: 'E-mail',
    _EMAIL: 'e-mail',
    _PASSWORD: 'wachtwoord',
    AT_LEAST: 'Minimaal',
    _CHARACTERS: 'tekens',
    A_MIXTURE_OF_BOTH_UPPERCASE_AND_LOWERCASE_LETTERS:
      'Een mix van hoofdletters en kleine letters',
    A_MIXTURE_OF_LETTERS_AND_NUMBERS: 'Een mix van letters en cijfers',
    INCLUSION_OF_AT_LEAST_ONE_SPECIAL_CHARACTER:
      'Inclusie van ten minste één speciaal teken, bijv. ! @ # ? ]',
    PASSWORD_MATCHED: 'Wachtwoord komt overeen',
    CREATE_A_PASSWORD_IN_ORDER_TO_PROTECT_YOUR_ACCOUNT_AND_BE_THE_ONLY_ONE_WHO_HAS_ACCESS_TO_IT:
      'Maak een wachtwoord aan om uw account te beschermen en de enige te zijn die er toegang toe heeft',
    CREATE_A_PASSWORD: 'Maak een wachtwoord aan',
    REPEAT_PASSWORD: 'Herhaal wachtwoord',
    POST_CODE: 'Postcode',
    EAN: 'EAN',
    PDL: 'PDL',
    EAN_STATUS: 'EAN-status',
    STATUS: 'Status',
    TYPE: 'Type',
    JOINED: 'Toegetreden',
    JOINED_ON: 'Toegetreden op',
    REQUEST_STATUS: 'Status verzoek',
    RESPOND: 'Reageren',
    ALWAYS_ACCEPT_REQUESTS: 'Altijd verzoeken accepteren',
    SWITCH_VIEW: 'Wissel weergave',
    DOWNLOAD_ALL_INVOICES: 'Download alle facturen',
    COMMUNITY_CONTRACTS: 'Gemeenschapscontracten',
    ENERGY_BILLS: 'Energie rekeningen',
    DOCUMENT_NAME: 'Documentnaam',
    DOCUMENT_ID: 'Document-ID',
    CREATED_ON: 'Gemaakt op',
    CONTRACT_STATUS: 'Contractstatus',
    PRODUCTION: 'Productie',
    SHAREABLE_PRODUCTION: 'Deelbare productie',
    VIEW_MY_PUBLIC_PROFILE: 'Bekijk mijn openbare profiel',
    PROFILE: 'Profiel',
    BILLING_PLAN: 'Factureringsplan',
    NOTIFICATION_SETTINGS: 'Meldingsinstellingen',
    NOTIFICATIONS: 'Meldingen',
    SECURITY: 'Beveiliging',
    LANGUAGE_AND_APPEARENCE: 'Taal en uiterlijk',
    OTHER_SETTINGS: 'Andere instellingen',
    WELCOME_HOW_CAN_WE_HELP_YOU: 'Welkom, hoe kunnen we u helpen?',
    SEARCH_HERE_FOR_ANYTHING: 'Zoek hier naar alles',
    POPULAR_SEARCHES: 'Populaire zoekopdrachten',
    COMMUNITY_TIPS: 'Gemeenschapstips',
    ENERGY_CALCULATOR: 'Energiecalculator',
    A_GUIDE_TO_GETTING_STARTED: 'Een gids voor het beginnen',
    VISIT_OUR_QNA_SECTION: 'Bezoek onze vraag- en antwoordsectie',
    CONTACT_US_FOR_MORE_QUESTIONS: 'Neem contact met ons op voor meer vragen',
    READ_MORE: 'Lees meer',
    DIVE_IN_THE_WORLD_OF_ENERGY_COMMUNITIES_AND_DISCOVER_NEW_WAYS_TO_SAVE_ON_YOUR_BILLS:
      'Dompel je onder in de wereld van energiegemeenschappen en ontdek nieuwe manieren om te besparen op je rekeningen',
    CONTACT_US_NOW: 'Neem nu contact met ons op',
    HELP_US_IMPROVE_YOUR_EXPERIENCE: 'Help ons uw ervaring te verbeteren!',
    DO_YOU_HAVE_A_SUGGESTION_COMMENT_OR_FOUND_SOME_BUG_GIVE_US_SOME_MORE_DETAILS_IN_THE_FIELD_BELOW:
      'Heeft u een suggestie, opmerking of een bug gevonden?\nGeef ons wat meer details in het veld hieronder',
    DESCRIBE_YOUR_ISSUE_OR_IDEA_HERE: 'Beschrijf hier uw probleem of idee...',
    REPORT_A_BUG: 'Meld een bug',
    COMMENT: 'Opmerking',
    OTHER: 'Anders',
    MANAGE_AND_MONITOR_YOUR_ENERGY_COMMUNITY_IN_REAL_TIME:
      'Beheer en monitor uw energieverbruik in realtime.',
    BECOME_PART_OF_AN_ENERGY_COMMUNITY_AND_START_SHARING_ENERGY_TODAY:
      'Word deel van een energiegemeenschap en begin vandaag nog met het delen van energie',
    OR: 'of',
    IF_YOU_ARE_ALREADY_REGISTERED: 'als u al geregistreerd bent',
    LOGIN: 'inloggen',
    SIGNIN: 'Aanmelden',
    REGISTER_TODAY: 'Registreer vandaag',
    TOTAL_PRODUCTION: 'Totale productie',
    TOTAL_CONSUMPTION: 'Totale consumptie',
    KWH: 'kWh',
    MWH: 'MWh',
    GWH: 'GWh',
    YOUR_PROFILE_PHOTO: 'Uw profielfoto',
    UPLOAD_NEW_PHOTO: 'Nieuwe foto uploaden',
    REMOVE_PHOTO: 'Foto verwijderen',
    UPLOAD_A_PHOTO_SO_OTHER_MEMBERS_CAN_RECOGNIZE_YOU:
      'Upload een foto zodat andere leden u kunnen herkennen',
    PRONOUNS: 'Voornaamwoorden',
    HE: 'Hij',
    HIM: 'Hem',
    YOUR_EMAIL: 'Uw e-mail',
    WESMART_SOLUTIONS: 'WeSmart Solutions',
    FIRST_NAME: 'Voornaam',
    LAST_NAME: 'Achternaam',
    YOUR_NAME: 'Uw naam',
    YOUR_SURNAME: 'Uw achternaam',
    PHONE: 'Telefoon',
    ROLE: 'Rol',
    GENDER: 'Geslacht',
    JOB_TITLE: 'Functietitel',
    COMPANY_NAME: 'Bedrijfsnaam',
    COMPANY_VAT_NUMBER: 'Bedrijfs BTW-nummer',
    ADDRESS: 'Adres',
    CITY: 'Stad',
    COUNTRY: 'Land',
    POSTCODE: 'Postcode',
    DSO: 'DSO',
    ID_NUMBER: 'ID Number',
    OFFICE_ID_NUMBER: 'Kantoor-ID-nummer',
    VAT: 'BTW',
    NACE: 'NACE',
    LEGAL_STRUCTURE_CODE: 'Wettelijke Structuurcode',
    COMPANY_ADDRESS: 'Bedrijfsadres',
    COMPANY_CITY: 'Bedrijfsstad',
    COMPANY_COUNTRY: 'Bedrijfsland',
    COMPANY_POSTCODE: 'Bedrijfspostcode',
    INVITED_BY: 'Uitgenodigd door',
    ON: 'op',
    PERMANENTLY_DEACTIVATE_ACCOUNT: 'Account permanent deactiveren',
    YOUR_CURRENT_PLAN: 'Uw huidige plan',
    UPGRADE_PLAN: 'Plan upgraden',
    CANCEL_PLAN: 'Plan annuleren',
    GO_BACK_TO_MY_PROFILE: 'Ga terug naar mijn profiel',
    CHOOSE_THE_PLAN_THAT_WORKS_BEST_FOR_YOU:
      'Kies het plan dat het beste bij u past',
    BILLING_PERSON: 'Facturatiepersoon',
    UPDATE_YOUR_BILLING_INFORMATION: 'Werk uw factureringsinformatie bij',
    POWERED_BY: 'Aangedreven door',
    STRIPE: 'Stripe',
    BILLING_HISTORY: 'Factureringsgeschiedenis',
    SEE_ALL_INVOICES: 'Bekijk alle facturen',
    ACTIVE_PLAN: 'Actief plan',
    PLAN_COST: 'Plan kosten',
    PAYMENT_DATE: 'Betalingsdatum',
    SAVE_TO_MY_COMPUTER: 'Opslaan op mijn computer',
    DOWNLOAD_INVOICE: 'Download factuur',
    VIEW_INVOICE: 'Bekijk factuur',
    YOUR_PLAN_RENEWS_ON: 'Uw plan wordt vernieuwd op',
    CANCEL: 'Annuleren',
    SAVE_UPDATES: 'Updates opslaan',
    CHOOSE_THE_PLAN_THAT_SUITS_YOUR_NEEDS:
      'Kies het plan dat bij uw behoeften past',
    USER: 'Gebruiker',
    PARTICIPANT: 'Deelnemer',
    PARTICIPANTS: 'Deelnemers',
    COMMUNITY_MANAGER: 'Community Manager',
    ADMIN: 'Admin',
    SUPER: 'Super',
    SUB: 'Sub',
    PRO: 'Pro',
    MONTHLY_CHARGE: 'Maandelijkse kosten',
    FREE: 'Gratis',
    COMMUNITY_DASHBOARD_SELF_CONSUMPTION_KPIS:
      "Community Dashboard: zelfverbruik KPI's",
    ENERGY_DATA_DASHBOARD_IF_DSO_DATA_IS_AVAILABLE:
      'Energiegegevensdashboard (indien DSO-gegevens beschikbaar zijn)',
    SETTINGS_AND_NOTIFICATIONS: 'Instellingen en meldingen',
    THERE_ARE_NO_RECORDS_TO_DISPLAY: 'Er zijn geen records om weer te geven',
    ROWS_PER_PAGE: 'Rijen per pagina',
    PARTICIPANT_MANAGEMENT: 'Deelnemersbeheer',
    DEVICE_MANAGEMENT: 'Apparaatbeheer',
    DISTRIBUTION_OF_SELF_CONSUMED_ENERGY:
      'Verdeling van zelfverbruikte energie',
    PRICE_MANAGEMENT: 'Prijsbeheer',
    INVOICING_TO_PARTICIPANTS: 'Facturering aan deelnemers',
    OPTIMISATION_AND_RECOMMENDATIONS: 'Optimalisatie en aanbevelingen',
    PERSONALISED_DASHBOARDS_AND_BRANDING:
      'Gepersonaliseerde dashboards en branding',
    PREMIUM_HELPDESK: 'Premium helpdesk',
    CHOOSE_THIS_PLAN: 'Kies dit plan',
    CURRENT_PLAN: 'Huidig plan',
    GET_A_DEMO: 'Vraag een demo aan',
    DO_NOT_DISTURB: 'Niet storen',
    DISABLE_ALL_NOTIFICATIONS: 'Schakel alle meldingen uit',
    CONFIGURE_EMAIL_NOTIFICATIONS_FREQUENCY:
      'Configureer de frequentie van e-mailmeldingen',
    DAILY_SUMMARIES: 'Dagelijkse samenvattingen',
    GET_NOTIFIED_DAILY_ABOUT_WHAT_HAPPENS_IN_YOUR_COMMUNITY:
      'Ontvang dagelijks meldingen over wat er in uw gemeenschap gebeurt',
    WEEKLY_REPORT: 'Wekelijks rapport',
    GET_NOTIFIED_WEEKLY_ABOUT_WHAT_HAPPENS_IN_YOUR_COMMUNITY:
      'Ontvang wekelijks meldingen over wat er gebeurt in uw gemeenschap',
    MONTHLY_REPORT: 'Maandelijks rapport',
    GET_NOTIFIED_MONTHLY_ABOUT_WHAT_HAPPENS_IN_YOUR_COMMUNITY:
      'Ontvang maandelijks meldingen over wat er gebeurt in uw gemeenschap',
    NOTIFICATIONS_ARE_SENT_DIRECTLY_TO_YOUR_CURRENT_EMAIL:
      'Meldingen worden rechtstreeks naar uw huidige e-mailadres gestuurd',
    WESMART_ENERGY_COMMUNITY: 'WeSmart Energy Community',
    DOWNLOAD_YOUR_DATA: 'Download uw gegevens',
    DECIDE_HOW_THE_COMMUNITY_MANAGERS_YOUR_PERSONAL_INFO:
      'Beslis hoe de gemeenschap uw persoonlijke informatie beheert',
    CURRENT_PASSWORD: 'Huidig wachtwoord',
    UPDATE_PASSWORD: 'Wachtwoord bijwerken',
    CONFIRM_NEW_PASSWORD: 'Bevestig nieuw wachtwoord',
    SECURITY_LEVELS: 'Beveiligingsniveaus',
    TWO_FACTOR_AUTHENTICATION: 'Twee-factor authenticatie',
    REQUIRE_AN_AUTHENTICATION_CODE_WHEN_LOGGING_IN:
      'Vereis een authenticatiecode bij het inloggen',
    SECURITY_LOGOUT: 'Beveiligingslogout',
    LOGOUT_OF_ALL_DEVICES_AND_BROWSERS:
      'Uitloggen van alle apparaten en browsers',
    IN_APP_LANGUAGE: 'In-app taal',
    APP_BRANDING: 'App branding',
    CHANGE_LOGO: 'Logo wijzigen',
    REMOVE_LOGO: 'Logo verwijderen',
    THEME: 'Thema',
    LIGHT: 'Licht',
    ACTIVE: 'Actief',
    ACCEPTED: 'Geaccepteerd',
    NOT_ACCEPTED: 'Niet geaccepteerd',
    DARK: 'Donker',
    SYNC_WITH_SYSTEM: 'Synchroniseren met systeem',
    APP_APPEARENCE_CHANGES_WITH_THE_OPERATING_SYSTEM_BROWSER:
      'App-uitstraling verandert met het besturingssysteem / browser',
    THE_APP_WITH_GO_TO_DARK_MODE_IN_ABOUT:
      'De app gaat naar de donkere modus in ongeveer',
    THE_APP_WITH_GO_TO_LIGHT_MODE_IN_ABOUT:
      'De app gaat naar de lichte modus in ongeveer',
    HOURS: 'uren',
    AND: 'en',
    MINUTES: 'minuten',
    CANCELED: 'Geannuleerd',
    SIGNED: 'Ondertekend',
    NOT_SIGNED_YET: 'Nog niet ondertekend',
    PAID: 'Betaald',
    NOT_PAID_YET: 'Nog niet betaald',
    WESMART_BRINGS_A_DIGITAL_PLATFORM_AND_SERVICES_TO_OPERATE_RENEWABLE_ENERGY_COMMUNITIES:
      'Wesmart brengt een digitaal platform en diensten om hernieuwbare energiegemeenschappen te exploiteren',
    SWEEN_BRINGS_A_DIGITAL_PLATFORM_AND_SERVICES_TO_OPERATE_RENEWABLE_ENERGY_COMMUNITIES:
      'Sween brengt een digitaal platform en diensten om duurzame energiegemeenschappen te exploiteren',
    PARTICIPANT_NAME: 'Deelnemersnaam',
    DEVICE: 'Apparaat',
    DEVICES: 'Apparaten',
    TAGS: 'Labels',
    DEVICE_NAME: 'Toestelnaam',
    DEVICE_TYPE: 'Soort apparaat',
    DOCUMENT: 'Document',
    DOWNLOAD: 'Downloaden',
    DOCUMENT_TYPE: 'Type document',
    DOCUMENT_STATUS: 'Document status',
    DOCUMENT_URL: 'Document URL',
    PRODUCER: 'Producent',
    CONSUMER: 'Klant',
    PROSUMER: 'Prosument',
    PERSON_TYPE: 'Type persoon',
    EXPORT_DATA: 'Exportgegevens',
    INDIVIDUAL_SELF_CONSUMPTION: 'Individueel eigen verbruik',
    COLLECTIVE_SELF_CONSUMPTION: 'Collectieve eigen consumptie',
    SHARED_PRODUCTION: 'Gedeelde productie',
    UNKNOWN: 'Onbekend',
    POWER: 'Stroom',
    DECLINATION: 'Declinatie',
    AZIMUTH: 'Azimut',
    LATITUDE: 'Breedtegraad',
    LONGITUDE: 'Lengtegraad',
    THIS_FIELD_MUST_BE_NUMBER: 'Dit veld moet nummer zijn',
    STREAM: 'Stroom',
    VALUE: 'Waarde',
    UNIT: 'Eenheid',
    TIME: 'Tijd',
    TAG: 'Label',
    AMOUNT: 'Hoeveelheid',
    THIS_FIELD_CANNOT_BE_UPDATED_TO_UPDATE_REMOVE_AND_RECREATE:
      'Dit veld kan niet worden bijgewerkt.\nOm bij te werken, te verwijderen en opnieuw te maken.',
    COMMUNITY_ENERGY_USAGE: 'Communautair energieverbruik',
    EMAIL_SENT: 'Email verzonden',
    REMOVED: 'Verwijderd',
    SESSION_HAS_EXPIRED: 'Sessie is verlopen',
    THE__: 'De ',
    SELF_CONSUMPTION: 'zelf-consumptie',
    IS_THE_PART_OF_THE_CONSUMPTION_THAT_COMES_FROM_THE_RENEWABLE_ENERGY_PRODUCTION_OF_THE_COMMUNITY:
      'is het deel van het verbruik dat afkomstig is van de duurzame energieproductie van de gemeenschap.',
    IN_THIS_CASE_IT_IS_EQUAL_TO: 'In dit geval is het gelijk aan',
    SELF_SUFFICIENCY: 'zelf-toereikendheid',
    IS_THE_SELF_CONSUMPTION_DIVIDED_BY_THE_TOTAL_CONSUMPTION:
      'is het eigen verbruik gedeeld door het totale verbruik.',
    THIS_MEANS_THAT: 'Dit betekent dat',
    OF_THE_COMMUNITY_CONSUMPTION_COMES_FROM_THE_RENEWABLE_ENERGY_PRODUCTION_OF_THE_COMMUNITY:
      'van de gemeenschapsconsumptie is afkomstig van de duurzame energieproductie van de gemeenschap.',
    THE_SAVINGS_ARE_THE_AGGREGATED: 'De besparingen zijn het totaalbedrag',
    BILL_SAVINGS: 'besparingen op de rekeningen',
    OF_THE_COMMUNITY_MEMBERS_DUE_TO_THE_CONSUMPTION_OF_THE_RENEWABLE_ENERGY_OF_THE_COMMUNITY:
      'van de leden van de gemeenschap als gevolg van het verbruik van hernieuwbare energie van de gemeenschap.',
    THESE_SAVINGS_ARE_HOWEVER_AN_APPROXIMATION_TAKEN_WITH_HYPOTHESES_AND_ARE_NOT_THE_REAL_ONE:
      'Deze besparingen zijn echter een benadering op basis van hypothesen en zijn niet de werkelijke.',
    CODE_LABEL: 'Code; Etiket',
    CLICK_TO_SEE_DETAILS: 'Klik om details te zien',
    WESMART: 'WeSmart',
    SEND_PASSWORD_RESET_EMAIL:
      'Stuur een e-mail voor het opnieuw instellen van het wachtwoord',
    ENTER_YOUR_USER_ACCOUNTS_VERIFIED_EMAIL_ADDRESS_AND_WE_WILL_SEND_YOU_A_PASSWORD_RESET_LINK:
      'Voer het geverifieerde e-mailadres van uw gebruikersaccount in en wij sturen u een link voor het opnieuw instellen van uw wachtwoord',
    PASSWORD_RESET_EMAIL_SENT: 'E-mail voor wachtwoordherstel verzonden.',
    CHECK_YOUR_EMAIL_FOR_A_LINK_TO_RESET_YOUR_PASSWORD:
      'Controleer uw e-mail voor een link om uw wachtwoord opnieuw in te stellen.',
    IF_IT_DOES_NOT_APPEAR_WITHIN_A_FEW_MINUTES_CHECK_YOUR_SPAM_FOLDER:
      'Als het niet binnen een paar minuten verschijnt, controleer dan uw spammap.',
    CONTINUE: 'Doorgaan',
    IS_THE_PART_OF_THE_SHAREABLE_PRODUCTION_THAT_HAS_BEEN_SHARED_AMONG_THE_MEMBERS_AND_SELF_CONSUMED:
      'is het deel van de deelbare productie dat onder de leden is gedeeld en zelf wordt geconsumeerd.',
    IS_THE_PART_OF_THE_ENERGY_PRODUCTION_OF_PRODUCERS_THAT_IS_AVAILABLE_FOR_THE_CUSTOMERS:
      'is het deel van de energieproductie van producenten dat beschikbaar is voor de consumenten.',
    IT_IS_ALSO_CALLED_INJECTION: 'Het wordt ook wel injectie genoemd.',
    IS_THE_AMOUNT_OF_ENERGY_THAT_MEMBERS_TAKE_FROM_THE_GRID:
      'is de hoeveelheid energie die leden van het net afnemen.',
    IT_IS_THE_OFFTAKE_CONSUMPTION: 'Het is het afnameverbruik.',
    THE_AMOUNT_OF_ENERGY_THAT_YOU_TAKE_FROM_THE_GRID:
      'De hoeveelheid energie die je van het net haalt.',
    THE_PART_OF_YOUR_OWN_PRODUCTION_THAT_YOU_INJECT_INTO_THE_GRID_AND_MAKE_AVAILABLE_TO_THE_OTHER_MEMBERS:
      'Het deel van je eigen productie dat je in het net injecteert en ter beschikking stelt aan de andere leden.',
    THE_PART_OF_THE_CONSUMPTION_THAT_COMES_FROM_THE_ENERGY_SHARING:
      'Het deel van het verbruik dat voortkomt uit het delen van energie.',
    THE_PART_OF_THE_SHAREABLE_PRODUCTION_THAT_HAS_BEEN_SHARED:
      'Het deel van de deelbare productie dat is gedeeld.',
    THE_PART_OF_YOUR_OWN_PRODUCTION_THAT_IS_SELF_CONSUMED_AND_NOT_INJECTED_INTO_THE_GRID:
      'Het deel van uw eigen productie dat u zelf consumeert en niet in het net injecteert.',
    IF_YOU_SUM_THE_CONSUMPTION_WITH_THE_INDIVIDUAL_SELF_CONSUMPTION_YOU_OBTAIN_YOUR_REAL_CONSUMPTION:
      'Als u het Verbruik bij het Individueel Eigen Verbruik optelt, krijgt u uw werkelijke verbruik.',
    IF_YOU_SUM_THE_SHAREABLE_PRODUCTION_WITH_THE_INDIVIDUAL_SELF_CONSUMPTION_YOU_OBTAIN_YOUR_REAL_PRODUCTION:
      'Als u de deelbare productie optelt bij de individuele eigen consumptie, krijgt u uw werkelijke productie.',
    DRAG_AND_DROP_A_FILE_HERE_OR_CLICK_TO_SELECT_FILE:
      'Sleep hier een bestand en zet het neer of klik om het bestand te selecteren',
    FILE_FORMAT_ERROR: 'Fout in bestandsformaat',
    I_WILL_FOLLOW_THE_RECOMMENDATION: 'Ik zal de aanbeveling opvolgen',
    I_WILL_NOT_FOLLOW_THE_RECOMMENDATION: 'Ik zal de aanbeveling niet opvolgen',
    DISCOVER_NOW: 'Ontdek nu',
    DISCOVER_NEWS: 'Ontdek nieuws',
    // New string
  },
} as const;

export const stringsBE: {
  [key in LanguageSrcProps]: { [key in LanguageKeyPropsBE]: string };
} = {
  en: {
    'Session has expired': 'Session has expired',
    'You do not have permission': 'You do not have permission',
    'Invalid request data': 'Invalid request data',
    'Not enough request data': 'Not enough request data',
    'Invalid sort field': 'Invalid sort field',
    'Invalid tag format': 'Invalid tag format',
    'Invalid document type': 'Invalid document type',
    'Error catched': 'Error catched',
    'Invalid notification type': 'Invalid notification type',
    'This device is producer.': 'This device is producer.',
    'This device is consumer.': 'This device is consumer.',
    'Can not do this action.': 'Can not do this action.',
    'Request data is not correct': 'Request data is not correct',
    'Incorrect field type': 'Incorrect field type',
    'You have already signed up': 'You have already signed up',
    'Please reset your password': 'Please reset your password',
    'Invalid notification setting format':
      'Invalid notification setting format',
    'Report a bug': 'Report a bug',
    Comment: 'Comment',
    Other: 'Other',
    'Email already exists': 'Email already exists',
    'Sorry, there was an issue with your login.':
      'Sorry, there was an issue with your login.',
    'Please check your credentials and try again.':
      'Please check your credentials and try again.',
    // New BE string
  },
  fr: {
    'Session has expired': 'La session a expiré',
    'You do not have permission': "Vous n'avez pas la permission",
    'Invalid request data': 'Données de requête invalides',
    'Not enough request data': 'Données de requête insuffisantes',
    'Invalid sort field': 'Champ de tri invalide',
    'Invalid tag format': "Format d'étiquette invalide",
    'Invalid document type': 'Type de document invalide',
    'Error catched': 'Erreur capturée',
    'Invalid notification type': 'Type de notification invalide',
    'This device is producer.': 'Cet appareil est producteur.',
    'This device is consumer.': 'Cet appareil est grand public.',
    'Can not do this action.': "Impossible d'effectuer cette action.",
    'Request data is not correct':
      'Les données de la requête ne sont pas correctes',
    'Incorrect field type': 'Type de champ incorrect',
    'You have already signed up': 'Vous êtes déjà inscrit',
    'Please reset your password': 'Veuillez réinitialiser votre mot de passe',
    'Invalid notification setting format':
      'Format de paramètres de notification invalide',
    'Report a bug': 'Signaler un bug',
    Comment: 'Commentaire',
    Other: 'Autre',
    'Email already exists': "L'email existe déjà.",
    'Sorry, there was an issue with your login.':
      'Désolé, il y a eu un problème avec votre connexion.',
    'Please check your credentials and try again.':
      "Veuillez vérifier vos informations d'identification et réessayer.",
    // New BE string
  },
  es: {
    'Session has expired': 'La sesión ha expirado',
    'You do not have permission': 'No tienes permiso',
    'Invalid request data': 'Datos de solicitud no válidos',
    'Not enough request data': 'Datos de solicitud insuficientes',
    'Invalid sort field': 'Campo de ordenación no válido',
    'Invalid tag format': 'Formato de etiqueta no válido',
    'Invalid document type': 'Tipo de documento no válido',
    'Error catched': 'Error capturado',
    'Invalid notification type': 'Tipo de notificación no válido',
    'This device is producer.': 'Este dispositivo es productor.',
    'This device is consumer.': 'Este dispositivo es de consumo.',
    'Can not do this action.': 'No se puede hacer esta acción.',
    'Request data is not correct': 'Los datos de solicitud no son correctos',
    'Incorrect field type': 'Tipo de campo incorrecto',
    'You have already signed up': 'Ya te has registrado',
    'Please reset your password': 'por favor, cambie su contraseña',
    'Invalid notification setting format':
      'Formato de configuración de notificación no válido',
    'Report a bug': 'Informar de un error',
    Comment: 'Comentario',
    Other: 'Otro',
    'Email already exists': 'El correo electrónico ya existe',
    'Sorry, there was an issue with your login.':
      'Lo sentimos, hubo un problema con tu inicio de sesión.',
    'Please check your credentials and try again.':
      'Por favor verifique sus credenciales e inténtelo nuevamente.',
    // New BE string
  },
  nl: {
    'Session has expired': 'Sessie is verlopen',
    'You do not have permission': 'U heeft geen toestemming',
    'Invalid request data': 'Ongeldige verzoekgegevens',
    'Not enough request data': 'Onvoldoende verzoekgegevens',
    'Invalid sort field': 'Ongeldig sorteerveld',
    'Invalid tag format': 'Ongeldig tagformaat',
    'Invalid document type': 'Ongeldig documenttype',
    'Error catched': 'Fout opgevangen',
    'Invalid notification type': 'Ongeldig meldingstype',
    'This device is producer.': 'Dit apparaat is producent.',
    'This device is consumer.': 'Dit apparaat is consumenten.',
    'Can not do this action.': 'Kan deze actie niet uitvoeren.',
    'Request data is not correct': 'Verzoekgegevens zijn niet correct',
    'Incorrect field type': 'Onjuist veldtype',
    'You have already signed up': 'U heeft zich al aangemeld',
    'Please reset your password': 'Stel alstublieft uw wachtwoord opnieuw in',
    'Invalid notification setting format':
      'Ongeldig meldingsinstellingenformaat',
    'Report a bug': 'Rapporteer een bug',
    Comment: 'Opmerking',
    Other: 'Anders',
    'Email already exists': 'E-mail bestaat al',
    'Sorry, there was an issue with your login.':
      'Sorry, er is een probleem opgetreden met uw aanmelding.',
    'Please check your credentials and try again.':
      'Controleer uw gegevens en probeer het opnieuw.',
    // New BE string
  },
} as const;

export const strings: {
  [key in LanguageSrcProps]: { [key in LanguageKeyProps]: string };
} = {
  en: {
    ...stringsFR.en,
    ...stringsBE.en,
  },
  fr: {
    ...stringsFR.fr,
    ...stringsBE.fr,
  },
  es: {
    ...stringsFR.es,
    ...stringsBE.es,
  },
  nl: {
    ...stringsFR.nl,
    ...stringsBE.nl,
  },
} as const;
