import { _global } from '../config/config';
import requestAPI from '../utils/requestAPI';
import { getStringByLanguage } from '../utils/utils';
import { CommunityInfo } from './communities';
import { DeviceInfo } from './devices';
import { UserInfo } from './users';
import { PaginationProps, prepareRequestParams, ResponseProps } from './utils';

export enum PersonType {
  Producer = 'Producer',
  Consumer = 'Consumer',
  Prosumer = 'Prosumer',
}

export const printPersonType = (type: PersonType) => {
  return type === PersonType.Producer
    ? getStringByLanguage('PRODUCER')
    : type === PersonType.Consumer
    ? getStringByLanguage('CONSUMER')
    : getStringByLanguage('PROSUMER');
};

export interface ParticipantInfo {
  id: number;
  community_id?: number | null;
  participant_name: string;
  joined?: string | null;
  type: PersonType;
  participant_address: string | null;
  participant_city: string | null;
  participant_country: string | null;
  participant_postalcode: string | null;
  participant_idn: number | null;
  participant_office_idn: number | null;
  participant_vat: number | null;
  participant_nace: number | null;
  participant_lscn: number | null;
  participant_lscs: string | null;
  created_at: string;
  updated_at: string;
  deleted_at?: string | null;
  community: CommunityInfo;
  user: Pick<UserInfo, 'email' | 'avatar' | 'role' | 'gender'>;
  devices: DeviceInfo[];
}

export interface ParticipantDictionaryById {
  [key: number | string]: ParticipantInfo;
}

export const getParticipantInfo = async (
  id?:
    | number
    | string
    | { id?: number | string; community_id?: number | string },
) => {
  if (typeof id !== 'object') {
    id = {
      id: id,
      community_id: _global.user?.participant?.community_id || 0,
    };
  }

  const { community_id = 0, id: participant_id = 0 } = id;

  const url = `community/${community_id}/participant/${participant_id}`;
  return await requestAPI.get<ResponseProps<ParticipantInfo>>(url);
};

export const sendInvitation = async (
  id?:
    | number
    | string
    | { id?: number | string; community_id?: number | string },
) => {
  if (typeof id !== 'object') {
    id = {
      id: id,
      community_id: _global.user?.participant?.community_id || 0,
    };
  }

  const { community_id = 0, id: participant_id = 0 } = id;

  const url = `community/${community_id}/participant/${participant_id}/invite`;
  return await requestAPI.get<ResponseProps<ParticipantInfo>>(url);
};

export const getParticipants = async (
  data?: {
    community_id?: number;
    type?: 'managers' | 'requests' | 'joined' | 'all';
    withDeleted?: boolean;
  } & PaginationProps<ParticipantInfo>,
) => {
  const {
    community_id = _global.user?.participant?.community_id,
    type = 'all',
    search,
    page,
    perPage,
    sort,
    sortField,
    withDeleted,
  } = data || {};

  let url = `community/${community_id}/participant`;
  url += prepareRequestParams({
    type,
    search,
    page,
    perPage,
    sort,
    sortField,
    withDeleted,
  });
  return await requestAPI.get<ResponseProps<[ParticipantInfo[], number]>>(url);
};

export const updateParticipant = async (
  community_id: string | number | null | undefined,
  data: Partial<ParticipantInfo>,
) => {
  community_id = community_id || 1;
  return (await requestAPI.patch)<ResponseProps<ParticipantInfo>>(
    `/community/${community_id}/participant/${data.id}`,
    data,
  );
};

export const deleteParticipant = async ({
  community_id,
  id,
  forever = false,
}: {
  community_id?: string | number | null;
  id: number;
  forever?: boolean;
}) => {
  community_id = community_id || 1;
  return (await requestAPI.delete)<ResponseProps<ParticipantInfo>>(
    `/community/${community_id}/participant/${id}?safeMode=${!forever}`,
  );
};
