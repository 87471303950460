import { FC, useEffect, useState } from 'react';
import {
  buildStyles,
  CircularProgressbar as ProgressBar,
  CircularProgressbarWithChildren as ProgressBarWithChildren,
} from 'react-circular-progressbar';
import { Animate } from 'react-move';
import { easeQuadInOut } from 'd3-ease';
import { CircularProgressbarWrapperProps } from 'react-circular-progressbar/dist/types';

import { colorDict, numUtils } from '../../utils/utils';

interface AnimatedProgressProps {
  value: number;
  maxValue?: number;
  duration?: number;
  easingFunction?: (normalizedTime: number) => number;
  children: (value: number, maxValue: number) => JSX.Element;
}

export const AnimatedProgressProvider: FC<AnimatedProgressProps> = ({
  value,
  maxValue = 100,
  duration = 1,
  easingFunction = easeQuadInOut,
  children,
}) => {
  const [preValue, setPreValue] = useState(0);
  const [preMax, setPreMax] = useState(0);
  useEffect(() => {
    if (value !== preValue || maxValue !== preMax) {
      const timeoutId = setTimeout(() => {
        setPreValue(value);
        setPreMax(maxValue);
      }, duration * 1000);
      return () => clearTimeout(timeoutId);
    }
  }, [value, maxValue]);

  return (
    <Animate
      start={() => ({
        value: preValue,
      })}
      update={() => ({
        value: [value],
        timing: {
          duration: duration * 1000,
          ease: easingFunction,
        },
      })}
    >
      {({ value }) => children(value, preMax)}
    </Animate>
  );
};

interface FuncGroup extends CircularProgressbarWrapperProps {
  className?: string;
  duration?: number;
  easingFunction?: (normalizedTime: number) => number;
  children?: (value: number) => JSX.Element;
}

const CircularProgressBar: FC<FuncGroup> = ({
  className = '',
  maxValue = 100,
  value,
  duration,
  easingFunction,
  children,
  styles,
  ...props
}) => {
  return (
    <AnimatedProgressProvider
      value={value}
      maxValue={maxValue}
      duration={duration}
      easingFunction={easingFunction}
    >
      {(cValue, cMaxValue) => {
        if (children) {
          return (
            <ProgressBarWithChildren
              className={className}
              value={cValue}
              maxValue={
                maxValue >= cMaxValue
                  ? maxValue
                  : maxValue < cValue
                  ? cValue
                  : maxValue
              }
              styles={{
                ...styles,
                ...buildStyles({
                  pathTransition: 'none',
                  pathColor: colorDict['color-primary'],
                  trailColor: colorDict['color-primary-little-light'],
                }),
              }}
              {...props}
            >
              {children(cValue)}
            </ProgressBarWithChildren>
          );
        }
        return (
          <ProgressBar
            className={className}
            value={cValue}
            maxValue={
              maxValue >= cMaxValue
                ? maxValue
                : maxValue < cValue
                ? cValue
                : maxValue
            }
            text={`${numUtils.decimal(value)}%`}
            {...props}
          />
        );
      }}
    </AnimatedProgressProvider>
  );
};

export default CircularProgressBar;
