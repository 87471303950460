import {
  AggregatedByTimeline,
  GroupByDevice,
  StreamTag,
  StreamType,
  TimeRange,
} from '../../apis/streams';
import { GenericNavbar } from '../../components/Navbar/Navbar';
import {
  getStringByLanguage,
  value2FlexibleRounded,
  value2FlexibleUnit,
  value2KWH,
} from '../../utils/utils';

export enum PRICE_TYPE {
  DETAILS_PRICE = 'price',
  DETAILS_GAINS = 'gains',
}

export interface DeviceAggInfo {
  value: number;
  count: number;
  unit: string;
}

export interface ChartDataObject {
  timestamp: number;
  value: number;
}

export interface ChartObjectProps {
  Total: ChartDataObject[];
  Shared: ChartDataObject[];
  Individual: ChartDataObject[];
}

export interface ChartDataProps {
  unit: string;
  Production: ChartObjectProps;
  Consumption: ChartObjectProps;
}

export interface Sufficiency {
  total: number;
  community: number;
  unit: string;
}

export interface CommunityProps {
  chartLists: ChartDataProps;
  sufficiency: Sufficiency;
  producers: DeviceAggInfo;
  consumers: DeviceAggInfo;
  gains: number;
}

export interface AxisLabels {
  [range: string]: number[];
}

export const NavbarComponent = GenericNavbar<TimeRange>();

export const getChartListsAndSufficiency = (
  streams: AggregatedByTimeline<GroupByDevice>['buckets'],
) => {
  const uKWH = getStringByLanguage('KWH');
  const uMWH = getStringByLanguage('MWH');
  const uGWH = getStringByLanguage('GWH');

  const deviceDict: Record<
    string,
    { Production: boolean; Consumption: boolean }
  > = {};

  const producers: DeviceAggInfo = {
    value: 0,
    count: 0,
    unit: uKWH,
  };
  const consumers: DeviceAggInfo = {
    value: 0,
    count: 0,
    unit: uKWH,
  };

  const chartLists: ChartDataProps = {
    unit: uKWH,
    Production: { Total: [], Shared: [], Individual: [] },
    Consumption: { Total: [], Shared: [], Individual: [] },
  };

  const sufficiency: Sufficiency = {
    total: 0,
    community: 0,
    unit: uKWH,
  };
  let maxAmount = 0;

  streams.forEach((stream) => {
    const {
      key: { timeline: timestamp },
      tag: { buckets: tagItems },
    } = stream;

    tagItems.forEach((tagItem) => {
      const {
        key: tag,
        type: { buckets: types },
      } = tagItem;

      types.forEach((typeItem) => {
        const {
          key: type,
          device: { buckets: devices },
        } = typeItem;

        const data: ChartDataObject = {
          timestamp,
          value: 0,
        };

        devices.forEach((deviceItem) => {
          const {
            key: id,
            unit: { buckets: units },
          } = deviceItem;

          units.forEach((unitItem) => {
            const {
              key: unit,
              total: { value },
            } = unitItem;

            const { value: kValue } = value2KWH({ value, unit });
            data.value += kValue;
          });

          if (!deviceDict[id]) {
            deviceDict[id] = {
              Production: false,
              Consumption: false,
            };
          }

          if (!deviceDict[id][tag]) {
            deviceDict[id][tag] = true;
            if (tag === StreamTag.Consumption) {
              consumers.count++;
            } else {
              producers.count++;
            }
          }
        });

        if (data.value > maxAmount) {
          maxAmount = data.value;
        }

        chartLists[tag][type].push(data);

        if (tag === StreamTag.Consumption) {
          if (type === StreamType.Total) {
            sufficiency.total += data.value;
            consumers.value += data.value;
          } else {
            sufficiency.community += data.value;
          }
        } else if (type === StreamType.Total) {
          producers.value += data.value;
        }
      });
    });
  });

  const tags = ['Production', 'Consumption'] as const;
  const types = ['Total', 'Shared', 'Individual'] as const;

  const divide = maxAmount >= 1000000 ? 1000000 : maxAmount >= 1000 ? 1000 : 1;
  const unit = maxAmount >= 1000000 ? uGWH : maxAmount >= 1000 ? uMWH : uKWH;

  if (unit !== uKWH) {
    chartLists.unit = unit;

    tags.forEach((tag) => {
      types.forEach((type) => {
        chartLists[tag][type].forEach((data) => {
          const { value } = data;
          data.value = value2FlexibleRounded(value / divide);
        });
      });
    });
  }

  Object.assign(producers, value2FlexibleUnit(producers));
  Object.assign(consumers, value2FlexibleUnit(consumers));

  return { chartLists, sufficiency, producers, consumers };
};
