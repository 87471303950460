import { _global } from '../config/config';
import requestAPI from '../utils/requestAPI';
import { PaginationProps, ResponseProps, prepareRequestParams } from './utils';

export interface CommunityInfo {
  id?: number;
  community_name: string;
  community_address: string | null;
  community_city: string | null;
  community_country: string | null;
  community_postalcode: string | null;
  community_dso: string | null;
  community_idn: number | null;
  community_office_idn: number | null;
  community_vat: number | null;
  community_nace: number | null;
  community_lscn: number | null;
  community_lscs: string | null;
  community_latitude: number;
  community_longitude: number;
  community_price: number;
  supplier_price: number;
  created_at?: string;
}

export const getCommunityInfo = async (id?: number | string) => {
  id = id || _global.user?.participant?.community_id || undefined;

  if (!id || (typeof id === 'string' && !id.length)) {
    return { data: { data: null } };
  }

  const url = `community/${id}`;
  return await requestAPI.get<ResponseProps<CommunityInfo>>(url);
};

export const getCommunities = async (data?: PaginationProps<CommunityInfo>) => {
  data = data || {};
  const { search, page, perPage, sort, sortField } = data;
  let url = '/community';
  url += prepareRequestParams({ search, page, perPage, sort, sortField });
  return await requestAPI.get<ResponseProps<[CommunityInfo[], number]>>(url);
};

export const addCommunity = async (
  data: Partial<Omit<CommunityInfo, 'id'>>,
) => {
  return (await requestAPI.post)<ResponseProps<CommunityInfo>>(
    `/community`,
    data,
  );
};

export const updateCommunity = async (data: Partial<CommunityInfo>) => {
  return (await requestAPI.patch)<ResponseProps<CommunityInfo>>(
    `/community/${data.id}`,
    data,
  );
};

export const deleteCommunity = async (id: number, forever = false) => {
  return (await requestAPI.delete)<ResponseProps<CommunityInfo>>(
    `/community/${id}?safeMode=${!forever}`,
  );
};
