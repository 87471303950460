import { FC } from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { HiOutlineGlobeAsiaAustralia } from 'react-icons/hi2';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { SubComponentsProps } from './Settings';
import Button, { BUTTON_TYPE } from '../../components/Button/Button';
import IOSSwitch from '../../components/IOSSwitch/IOSSwitch';
import InputForm from '../../components/InputForm/InputForm';
import { useLoadingBars } from '../../components/LoadingBar/LoadingBar';
import TextWithTick from '../../components/TextWithTick/TextWithTick';

import { UserRole, updateUserInfo } from '../../apis/users';
import { getStringByLanguage } from '../../utils/utils';
import { PASSWORD_MIN_LENGTH } from '../../config/config';

import styles from './styles.module.scss';

const UserSecurityComponent: FC<
  Omit<SubComponentsProps, 'userInfo'> &
    Required<Pick<SubComponentsProps, 'userInfo' | 'updateUser2NewInfo'>>
> = ({ userInfo, updateUser2NewInfo }) => {
  const [showLoadingBar, hideLoadingBar] = useLoadingBars();

  const formik = useFormik({
    initialValues: {
      currentPassword: '',
      updatePassword: '',
      confirmPassword: '',
    },
    validationSchema: Yup.object().shape({
      updatePassword: Yup.string()
        .min(PASSWORD_MIN_LENGTH)
        .matches(/^(?=.*[a-z])(?=.*[A-Z]).+$/, {
          message: getStringByLanguage(
            'A_MIXTURE_OF_BOTH_UPPERCASE_AND_LOWERCASE_LETTERS',
          ),
        })
        .matches(/^(?=.*[\d])(?=.*[\D]).+$/, {
          message: getStringByLanguage('A_MIXTURE_OF_LETTERS_AND_NUMBERS'),
        })
        .matches(/^(?=.*[\W]).+$/, {
          message: getStringByLanguage(
            'INCLUSION_OF_AT_LEAST_ONE_SPECIAL_CHARACTER',
          ),
        })
        .required(getStringByLanguage('ENTER_PASSWORD')),
      confirmPassword: Yup.string().oneOf(
        [Yup.ref('updatePassword'), null],
        getStringByLanguage('PASSWORD_MATCHED'),
      ),
    }),
    enableReinitialize: true,
    onSubmit: async ({ currentPassword, updatePassword }) => {
      showLoadingBar();
      try {
        const { data } = await updateUserInfo({
          currentPassword,
          updatePassword,
        });
        if (!data.data) {
          return;
        }
        updateUser2NewInfo(data.data);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        console.log(error);
      }
      hideLoadingBar();
    },
  });

  const handleSubmit = () => {
    formik.handleSubmit();
  };

  const message =
    (formik.touched.updatePassword && formik.errors.updatePassword) ||
    (formik.touched.confirmPassword && formik.errors.confirmPassword);

  return (
    <div className={styles['kut-user-security']}>
      {userInfo.role === UserRole.Community_Manager && (
        <>
          <p
            className={cn([
              styles['kut-security-title'],
              styles['kut-security-communities'],
            ])}
          >
            {getStringByLanguage('COMMUNITIES')}
          </p>
          <div className={styles['kut-security-communities-button']}>
            <HiOutlineGlobeAsiaAustralia />
            <span>{getStringByLanguage('WESMART_ENERGY_COMMUNITY')}</span>
          </div>
          <Link className={styles['kut-security-download-data']} to="#">
            {getStringByLanguage('DOWNLOAD_YOUR_DATA')}
          </Link>
          <p className={styles['kut-security-download-data-description']}>
            {getStringByLanguage(
              'DECIDE_HOW_THE_COMMUNITY_MANAGERS_YOUR_PERSONAL_INFO',
            )}
          </p>
          <hr className={styles['kut-security-password-devider']} />
        </>
      )}
      <div className={styles['kut-security-password']}>
        <InputForm
          className={styles['kut-input-form']}
          type="password"
          heading={getStringByLanguage('CURRENT_PASSWORD')}
          name={`currentPassword`}
          value={formik.values.currentPassword}
          onChange={formik.handleChange}
        />
        <InputForm
          className={styles['kut-input-form']}
          type="password"
          heading={getStringByLanguage('UPDATE_PASSWORD')}
          name={`updatePassword`}
          value={formik.values.updatePassword}
          onChange={formik.handleChange}
        />
        <InputForm
          className={styles['kut-input-form']}
          type="password"
          heading={getStringByLanguage('CONFIRM_NEW_PASSWORD')}
          name={`confirmPassword`}
          value={formik.values.confirmPassword}
          onChange={formik.handleChange}
        />
      </div>
      {message && (
        <TextWithTick status={false} style={{ marginTop: '10px' }}>
          {message}
        </TextWithTick>
      )}
      <hr />
      <div className={styles['kut-security-level']}>
        <p className={styles['kut-heading']}>
          {getStringByLanguage('SECURITY_LEVELS')}
        </p>
        <div className={styles['kut-security-level-form']}>
          <div className={styles['kut-input-form']}>
            <IOSSwitch
              type="primary"
              sx={{ m: 1, width: 28, height: 17, margin: 0 }}
              defaultChecked={!!userInfo.two_factor_auth?.length}
            />
            <p className={styles['kut-security-level-title']}>
              {getStringByLanguage('TWO_FACTOR_AUTHENTICATION')}
            </p>
            <p className={styles['kut-security-level-content']}>
              {getStringByLanguage(
                'REQUIRE_AN_AUTHENTICATION_CODE_WHEN_LOGGING_IN',
              )}
            </p>
          </div>
          <div className={styles['kut-input-form']}>
            <IOSSwitch
              type="primary"
              sx={{ m: 1, width: 28, height: 17, margin: 0 }}
              defaultChecked={!!userInfo.security_logout}
            />
            <p className={styles['kut-security-level-title']}>
              {getStringByLanguage('SECURITY_LOGOUT')}
            </p>
            <p className={styles['kut-security-level-content']}>
              {getStringByLanguage('LOGOUT_OF_ALL_DEVICES_AND_BROWSERS')}
            </p>
          </div>
          <div className={styles['kut-input-form']} />
        </div>
      </div>
      <hr />
      <div className={styles['kut-bottom-form']}>
        <p>{getStringByLanguage('DOWNLOAD_YOUR_DATA')}</p>
        <div className={styles['kut-bottom-actions']}>
          <Button type={BUTTON_TYPE.DEFAULT} outline={true}>
            {getStringByLanguage('CANCEL')}
          </Button>
          <Button type={BUTTON_TYPE.PRIMARY} onClick={handleSubmit}>
            {getStringByLanguage('SAVE_UPDATES')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default UserSecurityComponent;
