import { FC, useEffect, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import { TableColumn } from 'react-data-table-component';
import * as DateFNS from 'date-fns';
import { useParams } from 'react-router-dom';
import cn from 'classnames';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import Select from 'react-select';
import Popup from 'reactjs-popup';
import {
  BsArrowDownCircle,
  BsPlus,
  BsThreeDotsVertical,
  BsTrash2,
} from 'react-icons/bs';
import { TbFileInvoice } from 'react-icons/tb';

import Table from '../../../components/Table/Table';
import SwitchView from '../../../components/SwitchView/SwitchView';
import Button, { BUTTON_TYPE } from '../../../components/Button/Button';
import SearchInput from '../../../components/SearchInput/SearchInput';
import { useLoadingBars } from '../../../components/LoadingBar/LoadingBar';
import ListView, { SortOrder } from '../../../components/ListView/ListView';
import StatusSpan, { STATUS } from '../../../components/StatusSpan/StatusSpan';
import Modal, { useModal } from '../../../components/Modal/Modal';
import InputForm from '../../../components/InputForm/InputForm';
import IOSSwitch from '../../../components/IOSSwitch/IOSSwitch';
import {
  documentTypes,
  fieldDict,
  filterOption,
  sortFieldList,
  sortStringList,
  switchItems,
} from './Documents.helper';

import { useDeviceType } from '../../../services/hooks';
import {
  DOCUMENT_TYPE,
  DocumentInfo,
  addDocument,
  deleteDocument,
  getDocuments,
  updateDocument,
} from '../../../apis/documents';
import {
  colorDict,
  getDocumentUrl,
  getStringByLanguage,
  isNewDocument,
} from '../../../utils/utils';

import styles from './styles.module.scss';

const Documents: FC = () => {
  const [showLoadingBar, hideLoadingBar] = useLoadingBars();
  const { isMobile } = useDeviceType();
  const { community_id, participant_id } = useParams();

  const [activeView, setActiveView] = useState(1);
  const [tableUrlParam, setTableUrlParam] = useState<{
    search: string;
  }>({
    search: '',
  });

  const [listUrlParam, setListUrlParam] = useState<{
    search: string;
    sort: SortOrder;
    sortField: keyof DocumentInfo;
  }>({
    search: '',
    sort: SortOrder.ASC,
    sortField: 'document_name',
  });

  const [selectedItems, setSelectedItems] = useState<{
    allSelected: boolean;
    selectedCount: number;
    selectedRows: Partial<DocumentInfo>[];
  }>({ allSelected: false, selectedCount: 0, selectedRows: [] });

  const [modalItem, setModalItem] = useState<DocumentInfo>();
  const [modalDeleteItems, setModalDeleteItems] = useState<
    Partial<DocumentInfo>[]
  >([]);
  const [modalTitle, setModalTitle] = useState('');
  const [selectAll, setSelectAll] = useState(false);
  const [isEventFromAll, setIsEventFromAll] = useState(false);

  const init = async () => {};

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (isMobile) {
      setActiveView(0);
    }
  }, [isMobile, activeView]);

  const ActionComponent: FC<{ data: DocumentInfo }> = ({ data }) => (
    <Popup
      className="action-popup"
      trigger={
        <div>
          <BsThreeDotsVertical />
        </div>
      }
      position={['bottom right']}
      arrow={false}
      contentStyle={{
        width: 'initial',
        backgroundColor: 'rgb(var(--content-background-color))',
        borderColor: 'rgb(var(--background-color))',
        padding: '5px 0px',
        boxShadow: '2px 2px 5px 0px rgba(var(--text-color), .2)',
      }}
    >
      <div className={styles['action-item-list']}>
        <span
          className={cn([styles['action-button-update']])}
          onClick={() => updateRow(data)}
        >
          {getStringByLanguage('UPDATE')}
        </span>
        <span
          className={cn([styles['action-button-delete']])}
          onClick={() => deleteRow(data)}
        >
          {getStringByLanguage('DELETE')}
        </span>
      </div>
    </Popup>
  );

  const onViewChange = (index: number) => {
    setActiveView(index);
    setSelectedItems({
      allSelected: false,
      selectedCount: 0,
      selectedRows: [],
    });
    setSelectAll(false);
  };

  const updateRow = (document: DocumentInfo) => {
    setModalItem(document);
    updateModal.show();
  };

  const deleteRow = (document: DocumentInfo) => {
    setModalTitle(
      `${getStringByLanguage('ARE_YOU_SURE_WANT_TO_DELETE')} <b>${
        document.document_name
      }</b> ${getStringByLanguage('DOCUMENT').toLowerCase()}?`,
    );
    setModalDeleteItems([document]);
    deleteModal.show();
  };

  const onDeleteSelected = () => {
    if (!selectedItems.selectedCount) {
      return;
    }
    setModalTitle(
      `${getStringByLanguage(
        'ARE_YOU_SURE_WANT_TO_DELETE',
      )} <b>${`${getStringByLanguage('SELECTED').toLowerCase()} ${
        selectedItems.selectedCount
      } ${getStringByLanguage('DEVICES').toLowerCase()}`}</b>?`,
    );
    setModalDeleteItems(selectedItems.selectedRows);
    deleteModal.show();
  };

  const onCreateNew = () => {
    createModal.show();
  };

  const onCreate = async (document: Partial<DocumentInfo>) => {
    showLoadingBar();
    try {
      await addDocument({ community_id, participant_id, data: document });
      toast.success(getStringByLanguage('SUCCESSFULLY_CREATED'));
      createModal.hide();
      setTableUrlParam({ ...tableUrlParam });
      setListUrlParam({ ...listUrlParam });
    } catch (error) {
      console.log(error);
    }
    hideLoadingBar();
  };

  const onUpdate = async (document: Partial<DocumentInfo>) => {
    showLoadingBar();
    try {
      await updateDocument({ community_id, participant_id, data: document });
      toast.success(getStringByLanguage('SUCCESSFULLY_UPDATED'));
      updateModal.hide();
      setTableUrlParam({ ...tableUrlParam });
      setListUrlParam({ ...listUrlParam });
    } catch (error) {
      console.log(error);
    }
    hideLoadingBar();
  };

  const onDelete = async (params: { forever?: boolean }) => {
    showLoadingBar();

    const promises = modalDeleteItems.map(async (document) => {
      if (!document.id) {
        throw getStringByLanguage('REQUEST_ERROR');
      }
      return await deleteDocument(
        community_id,
        participant_id,
        document.id,
        params.forever,
      );
    });
    try {
      await promises;
      toast.success(getStringByLanguage('SUCCESSFULLY_DELETED'));
      setSelectedItems({
        allSelected: false,
        selectedCount: 0,
        selectedRows: [],
      });
      deleteModal.hide();
    } catch (error) {
      console.log(error);
    }
    setTableUrlParam({ ...tableUrlParam });
    setListUrlParam({ ...listUrlParam });
    hideLoadingBar();
  };

  const onItemSelected = (selected: {
    allSelected: boolean;
    selectedCount: number;
    selectedRows: Partial<DocumentInfo>[];
  }) => {
    setSelectedItems(selected);
  };

  const onSortChange = (sortField: keyof DocumentInfo) => {
    const sort: SortOrder = listUrlParam.sort;
    if (listUrlParam.sortField === sortField) {
      setListUrlParam({
        ...listUrlParam,
        sort: sort === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC,
      });
    } else {
      setListUrlParam({
        ...listUrlParam,
        sortField,
        sort: SortOrder.ASC,
      });
    }
  };

  const columns: TableColumn<DocumentInfo>[] = [
    {
      name: getStringByLanguage('DOCUMENT_NAME'),
      sortable: true,
      minWidth: '50px',
      cell: (data: DocumentInfo) => {
        return (
          <div
            style={{ overflow: 'hidden' }}
            className={cn([
              styles['table-item-name'],
              isNewDocument(data.document_create_on)
                ? styles['kut-new-data']
                : '',
            ])}
          >
            <div
              className={cn(['kut-tooltip-item'])}
              data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                <div className={styles['table-tooltip-item']}>
                  {data.document_name}
                </div>,
              )}
            >
              <TbFileInvoice /> {data.document_name}
            </div>
          </div>
        );
      },
    },
    {
      name: getStringByLanguage('DOCUMENT_ID'),
      sortable: true,
      minWidth: '50px',
      cell: (data) => {
        return (
          <span
            className={
              (styles['table-item-document-id'], cn(['kut-tooltip-item']))
            }
            data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
              <div className={styles['table-tooltip-item']}>
                {data.document_id}
              </div>,
            )}
          >
            {data.document_id}
          </span>
        );
      },
    },
    {
      name: getStringByLanguage('CREATED_ON'),
      sortable: true,
      minWidth: '50px',
      selector: (row) =>
        DateFNS.format(new Date(row.document_create_on), 'dd/MM/yyyy'),
    },
    {
      name: getStringByLanguage('TYPE'),
      sortable: true,
      minWidth: '50px',
      selector: (row) => row.document_type,
    },
    {
      name: getStringByLanguage('STATUS'),
      sortable: true,
      minWidth: '50px',
      cell: (data) => {
        const { document_status, document_type } = data;
        const status =
          document_type === DOCUMENT_TYPE.Contract
            ? document_status
              ? STATUS.PRIMARY
              : STATUS.DANGER
            : document_status
            ? STATUS.SUCCESS
            : STATUS.DANGER;
        const text =
          document_type === DOCUMENT_TYPE.Contract
            ? document_status
              ? getStringByLanguage('SIGNED')
              : getStringByLanguage('NOT_SIGNED_YET')
            : document_status
            ? getStringByLanguage('PAID')
            : getStringByLanguage('NOT_PAID_YET');
        return <StatusSpan {...{ status, text }} />;
      },
    },
    {
      name: '',
      sortable: true,
      minWidth: '30px',
      maxWidth: '30px',
      center: true,
      cell: (data) => {
        return (
          <a
            target={`_blank`}
            href={getDocumentUrl(data)}
            className={styles['action-item-download']}
          >
            <BsArrowDownCircle />
          </a>
        );
      },
    },
    {
      name: '',
      sortable: true,
      minWidth: '30px',
      maxWidth: '30px',
      center: true,
      cell: (data) => {
        return <ActionComponent data={data} />;
      },
    },
  ];

  const createModal = useModal<Partial<DocumentInfo>>({
    onSubmit: onCreate,
    initialValues: {
      document_name: '',
      document_id: '',
      document_type: DOCUMENT_TYPE.Contract,
      document_url: '',
      document_status: false,
    },
    validationSchema: Yup.object().shape({
      document_name: Yup.string().required(
        getStringByLanguage('THIS_FIELD_IS_REQUIRED'),
      ),
      document_id: Yup.string().required(
        getStringByLanguage('THIS_FIELD_IS_REQUIRED'),
      ),
      document_type: Yup.string()
        .oneOf([DOCUMENT_TYPE.Contract, DOCUMENT_TYPE.Invoice])
        .required(getStringByLanguage('THIS_FIELD_IS_REQUIRED')),
      document_url: Yup.string().required(
        getStringByLanguage('THIS_FIELD_IS_REQUIRED'),
      ),
      document_status: Yup.boolean().notRequired(),
    }),
  });
  const updateModal = useModal<Partial<DocumentInfo>>({
    onSubmit: onUpdate,
    initialValues: modalItem || {},
    validationSchema: Yup.object().shape({
      document_name: Yup.string().required(
        getStringByLanguage('THIS_FIELD_IS_REQUIRED'),
      ),
      document_id: Yup.string().required(
        getStringByLanguage('THIS_FIELD_IS_REQUIRED'),
      ),
      document_type: Yup.string()
        .oneOf([DOCUMENT_TYPE.Contract, DOCUMENT_TYPE.Invoice])
        .required(getStringByLanguage('THIS_FIELD_IS_REQUIRED')),
      document_url: Yup.string().required(
        getStringByLanguage('THIS_FIELD_IS_REQUIRED'),
      ),
      document_status: Yup.boolean().notRequired(),
    }),
  });
  const deleteModal = useModal<{ forever?: boolean }>({
    onSubmit: onDelete,
    initialValues: {
      forever: false,
    },
    validationSchema: Yup.object().shape({
      forever: Yup.boolean().required(),
    }),
  });

  return (
    <div className={styles['kut-component-body']}>
      <div className={styles['kut-component-content']}>
        <div className={styles['kut-component-header']}>
          <div
            className={cn([
              styles['kut-component-sort'],
              activeView === 1 ? styles['hide'] : '',
            ])}
          >
            <span>{getStringByLanguage('SORT_BY')}</span>
            {sortFieldList.map((field, i) => (
              <span
                key={i}
                className={cn([
                  styles['kut-component-sort-option'],
                  listUrlParam.sortField === field ? styles['active'] : '',
                  styles[listUrlParam.sort],
                ])}
                onClick={() => onSortChange(field)}
              >
                {sortStringList[i]}
              </span>
            ))}
          </div>
          <div
            className={cn([
              styles['kut-component-select-all'],
              activeView === 1 ? styles['hide'] : '',
            ])}
          >
            <input
              type="checkbox"
              checked={selectAll}
              onChange={(event) => {
                const status = event.target.checked;
                setSelectAll(status);
                setIsEventFromAll(true);
              }}
              ref={(input) => {
                if (input) {
                  const status = selectAll;
                  if (!status && selectedItems.selectedCount) {
                    input.indeterminate = true;
                    return;
                  }
                  input.indeterminate = false;
                  status ? setSelectAll(true) : setSelectAll(false);
                }
              }}
            />
            <span>{getStringByLanguage('SELECT_ALL')}</span>
          </div>
          {!isMobile && (
            <SwitchView
              className={styles['kut-component-switch-view']}
              items={switchItems}
              selected={activeView}
              onViewChange={onViewChange}
            />
          )}
        </div>
        {activeView === 1 && (
          <div
            className={cn([
              styles['kut-component-table'],
              styles['kut-component-active'],
            ])}
          >
            <div className={styles['kut-component-actions']}>
              <Button
                className={cn([
                  styles['kut-component-buttons'],
                  styles['kut-buttons-large'],
                ])}
                outline={true}
                onClick={onCreateNew}
              >
                + {getStringByLanguage('CREATE_NEW')}
              </Button>
              <SearchInput
                className={styles['kut-component-search']}
                value={tableUrlParam.search}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setTableUrlParam({
                    search: event.target.value,
                  })
                }
              />
              <Button
                className={cn([
                  styles['kut-component-buttons'],
                  styles['kut-buttons-large'],
                  styles['kut-component-button-delete'],
                  selectedItems.selectedCount ? styles['kut-active'] : '',
                ])}
                type={BUTTON_TYPE.DANGER}
                onClick={onDeleteSelected}
              >
                {getStringByLanguage('DELETE_SELECTED')}
              </Button>
              <Button
                className={cn([
                  styles['kut-component-buttons'],
                  styles['kut-buttons-small'],
                ])}
                onClick={onCreateNew}
              >
                <BsPlus />
              </Button>
              <Button
                className={cn([
                  styles['kut-component-buttons'],
                  styles['kut-buttons-small'],
                  styles['kut-component-button-delete'],
                  selectedItems.selectedCount ? styles['kut-active'] : '',
                ])}
                type={BUTTON_TYPE.DANGER}
                onClick={onDeleteSelected}
              >
                <BsTrash2 />
              </Button>
            </div>
            <Table
              pagination={true}
              selectableRows
              onSelectedRowsChange={onItemSelected}
              urlParam={tableUrlParam}
              requestFunc={(options) =>
                getDocuments({ ...options, community_id, participant_id })
              }
              fieldDict={fieldDict}
              columns={columns}
              paginationPerPage={10}
              paginationRowsPerPageOptions={[10, 20, 50]}
            />
          </div>
        )}
        {activeView === 0 && (
          <div
            className={cn([
              styles['kut-component-list-view'],
              styles['kut-component-active'],
            ])}
          >
            <div className={styles['kut-component-actions']}>
              <Button
                className={cn([
                  styles['kut-component-buttons'],
                  styles['kut-buttons-large'],
                ])}
                outline={true}
                onClick={onCreateNew}
              >
                + {getStringByLanguage('CREATE_NEW')}
              </Button>
              <SearchInput
                className={styles['kut-component-search']}
                value={listUrlParam.search}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setListUrlParam({
                    ...listUrlParam,
                    search: event.target.value,
                  })
                }
              />
              <Button
                className={cn([
                  styles['kut-component-buttons'],
                  styles['kut-buttons-large'],
                  styles['kut-component-button-delete'],
                  selectedItems.selectedCount ? styles['kut-active'] : '',
                ])}
                type={BUTTON_TYPE.DANGER}
                onClick={onDeleteSelected}
              >
                {getStringByLanguage('DELETE_SELECTED')}
              </Button>
              <Button
                className={cn([
                  styles['kut-component-buttons'],
                  styles['kut-buttons-small'],
                ])}
                onClick={onCreateNew}
              >
                <BsPlus />
              </Button>
              <Button
                className={cn([
                  styles['kut-component-buttons'],
                  styles['kut-buttons-small'],
                  styles['kut-component-button-delete'],
                  selectedItems.selectedCount ? styles['kut-active'] : '',
                ])}
                type={BUTTON_TYPE.DANGER}
                onClick={onDeleteSelected}
              >
                <BsTrash2 />
              </Button>
            </div>
            <ListView
              urlParam={listUrlParam}
              selectAll={selectAll}
              setSelectAll={setSelectAll}
              isEventFromAll={isEventFromAll}
              setIsEventFromAll={setIsEventFromAll}
              onItemSelected={onItemSelected}
              requestFunc={(options) =>
                getDocuments({ ...options, community_id, participant_id })
              }
              ChildComponent={({
                className,
                onChange,
                checked,
                data,
              }: {
                className: string;
                onChange: (status: boolean) => void;
                checked: boolean;
                data: DocumentInfo;
              }) => {
                const { document_status, document_type } = data;
                const status =
                  document_type === DOCUMENT_TYPE.Contract
                    ? document_status
                      ? STATUS.PRIMARY
                      : STATUS.DANGER
                    : document_status
                    ? STATUS.SUCCESS
                    : STATUS.DANGER;
                const text =
                  document_type === DOCUMENT_TYPE.Contract
                    ? document_status
                      ? getStringByLanguage('SIGNED')
                      : getStringByLanguage('NOT_SIGNED_YET')
                    : document_status
                    ? getStringByLanguage('PAID')
                    : getStringByLanguage('NOT_PAID_YET');
                return (
                  <div
                    className={cn([
                      className,
                      styles['kut-component-list-item'],
                    ])}
                  >
                    <div className={cn([styles['list-item-heading']])}>
                      <div className={cn([styles['list-item-name']])}>
                        <div>
                          <p>
                            <b>{data.document_name}</b>
                          </p>
                          <p className={cn([styles['list-item-created']])}>
                            {getStringByLanguage('CREATED_ON')}{' '}
                            <span>
                              {data.document_create_on
                                ? DateFNS.format(
                                    new Date(data.document_create_on),
                                    'dd/MM/yyyy',
                                  )
                                : ''}
                            </span>
                          </p>
                        </div>
                      </div>
                      <input
                        type="checkbox"
                        checked={checked}
                        onChange={(event) => onChange(event.target.checked)}
                      />
                    </div>
                    <div className={cn([styles['list-item-document-content']])}>
                      <div className={cn([styles['list-item-document-id']])}>
                        <span>{data.document_id}</span>
                        <a
                          target={`_blank`}
                          href={getDocumentUrl(data)}
                          className={`${styles['list-item-download']}`}
                        >
                          <BsArrowDownCircle />{' '}
                          <span>{getStringByLanguage('DOWNLOAD')}</span>
                        </a>
                      </div>
                      <div className={cn([styles['list-item-document-type']])}>
                        <span>{data.document_type}</span>
                        <StatusSpan
                          className={cn([styles['list-item-document-status']])}
                          {...{ status, text }}
                        />
                      </div>
                    </div>
                    <div className={cn([styles['list-item-actions']])}>
                      <div>
                        <span
                          className={cn([styles['list-item-button-update']])}
                          onClick={() => updateRow(data)}
                        >
                          {getStringByLanguage('UPDATE')}
                        </span>
                        <span
                          className={cn([styles['list-item-button-delete']])}
                          onClick={() => deleteRow(data)}
                        >
                          {getStringByLanguage('DELETE')}
                        </span>
                      </div>
                    </div>
                  </div>
                );
              }}
            />
          </div>
        )}
        <Modal {...createModal}>
          {(formik) => {
            return (
              <form
                className={cn([
                  styles['kut-modal-form'],
                  styles['kut-create-form'],
                ])}
              >
                <p className={cn([styles['kut-title']])}>
                  {getStringByLanguage(['CREATE', 'DOCUMENT'])}
                </p>
                <hr />
                <InputForm
                  className={cn([
                    styles['kut-form-document-name'],
                    styles['kut-input-form'],
                  ])}
                  heading={getStringByLanguage('DOCUMENT_NAME')}
                  name={'document_name'}
                  onChange={formik.handleChange}
                  required
                  value={formik.values.document_name}
                  error={
                    formik.touched.document_name && formik.errors.document_name
                  }
                />
                <InputForm
                  className={cn([
                    styles['kut-form-document-id'],
                    styles['kut-input-form'],
                  ])}
                  heading={getStringByLanguage('DOCUMENT_ID')}
                  name={'document_id'}
                  onChange={formik.handleChange}
                  required
                  value={formik.values.document_id}
                  error={
                    formik.touched.document_id && formik.errors.document_id
                  }
                />
                <div
                  className={cn([
                    styles['kut-input-form'],
                    styles['kut-form-document-type'],
                  ])}
                >
                  <div>
                    <span className={cn([styles['input-form-title']])}>
                      {getStringByLanguage('DOCUMENT_TYPE')}
                    </span>
                    <Select
                      className={`${styles['input-form-content']}`}
                      options={documentTypes}
                      filterOption={filterOption}
                      name={`document_type`}
                      isSearchable
                      onChange={(v) => {
                        formik.setFieldValue('document_type', v?.value);
                      }}
                      value={documentTypes.find(
                        (value) => value.value === formik.values.document_type,
                      )}
                      maxMenuHeight={200}
                      styles={{
                        control: (baseStyle) => ({
                          ...baseStyle,
                          boxShadow: 'none',
                          border: 'none',
                          color: `rgb(var(--text-color))`,
                          padding: '0.5em 0.75em',
                          backgroundColor: 'rgb(var(--background-color))',
                          borderRadius: '10px',
                        }),
                        input: (baseStyle) => ({
                          ...baseStyle,
                          color: `rgb(var(--text-color))`,
                        }),
                        placeholder: (baseStyle) => ({
                          ...baseStyle,
                          color: `rgb(var(--text-color))`,
                        }),
                        singleValue: (baseStyle) => ({
                          ...baseStyle,
                          color: `rgb(var(--text-color))`,
                        }),
                        indicatorSeparator: (baseStyle) => ({
                          ...baseStyle,
                          width: 0,
                        }),
                        dropdownIndicator: (baseStyle) => ({
                          ...baseStyle,
                          padding: '0px',
                          marginRight: '0.5em',
                          color: colorDict['color-black-strong-light'],
                        }),
                        menu: (baseStyle) => ({
                          ...baseStyle,
                          backgroundColor: `rgb(var(--background-color))`,
                        }),
                        option: (baseStyle, state) => {
                          return {
                            ...baseStyle,
                            backgroundColor: state.isSelected
                              ? `rgba(var(--text-color), 1)`
                              : state.isFocused
                              ? `rgba(var(--text-color), .3)`
                              : 'inherit',
                            color: state.isSelected
                              ? `rgb(var(--content-background-color))`
                              : 'inherit',
                            cursor: 'pointer',
                            transition: 'all .3s',
                          };
                        },
                      }}
                    />
                  </div>
                </div>
                <InputForm
                  className={cn([
                    styles['kut-input-form'],
                    styles['kut-form-document-url'],
                  ])}
                  heading={getStringByLanguage('DOCUMENT_URL')}
                  name={'document_url'}
                  onChange={formik.handleChange}
                  required
                  value={formik.values.document_url}
                  error={
                    formik.touched.document_url && formik.errors.document_url
                  }
                />
                <div
                  className={cn([
                    styles['kut-input-form'],
                    styles['kut-form-document-status'],
                  ])}
                >
                  <div>
                    <span className={cn([styles['input-form-title']])}>
                      {getStringByLanguage('DOCUMENT_STATUS')}
                    </span>
                    <div className={cn([styles['kut-form-document-switch']])}>
                      <IOSSwitch
                        name={'document_status'}
                        checked={formik.values.document_status}
                        onChange={formik.handleChange}
                      />
                      <span className={cn([styles['input-form-content']])}>
                        {formik.values.document_type === DOCUMENT_TYPE.Invoice
                          ? formik.values.document_status
                            ? getStringByLanguage('PAID')
                            : getStringByLanguage('NOT_PAID_YET')
                          : formik.values.document_status
                          ? getStringByLanguage('SIGNED')
                          : getStringByLanguage('NOT_SIGNED_YET')}
                      </span>
                    </div>
                  </div>
                </div>
                <hr />
                <div className={cn([styles['kut-form-actions']])}>
                  <Button onClick={() => formik.handleSubmit()}>
                    {getStringByLanguage('CREATE')}
                  </Button>
                  <Button
                    type={BUTTON_TYPE.DANGER}
                    onClick={() => createModal.hide()}
                  >
                    {getStringByLanguage('CANCEL')}
                  </Button>
                </div>
              </form>
            );
          }}
        </Modal>
        <Modal {...updateModal}>
          {(formik) => {
            return (
              <form
                className={cn([
                  styles['kut-modal-form'],
                  styles['kut-update-form'],
                ])}
              >
                <p className={cn([styles['kut-title']])}>
                  {getStringByLanguage(['UPDATE', 'DOCUMENT'])}
                </p>
                <hr />
                <InputForm
                  className={cn([
                    styles['kut-form-document-name'],
                    styles['kut-input-form'],
                  ])}
                  heading={getStringByLanguage('DOCUMENT_NAME')}
                  name={'document_name'}
                  onChange={formik.handleChange}
                  required
                  value={formik.values.document_name}
                  error={
                    formik.touched.document_name && formik.errors.document_name
                  }
                />
                <InputForm
                  className={cn([
                    styles['kut-form-document-id'],
                    styles['kut-input-form'],
                  ])}
                  heading={getStringByLanguage('DOCUMENT_ID')}
                  name={'document_id'}
                  onChange={formik.handleChange}
                  required
                  value={formik.values.document_id}
                  error={
                    formik.touched.document_id && formik.errors.document_id
                  }
                />
                <div
                  className={cn([
                    styles['kut-input-form'],
                    styles['kut-form-document-type'],
                  ])}
                >
                  <div>
                    <span className={cn([styles['input-form-title']])}>
                      {getStringByLanguage('DOCUMENT_TYPE')}
                    </span>
                    <Select
                      className={`${styles['input-form-content']}`}
                      options={documentTypes}
                      filterOption={filterOption}
                      name={`document_type`}
                      isSearchable
                      onChange={(v) => {
                        formik.setFieldValue('document_type', v?.value);
                      }}
                      value={documentTypes.find(
                        (value) => value.value === formik.values.document_type,
                      )}
                      maxMenuHeight={200}
                      styles={{
                        control: (baseStyle) => ({
                          ...baseStyle,
                          boxShadow: 'none',
                          border: 'none',
                          color: `rgb(var(--text-color))`,
                          padding: '0.5em 0.75em',
                          backgroundColor: 'rgb(var(--background-color))',
                          borderRadius: '10px',
                        }),
                        input: (baseStyle) => ({
                          ...baseStyle,
                          color: `rgb(var(--text-color))`,
                        }),
                        placeholder: (baseStyle) => ({
                          ...baseStyle,
                          color: `rgb(var(--text-color))`,
                        }),
                        singleValue: (baseStyle) => ({
                          ...baseStyle,
                          color: `rgb(var(--text-color))`,
                        }),
                        indicatorSeparator: (baseStyle) => ({
                          ...baseStyle,
                          width: 0,
                        }),
                        dropdownIndicator: (baseStyle) => ({
                          ...baseStyle,
                          padding: '0px',
                          marginRight: '0.5em',
                          color: colorDict['color-black-strong-light'],
                        }),
                        menu: (baseStyle) => ({
                          ...baseStyle,
                          backgroundColor: `rgb(var(--background-color))`,
                        }),
                        option: (baseStyle, state) => {
                          return {
                            ...baseStyle,
                            backgroundColor: state.isSelected
                              ? `rgba(var(--text-color), 1)`
                              : state.isFocused
                              ? `rgba(var(--text-color), .3)`
                              : 'inherit',
                            color: state.isSelected
                              ? `rgb(var(--content-background-color))`
                              : 'inherit',
                            cursor: 'pointer',
                            transition: 'all .3s',
                          };
                        },
                      }}
                    />
                  </div>
                </div>
                <InputForm
                  className={cn([
                    styles['kut-input-form'],
                    styles['kut-form-document-url'],
                  ])}
                  heading={getStringByLanguage('DOCUMENT_URL')}
                  name={'document_url'}
                  onChange={formik.handleChange}
                  required
                  value={formik.values.document_url}
                  error={
                    formik.touched.document_url && formik.errors.document_url
                  }
                />
                <div
                  className={cn([
                    styles['kut-input-form'],
                    styles['kut-form-document-status'],
                  ])}
                >
                  <div>
                    <span className={cn([styles['input-form-title']])}>
                      {getStringByLanguage('DOCUMENT_STATUS')}
                    </span>
                    <div className={cn([styles['kut-form-document-switch']])}>
                      <IOSSwitch
                        name={'document_status'}
                        checked={formik.values.document_status}
                        onChange={formik.handleChange}
                      />
                      <span className={cn([styles['input-form-content']])}>
                        {formik.values.document_type === DOCUMENT_TYPE.Invoice
                          ? formik.values.document_status
                            ? getStringByLanguage('PAID')
                            : getStringByLanguage('NOT_PAID_YET')
                          : formik.values.document_status
                          ? getStringByLanguage('SIGNED')
                          : getStringByLanguage('NOT_SIGNED_YET')}
                      </span>
                    </div>
                  </div>
                </div>
                <hr />
                <div className={cn([styles['kut-form-actions']])}>
                  <Button onClick={() => formik.handleSubmit()}>
                    {getStringByLanguage('SAVE_UPDATES')}
                  </Button>
                  <Button
                    type={BUTTON_TYPE.DANGER}
                    onClick={() => updateModal.hide()}
                  >
                    {getStringByLanguage('CANCEL')}
                  </Button>
                </div>
              </form>
            );
          }}
        </Modal>
        <Modal {...deleteModal}>
          {(formik) => (
            <form
              className={cn([
                styles['kut-modal-form'],
                styles['kut-delete-form'],
              ])}
            >
              <p dangerouslySetInnerHTML={{ __html: modalTitle }} />
              <hr />
              <div className={cn([styles['kut-form-delete-forever']])}>
                <span
                  style={{
                    color: `rgb(var(--text-color))`,
                  }}
                >
                  {getStringByLanguage('DELETE_FOREVER')}?
                </span>
                <IOSSwitch
                  name={'forever'}
                  checked={formik.values.forever}
                  onChange={formik.handleChange}
                />
              </div>
              <p
                className={cn([styles['kut-form-delete-forever-description']])}
              >
                {getStringByLanguage('DELETE_FOREVER_DESCRIPTION')}
              </p>
              <hr />
              <div className={cn([styles['kut-form-actions']])}>
                <Button onClick={() => formik.handleSubmit()}>
                  {getStringByLanguage('DELETE')}
                </Button>
                <Button
                  type={BUTTON_TYPE.DANGER}
                  onClick={() => deleteModal.hide()}
                >
                  {getStringByLanguage('CANCEL')}
                </Button>
              </div>
            </form>
          )}
        </Modal>
      </div>
    </div>
  );
};

export default Documents;
