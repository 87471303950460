import { BsGrid } from 'react-icons/bs';
import { AiOutlineUnorderedList } from 'react-icons/ai';

import { SwitchItem } from '../../../components/SwitchView/SwitchView';

import { getStringByLanguage } from '../../../utils/utils';
import { DOCUMENT_TYPE, DocumentInfo } from '../../../apis/documents';

export const fieldDict: {
  [key: string]: keyof DocumentInfo;
} = {
  [getStringByLanguage('DOCUMENT_NAME')]: 'document_name',
  [getStringByLanguage('DOCUMENT_ID')]: 'document_id',
  [getStringByLanguage('CREATED_ON')]: 'document_create_on',
  [getStringByLanguage('TYPE')]: 'document_type',
  [getStringByLanguage('STATUS')]: 'document_status',
};

export const sortFieldList: (keyof DocumentInfo)[] = Object.values(fieldDict);
export const sortStringList: string[] = Object.keys(fieldDict);

export interface DocumentTypeProps {
  value: DOCUMENT_TYPE;
  label: string | number | React.ReactElement;
}

export const documentTypes: DocumentTypeProps[] = [
  {
    value: DOCUMENT_TYPE.Contract,
    label: DOCUMENT_TYPE.Contract,
  },
  {
    value: DOCUMENT_TYPE.Invoice,
    label: DOCUMENT_TYPE.Invoice,
  },
];

export const filterOption = (
  candidate: {
    value: string;
    label: string;
  },
  input: string,
) => {
  if (input) {
    return candidate.label.toLowerCase().indexOf(input.toLowerCase()) !== -1;
  }
  return true;
};

export const switchItems: SwitchItem[] = [
  {
    IconComponent: BsGrid,
  },
  {
    IconComponent: AiOutlineUnorderedList,
  },
];
