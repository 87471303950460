import { ParticipantInfo } from '../../apis/participants';
import { getStringByLanguage } from '../../utils/utils';

export const fieldDict: {
  [key: string]: keyof ParticipantInfo;
} = {
  [getStringByLanguage('NAME')]: 'participant_name',
  [getStringByLanguage('JOINED_ON')]: 'joined',
};

export const sortFieldList: (keyof ParticipantInfo)[] =
  Object.values(fieldDict);
export const sortStringList: string[] = Object.keys(fieldDict);
