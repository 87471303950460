import { createTheme, styled, ThemeProvider } from '@mui/material/styles';
import Switch, { SwitchProps } from '@mui/material/Switch';
import { colorDict } from '../../utils/utils';

import styles from './styles.module.scss';

type TypeDictProps =
  | 'primary'
  | 'secondary'
  | 'error'
  | 'warning'
  | 'info'
  | 'success';

const typeDict = {
  primary: 'color-primary',
  secondary: 'color-secondary',
  error: 'color-error',
  warning: 'color-warning',
  info: 'color-info',
  success: 'color-success',
};

interface IOSSwitchProps extends SwitchProps {
  type?: TypeDictProps;
}

const IOSSwitch = styled(({ type = 'primary', ...props }: IOSSwitchProps) => {
  const switchTheme = createTheme({
    palette: {
      primary: {
        main: colorDict[typeDict[type]],
      },
    },
  });

  return (
    <ThemeProvider theme={switchTheme}>
      <Switch
        className={`${styles['kut-switch-button']}`}
        focusVisibleClassName=".Mui-focusVisible"
        disableRipple
        {...props}
      />
    </ThemeProvider>
  );
})(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'flex-start',
    padding: '8%',
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(calc(45%))',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.primary,
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: '50%',
      height: '100%',
      boxShadow: 'none',
    },
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: 'rgba(var(--text-color), .3)',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

export default IOSSwitch;
