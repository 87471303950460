import { FC, HTMLAttributes, useEffect, useState } from 'react';
import cn from 'classnames';
import { RxCrossCircled } from 'react-icons/rx';

import {
  NOTIFICATION_STATUS,
  NotificationInfo,
  updateNotificationStatus,
} from '../../apis/notifications';

import { ReactComponent as StarsSVG } from '../../assets/images/stars.svg';

import styles from './styles.module.scss';
import Button, { BUTTON_TYPE } from '../Button/Button';
import { getStringByLanguage, googleTranslate } from '../../utils/utils';
import LoadingBar from '../LoadingBar/LoadingBar';

const runScript = (
  str: string | null,
  {
    notification_start_at,
    notification_end_at,
    notification_fields,
  }: NotificationInfo,
) => {
  str = str || '';
  notification_fields = notification_fields || {};
  let result = '';

  for (let i = 0, n = str.length; i < n; i++) {
    if (
      i + 2 < n &&
      str[i] === '*' &&
      str[i + 1] === '*' &&
      str[i + 2] === '{'
    ) {
      i = i + 3;
      let inner = '';
      while (
        i + 2 < n &&
        !(str[i] === '}' && str[i + 1] === '*' && str[i + 2] === '*')
      ) {
        inner += str[i++];
      }
      if (i + 2 === n) {
        result = 'Error!';
        break;
      }
      i = i + 2;
      if (inner === 'notification_start_at') {
        result += notification_start_at;
      } else if (inner === 'notification_end_at') {
        result += notification_end_at;
      } else {
        if (inner.indexOf('notification_fields.') === 0) {
          inner = inner.slice('notification_fields.'.length);
        }
        result += notification_fields[inner] || '';
      }
    } else {
      result += str[i];
    }
  }

  return result;
};

interface FuncGroup extends HTMLAttributes<HTMLDivElement> {
  data: NotificationInfo[];
}

const Notification: FC<FuncGroup> = ({ className = '', data, ...props }) => {
  // const isAIRecommendation = data.notification_type !== NOTIFICATION_TYPE.Other;
  const [datasets, setDatasets] = useState<NotificationInfo[]>(data);
  const [translatedTitle, setTranslatedTitle] = useState('');
  const [translatedText, setTranslatedText] = useState('');
  const [translatedSubText, setTranslatedSubText] = useState('');
  const [isTranslationComplete, setIsTranslationComplete] = useState(false);

  useEffect(() => {
    setDatasets(
      data.filter(
        (v) => !(v.notification_status & NOTIFICATION_STATUS.NOT_AGAIN),
      ),
    );
  }, [data]);

  const active = datasets[0];

  useEffect(() => {
    const translate = async () => {
      if (active) {
        const title = runScript(active.notification_title, active);
        const text = runScript(active.notification_text, active);
        const subText = runScript(active.notification_subtext, active);

        const translatedTitle = await googleTranslate(title);
        const translatedText = await googleTranslate(text);
        const translatedSubText = await googleTranslate(subText);

        setTranslatedTitle(translatedTitle);
        setTranslatedText(translatedText);
        setTranslatedSubText(translatedSubText);
        setIsTranslationComplete(true);
      }
    };

    translate();
  }, [active]);

  const handleButtons = async ({
    status,
    value,
  }: {
    status: keyof typeof NOTIFICATION_STATUS;
    value: boolean;
  }) => {
    await updateNotificationStatus({
      data: active,
      status,
      value,
    });
    setDatasets(datasets.slice(1));
  };

  return (
    <div
      className={cn([
        className,
        styles['kut-notification-component'],
        active && styles['active'],
      ])}
      {...props}
    >
      {isTranslationComplete ? (
        <div
          className={cn([
            styles['kut-notification-inner'],
            active && styles['active'],
          ])}
        >
          <StarsSVG className={cn([styles['kut-notification-icon']])} />
          <p className={cn([styles['kut-notification-ai-text']])}>
            AI recommendation
          </p>
          <div
            className={cn([styles['kut-notification-title']])}
            dangerouslySetInnerHTML={{ __html: translatedTitle }}
          />
          <div
            className={cn([styles['kut-notification-text']])}
            dangerouslySetInnerHTML={{ __html: translatedText }}
          />
          <div
            className={cn([styles['kut-notification-sub-text']])}
            dangerouslySetInnerHTML={{ __html: translatedSubText }}
          />
          <Button
            className={cn([styles['kut-notification-ok']])}
            type={BUTTON_TYPE.PRIMARY}
            outline
            onClick={() =>
              handleButtons({
                status: 'FOLLOWED',
                value: true,
              })
            }
          >
            {getStringByLanguage('I_WILL_FOLLOW_THE_RECOMMENDATION')}
          </Button>
          <Button
            className={cn([styles['kut-notification-ok']])}
            type={BUTTON_TYPE.SECONDARY}
            outline
            onClick={() =>
              handleButtons({
                status: 'FOLLOWED',
                value: false,
              })
            }
          >
            {getStringByLanguage('I_WILL_NOT_FOLLOW_THE_RECOMMENDATION')}
          </Button>
          <RxCrossCircled
            className={cn([styles['kut-notification-close']])}
            onClick={() =>
              handleButtons({
                status: 'NOT_AGAIN',
                value: true,
              })
            }
          />
        </div>
      ) : (
        <LoadingBar />
      )}
    </div>
  );
};

export default Notification;
