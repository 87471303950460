import { BsGrid } from 'react-icons/bs';
import { AiOutlineUnorderedList } from 'react-icons/ai';

import { SwitchItem } from '../../../components/SwitchView/SwitchView';

import { getStringByLanguage } from '../../../utils/utils';
import { DeviceInfo } from '../../../apis/devices';

export const fieldDict: {
  [key: string]: keyof DeviceInfo;
} = {
  [getStringByLanguage('NAME')]: 'device_name',
  [getStringByLanguage('EAN')]: 'ean_code',
  [getStringByLanguage('CREATED_ON')]: 'created_at',
};

export const sortFieldList: (keyof DeviceInfo)[] = Object.values(fieldDict);
export const sortStringList: string[] = Object.keys(fieldDict);

export interface DeviceTypeProps {
  value: string | number;
  label: string | number | React.ReactElement;
  search: string;
}

export const filterOption = (
  candidate: {
    value: string;
    label: string;
    data: DeviceTypeProps;
  },
  input: string,
) => {
  if (input) {
    return (
      candidate.data.search.toLowerCase().indexOf(input.toLowerCase()) !== -1
    );
  }
  return true;
};

export const switchItems: SwitchItem[] = [
  {
    IconComponent: BsGrid,
  },
  {
    IconComponent: AiOutlineUnorderedList,
  },
];
