import { CSSProperties, FC } from 'react';
import cn from 'classnames';

import styles from './styles.module.scss';
import { getStringByLanguage, numUtils } from '../../utils/utils';

interface FuncGroup {
  className?: string;
  name?: string;
  heading: string;
  required?: boolean;
  value: { lscn?: number; lscs?: string };
  onChange: (value: { lscn?: number; lscs?: string }) => void;
  style?: CSSProperties;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error?: any;
  readOnly?: boolean;
}

const LegalStructureCode: FC<FuncGroup> = ({
  className = '',
  name,
  heading,
  required,
  value,
  onChange,
  style,
  error,
  readOnly = false,
}) => {
  const onInputChange = (nValue: string) => {
    const parts = nValue.split(';');
    if (!numUtils.isIntegerString(parts[0])) {
      if (parts[0].length === 0) {
        onChange({});
        return;
      }
      onChange(value);
      return;
    }

    const lscn = parseInt(parts[0]);
    const lscs = parts[1];
    onChange({ lscn, lscs });
  };

  return (
    <div
      className={cn([
        className,
        styles['kut-input-form'],
        required && styles['required'],
      ])}
    >
      <div>
        <p
          className={`${styles['kut-heading']}`}
          style={{ color: style?.color }}
        >
          {heading}
        </p>
        <input
          className={`${styles['kut-content']}`}
          value={
            value.lscn
              ? value.lscn + (value.lscs !== undefined ? ';' + value.lscs : '')
              : ''
          }
          name={name}
          readOnly={readOnly}
          onChange={(value) => {
            onInputChange(value.target.value);
          }}
          placeholder={getStringByLanguage('CODE_LABEL')}
        />
      </div>
      {error && <p className={`${styles['kut-error']}`}>{error}</p>}
    </div>
  );
};

export default LegalStructureCode;
