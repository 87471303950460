import { FC, useEffect, useState } from 'react';
import { TableColumn } from 'react-data-table-component';
import * as DateFNS from 'date-fns';
import { Link, useParams } from 'react-router-dom';
import ReactDOMServer from 'react-dom/server';
import Popup from 'reactjs-popup';
import cn from 'classnames';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { BsPlus, BsThreeDotsVertical, BsTrash2 } from 'react-icons/bs';
import { IoArrowBackOutline } from 'react-icons/io5';

import Table from '../../../components/Table/Table';
import SwitchView from '../../../components/SwitchView/SwitchView';
import Button, { BUTTON_TYPE } from '../../../components/Button/Button';
import SearchInput from '../../../components/SearchInput/SearchInput';
import { useLoadingBars } from '../../../components/LoadingBar/LoadingBar';
import ListView, { SortOrder } from '../../../components/ListView/ListView';
import StatusSpan, { STATUS } from '../../../components/StatusSpan/StatusSpan';
import Modal, { useModal } from '../../../components/Modal/Modal';
import RadioGroup, {
  RadioItemProps,
} from '../../../components/RadioGroup/RadioGroup';
import InputForm from '../../../components/InputForm/InputForm';
import IOSSwitch from '../../../components/IOSSwitch/IOSSwitch';
import LegalStructureCode from '../../../components/LegalStructureCode/LegalStructureCode';
import TabView, { useTabView } from '../../../components/TabView/TabView';
import {
  fieldDict,
  sortFieldList,
  sortStringList,
  switchItems,
} from './Participants.helper';

import { defaultAvatars } from '../../../utils/constants';
import { useDeviceType } from '../../../services/hooks';
import {
  ParticipantInfo,
  PersonType,
  deleteParticipant,
  getParticipants,
  printPersonType,
  sendInvitation,
  updateParticipant,
} from '../../../apis/participants';
import {
  UserAndInviteProps,
  UserRole,
  addUserAndSendInvite,
  printUserRole,
} from '../../../apis/users';
import { getServerImageUrl, getStringByLanguage } from '../../../utils/utils';

import styles from './styles.module.scss';

const Participants: FC = () => {
  const [showLoadingBar, hideLoadingBar] = useLoadingBars();
  const { isMobile } = useDeviceType();
  const { community_id } = useParams();

  const [activeView, setActiveView] = useState(1);
  const [tableUrlParam, setTableUrlParam] = useState<{
    search: string;
  }>({
    search: '',
  });

  const [listUrlParam, setListUrlParam] = useState<{
    search: string;
    sort: SortOrder;
    sortField: keyof ParticipantInfo;
  }>({
    search: '',
    sort: SortOrder.ASC,
    sortField: 'participant_name',
  });

  const [selectedItems, setSelectedItems] = useState<{
    allSelected: boolean;
    selectedCount: number;
    selectedRows: Partial<ParticipantInfo>[];
  }>({ allSelected: false, selectedCount: 0, selectedRows: [] });

  const [modalItem, setModalItem] = useState<ParticipantInfo>();
  const [modalDeleteItems, setModalDeleteItems] = useState<
    Partial<ParticipantInfo>[]
  >([]);
  const [modalText, setModalText] = useState('');
  const [selectAll, setSelectAll] = useState(false);
  const [isEventFromAll, setIsEventFromAll] = useState(false);
  const [onlyView, setOnlyView] = useState(false);

  const ActionComponent: FC<{ data: ParticipantInfo }> = ({ data }) => (
    <Popup
      className="action-popup"
      trigger={
        <div style={{ padding: '0px 10px' }}>
          <BsThreeDotsVertical />
        </div>
      }
      position={['bottom right']}
      arrow={false}
      contentStyle={{
        width: 'initial',
        backgroundColor: 'rgb(var(--content-background-color))',
        borderColor: 'rgb(var(--background-color))',
        padding: '5px 0px',
        boxShadow: '2px 2px 5px 0px rgba(var(--text-color), .2)',
      }}
    >
      <div className={styles['action-item-list']}>
        <span
          className={cn([styles['action-button-invite']])}
          onClick={() => onInvite(data)}
        >
          {getStringByLanguage('INVITE')}
        </span>
        <span
          className={cn([styles['action-button-update']])}
          onClick={() => updateRow(data)}
        >
          {getStringByLanguage('UPDATE')}
        </span>
        <span
          className={cn([styles['action-button-delete']])}
          onClick={() => deleteRow(data)}
        >
          {getStringByLanguage('DELETE')}
        </span>
      </div>
    </Popup>
  );

  const personTypes: RadioItemProps[] = [
    {
      value: PersonType.Producer,
      text: printPersonType(PersonType.Producer),
      component: StatusSpan,
      props: {
        IconComponent: () => <></>,
        status: STATUS.WARNING,
      },
    },
    {
      value: PersonType.Consumer,
      text: printPersonType(PersonType.Consumer),
      component: StatusSpan,
      props: {
        IconComponent: () => <></>,
        status: STATUS.SUCCESS,
      },
    },
    {
      value: PersonType.Prosumer,
      text: printPersonType(PersonType.Prosumer),
      component: StatusSpan,
      props: {
        IconComponent: () => <></>,
        status: STATUS.PRIMARY,
      },
    },
  ];

  const userRoles: RadioItemProps[] = [
    {
      value: UserRole.Participant,
      text: printUserRole(UserRole.Participant),
      component: StatusSpan,
      props: {
        IconComponent: () => <></>,
        status: STATUS.PRIMARY,
      },
    },
    {
      value: UserRole.Community_Manager,
      text: printUserRole(UserRole.Community_Manager),
      component: StatusSpan,
      props: {
        IconComponent: () => <></>,
        status: STATUS.PRIMARY,
      },
    },
  ];

  useEffect(() => {
    if (isMobile) {
      setActiveView(0);
    }
  }, [isMobile, activeView]);

  const onViewChange = (index: number) => {
    setActiveView(index);
    setSelectedItems({
      allSelected: false,
      selectedCount: 0,
      selectedRows: [],
    });
    setSelectAll(false);
  };

  const onRowClicked = (participant: ParticipantInfo) => {
    setModalItem(participant);
    setOnlyView(true);
    updateModal.show();
  };

  const updateRow = (participant: ParticipantInfo) => {
    setModalItem(participant);
    setOnlyView(false);
    updateModal.show();
    tabView.initStatus();
  };

  const deleteRow = (participant: Partial<ParticipantInfo>) => {
    setModalText(
      `${getStringByLanguage('ARE_YOU_SURE_WANT_TO_DELETE')} <b>${
        participant.participant_name
      }</b> ${getStringByLanguage('PARTICIPANT').toLowerCase()}?`,
    );
    setModalDeleteItems([participant]);
    deleteModal.show();
  };

  const onDeleteSelected = () => {
    if (!selectedItems.selectedCount) {
      return;
    }
    setModalText(
      `${getStringByLanguage(
        'ARE_YOU_SURE_WANT_TO_DELETE',
      )} <b>${`${getStringByLanguage('SELECTED').toLowerCase()} ${
        selectedItems.selectedCount
      } ${getStringByLanguage('PARTICIPANTS').toLowerCase()}`}</b>?`,
    );
    setModalDeleteItems(selectedItems.selectedRows);
    deleteModal.show();
  };

  const onCreateNew = () => {
    createModal.show();
    tabView.initStatus();
  };

  const onCreate = async (user: UserAndInviteProps) => {
    showLoadingBar();
    try {
      await addUserAndSendInvite({ community_id, data: user });
      toast.success(getStringByLanguage('SUCCESSFULLY_CREATED'));
      setTableUrlParam({ ...tableUrlParam });
      setListUrlParam({ ...listUrlParam });
      createModal.hide();
    } catch (error) {
      console.log(error);
    }
    hideLoadingBar();
  };

  const onUpdate = async (participant: Partial<ParticipantInfo>) => {
    showLoadingBar();
    try {
      await updateParticipant(community_id, participant);
      toast.success(getStringByLanguage('SUCCESSFULLY_UPDATED'));
      setTableUrlParam({ ...tableUrlParam });
      setListUrlParam({ ...listUrlParam });
      updateModal.hide();
    } catch (error) {
      console.log(error);
    }
    hideLoadingBar();
  };

  const onDelete = async (params: { forever?: boolean }) => {
    showLoadingBar();

    const promises = modalDeleteItems.map(async (participant) => {
      if (!participant.id) {
        throw getStringByLanguage('REQUEST_ERROR');
      }
      return await deleteParticipant({
        community_id,
        id: participant.id,
        forever: params.forever,
      });
    });
    try {
      await promises;
      toast.success(getStringByLanguage('SUCCESSFULLY_DELETED'));
      setSelectedItems({
        allSelected: false,
        selectedCount: 0,
        selectedRows: [],
      });
      setTableUrlParam({ ...tableUrlParam });
      setListUrlParam({ ...listUrlParam });
      deleteModal.hide();
    } catch (error) {
      console.log(error);
    }
    hideLoadingBar();
  };

  const onInvite = async ({ id }: ParticipantInfo) => {
    showLoadingBar();
    try {
      await sendInvitation(id);
      toast.success(getStringByLanguage('EMAIL_SENT'));
      createModal.hide();
    } catch (error) {
      console.log(error);
    }
    hideLoadingBar();
  };

  const onItemSelected = (selected: {
    allSelected: boolean;
    selectedCount: number;
    selectedRows: Partial<ParticipantInfo>[];
  }) => {
    setSelectedItems(selected);
  };

  const onSortChange = (sortField: keyof ParticipantInfo) => {
    const sort: SortOrder = listUrlParam.sort;
    if (listUrlParam.sortField === sortField) {
      setListUrlParam({
        ...listUrlParam,
        sort: sort === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC,
      });
    } else {
      setListUrlParam({
        ...listUrlParam,
        sortField,
        sort: SortOrder.ASC,
      });
    }
  };

  const columns: TableColumn<ParticipantInfo>[] = [
    {
      name: getStringByLanguage('NAME'),
      sortable: true,
      minWidth: '100px',
      compact: true,
      cell: (data) => {
        return (
          <div
            className={styles['table-item-participant-name']}
            onClick={() => onRowClicked(data)}
          >
            <img
              alt="Avatar"
              src={
                (data.user?.avatar && getServerImageUrl(data.user.avatar)) ||
                (data.user?.gender && defaultAvatars[data.user.gender]) ||
                defaultAvatars['Male']
              }
            />
            <span
              className={cn(['kut-tooltip-item'])}
              data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                <div className={styles['table-tooltip-item']}>
                  {data.participant_name}
                </div>,
              )}
            >
              {data.participant_name}
            </span>
          </div>
        );
      },
    },
    {
      name: getStringByLanguage('JOINED_ON'),
      sortable: true,
      minWidth: '100px',
      compact: true,
      style: {
        cursor: 'pointer',
      },
      selector: (data) =>
        data.joined ? DateFNS.format(new Date(data.joined), 'dd/MM/yyyy') : '',
    },
    {
      name: getStringByLanguage('TYPE'),
      sortable: true,
      minWidth: '100px',
      compact: true,
      cell: (data) => {
        return (
          <StatusSpan
            IconComponent={() => <></>}
            text={printPersonType(data.type)}
            status={
              data.type === PersonType.Producer
                ? STATUS.WARNING
                : data.type === PersonType.Consumer
                ? STATUS.SUCCESS
                : STATUS.PRIMARY
            }
          />
        );
      },
    },
    {
      name: getStringByLanguage('STATUS'),
      sortable: true,
      minWidth: '100px',
      compact: true,
      cell: (data) => {
        return (
          <StatusSpan
            text={
              data.joined
                ? getStringByLanguage('ACCEPTED')
                : getStringByLanguage('NOT_ACCEPTED')
            }
            status={data.joined ? STATUS.PRIMARY : STATUS.DANGER}
          />
        );
      },
    },
    {
      name: '',
      minWidth: '70px',
      compact: true,
      cell: (data) => {
        return (
          <Link
            className={styles['table-item-view-devices']}
            to={`/manage/communities/${community_id}/participants/${data.id}/devices`}
          >
            {getStringByLanguage('VIEW_DEVICES')} {'>'}
          </Link>
        );
      },
    },
    {
      name: '',
      minWidth: '70px',
      compact: true,
      cell: (data) => {
        return (
          <Link
            className={styles['table-item-view-documents']}
            to={`/manage/communities/${community_id}/participants/${data.id}/documents`}
          >
            {getStringByLanguage('VIEW_DOCUMENTS')} {'>'}
          </Link>
        );
      },
    },
    {
      name: '',
      width: '2.813rem',
      compact: true,
      style: {
        cursor: 'pointer',
      },
      cell: (data) => {
        return <ActionComponent data={data} />;
      },
    },
  ];

  const createModal = useModal<UserAndInviteProps>({
    onSubmit: onCreate,
    initialValues: {
      email: '',
      first_name: '',
      last_name: '',
      phone: '',
      role: UserRole.Participant,
      company_name: '',
      type: PersonType.Consumer,
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().required(
        getStringByLanguage('THIS_FIELD_IS_REQUIRED'),
      ),
      first_name: Yup.string().required(
        getStringByLanguage('THIS_FIELD_IS_REQUIRED'),
      ),
      last_name: Yup.string().required(
        getStringByLanguage('THIS_FIELD_IS_REQUIRED'),
      ),
      phone: Yup.string().notRequired(),
      role: Yup.string()
        .oneOf([UserRole.Participant, UserRole.Community_Manager])
        .required(getStringByLanguage('THIS_FIELD_IS_REQUIRED')),
      company_name: Yup.string().notRequired(),
      type: Yup.string()
        .oneOf([PersonType.Producer, PersonType.Consumer, PersonType.Prosumer])
        .required(getStringByLanguage('THIS_FIELD_IS_REQUIRED')),
      participant_address: Yup.string().nullable().notRequired(),
      participant_city: Yup.string().nullable().notRequired(),
      participant_country: Yup.string().nullable().notRequired(),
      participant_postalcode: Yup.string().nullable().notRequired(),
      participant_idn: Yup.number().nullable().notRequired(),
      participant_office_idn: Yup.number().nullable().notRequired(),
      participant_vat: Yup.number().nullable().notRequired(),
      participant_nace: Yup.number().nullable().notRequired(),
      participant_lscn: Yup.number().nullable().notRequired(),
      participant_lscs: Yup.string().when(
        ['participant_lscn'],
        (participant_lscn, schema) => {
          if (participant_lscn) {
            return schema.required();
          }
          return schema.nullable().notRequired();
        },
      ),
    }),
  });
  const updateModal = useModal<Partial<ParticipantInfo>>({
    onSubmit: onUpdate,
    initialValues: modalItem || {},
    validationSchema: Yup.object().shape({
      participant_name: Yup.string().required(
        getStringByLanguage('THIS_FIELD_IS_REQUIRED'),
      ),
      joined: Yup.date().required(),
      type: Yup.string()
        .oneOf([PersonType.Producer, PersonType.Consumer, PersonType.Prosumer])
        .required(getStringByLanguage('THIS_FIELD_IS_REQUIRED')),
      participant_address: Yup.string().nullable().notRequired(),
      participant_city: Yup.string().nullable().notRequired(),
      participant_country: Yup.string().nullable().notRequired(),
      participant_postalcode: Yup.string().nullable().notRequired(),
      participant_idn: Yup.number().nullable().notRequired(),
      participant_office_idn: Yup.number().nullable().notRequired(),
      participant_vat: Yup.number().nullable().notRequired(),
      participant_nace: Yup.number().nullable().notRequired(),
      participant_lscn: Yup.number().nullable().notRequired(),
      participant_lscs: Yup.string().when(
        ['participant_lscn'],
        (participant_lscn, schema) => {
          if (participant_lscn) {
            return schema.required();
          }
          return schema.nullable().notRequired();
        },
      ),
    }),
  });
  const deleteModal = useModal<{ forever?: boolean }>({
    onSubmit: onDelete,
    initialValues: {
      forever: false,
    },
    validationSchema: Yup.object().shape({
      forever: Yup.boolean().required(),
    }),
  });

  const tabView = useTabView({ showTabs: false });

  return (
    <div className={styles['kut-component-body']}>
      <div className={styles['kut-component-content']}>
        <div className={styles['kut-component-header']}>
          <div
            className={cn([
              styles['kut-component-sort'],
              activeView === 1 ? styles['hide'] : '',
            ])}
          >
            <span>{getStringByLanguage('SORT_BY')}</span>
            {sortFieldList.map((field, i) => (
              <span
                key={i}
                className={cn([
                  styles['kut-component-sort-option'],
                  listUrlParam.sortField === field ? styles['active'] : '',
                  styles[listUrlParam.sort],
                ])}
                onClick={() => onSortChange(field)}
              >
                {sortStringList[i]}
              </span>
            ))}
          </div>
          <div
            className={cn([
              styles['kut-component-select-all'],
              activeView === 1 ? styles['hide'] : '',
            ])}
          >
            <input
              type="checkbox"
              checked={selectAll}
              onChange={(event) => {
                const status = event.target.checked;
                setSelectAll(status);
                setIsEventFromAll(true);
              }}
              ref={(input) => {
                if (input) {
                  const status = selectAll;
                  if (!status && selectedItems.selectedCount) {
                    input.indeterminate = true;
                    return;
                  }
                  input.indeterminate = false;
                  status ? setSelectAll(true) : setSelectAll(false);
                }
              }}
            />
            <span>{getStringByLanguage('SELECT_ALL')}</span>
          </div>
          {!isMobile && (
            <SwitchView
              className={styles['kut-component-switch-view']}
              items={switchItems}
              selected={activeView}
              onViewChange={onViewChange}
            />
          )}
        </div>
        {activeView === 1 && (
          <div
            className={cn([
              styles['kut-component-table'],
              styles['kut-component-active'],
            ])}
          >
            <div className={styles['kut-component-actions']}>
              <Button
                className={cn([
                  styles['kut-component-buttons'],
                  styles['kut-buttons-large'],
                ])}
                outline={true}
                onClick={onCreateNew}
              >
                + {getStringByLanguage('CREATE_NEW')}
              </Button>
              <SearchInput
                className={styles['kut-component-search']}
                value={tableUrlParam.search}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setTableUrlParam({
                    search: event.target.value,
                  })
                }
              />
              <Button
                className={cn([
                  styles['kut-component-buttons'],
                  styles['kut-buttons-large'],
                  styles['kut-component-button-delete'],
                  selectedItems.selectedCount ? styles['kut-active'] : '',
                ])}
                type={BUTTON_TYPE.DANGER}
                onClick={onDeleteSelected}
              >
                {getStringByLanguage('DELETE_SELECTED')}
              </Button>
              <Button
                className={cn([
                  styles['kut-component-buttons'],
                  styles['kut-buttons-small'],
                ])}
                onClick={onCreateNew}
              >
                <BsPlus />
              </Button>
              <Button
                className={cn([
                  styles['kut-component-buttons'],
                  styles['kut-buttons-small'],
                  styles['kut-component-button-delete'],
                  selectedItems.selectedCount ? styles['kut-active'] : '',
                ])}
                type={BUTTON_TYPE.DANGER}
                onClick={onDeleteSelected}
              >
                <BsTrash2 />
              </Button>
            </div>
            <Table
              pagination={true}
              selectableRows
              onSelectedRowsChange={onItemSelected}
              urlParam={tableUrlParam}
              requestFunc={async (options) =>
                getParticipants({ ...options, community_id })
              }
              fieldDict={fieldDict}
              columns={columns}
              paginationPerPage={10}
              paginationRowsPerPageOptions={[10, 20, 50]}
            />
          </div>
        )}
        {activeView === 0 && (
          <div
            className={cn([
              styles['kut-component-list-view'],
              styles['kut-component-active'],
            ])}
          >
            <div className={styles['kut-component-actions']}>
              <Button
                className={cn([
                  styles['kut-component-buttons'],
                  styles['kut-buttons-large'],
                ])}
                outline={true}
                onClick={onCreateNew}
              >
                + {getStringByLanguage('CREATE_NEW')}
              </Button>
              <SearchInput
                className={styles['kut-component-search']}
                value={listUrlParam.search}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setListUrlParam({
                    ...listUrlParam,
                    search: event.target.value,
                  })
                }
              />
              <Button
                className={cn([
                  styles['kut-component-buttons'],
                  styles['kut-buttons-large'],
                  styles['kut-component-button-delete'],
                  selectedItems.selectedCount ? styles['kut-active'] : '',
                ])}
                type={BUTTON_TYPE.DANGER}
                onClick={onDeleteSelected}
              >
                {getStringByLanguage('DELETE_SELECTED')}
              </Button>
              <Button
                className={cn([
                  styles['kut-component-buttons'],
                  styles['kut-buttons-small'],
                ])}
                onClick={onCreateNew}
              >
                <BsPlus />
              </Button>
              <Button
                className={cn([
                  styles['kut-component-buttons'],
                  styles['kut-buttons-small'],
                  styles['kut-component-button-delete'],
                  selectedItems.selectedCount ? styles['kut-active'] : '',
                ])}
                type={BUTTON_TYPE.DANGER}
                onClick={onDeleteSelected}
              >
                <BsTrash2 />
              </Button>
            </div>
            <ListView
              urlParam={listUrlParam}
              selectAll={selectAll}
              setSelectAll={setSelectAll}
              isEventFromAll={isEventFromAll}
              setIsEventFromAll={setIsEventFromAll}
              onItemSelected={onItemSelected}
              requestFunc={async (options) =>
                getParticipants({ ...options, community_id })
              }
              ChildComponent={({
                className,
                onChange,
                checked,
                data,
              }: {
                className: string;
                onChange: (status: boolean) => void;
                checked: boolean;
                data: ParticipantInfo;
              }) => (
                <div
                  className={cn([className, styles['kut-component-list-item']])}
                >
                  <div className={cn([styles['list-item-heading']])}>
                    <div
                      className={cn([styles['list-item-name']])}
                      onClick={() => onRowClicked(data)}
                    >
                      <img
                        alt="Avatar"
                        src={
                          (data.user?.avatar &&
                            getServerImageUrl(data.user.avatar)) ||
                          (data.user?.gender &&
                            defaultAvatars[data.user.gender]) ||
                          defaultAvatars['Male']
                        }
                      />
                      <div>
                        <span>
                          <b>{data.participant_name}</b>
                        </span>
                        <span
                          className={cn(
                            [styles['list-item-joined']],
                            data.joined ? styles['active'] : styles['inactive'],
                          )}
                        >
                          {getStringByLanguage('JOINED_ON')}{' '}
                          <span>
                            {data.joined
                              ? DateFNS.format(
                                  new Date(data.joined),
                                  'dd/MM/yyyy',
                                )
                              : getStringByLanguage('NOT_ACCEPTED')}
                          </span>
                        </span>
                      </div>
                    </div>
                    <input
                      type="checkbox"
                      checked={checked}
                      onChange={(event) => onChange(event.target.checked)}
                    />
                  </div>
                  <div className={cn([styles['list-item-views']])}>
                    <div
                      className={cn([
                        'kut-tooltip-item',
                        styles['list-item-type'],
                        styles[data.type],
                      ])}
                      data-tooltip-content={printPersonType(data.type)}
                    >
                      {data.type[0]}
                    </div>
                    <div className={cn([styles['list-item-views-link']])}>
                      <Link
                        className={cn([styles['list-item-view-devices']])}
                        to={`/manage/communities/${community_id}/participants/${data.id}/devices`}
                      >
                        {getStringByLanguage('VIEW_DEVICES')} {'>'}
                      </Link>
                      <Link
                        className={cn([styles['list-item-view-documents']])}
                        to={`/manage/communities/${community_id}/participants/${data.id}/documents`}
                      >
                        {getStringByLanguage('VIEW_DOCUMENTS')} {'>'}
                      </Link>
                    </div>
                  </div>
                  <div className={cn([styles['list-item-actions']])}>
                    <div>
                      <span
                        className={cn([styles['list-item-button-update']])}
                        onClick={() => updateRow(data)}
                      >
                        {getStringByLanguage('UPDATE')}
                      </span>
                      <span
                        className={cn([styles['list-item-button-delete']])}
                        onClick={() => deleteRow(data)}
                      >
                        {getStringByLanguage('DELETE')}
                      </span>
                    </div>
                  </div>
                </div>
              )}
            />
          </div>
        )}
        <Modal {...createModal}>
          {(formik) => {
            return (
              <form className={cn([styles['kut-modal-form']])}>
                <p className={cn([styles['kut-title']])}>
                  {getStringByLanguage(['CREATE', 'PARTICIPANT'])}
                </p>
                <hr />
                <div className={cn([styles['kut-body']])}>
                  <TabView
                    {...tabView}
                    datasets={[
                      {
                        element: (
                          <>
                            <InputForm
                              className={cn([
                                styles['kut-form-element'],
                                styles['kut-form-email'],
                                styles['kut-input-form'],
                              ])}
                              heading={getStringByLanguage('EMAIL')}
                              name={'email'}
                              onChange={formik.handleChange}
                              required
                              value={formik.values.email}
                              error={
                                formik.touched.email && formik.errors.email
                              }
                            />
                            <InputForm
                              className={cn([
                                styles['kut-form-element'],
                                styles['kut-form-first-name'],
                                styles['kut-input-form'],
                              ])}
                              heading={getStringByLanguage('FIRST_NAME')}
                              name={'first_name'}
                              onChange={formik.handleChange}
                              required
                              value={formik.values.first_name || ''}
                              error={
                                formik.touched.first_name &&
                                formik.errors.first_name
                              }
                            />
                            <InputForm
                              className={cn([
                                styles['kut-form-element'],
                                styles['kut-form-last-name'],
                                styles['kut-input-form'],
                              ])}
                              heading={getStringByLanguage('LAST_NAME')}
                              name={'last_name'}
                              onChange={formik.handleChange}
                              required
                              value={formik.values.last_name || ''}
                              error={
                                formik.touched.last_name &&
                                formik.errors.last_name
                              }
                            />
                            <InputForm
                              className={cn([
                                styles['kut-form-element'],
                                styles['kut-form-phone'],
                                styles['kut-input-form'],
                              ])}
                              heading={getStringByLanguage('PHONE')}
                              name={'phone'}
                              onChange={formik.handleChange}
                              value={formik.values.phone || ''}
                              error={
                                formik.touched.phone && formik.errors.phone
                              }
                            />
                            <div
                              className={cn([
                                styles['kut-form-element'],
                                styles['kut-form-role'],
                                styles['kut-input-form'],
                                styles['kut-radio-group'],
                              ])}
                            >
                              <div>
                                <p>{getStringByLanguage('ROLE')}</p>
                                <RadioGroup
                                  value={formik.values.role}
                                  groupId={'role'}
                                  onChange={(value) => {
                                    formik.setFieldValue('role', value);
                                  }}
                                  itemList={userRoles}
                                />
                              </div>
                            </div>
                            <InputForm
                              className={cn([
                                styles['kut-form-element'],
                                styles['kut-form-company-name'],
                                styles['kut-input-form'],
                              ])}
                              heading={getStringByLanguage('COMPANY_NAME')}
                              name={'company_name'}
                              onChange={formik.handleChange}
                              value={formik.values.company_name || ''}
                              error={
                                formik.touched.company_name &&
                                formik.errors.company_name
                              }
                            />
                            <div
                              className={cn([
                                styles['kut-form-element'],
                                styles['kut-form-type'],
                                styles['kut-input-form'],
                                styles['kut-radio-group'],
                              ])}
                            >
                              <div>
                                <p>{getStringByLanguage('PERSON_TYPE')}</p>
                                <RadioGroup
                                  value={formik.values.type}
                                  groupId={'type'}
                                  onChange={(value) => {
                                    formik.setFieldValue('type', value);
                                  }}
                                  itemList={personTypes}
                                />
                              </div>
                            </div>
                          </>
                        ),
                      },
                      {
                        element: (
                          <>
                            <InputForm
                              className={cn([
                                styles['kut-form-element'],
                                styles['kut-form-address'],
                                styles['kut-input-form'],
                              ])}
                              heading={getStringByLanguage(['ADDRESS'])}
                              name={'participant_address'}
                              onChange={formik.handleChange}
                              readOnly={onlyView}
                              value={formik.values.participant_address || ''}
                              error={
                                formik.touched.participant_address &&
                                formik.errors.participant_address
                              }
                            />
                            <InputForm
                              className={cn([
                                styles['kut-form-element'],
                                styles['kut-form-city'],
                                styles['kut-input-form'],
                              ])}
                              heading={getStringByLanguage(['CITY'])}
                              name={'participant_city'}
                              onChange={formik.handleChange}
                              readOnly={onlyView}
                              value={formik.values.participant_city || ''}
                              error={
                                formik.touched.participant_city &&
                                formik.errors.participant_city
                              }
                            />
                            <InputForm
                              className={cn([
                                styles['kut-form-element'],
                                styles['kut-form-country'],
                                styles['kut-input-form'],
                              ])}
                              heading={getStringByLanguage(['COUNTRY'])}
                              name={'participant_country'}
                              onChange={formik.handleChange}
                              readOnly={onlyView}
                              value={formik.values.participant_country || ''}
                              error={formik.errors.participant_country}
                            />
                            <InputForm
                              className={cn([
                                styles['kut-form-element'],
                                styles['kut-form-postcode'],
                                styles['kut-input-form'],
                              ])}
                              heading={getStringByLanguage(['POSTCODE'])}
                              name={'participant_postalcode'}
                              onChange={formik.handleChange}
                              readOnly={onlyView}
                              value={formik.values.participant_postalcode || ''}
                              error={
                                formik.touched.participant_postalcode &&
                                formik.errors.participant_postalcode
                              }
                            />
                            <InputForm
                              className={cn([
                                styles['kut-form-element'],
                                styles['kut-form-idn'],
                                styles['kut-input-form'],
                              ])}
                              heading={getStringByLanguage(['ID_NUMBER'])}
                              name={'participant_idn'}
                              onChange={formik.handleChange}
                              readOnly={onlyView}
                              value={formik.values.participant_idn || ''}
                              error={
                                formik.touched.participant_idn &&
                                formik.errors.participant_idn
                              }
                            />
                            <InputForm
                              className={cn([
                                styles['kut-form-element'],
                                styles['kut-form-office-idn'],
                                styles['kut-input-form'],
                              ])}
                              heading={getStringByLanguage([
                                'OFFICE_ID_NUMBER',
                              ])}
                              name={'participant_office_idn'}
                              onChange={formik.handleChange}
                              readOnly={onlyView}
                              value={formik.values.participant_office_idn || ''}
                              error={
                                formik.touched.participant_office_idn &&
                                formik.errors.participant_office_idn
                              }
                            />
                            <InputForm
                              className={cn([
                                styles['kut-form-element'],
                                styles['kut-form-dso'],
                                styles['kut-input-form'],
                              ])}
                              heading={getStringByLanguage(['VAT'])}
                              name={'participant_vat'}
                              onChange={formik.handleChange}
                              readOnly={onlyView}
                              value={formik.values.participant_vat || ''}
                              error={
                                formik.touched.participant_vat &&
                                formik.errors.participant_vat
                              }
                            />
                            <InputForm
                              className={cn([
                                styles['kut-form-element'],
                                styles['kut-form-nace'],
                                styles['kut-input-form'],
                              ])}
                              heading={getStringByLanguage(['NACE'])}
                              name={'participant_nace'}
                              onChange={formik.handleChange}
                              readOnly={onlyView}
                              value={formik.values.participant_nace || ''}
                              error={
                                formik.touched.participant_nace &&
                                formik.errors.participant_nace
                              }
                            />
                            <LegalStructureCode
                              className={cn([
                                styles['kut-form-element'],
                                styles['kut-form-legal-structure-code'],
                                styles['kut-input-form'],
                              ])}
                              name="participant_lscn"
                              heading={getStringByLanguage([
                                'LEGAL_STRUCTURE_CODE',
                              ])}
                              onChange={({ lscn, lscs }) => {
                                formik.setFieldValue('participant_lscn', lscn);
                                formik.setFieldValue('participant_lscs', lscs);
                              }}
                              readOnly={onlyView}
                              value={{
                                lscn: formik.values.participant_lscn,
                                lscs: formik.values.participant_lscs,
                              }}
                              error={
                                (formik.touched.participant_lscn &&
                                  formik.errors.participant_lscn) ||
                                (formik.touched.participant_lscn &&
                                  formik.errors.participant_lscs)
                              }
                            />
                          </>
                        ),
                      },
                    ]}
                  />
                </div>
                <hr />
                <div className={cn([styles['kut-form-actions']])}>
                  {tabView.status.activeTab === 0 ? (
                    <>
                      <Button
                        outline
                        type={BUTTON_TYPE.INFO}
                        style={{
                          padding: 10,
                          fontSize: '1em',
                          visibility: 'hidden',
                          transition: 'none',
                        }}
                      >
                        <IoArrowBackOutline />
                      </Button>
                      <div className={cn([styles['kut-form-submit']])}>
                        <Button
                          onClick={async () => {
                            const result = await formik.validateForm();
                            const fields = [
                              'email',
                              'first_name',
                              'last_name',
                              'phone',
                              'role',
                              'company_name',
                              'type',
                            ];
                            fields.forEach((field) =>
                              formik.setFieldTouched(field, true),
                            );
                            if (
                              fields.reduce<boolean>(
                                (check, field) => check || !!result[field],
                                false,
                              )
                            ) {
                              return;
                            }
                            tabView.status.switchTab(1);
                          }}
                        >
                          {getStringByLanguage('CONTINUE')}
                        </Button>
                        <Button
                          type={BUTTON_TYPE.DANGER}
                          onClick={() => createModal.hide()}
                        >
                          {getStringByLanguage('CANCEL')}
                        </Button>
                      </div>
                    </>
                  ) : (
                    <>
                      <Button
                        outline
                        type={BUTTON_TYPE.INFO}
                        onClick={() => tabView.status.switchTab(0)}
                        style={{
                          padding: 10,
                          fontSize: '1em',
                        }}
                      >
                        <IoArrowBackOutline />
                      </Button>
                      <div className={cn([styles['kut-form-submit']])}>
                        <Button onClick={() => formik.handleSubmit()}>
                          {getStringByLanguage('CREATE')}
                        </Button>
                        <Button
                          type={BUTTON_TYPE.DANGER}
                          onClick={() => createModal.hide()}
                        >
                          {getStringByLanguage('CANCEL')}
                        </Button>
                      </div>
                    </>
                  )}
                </div>
              </form>
            );
          }}
        </Modal>
        <Modal {...updateModal}>
          {(formik) => {
            return (
              <form className={cn([styles['kut-modal-form']])}>
                <p className={cn([styles['kut-title']])}>
                  {onlyView
                    ? getStringByLanguage('PARTICIPANT')
                    : getStringByLanguage(['UPDATE', 'PARTICIPANT'])}
                </p>
                <hr />
                <div className={cn([styles['kut-body']])}>
                  <TabView
                    datasets={[
                      {
                        element: (
                          <>
                            <InputForm
                              className={cn([
                                styles['kut-form-element'],
                                styles['kut-form-participant-name'],
                                styles['kut-input-form'],
                              ])}
                              heading={getStringByLanguage('PARTICIPANT_NAME')}
                              name={'participant_name'}
                              onChange={formik.handleChange}
                              readOnly={onlyView}
                              required
                              value={formik.values.participant_name}
                              error={formik.errors.participant_name}
                              style={{
                                backgroundColor: `rgb(var(--background-color))`,
                              }}
                            />
                            <InputForm
                              className={cn([
                                styles['kut-form-element'],
                                styles['kut-form-participant-joined'],
                                styles['kut-input-form'],
                              ])}
                              type={'date'}
                              heading={getStringByLanguage('JOINED')}
                              name={'joined'}
                              onChange={formik.handleChange}
                              readOnly={onlyView}
                              required
                              value={formik.values.joined.slice(0, 10)}
                              error={formik.errors.joined}
                              style={{
                                backgroundColor: `rgb(var(--background-color))`,
                              }}
                            />
                            <div
                              className={cn([
                                styles['kut-form-element'],
                                styles['kut-input-form'],
                                styles['kut-radio-group'],
                                styles['kut-form-type'],
                              ])}
                            >
                              <div>
                                <p>{getStringByLanguage('PERSON_TYPE')}</p>
                                <RadioGroup
                                  value={formik.values.type}
                                  readOnly={onlyView}
                                  groupId={'type'}
                                  onChange={(value) => {
                                    formik.setFieldValue('type', value);
                                  }}
                                  itemList={personTypes}
                                />
                              </div>
                            </div>
                            <InputForm
                              className={cn([
                                styles['kut-form-element'],
                                styles['kut-form-address'],
                                styles['kut-input-form'],
                              ])}
                              heading={getStringByLanguage(['ADDRESS'])}
                              name={'participant_address'}
                              onChange={formik.handleChange}
                              readOnly={onlyView}
                              value={formik.values.participant_address || ''}
                              error={
                                formik.touched.participant_address &&
                                formik.errors.participant_address
                              }
                            />
                            <InputForm
                              className={cn([
                                styles['kut-form-element'],
                                styles['kut-form-city'],
                                styles['kut-input-form'],
                              ])}
                              heading={getStringByLanguage(['CITY'])}
                              name={'participant_city'}
                              onChange={formik.handleChange}
                              readOnly={onlyView}
                              value={formik.values.participant_city || ''}
                              error={
                                formik.touched.participant_city &&
                                formik.errors.participant_city
                              }
                            />
                            <InputForm
                              className={cn([
                                styles['kut-form-element'],
                                styles['kut-form-country'],
                                styles['kut-input-form'],
                              ])}
                              heading={getStringByLanguage(['COUNTRY'])}
                              name={'participant_country'}
                              onChange={formik.handleChange}
                              readOnly={onlyView}
                              value={formik.values.participant_country || ''}
                              error={formik.errors.participant_country}
                            />
                            <InputForm
                              className={cn([
                                styles['kut-form-element'],
                                styles['kut-form-postcode'],
                                styles['kut-input-form'],
                              ])}
                              heading={getStringByLanguage(['POSTCODE'])}
                              name={'participant_postalcode'}
                              onChange={formik.handleChange}
                              readOnly={onlyView}
                              value={formik.values.participant_postalcode || ''}
                              error={
                                formik.touched.participant_postalcode &&
                                formik.errors.participant_postalcode
                              }
                            />
                          </>
                        ),
                      },
                      {
                        element: (
                          <>
                            <InputForm
                              className={cn([
                                styles['kut-form-element'],
                                styles['kut-form-idn'],
                                styles['kut-input-form'],
                              ])}
                              heading={getStringByLanguage(['ID_NUMBER'])}
                              name={'participant_idn'}
                              onChange={formik.handleChange}
                              readOnly={onlyView}
                              value={formik.values.participant_idn || ''}
                              error={
                                formik.touched.participant_idn &&
                                formik.errors.participant_idn
                              }
                            />
                            <InputForm
                              className={cn([
                                styles['kut-form-element'],
                                styles['kut-form-office-idn'],
                                styles['kut-input-form'],
                              ])}
                              heading={getStringByLanguage([
                                'OFFICE_ID_NUMBER',
                              ])}
                              name={'participant_office_idn'}
                              onChange={formik.handleChange}
                              readOnly={onlyView}
                              value={formik.values.participant_office_idn || ''}
                              error={
                                formik.touched.participant_office_idn &&
                                formik.errors.participant_office_idn
                              }
                            />
                            <InputForm
                              className={cn([
                                styles['kut-form-element'],
                                styles['kut-form-dso'],
                                styles['kut-input-form'],
                              ])}
                              heading={getStringByLanguage(['VAT'])}
                              name={'participant_vat'}
                              onChange={formik.handleChange}
                              readOnly={onlyView}
                              value={formik.values.participant_vat || ''}
                              error={
                                formik.touched.participant_vat &&
                                formik.errors.participant_vat
                              }
                            />
                            <InputForm
                              className={cn([
                                styles['kut-form-element'],
                                styles['kut-form-nace'],
                                styles['kut-input-form'],
                              ])}
                              heading={getStringByLanguage(['NACE'])}
                              name={'participant_nace'}
                              onChange={formik.handleChange}
                              readOnly={onlyView}
                              value={formik.values.participant_nace || ''}
                              error={
                                formik.touched.participant_nace &&
                                formik.errors.participant_nace
                              }
                            />
                            <LegalStructureCode
                              className={cn([
                                styles['kut-form-element'],
                                styles['kut-form-legal-structure-code'],
                                styles['kut-input-form'],
                              ])}
                              name="participant_lscn"
                              heading={getStringByLanguage([
                                'LEGAL_STRUCTURE_CODE',
                              ])}
                              onChange={({ lscn, lscs }) => {
                                formik.setFieldValue('participant_lscn', lscn);
                                formik.setFieldValue('participant_lscs', lscs);
                              }}
                              readOnly={onlyView}
                              value={{
                                lscn: formik.values.participant_lscn,
                                lscs: formik.values.participant_lscs,
                              }}
                              error={
                                (formik.touched.participant_lscn &&
                                  formik.errors.participant_lscn) ||
                                (formik.touched.participant_lscn &&
                                  formik.errors.participant_lscs)
                              }
                            />
                          </>
                        ),
                      },
                    ]}
                    {...tabView}
                  />
                </div>
                <hr />
                <div className={cn([styles['kut-form-actions']])}>
                  {tabView.status.activeTab === 0 ? (
                    <>
                      <Button
                        outline
                        type={BUTTON_TYPE.INFO}
                        style={{
                          padding: 10,
                          fontSize: '1em',
                          visibility: 'hidden',
                          transition: 'none',
                        }}
                      >
                        <IoArrowBackOutline />
                      </Button>
                      <div className={cn([styles['kut-form-submit']])}>
                        <Button
                          onClick={async () => {
                            const result = await formik.validateForm();
                            const fields = [
                              'participant_name',
                              'joined',
                              'type',
                              'participant_address',
                              'participant_city',
                              'participant_country',
                              'participant_postalcode',
                            ];
                            fields.forEach((field) =>
                              formik.setFieldTouched(field, true),
                            );
                            if (
                              fields.reduce<boolean>(
                                (check, field) => check || !!result[field],
                                false,
                              )
                            ) {
                              return;
                            }
                            tabView.status.switchTab(1);
                          }}
                        >
                          {getStringByLanguage('CONTINUE')}
                        </Button>
                        <Button
                          type={BUTTON_TYPE.DANGER}
                          onClick={() => updateModal.hide()}
                        >
                          {getStringByLanguage('CANCEL')}
                        </Button>
                      </div>
                    </>
                  ) : (
                    <>
                      <Button
                        outline
                        type={BUTTON_TYPE.INFO}
                        onClick={() => tabView.status.switchTab(0)}
                        style={{
                          padding: 10,
                          fontSize: '1em',
                        }}
                      >
                        <IoArrowBackOutline />
                      </Button>
                      <div className={cn([styles['kut-form-submit']])}>
                        <Button onClick={() => formik.handleSubmit()}>
                          {getStringByLanguage('SAVE_UPDATES')}
                        </Button>
                        <Button
                          type={BUTTON_TYPE.DANGER}
                          onClick={() => updateModal.hide()}
                        >
                          {getStringByLanguage('CANCEL')}
                        </Button>
                      </div>
                    </>
                  )}
                </div>
              </form>
            );
          }}
        </Modal>
        <Modal {...deleteModal}>
          {(formik) => (
            <form
              className={cn([
                styles['kut-modal-form'],
                styles['kut-delete-form'],
              ])}
            >
              <p dangerouslySetInnerHTML={{ __html: modalText }} />
              <hr />
              <div className={cn([styles['kut-form-delete-forever']])}>
                <span
                  style={{
                    color: `rgb(var(--text-color))`,
                  }}
                >
                  {getStringByLanguage('DELETE_FOREVER')}?&nbsp;
                </span>
                <IOSSwitch
                  name={'forever'}
                  checked={formik.values.forever}
                  onChange={formik.handleChange}
                />
              </div>
              <p
                className={cn([styles['kut-form-delete-forever-description']])}
              >
                {getStringByLanguage('DELETE_FOREVER_DESCRIPTION')}
              </p>
              <hr />
              <div className={cn([styles['kut-form-actions']])}>
                <Button onClick={() => formik.handleSubmit()}>
                  {getStringByLanguage('DELETE')}
                </Button>
                <Button
                  type={BUTTON_TYPE.DANGER}
                  onClick={() => deleteModal.hide()}
                >
                  {getStringByLanguage('CANCEL')}
                </Button>
              </div>
            </form>
          )}
        </Modal>
      </div>
    </div>
  );
};

export default Participants;
