import { FC, useState } from 'react';
import cn from 'classnames';

import styles from './styles.module.scss';

interface TabContent {
  title?: string;
  element: JSX.Element;
}

interface StatusProps {
  activeTab: number;
  inActiveTab: number;
  animation: boolean;
  switchTab: (active: number) => void;
}

interface TabViewProps {
  showTabs?: boolean;
  animationDuration?: number;
}

interface FuncGroup extends TabViewProps {
  className?: string;
  datasets: TabContent[];
  status?: StatusProps;
}

const TabView: FC<FuncGroup> = ({
  className = '',
  showTabs = true,
  datasets = [],
  status: { activeTab, inActiveTab, animation, switchTab } = {},
  animationDuration = 0.3,
}) => {
  const [localStatus, setLocalStatus] = useState<{
    activeTab: number;
    inActiveTab: number;
    animation: boolean;
  }>({ activeTab: 0, inActiveTab: -1, animation: false });

  const localSwitchTab = (active: number) => {
    setLocalStatus({
      activeTab: active,
      inActiveTab: localStatus.activeTab,
      animation: true,
    });

    setTimeout(() => {
      setLocalStatus({
        activeTab: active,
        inActiveTab: localStatus.activeTab,
        animation: false,
      });
    }, animationDuration * 1000);
  };

  const active = activeTab !== undefined ? activeTab : localStatus.activeTab;
  const inActive =
    inActiveTab !== undefined ? inActiveTab : localStatus.inActiveTab;
  const animStatus =
    animation !== undefined ? animation : localStatus.animation;
  const activeChange = switchTab ? switchTab : localSwitchTab;

  return (
    <div className={cn([className, styles['kut-tab-view']])}>
      {showTabs && (
        <div className={cn([styles['kut-header']])}>
          {datasets.map((content, index) => (
            <span
              key={index}
              onClick={() => {
                if (animation || active === index) {
                  return;
                }
                activeChange(index);
              }}
            >
              {content.title || `Tab ${index}`}
            </span>
          ))}
        </div>
      )}
      <div className={cn([styles['kut-content']])}>
        {animStatus && (
          <>
            <div
              className={cn([
                styles['kut-animation-item'],
                active > inActive
                  ? styles['kut-animation-active']
                  : styles['kut-animation-active-reverse'],
              ])}
              style={{ animationDuration: `${animationDuration}s` }}
            >
              {datasets[active].element}
            </div>
            <div
              className={cn([
                styles['kut-animation-item'],
                active > inActive
                  ? styles['kut-animation-inactive']
                  : styles['kut-animation-inactive-reverse'],
              ])}
              style={{ animationDuration: `${animationDuration}s` }}
            >
              {datasets[inActive].element}
            </div>
          </>
        )}
        <div style={{ opacity: animStatus ? 0 : 1 }}>
          {datasets[active].element}
        </div>
      </div>
    </div>
  );
};

export function useTabView(
  option?: TabViewProps,
): { status: StatusProps; initStatus: () => void } & TabViewProps {
  const { animationDuration = 0.3, ...props } = option || {};

  const [status, setStatus] = useState<{
    activeTab: number;
    inActiveTab: number;
    animation: boolean;
  }>({ activeTab: 0, inActiveTab: -1, animation: false });

  const initStatus = () => {
    setStatus({ activeTab: 0, inActiveTab: -1, animation: false });
  };

  const switchTab = (active: number) => {
    setStatus({
      activeTab: active,
      inActiveTab: status.activeTab,
      animation: true,
    });

    setTimeout(() => {
      setStatus({
        activeTab: active,
        inActiveTab: status.activeTab,
        animation: false,
      });
    }, animationDuration * 1000);
  };

  return {
    status: {
      ...status,
      switchTab,
    },
    initStatus,
    animationDuration,
    ...props,
  };
}

export default TabView;
