import { FC, useState } from 'react';
import ReactModal from 'react-modal';
import cn from 'classnames';
import { Formik, FormikProps } from 'formik';

import styles from './styles.module.scss';

ReactModal.setAppElement('#root');

export const defaultStyle: ReactModal.Styles = {
  overlay: {
    position: 'fixed',
    zIndex: 10,
    backgroundColor: '#0000007F',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  content: {
    maxWidth: 500,
    maxHeight: '90%',
    width: '90%',
    inset: 'auto',
    fontSize: '20px',
    padding: '20px 30px',
    border: 'none',
    outline: 'none',
    borderRadius: '10px',
    color: `rgb(var(--text-color))`,
    backgroundColor: `rgb(var(--content-background-color))`,
  },
};

export interface FormikValues {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [field: string]: any;
}

interface ModalControlProps {
  modalStatus: boolean;
  show: () => void;
  hide: () => void;
}

interface ModalProps<T extends FormikValues = FormikValues>
  extends ModalControlProps {
  initialValues: T;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  validationSchema: any;
  onSubmit: (values: T) => void;
}

interface FuncGroup<T extends FormikValues = FormikValues>
  extends ModalProps<T> {
  className?: string;
  children: (formik: FormikProps<FormikValues>) => JSX.Element;
  customStyle?: ReactModal.Styles;
}

const Modal: FC<FuncGroup> = ({
  className = '',
  children,
  customStyle,
  initialValues,
  validationSchema,
  onSubmit,
  ...props
}) => {
  return (
    <ReactModal
      className={cn([className, styles['kut-modal']])}
      isOpen={props.modalStatus}
      onRequestClose={props.hide}
      style={{
        overlay: { ...defaultStyle.overlay, ...customStyle?.overlay },
        content: { ...defaultStyle.content, ...customStyle?.content },
      }}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {children}
      </Formik>
    </ReactModal>
  );
};

interface ModalConfig<T> {
  initialValues: T;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  validationSchema: any;
  onSubmit: (values: T) => void;
  customStyle?: ReactModal.Styles;
}

export function useModal<T extends FormikValues = FormikValues>({
  ...props
}: ModalConfig<T>): ModalProps<T> {
  const [modalStatus, setModalStatus] = useState(false);

  const show = () => {
    setModalStatus(true);
  };

  const hide = () => {
    setModalStatus(false);
  };

  return {
    modalStatus,
    show,
    hide,
    ...props,
  };
}

export default Modal;
