import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { FormikProvider, useFormik } from 'formik';
import cn from 'classnames';
import { toast } from 'react-toastify';

import { useLoadingBars } from '../../../components/LoadingBar/LoadingBar';

import { useUrlTrack } from '../../../services/hooks';
import { forgotPassword, resetPassword } from '../../../apis/users';
import { PASSWORD_MIN_LENGTH } from '../../../config/config';
import { getServerImageUrl, getStringByLanguage } from '../../../utils/utils';

import styles from './styles.module.scss';
import TextWithTick from '../../../components/TextWithTick/TextWithTick';

const ResetPassword: FC = () => {
  const urlInfo = useUrlTrack();
  const navigate = useNavigate();
  const { token } = useParams();
  const [showLoadingBar, hideLoadingBar] = useLoadingBars();

  if (!token) {
    const formik = useFormik({
      initialValues: {
        email: '',
        captcha: '',
      },
      enableReinitialize: true,
      validationSchema: Yup.object().shape({
        email: Yup.string()
          .matches(
            /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/,
            getStringByLanguage('INVALID_EMAIL_ADDRESS'),
          )
          .required(getStringByLanguage('ENTER_EMAIL_ADDRESS')),
      }),
      onSubmit: async (values) => {
        showLoadingBar();
        try {
          const { data } = await forgotPassword(values);
          if (data.data) {
            toast.success(
              getStringByLanguage([
                'PASSWORD_RESET_EMAIL_SENT',
                'CHECK_YOUR_EMAIL_FOR_A_LINK_TO_RESET_YOUR_PASSWORD',
                'IF_IT_DOES_NOT_APPEAR_WITHIN_A_FEW_MINUTES_CHECK_YOUR_SPAM_FOLDER',
              ]),
            );
            navigate('/signin');
          }
        } catch (error) {
          console.log(error);
        }
        hideLoadingBar();
      },
    });

    return (
      <FormikProvider value={formik}>
        <form
          className={styles['kut-reset-form']}
          noValidate
          onSubmit={formik.handleSubmit}
          style={{
            backgroundImage: `url('${getServerImageUrl(
              urlInfo.auth_background,
            )}')`,
          }}
        >
          <div className={styles['kut-reset-container']}>
            <img
              className={styles['kut-logo']}
              alt="wesmart logo"
              src={getServerImageUrl(urlInfo.logo)}
              width={265}
            />
            <div className={styles['kut-reset-content']}>
              <p>
                {getStringByLanguage(
                  'ENTER_YOUR_USER_ACCOUNTS_VERIFIED_EMAIL_ADDRESS_AND_WE_WILL_SEND_YOU_A_PASSWORD_RESET_LINK',
                )}
              </p>
              <input
                className={styles['kut-reset-email']}
                type="text"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                placeholder={getStringByLanguage('_EMAIL')}
              />
            </div>
            <div className={styles['kut-reset-actions']}>
              <span
                className={styles['kut-reset-button']}
                onClick={() => formik.handleSubmit()}
              >
                {getStringByLanguage('SEND_PASSWORD_RESET_EMAIL')}
              </span>
            </div>
          </div>
          <div className={styles['kut-reset-footer']}>
            {urlInfo.description}
          </div>
        </form>
      </FormikProvider>
    );
  }

  const formik = useFormik({
    initialValues: {
      password: '',
      confirm: '',
      captcha: '',
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      password: Yup.string()
        .min(PASSWORD_MIN_LENGTH)
        .matches(/^(?=.*[a-z])(?=.*[A-Z]).+$/, {
          message: getStringByLanguage(
            'A_MIXTURE_OF_BOTH_UPPERCASE_AND_LOWERCASE_LETTERS',
          ),
        })
        .matches(/^(?=.*[\d])(?=.*[\D]).+$/, {
          message: getStringByLanguage('A_MIXTURE_OF_LETTERS_AND_NUMBERS'),
        })
        .matches(/^(?=.*[\W]).+$/, {
          message: getStringByLanguage(
            'INCLUSION_OF_AT_LEAST_ONE_SPECIAL_CHARACTER',
          ),
        })
        .required(getStringByLanguage('ENTER_PASSWORD')),
      confirm: Yup.string().oneOf(
        [Yup.ref('password'), null],
        getStringByLanguage('PASSWORD_MATCHED'),
      ),
    }),
    onSubmit: async ({ password }) => {
      showLoadingBar();
      try {
        const { data } = await resetPassword({ token, password });
        if (data.data) {
          toast.success(
            'Password reset email sent. Check your email for a link to reset your password. If it doesn’t appear within a few minutes, check your spam folder.',
          );
          navigate('/signin');
        }
      } catch (error) {
        console.log(error);
      }
      hideLoadingBar();
    },
  });

  return (
    <FormikProvider value={formik}>
      <form
        className={cn([styles['kut-reset-form']])}
        noValidate
        onSubmit={formik.handleSubmit}
        style={{
          backgroundImage: `url('${getServerImageUrl(
            urlInfo.auth_background,
          )}')`,
        }}
      >
        <div className={styles['kut-reset-container']}>
          <img
            className={styles['kut-logo']}
            alt="wesmart logo"
            src={getServerImageUrl(urlInfo.logo)}
            width={265}
          />
          <div className={styles['kut-reset-content']}>
            <input
              className={styles['kut-reset-password']}
              type="password"
              name="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              placeholder={getStringByLanguage('UPDATE_PASSWORD')}
            />
            <input
              className={styles['kut-reset-confirm']}
              type="password"
              name="confirm"
              value={formik.values.confirm}
              onChange={formik.handleChange}
              placeholder={getStringByLanguage('CONFIRM_NEW_PASSWORD')}
            />
            <TextWithTick
              status={
                !!formik.values.password.match(
                  new RegExp(`^.{${PASSWORD_MIN_LENGTH},}$`),
                )
              }
            >
              {`${getStringByLanguage(
                'AT_LEAST',
              )} ${PASSWORD_MIN_LENGTH} ${getStringByLanguage('_CHARACTERS')}`}
            </TextWithTick>
            <TextWithTick
              status={
                !!formik.values.password.match(/^(?=.*[a-z])(?=.*[A-Z]).+$/)
              }
            >
              {getStringByLanguage(
                'A_MIXTURE_OF_BOTH_UPPERCASE_AND_LOWERCASE_LETTERS',
              )}
            </TextWithTick>
            <TextWithTick
              status={
                !!formik.values.password.match(/^(?=.*[\d])(?=.*[\D]).+$/)
              }
            >
              {getStringByLanguage('A_MIXTURE_OF_LETTERS_AND_NUMBERS')}
            </TextWithTick>
            <TextWithTick
              status={!!formik.values.password.match(/^(?=.*[\W]).+$/)}
            >
              {getStringByLanguage(
                'INCLUSION_OF_AT_LEAST_ONE_SPECIAL_CHARACTER',
              )}
            </TextWithTick>
            <TextWithTick
              status={!!formik.values.confirm && !formik.errors.confirm}
            >
              {getStringByLanguage('PASSWORD_MATCHED')}
            </TextWithTick>
          </div>
          <div className={styles['kut-reset-actions']}>
            <span
              className={styles['kut-reset-button']}
              onClick={() => formik.handleSubmit()}
            >
              {getStringByLanguage('UPDATE_PASSWORD')}
            </span>
          </div>
        </div>
        <div className={styles['kut-reset-footer']}>{urlInfo.description}</div>
      </form>
    </FormikProvider>
  );
};

export default ResetPassword;
