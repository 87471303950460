import { UserState } from '../services/reducers/userReducer';

// Backend url
export const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL || 'http://localhost:9000';
// Weather Key
export const API_WEATHER_URL = 'https://api.openweathermap.org/data/2.5';
export const API_WEATHER_KEY = process.env.REACT_APP_API_WEATHER_KEY || '';
// Geolocation key
export const API_GOOGLE_KEY = process.env.REACT_APP_API_GOOGLE_KEY || '';
// reCAPTCHA site key
export const API_RECAPTCHA_KEY = process.env.REACT_APP_API_RECAPTCHA_KEY || '';

export const PASSWORD_MIN_LENGTH = 6;

export const ACCESS_TOKEN = process.env.REACT_APP_ACCESS_TOKEN || '';

export const GOOGLE_TRANSLATE_API_KEY =
  process.env.REACT_APP_API_GOOGLE_TRANSLATE_KEY || '';

export const COOKIE_OPTION_DEFAULT =
  process.env.NODE_ENV !== 'development'
    ? { path: '/', domain: '.wesmart.com' }
    : { path: '/' };

interface GlobalVariable {
  user?: UserState['userInfo'];
}

export const _global: GlobalVariable = {};
