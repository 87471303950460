import { FC, useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import cn from 'classnames';

import { useLoadingBars } from '../../../components/LoadingBar/LoadingBar';
import Button, { BUTTON_TYPE } from '../../../components/Button/Button';
import TextWithTick from '../../../components/TextWithTick/TextWithTick';
import { buildToastHTML } from '../../../components/ToastTemplate/ToastTemplate';

import { PASSWORD_MIN_LENGTH } from '../../../config/config';
import { signup } from '../../../apis/users';
import { getServerImageUrl, getStringByLanguage } from '../../../utils/utils';
import { useUrlTrack } from '../../../services/hooks';

import styles from './styles.module.scss';

const Signup: FC = () => {
  const [showLoadingBar, hideLoadingBar] = useLoadingBars();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const urlInfo = useUrlTrack();

  const [invitation, setInvitation] = useState<string | null>(null);

  useEffect(() => {
    if (!searchParams.has('invitation')) {
      navigate('/signin');
      return;
    }
    setInvitation(searchParams.get('invitation'));
  }, [searchParams]);

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .min(PASSWORD_MIN_LENGTH)
      .matches(/^(?=.*[a-z])(?=.*[A-Z]).+$/, {
        message: getStringByLanguage(
          'A_MIXTURE_OF_BOTH_UPPERCASE_AND_LOWERCASE_LETTERS',
        ),
      })
      .matches(/^(?=.*[\d])(?=.*[\D]).+$/, {
        message: getStringByLanguage('A_MIXTURE_OF_LETTERS_AND_NUMBERS'),
      })
      .matches(/^(?=.*[\W]).+$/, {
        message: getStringByLanguage(
          'INCLUSION_OF_AT_LEAST_ONE_SPECIAL_CHARACTER',
        ),
      })
      .required(getStringByLanguage('ENTER_PASSWORD')),
    confirm: Yup.string().oneOf(
      [Yup.ref('password'), null],
      getStringByLanguage('PASSWORD_MATCHED'),
    ),
  });

  const formik = useFormik({
    initialValues: {
      password: '',
      confirm: '',
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values) => {
      if (!invitation) {
        toast.error(
          buildToastHTML(
            getStringByLanguage(
              'YOU_CANNOT_REGISTER_YOURSELF_PLEASE_MAIL_TO_ADMIN_TO_SIGN_UP',
            ),
          ),
        );
        navigate('/');
        return;
      }
      showLoadingBar();
      try {
        const { password } = values;
        await signup({ invitation, password });
        toast.success(getStringByLanguage('SUCCESSFULLY_REGISTERED'));
        navigate('/signin');
      } catch (error) {
        console.log(error);
      }
      hideLoadingBar();
    },
  });

  const onSignup = () => {
    return formik.handleSubmit();
  };

  return (
    <FormikProvider value={formik}>
      <form noValidate onSubmit={formik.handleSubmit}>
        <div
          className={cn([styles['kut-component-body']])}
          style={{
            backgroundImage: `url('${getServerImageUrl(
              urlInfo.auth_background,
            )}')`,
          }}
        >
          <div className={cn([styles['kut-component-content']])}>
            <div className={cn([styles['kut-component-form']])}>
              <div className={cn([styles['kut-component-logo']])}>
                <img alt="WeSmart logo" src={getServerImageUrl(urlInfo.logo)} />
              </div>
              <p className={cn([styles['kut-component-title']])}>
                {getStringByLanguage(
                  'CREATE_A_PASSWORD_IN_ORDER_TO_PROTECT_YOUR_ACCOUNT_AND_BE_THE_ONLY_ONE_WHO_HAS_ACCESS_TO_IT',
                )}
              </p>
              <input
                className={`${styles['kut-component-password']}`}
                type="password"
                name="password"
                value={formik?.values?.password}
                onChange={formik.handleChange}
                placeholder={getStringByLanguage('CREATE_A_PASSWORD')}
              />
              <input
                className={`${styles['kut-component-confirm']}`}
                type="password"
                name="confirm"
                value={formik?.values?.confirm}
                onChange={formik.handleChange}
                placeholder={getStringByLanguage('REPEAT_PASSWORD')}
              />
              <TextWithTick
                status={
                  !!formik.values.password.match(
                    new RegExp(`^.{${PASSWORD_MIN_LENGTH},}$`),
                  )
                }
              >
                {`${getStringByLanguage(
                  'AT_LEAST',
                )} ${PASSWORD_MIN_LENGTH} ${getStringByLanguage(
                  '_CHARACTERS',
                )}`}
              </TextWithTick>
              <TextWithTick
                status={
                  !!formik.values.password.match(/^(?=.*[a-z])(?=.*[A-Z]).+$/)
                }
              >
                {getStringByLanguage(
                  'A_MIXTURE_OF_BOTH_UPPERCASE_AND_LOWERCASE_LETTERS',
                )}
              </TextWithTick>
              <TextWithTick
                status={
                  !!formik.values.password.match(/^(?=.*[\d])(?=.*[\D]).+$/)
                }
              >
                {getStringByLanguage('A_MIXTURE_OF_LETTERS_AND_NUMBERS')}
              </TextWithTick>
              <TextWithTick
                status={!!formik.values.password.match(/^(?=.*[\W]).+$/)}
              >
                {getStringByLanguage(
                  'INCLUSION_OF_AT_LEAST_ONE_SPECIAL_CHARACTER',
                )}
              </TextWithTick>
              <TextWithTick
                status={!!formik.values.confirm && !formik.errors.confirm}
              >
                {getStringByLanguage('PASSWORD_MATCHED')}
              </TextWithTick>
              <Button
                className={cn([styles['kut-component-button']])}
                type={BUTTON_TYPE.PRIMARY}
                onClick={onSignup}
              >
                Register now
              </Button>
              <p>
                Already have an account?&nbsp;
                <Link className={`${styles['kut-signin-button']}`} to="/signin">
                  Login here
                </Link>
              </p>
            </div>
            <div className={`${styles['kut-signup-footer']}`}>
              Developed by Wesmart © All rights reserved 2022
            </div>
          </div>
        </div>
      </form>
    </FormikProvider>
  );
};

export default Signup;
