import { FC, HTMLAttributes } from 'react';
import cn from 'classnames';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';

import styles from './styles.module.scss';

export interface NavbarOption<T> {
  label: string;
  value: T;
}

interface OptionalProps<T> {
  useArrow?: {
    onPrev: (value: T) => void;
    onNext: (value: T) => void;
    component: JSX.Element;
  };
}

interface FuncGroup<T>
  extends Omit<HTMLAttributes<HTMLDivElement>, 'onChange'>,
    OptionalProps<T> {
  value: T;
  options: NavbarOption<T>[];
  onChange: (value: T) => void;
}

export const GenericNavbar =
  <T,>(): FC<FuncGroup<T>> =>
  ({ className = '', value, options, onChange, useArrow, ...props }) => {
    return (
      <div className={cn([className, styles['kut-nav-container']])} {...props}>
        <div className={cn([styles['kut-nav-bar']])}>
          {options.map((option, i) => (
            <div
              key={i}
              className={cn([
                styles['kut-nav-item'],
                value === option.value ? styles['active'] : '',
              ])}
              onClick={() => onChange(option.value)}
            >
              {option.label}
            </div>
          ))}
        </div>
        {useArrow && (
          <div
            className={cn(['justify-content-center', styles['kut-nav-arrows']])}
          >
            <IoIosArrowBack onClick={() => useArrow.onPrev(value)} />
            {useArrow.component}
            <IoIosArrowForward onClick={() => useArrow.onNext(value)} />
          </div>
        )}
      </div>
    );
  };

export const DefaultNavbar = GenericNavbar<string | number>();
