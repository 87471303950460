import { DeviceMode, DeviceTypeInfo } from '../../apis/deviceTypes';
import { DeviceInfo } from '../../apis/devices';
import { ParticipantInfo } from '../../apis/participants';
import { AggregatedByDevice, StreamType, TimeRange } from '../../apis/streams';
import { UserInfo, UserRole } from '../../apis/users';
import { GenericNavbar } from '../../components/Navbar/Navbar';
import {
  getStringByLanguage,
  value2FlexibleUnit,
  value2KWH,
} from '../../utils/utils';

export const NavbarComponent = GenericNavbar<TimeRange>();

export type ListItemData =
  | (Pick<DeviceInfo, 'device_name' | 'deleted_at'> &
      Pick<DeviceTypeInfo, 'device_type_image'> &
      Partial<
        Pick<ParticipantInfo, 'participant_name'> & Pick<DeviceInfo, 'ean_code'>
      > & {
        value: number;
        unit: string;
      })
  | null;

export interface ListItemProps {
  data: ListItemData;
  mode: DeviceMode.Producer | DeviceMode.Consumer;
  reverse?: boolean;
  isDesktop: boolean;
}

export interface ListDataProps {
  Production: ListItemData;
  Consumption: ListItemData;
}

export interface AggregatedTotalProps {
  Production: {
    value: number;
    unit: string;
  };
  Consumption: {
    value: number;
    unit: string;
  };
}

export const getListAndValue = ({
  deviceList,
  userInfo,
  streams,
}: {
  deviceList: DeviceInfo[];
  userInfo: UserInfo;
  streams: AggregatedByDevice['buckets'];
}) => {
  const uKWH = getStringByLanguage('KWH');

  const aggValues: AggregatedTotalProps = {
    Production: {
      value: 0,
      unit: uKWH,
    },
    Consumption: {
      value: 0,
      unit: uKWH,
    },
  };

  const sharedvalues = {
    Production: 0,
    Consumption: 0,
  };
  const deviceDict: {
    [key: number]: ListDataProps;
  } = {};

  const listData: ListDataProps[] = [];
  const production: ListItemData[] = [];
  const consumption: ListItemData[] = [];

  deviceList.forEach(
    ({
      id,
      device_name,
      deviceType: { device_type_mode, device_type_image },
      ean_code,
      participant: { participant_name },
      deleted_at,
    }) => {
      deviceDict[id] = {
        Production: {
          participant_name,
          ean_code,
          device_name,
          device_type_image,
          unit: uKWH,
          value: 0,
          deleted_at,
        },
        Consumption: {
          participant_name,
          ean_code,
          device_name,
          device_type_image,
          unit: uKWH,
          value: 0,
          deleted_at,
        },
      };
      if (
        device_type_mode === DeviceMode.Producer ||
        device_type_mode === DeviceMode.Prosumer
      ) {
        production.push(deviceDict[id].Production);
      }
      if (
        device_type_mode === DeviceMode.Consumer ||
        device_type_mode === DeviceMode.Prosumer
      ) {
        consumption.push(deviceDict[id].Consumption);
      }
    },
  );

  streams.forEach((stream) => {
    const {
      key: { device: id },
      tag: { buckets: tagItems },
    } = stream;

    tagItems.forEach((tagItem) => {
      const {
        key: tag,
        type: { buckets: types },
      } = tagItem;

      types.forEach((typeItem) => {
        const {
          key: type,
          unit: { buckets: units },
        } = typeItem;

        let total = 0;

        units.forEach((unitItem) => {
          const {
            key: unit,
            total: { value },
          } = unitItem;

          const { value: kValue } = value2KWH({ value, unit });
          total += kValue;
        });

        if (type === StreamType.Total) {
          aggValues[tag].value += total;
          const itemData = deviceDict[id][tag];
          if (itemData) {
            itemData.value += total;
          }
        } else {
          sharedvalues[tag] += total;
        }
      });
    });
  });

  const _production = production.filter(
    (item) => !(item?.deleted_at && !item.value),
  );

  const _consumption = consumption.filter(
    (item) => !(item?.deleted_at && !item.value),
  );

  if (_production.length > _consumption.length) {
    for (let i = _production.length - _consumption.length; i > 0; i--) {
      _consumption.push(null);
    }
  } else {
    for (let i = _consumption.length - _production.length; i > 0; i--) {
      _production.push(null);
    }
  }

  listData.push(
    ..._production.map((item, i) => ({
      Production: item,
      Consumption: _consumption[i],
    })),
  );

  if (userInfo?.role === UserRole.Community_Manager) {
    const maxValue =
      aggValues.Production.value > aggValues.Consumption.value
        ? aggValues.Production.value
        : aggValues.Consumption.value;

    listData.push({
      Production: {
        device_name: 'Grid',
        device_type_image: 'uploads/Grid.svg',
        value: maxValue - aggValues.Production.value,
        unit: uKWH,
      },
      Consumption: {
        device_name: 'Grid',
        device_type_image: 'uploads/Grid.svg',
        value: maxValue - aggValues.Consumption.value,
        unit: uKWH,
      },
    });
    aggValues.Production.value = maxValue;
    aggValues.Consumption.value = maxValue;
  } else {
    listData.push(
      {
        Production: {
          device_name: 'Community Solar Panels',
          device_type_image: 'uploads/Solar Panels.svg',
          participant_name: 'Community',
          value: sharedvalues.Consumption,
          unit: uKWH,
        },
        Consumption: {
          device_name: 'Community Smart Meters',
          device_type_image: 'uploads/Smart Meter.svg',
          participant_name: 'Community',
          value: sharedvalues.Production,
          unit: uKWH,
        },
      },
      {
        Production: {
          device_name: 'Grid',
          device_type_image: 'uploads/Grid.svg',
          participant_name: 'Community',
          value: aggValues.Consumption.value - sharedvalues.Consumption,
          unit: uKWH,
        },
        Consumption: {
          device_name: 'Grid',
          device_type_image: 'uploads/Grid.svg',
          participant_name: 'Community',
          value: aggValues.Production.value - sharedvalues.Production,
          unit: uKWH,
        },
      },
    );
    aggValues.Production.value += sharedvalues.Consumption;
    aggValues.Consumption.value += sharedvalues.Production;
  }

  listData.forEach(({ Production, Consumption }) => {
    if (Production) {
      Object.assign(Production, value2FlexibleUnit(Production));
    }
    if (Consumption) {
      Object.assign(Consumption, value2FlexibleUnit(Consumption));
    }
  });

  const { Production, Consumption } = aggValues;
  Object.assign(Production, value2FlexibleUnit(Production));
  Object.assign(Consumption, value2FlexibleUnit(Consumption));

  return { listData, aggValues };
};
