import { FC, HTMLAttributes } from 'react';
import cn from 'classnames';

import styles from './styles.module.scss';

export enum BUTTON_TYPE {
  DEFAULT = 'Default',
  PRIMARY = 'Primary',
  SECONDARY = 'Secondary',
  SUCCESS = 'Success',
  WARNING = 'Warning',
  DANGER = 'Danger',
  INFO = 'Info',
}

const _default = {
  themes: {
    [BUTTON_TYPE.DEFAULT]: styles['kut-button-default'],
    [BUTTON_TYPE.PRIMARY]: styles['kut-button-primary'],
    [BUTTON_TYPE.SECONDARY]: styles['kut-button-secondary'],
    [BUTTON_TYPE.SUCCESS]: styles['kut-button-success'],
    [BUTTON_TYPE.WARNING]: styles['kut-button-warning'],
    [BUTTON_TYPE.DANGER]: styles['kut-button-danger'],
    [BUTTON_TYPE.INFO]: styles['kut-button-info'],
  },
  texts: {
    [BUTTON_TYPE.DEFAULT]: 'Default',
    [BUTTON_TYPE.PRIMARY]: 'Primary',
    [BUTTON_TYPE.SECONDARY]: 'Secondary',
    [BUTTON_TYPE.SUCCESS]: 'Success',
    [BUTTON_TYPE.WARNING]: 'Warning',
    [BUTTON_TYPE.DANGER]: 'Danger',
    [BUTTON_TYPE.INFO]: 'Info',
  },
};

interface FuncGroup extends HTMLAttributes<HTMLDivElement> {
  type?: BUTTON_TYPE;
  outline?: boolean;
}

const Button: FC<FuncGroup> = ({
  className = '',
  children,
  type = BUTTON_TYPE.PRIMARY,
  outline = false,
  ...props
}) => {
  children = children || _default.texts[type];

  return (
    <div
      className={cn([
        className,
        styles['kut-button-component'],
        _default.themes[type],
        outline && styles['kut-button-outline'],
      ])}
      {...props}
    >
      {children}
    </div>
  );
};

export default Button;
