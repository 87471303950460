import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../store';
import { PRICE_TYPE } from '../../pages/Community/Community.helper';

export interface CommunityState {
  selectedDetails: PRICE_TYPE;
}

const initialState: CommunityState = {
  selectedDetails: PRICE_TYPE.DETAILS_PRICE,
};

export const communitySlice = createSlice({
  name: 'community',
  initialState,
  reducers: {
    setSelectedDetails: (state, action: PayloadAction<PRICE_TYPE>) => {
      state.selectedDetails = action.payload;
    },
  },
});

export const { setSelectedDetails } = communitySlice.actions;

export const getSelectedDetails = (state: RootState) =>
  state.community.selectedDetails;

export default communitySlice.reducer;
