import { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import UserSelectPlanComponent from './UserSelectPlanComponent';
import UserProfileComponent from './UserProfileComponent';
import UserBillingPlanComponent from './UserBillingPlanComponent';
import UserNotificationComponent from './UserNotificationComponent';
import UserSecurityComponent from './UserSecurityComponent';
import UserLangAppComponent from './UserLangAppComponent';

import { defaultAvatars } from '../../utils/constants';
import { useDeviceType } from '../../services/hooks';
import { getUserInfo, UserInfo, UserRole } from '../../apis/users';
import { getServerImageUrl, getStringByLanguage } from '../../utils/utils';

import styles from './styles.module.scss';

export interface SubComponentsProps {
  userInfo?: UserInfo;
  updateUser2NewInfo: (nuserInfo: UserInfo) => void;
  navToSelectPlan?: () => void;
  navToBillingPlan?: () => void;
}

const Settings: FC = () => {
  const [userInfo, setUserInfo] = useState<UserInfo>();

  const { isMobile } = useDeviceType();
  const [navItemList, setNavItemList] = useState([
    { label: getStringByLanguage('PROFILE'), component: UserProfileComponent },
    {
      label: !isMobile
        ? getStringByLanguage('NOTIFICATION_SETTINGS')
        : getStringByLanguage('NOTIFICATIONS'),
      component: UserNotificationComponent,
    },
    {
      label: getStringByLanguage('SECURITY'),
      component: UserSecurityComponent,
    },
    {
      label: getStringByLanguage('LANGUAGE_AND_APPEARENCE'),
      component: UserLangAppComponent,
    },
  ]);

  const [selectedNav, setSelectedNav] = useState(0);

  useEffect(() => {
    getUserInfo()
      .then(({ data }) => {
        setUserInfo(data.data);
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .catch((error: any) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    setNavItemList([
      {
        label: getStringByLanguage('PROFILE'),
        component: UserProfileComponent,
      },
      ...(userInfo?.role === UserRole.Community_Manager
        ? [
            {
              label: getStringByLanguage('BILLING_PLAN'),
              component: UserBillingPlanComponent,
            },
            {
              label: !isMobile
                ? getStringByLanguage('NOTIFICATION_SETTINGS')
                : getStringByLanguage('NOTIFICATIONS'),
              component: UserNotificationComponent,
            },
          ]
        : []),
      {
        label: getStringByLanguage('SECURITY'),
        component: UserSecurityComponent,
      },
      {
        label: getStringByLanguage('LANGUAGE_AND_APPEARENCE'),
        component: UserLangAppComponent,
      },
    ]);
  }, [userInfo]);

  const navToSelectPlan = () => {
    setSelectedNav(navItemList.length);
  };

  const navToBillingPlan = () => {
    setSelectedNav(1);
  };

  const updateUser2NewInfo = (nuserInfo: UserInfo) => {
    setUserInfo({ ...userInfo, ...nuserInfo });
  };

  if (!userInfo) {
    return <></>;
  }

  if (selectedNav === navItemList.length) {
    return (
      <div className={styles['kut-component-body']}>
        <UserSelectPlanComponent
          userInfo={userInfo}
          updateUser2NewInfo={updateUser2NewInfo}
          navToBillingPlan={navToBillingPlan}
        />
      </div>
    );
  }

  const SelectedComponent = navItemList[selectedNav].component;
  return (
    <div className={styles['kut-component-body']}>
      <div className={styles['kut-component-header']} />
      <div className={styles['kut-component-content']}>
        <div className={styles['kut-user-avatar-div']}>
          <div
            className={styles['kut-user-avatar-img']}
            style={{
              backgroundImage: `url(${
                (userInfo.avatar && getServerImageUrl(userInfo.avatar)) ||
                defaultAvatars[userInfo.gender] ||
                defaultAvatars['Male']
              })`,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
            }}
          />
          <div className={styles['kut-user-name-email']}>
            <p className={styles['kut-user-name']}>
              {userInfo.first_name} {userInfo.last_name}
            </p>
            <p className={styles['kut-user-email']}>{userInfo.email}</p>
          </div>
          <Link className={styles['kut-user-public-profile']} to="#">
            {getStringByLanguage('VIEW_MY_PUBLIC_PROFILE')}
          </Link>
        </div>
        <div className={styles['kut-nav-bar']}>
          {navItemList.map((v, i) => (
            <div
              key={i}
              className={
                selectedNav === i
                  ? styles['active']
                  : selectedNav + 1 === i
                  ? styles['active-right']
                  : ''
              }
              onClick={() => setSelectedNav(i)}
            >
              <span>{v.label}</span>
            </div>
          ))}
          <div className={styles['nav-last-item']} />
        </div>
        <SelectedComponent
          userInfo={userInfo}
          updateUser2NewInfo={updateUser2NewInfo}
          navToSelectPlan={navToSelectPlan}
        />
      </div>
    </div>
  );
};

export default Settings;
