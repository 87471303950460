import { ChartTooltipMemberProps } from '../../components/ChartTooltip/ChartTooltip';
import { GenericNavbar } from '../../components/Navbar/Navbar';

import { ParticipantInfo } from '../../apis/participants';
import {
  AggregatedByTimeline,
  GroupByParticipant,
  TimeRange,
} from '../../apis/streams';
import {
  getStringByLanguage,
  value2FlexibleRounded,
  value2KWH,
} from '../../utils/utils';

export interface ChartDataObject {
  timestamp: number;
  members: ChartTooltipMemberProps[];
  value: number;
}

export interface ChartObjectProps {
  Total: ChartDataObject[];
  Shared: ChartDataObject[];
  Individual: ChartDataObject[];
}

export interface ChartDataProps {
  unit: string;
  Production: ChartObjectProps;
  Consumption: ChartObjectProps;
}

export type AggregatedByParticipant = Pick<
  ParticipantInfo,
  'id' | 'participant_name'
> & {
  value: number;
  unit: string;
};

export const NavbarComponent = GenericNavbar<TimeRange>();

export const gainPrice = {
  value: 0.09,
  unit: getStringByLanguage('KWH'),
};

export const communityPrice = {
  value: 0.25,
  unit: getStringByLanguage('KWH'),
};

export const getChartLists = (
  streams: AggregatedByTimeline<GroupByParticipant>['buckets'],
) => {
  const uKWH = getStringByLanguage('KWH');
  const uMWH = getStringByLanguage('MWH');
  const uGWH = getStringByLanguage('GWH');

  const tags = ['Production', 'Consumption'] as const;
  const types = ['Total', 'Shared', 'Individual'] as const;

  const chartLists: ChartDataProps = {
    unit: uKWH,
    Production: { Total: [], Shared: [], Individual: [] },
    Consumption: { Total: [], Shared: [], Individual: [] },
  };

  let maxAmount = 0;

  streams.forEach((stream) => {
    const {
      key: { timeline: timestamp },
      tag: { buckets: tagItems },
    } = stream;

    tagItems.forEach((tagItem) => {
      const {
        key: tag,
        type: { buckets: types },
      } = tagItem;

      types.forEach((typeItem) => {
        const {
          key: type,
          participant: { buckets: participants },
        } = typeItem;

        const data: ChartDataObject = {
          timestamp,
          members: [],
          value: 0,
        };

        participants.forEach((participantItem) => {
          const {
            key: id,
            unit: { buckets: units },
          } = participantItem;

          units.forEach((unitItem) => {
            const {
              key: unit,
              total: { value },
            } = unitItem;

            const { value: kValue } = value2KWH({ value, unit });

            data.members.push({
              id,
              value: kValue,
            });

            data.value += kValue;
          });
        });

        if (data.value > maxAmount) {
          maxAmount = data.value;
        }

        chartLists[tag][type].push(data);
      });
    });
  });

  // To sum individual and grid consumption and production
  const totalDict: {
    Production: {
      [key: number | string]: Omit<ChartDataObject, 'timestamp'>;
    };
    Consumption: {
      [key: number | string]: Omit<ChartDataObject, 'timestamp'>;
    };
  } = { Production: {}, Consumption: {} };

  tags.forEach((tag) => {
    chartLists[tag].Total.forEach(({ timestamp, ...props }) => {
      totalDict[tag][timestamp] = props;
    });
  });

  chartLists.Consumption.Individual.forEach(({ timestamp, ...props }) => {
    const { value, members } = props;

    tags.forEach((tag) => {
      if (!totalDict[tag][timestamp]) {
        totalDict[tag][timestamp] = props;
        return;
      }

      const totalMembers = totalDict[tag][timestamp].members;
      const membersDict: {
        [key: string]: number;
      } = {};

      totalMembers.forEach(({ id, value }) => {
        membersDict[id] = value;
      });

      members.forEach(({ id, value }) => {
        if (!membersDict[id]) {
          membersDict[id] = 0;
        }
        membersDict[id] += value;
      });

      const memberKeys = Object.keys(membersDict);
      const memberValues = Object.values(membersDict);

      totalDict[tag][timestamp].value += value;
      totalDict[tag][timestamp].members = memberKeys.map((id, index) => ({
        id,
        value: memberValues[index],
      }));
    });
  });

  tags.forEach((tag) => {
    const totalKeys = Object.keys(totalDict[tag]);
    const totalValues = Object.values(totalDict[tag]);
    chartLists[tag].Total = totalKeys.map((timestamp, index) => ({
      timestamp: parseInt(timestamp),
      ...totalValues[index],
    }));
  });

  // To process value to matched unit.
  const divide = maxAmount >= 1000000 ? 1000000 : maxAmount >= 1000 ? 1000 : 1;
  const unit = maxAmount >= 1000000 ? uGWH : maxAmount >= 1000 ? uMWH : uKWH;

  if (unit !== uKWH) {
    chartLists.unit = unit;

    tags.forEach((tag) => {
      types.forEach((type) => {
        chartLists[tag][type].forEach((data) => {
          const { value, members } = data;
          data.members = members.map(({ id, value }) => ({
            id,
            value: value2FlexibleRounded(value / divide),
          }));
          data.value = value2FlexibleRounded(value / divide);
        });
      });
    });
  }

  return chartLists;
};
