import React, { FC, HTMLAttributes } from 'react';
import cn from 'classnames';
import { BsSearch } from 'react-icons/bs';
import { IconType } from 'react-icons';

import { getStringByLanguage } from '../../utils/utils';

import styles from './style.module.scss';

interface FuncGroup extends HTMLAttributes<HTMLInputElement> {
  IconComponent?: IconType;
  value: string;
}

const SearchInput: FC<FuncGroup> = ({
  className,
  onChange,
  value,
  IconComponent,
  placeholder,
}) => {
  IconComponent = IconComponent || BsSearch;

  return (
    <div className={cn([className, styles['search-container']])}>
      <IconComponent className={cn([styles['search-icon']])} />
      <input
        type="text"
        onChange={onChange}
        value={value}
        placeholder={placeholder || getStringByLanguage('SEARCH_HERE')}
      />
    </div>
  );
};

export default SearchInput;
