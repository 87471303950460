import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import cn from 'classnames';

import Button, { BUTTON_TYPE } from '../../../components/Button/Button';

import { useUrlTrack } from '../../../services/hooks';
import { getServerImageUrl, getStringByLanguage } from '../../../utils/utils';

import styles from './styles.module.scss';

const Intro: FC = () => {
  const navigate = useNavigate();
  const urlInfo = useUrlTrack();

  return (
    <>
      <div
        className={cn([styles['kut-intro-body']])}
        style={{
          backgroundImage: `url('${getServerImageUrl(
            urlInfo.auth_background,
          )}')`,
        }}
      >
        <img
          className={cn([styles['kut-intro-logo']])}
          src={getServerImageUrl(urlInfo.logo)}
        />
        <Button
          className={cn([styles['kut-intro-button']])}
          onClick={() => navigate('/signin')}
          type={BUTTON_TYPE.PRIMARY}
        >
          {getStringByLanguage('SIGNIN')}
        </Button>
        <div className={cn([styles['kut-intro-footer']])}>
          {urlInfo.description}
        </div>
      </div>
    </>
  );
};

export default Intro;
