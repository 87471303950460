import { BsGrid } from 'react-icons/bs';
import { AiOutlineUnorderedList } from 'react-icons/ai';

import { SwitchItem } from '../../../components/SwitchView/SwitchView';

import { getStringByLanguage } from '../../../utils/utils';
import { ParticipantInfo } from '../../../apis/participants';

export const fieldDict: {
  [key: string]: keyof ParticipantInfo;
} = {
  [getStringByLanguage('NAME')]: 'participant_name',
  [getStringByLanguage('JOINED_ON')]: 'joined',
};

export const sortFieldList: (keyof ParticipantInfo)[] =
  Object.values(fieldDict);
export const sortStringList: string[] = Object.keys(fieldDict);

export const switchItems: SwitchItem[] = [
  {
    IconComponent: BsGrid,
  },
  {
    IconComponent: AiOutlineUnorderedList,
  },
];
