import { FC } from 'react';
import cn from 'classnames';
import PhoneNumberInput, { PhoneInputProps } from 'react-phone-input-2';

import 'react-phone-input-2/lib/material.css';
import styles from './styles.module.scss';

interface FuncGroup extends PhoneInputProps {
  className?: string;
  heading: string;
  required?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error?: any;
}

const PhoneInput: FC<FuncGroup> = ({
  className,
  heading,
  required,
  error,
  ...props
}) => {
  return (
    <div
      className={cn([
        className,
        styles['kut-phone-input-form'],
        required && styles['required'],
      ])}
    >
      <div className={cn([styles['kut-phone-input-content']])}>
        <p className={`${styles['kut-heading']}`}>{heading}</p>
        <PhoneNumberInput
          enableSearch
          inputProps={{
            name: 'phone',
            required,
          }}
          specialLabel=""
          preferredCountries={['fr', 'es', 'be']}
          isValid={!error}
          {...props}
        />
      </div>
      {error && <p className={`${styles['kut-error']}`}>{error}</p>}
    </div>
  );
};

export default PhoneInput;
