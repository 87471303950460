import {
  ChangeEventHandler,
  FC,
  MouseEventHandler,
  useEffect,
  useState,
} from 'react';
import cn from 'classnames';
import Select from 'react-select';
import SunCalc from 'suncalc';
import { useFormik } from 'formik';
import * as DateFNS from 'date-fns';
import * as Yup from 'yup';

import { SubComponentsProps } from './Settings';
import Button, { BUTTON_TYPE } from '../../components/Button/Button';
import IOSSwitch from '../../components/IOSSwitch/IOSSwitch';

import {
  colorDict,
  getServerImageUrl,
  getStringByLanguage,
} from '../../utils/utils';
import { MODE_DARK, MODE_LIGHT } from '../../utils/constants';
import { useAppDispatch, useAppSelector } from '../../services/hooks';
import {
  getLocationGPS,
  getViewAsyncMode,
  getViewMode,
  Mode,
  setViewMode,
} from '../../services/reducers/sharedReducer';
import { useLoadingBars } from '../../components/LoadingBar/LoadingBar';
import {
  LanguageItem,
  UserRole,
  languageOptions,
  updateUserInfo,
} from '../../apis/users';
import { setViewAsyncMode } from '../../services/reducers/sharedReducer';
import { userSignin } from '../../services/reducers/userReducer';

import themeDarkImg from '../../assets/images/theme-dark.png';
import themeLightImg from '../../assets/images/theme-light.png';

import styles from './styles.module.scss';

interface LeftTime {
  hour: number;
  minute: number;
}

const UserLangAppComponent: FC<
  Omit<SubComponentsProps, 'userInfo'> &
    Required<Pick<SubComponentsProps, 'userInfo' | 'updateUser2NewInfo'>>
> = ({ userInfo, updateUser2NewInfo }) => {
  const [showLoadingBar, hideLoadingBar] = useLoadingBars();
  const dispatch = useAppDispatch();
  const asyncMode = useAppSelector(getViewAsyncMode);
  const mode = useAppSelector(getViewMode);
  const [logoImagePreview, setLogoImagePreview] = useState<string | null>(null);
  const [logoImage, setLogoImage] = useState<File | null>(null);
  const [currentTime, setCurrentTime] = useState(new Date());

  const location = useAppSelector(getLocationGPS);

  const inputFormSchema = Yup.object().shape({
    language: Yup.number().required(
      getStringByLanguage('THIS_FIELD_IS_REQUIRED'),
    ),
  });

  const formik = useFormik({
    initialValues: {
      language: userInfo.language,
    },
    enableReinitialize: true,
    validationSchema: inputFormSchema,
    onSubmit: async (values) => {
      showLoadingBar();
      try {
        const { data } = await updateUserInfo({ ...values, logoImage });
        if (!data.data) {
          return;
        }
        updateUser2NewInfo(data.data);
        dispatch(userSignin(data.data));
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        console.log(error);
      }
      hideLoadingBar();
    },
  });

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 60000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const calcLeftTime = () => {
    const sunTime = SunCalc.getTimes(
      currentTime,
      location.latitude,
      location.longitude,
    );
    if (DateFNS.compareAsc(currentTime, sunTime.sunriseEnd) === -1) {
      if (mode === MODE_LIGHT) {
        handleThemeMode(MODE_DARK);
      }

      const dif = DateFNS.differenceInMinutes(sunTime.sunriseEnd, currentTime);

      return { hour: Math.floor(dif / 60), minute: dif % 60 };
    }

    if (DateFNS.compareAsc(currentTime, sunTime.night) === 1) {
      if (mode === MODE_LIGHT) {
        handleThemeMode(MODE_DARK);
      }

      const dif =
        24 * 60 + DateFNS.differenceInMinutes(sunTime.sunriseEnd, currentTime);

      return { hour: Math.floor(dif / 60), minute: dif % 60 };
    }

    if (mode === MODE_DARK) {
      handleThemeMode(MODE_LIGHT);
    }

    const dif = DateFNS.differenceInMinutes(sunTime.night, currentTime);

    return { hour: Math.floor(dif / 60), minute: dif % 60 };
  };

  const handleLogoImage: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (!e.target.files || !e.target.files.length) {
      return;
    }

    const file = e.target.files[0];
    if (!file.type.startsWith('image/')) {
      return;
    }

    showLoadingBar();
    const fr = new FileReader();
    fr.onload = (ev) => {
      hideLoadingBar();

      if (!ev.target || ev.target.result instanceof ArrayBuffer) {
        return;
      }
      setLogoImage(file);
      setLogoImagePreview(ev.target.result);
    };

    fr.readAsDataURL(file);
  };

  const handleRemoveLogoImage: MouseEventHandler<HTMLSpanElement> = () => {
    setLogoImage(null);
    setLogoImagePreview(null);
  };

  const handleAsyncTheme: ChangeEventHandler<HTMLInputElement> = (e) => {
    dispatch(setViewAsyncMode(e.target.checked));
  };

  const handleThemeMode = (theme: Mode) => {
    dispatch(setViewMode(theme));
  };

  const handleSubmit = () => {
    formik.handleSubmit();
  };

  const handleLanguageChange = (newValue: LanguageItem | null) => {
    if (newValue) formik.setFieldValue('language', newValue.value);
  };

  const [leftTime, setLeftTime] = useState<LeftTime>({ hour: 0, minute: 0 });

  useEffect(() => {
    setLeftTime(asyncMode ? calcLeftTime() : { hour: 0, minute: 0 });
  }, [asyncMode, currentTime]);

  return (
    <div className={styles['kut-user-langapp']}>
      <div className={styles['kut-langapp-config']}>
        <div
          className={cn([
            styles['kut-input-form'],
            styles['kut-langapp-config-lang'],
          ])}
        >
          <p className={styles['kut-heading']}>
            {getStringByLanguage('IN_APP_LANGUAGE')}
          </p>
          <Select
            className={styles['kut-content']}
            options={languageOptions}
            name={`language`}
            onChange={handleLanguageChange}
            value={languageOptions.find(
              (value) => value.value === formik.values.language,
            )}
            styles={{
              control: (baseStyle) => ({
                ...baseStyle,
                boxShadow: 'none',
                border: 'none',
                backgroundColor: 'transparent',
              }),
              singleValue: (baseStyle) => ({
                ...baseStyle,
                color: 'inherit',
              }),
              indicatorSeparator: (baseStyle) => ({
                ...baseStyle,
                width: 0,
              }),
              dropdownIndicator: (baseStyle) => ({
                ...baseStyle,
                padding: '0px',
                marginRight: '0.5em',
                color: colorDict['color-black-strong-light'],
              }),
              menu: (baseStyle) => ({
                ...baseStyle,
                backgroundColor: 'inherit',
              }),
              option: (baseStyle, state) => {
                return {
                  ...baseStyle,
                  backgroundColor: state.isSelected
                    ? 'rgb(var(--text-color), .7)'
                    : state.isFocused
                    ? 'rgba(var(--text-color), .3)'
                    : 'inherit',
                  color: state.isSelected
                    ? 'rgb(var(--content-background-color))'
                    : 'inherit',
                  transition: 'all .3s',
                };
              },
            }}
          />
        </div>
        <div className={styles['kut-langapp-config-logo']}>
          <p className={styles['kut-heading']}>
            {getStringByLanguage('APP_BRANDING')}
          </p>
          <div className={styles['kut-langapp-config-logo-div']}>
            <img
              src={
                logoImagePreview ||
                (userInfo.logo_url && getServerImageUrl(userInfo.logo_url)) ||
                getServerImageUrl('uploads/domain/logo-default.png')
              }
              alt="Logo"
            />
            {userInfo.role === UserRole.Community_Manager && (
              <div className={styles['kut-langapp-config-logo-actions']}>
                <div
                  className={styles['kut-langapp-config-logo-action-buttons']}
                >
                  <label
                    className={cn([styles['kut-link'], 'kut-color-primary'])}
                  >
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleLogoImage}
                      hidden
                    />
                    {getStringByLanguage('CHANGE_LOGO')}
                  </label>
                  <span
                    className={cn([styles['kut-link'], 'kut-color-primary'])}
                    onClick={handleRemoveLogoImage}
                  >
                    {getStringByLanguage('REMOVE_LOGO')}
                  </span>
                </div>
                <p className={styles['kut-langapp-config-logo-description']}>
                  {getStringByLanguage('PERSONALISED_DASHBOARDS_AND_BRANDING')}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={styles['kut-langapp-theme']}>
        <p className={styles['kut-heading']}>{getStringByLanguage('THEME')}</p>
        <div className={styles['kut-langapp-theme-content']}>
          <div
            className={cn([
              styles['kut-langapp-theme-light'],
              mode === MODE_LIGHT && styles['active'],
            ])}
            onClick={() => {
              handleThemeMode('light');
            }}
          >
            <p>
              {getStringByLanguage('LIGHT')}
              {mode === MODE_LIGHT && ' (active)'}
            </p>
            <div>
              <img src={themeLightImg} alt={getStringByLanguage('LIGHT')} />
            </div>
          </div>
          <div
            className={cn([
              styles['kut-langapp-theme-dark'],
              mode === MODE_DARK && styles['active'],
            ])}
            onClick={() => {
              handleThemeMode('dark');
            }}
          >
            <p>
              {getStringByLanguage('DARK')}
              {mode === MODE_DARK && ' (active)'}
            </p>
            <div>
              <img src={themeDarkImg} alt={getStringByLanguage('DARK')} />
            </div>
          </div>
        </div>
      </div>
      <div className={styles['kut-langapp-automation']}>
        <IOSSwitch
          type="primary"
          onChange={handleAsyncTheme}
          checked={asyncMode}
          sx={{ m: 1, width: 28, height: 17, margin: 0 }}
        />
        <p className={styles['kut-langapp-title']}>
          {getStringByLanguage('SYNC_WITH_SYSTEM')}
        </p>
        <p className={styles['kut-langapp-content']}>
          {getStringByLanguage(
            'APP_APPEARENCE_CHANGES_WITH_THE_OPERATING_SYSTEM_BROWSER',
          )}
        </p>
      </div>
      <hr />
      <div className={styles['kut-bottom-form']}>
        <p className={cn([!asyncMode && 'kut-hidden'])}>
          {mode === 'dark'
            ? getStringByLanguage('THE_APP_WITH_GO_TO_LIGHT_MODE_IN_ABOUT')
            : getStringByLanguage('THE_APP_WITH_GO_TO_DARK_MODE_IN_ABOUT')}
          <span>
            {' '}
            {leftTime.hour} {getStringByLanguage('HOURS')}{' '}
            {getStringByLanguage('AND')} {leftTime.minute}{' '}
            {getStringByLanguage('MINUTES')}
          </span>
        </p>
        <div className={styles['kut-bottom-actions']}>
          <Button type={BUTTON_TYPE.DEFAULT} outline={true}>
            {getStringByLanguage('CANCEL')}
          </Button>
          <Button type={BUTTON_TYPE.PRIMARY} onClick={handleSubmit}>
            {getStringByLanguage('SAVE_UPDATES')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default UserLangAppComponent;
