import React, { FC, HTMLAttributes } from 'react';
import cn from 'classnames';
import { IconType } from 'react-icons';

import { getStringByLanguage } from '../../utils/utils';

import styles from './style.module.scss';

export interface SwitchItem {
  IconComponent: IconType;
  tooltip?: string;
}

interface FuncGroup extends HTMLAttributes<HTMLDivElement> {
  items: SwitchItem[];
  selected?: number;
  onViewChange?: (index: number, item: SwitchItem) => void;
}

const SwitchView: FC<FuncGroup> = ({
  items,
  selected,
  onViewChange,
  className,
  ...props
}) => {
  return (
    <div className={cn([className, styles['kut-switch-view']])} {...props}>
      <span>{getStringByLanguage('SWITCH_VIEW')}</span>
      {items.map((item, i) => {
        const { IconComponent } = item;
        return (
          <IconComponent
            key={i}
            className={cn([i === selected ? styles['kut-active'] : ''])}
            onClick={() => onViewChange && onViewChange(i, item)}
          />
        );
      })}
    </div>
  );
};

export default SwitchView;
